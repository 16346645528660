import { message } from 'antd';
import actions from './actions';
import api from '../../config/api/index';

const messageKey = 'notifications';

const { notificationsListUpdateBegin, notificationsListUpdateSuccess, notificationsListUpdateErr } = actions;

const notificationsListUpdateAction = () => {
  return async dispatch => {
    dispatch(notificationsListUpdateBegin());

    try {
      api.Notifications.list().then(response => {
        // handle success
        if (response.data.notifications !== undefined) {
          const { notifications, count } = response.data;
          dispatch(notificationsListUpdateSuccess({ data: notifications, count }));
        } else {
          dispatch(notificationsListUpdateErr(response.data.message));
          message.error(response.data.message);
        }
      });
    } catch (err) {
      dispatch(notificationsListUpdateErr(err));
      message.error(err.toString());
    }
  };
};

const { notificationsListCleanupBegin, notificationsListCleanupSuccess, notificationsListCleanupErr } = actions;

const notificationsListCleanupAction = () => {
  return async dispatch => {
    dispatch(notificationsListCleanupBegin());
    try {
      dispatch(notificationsListCleanupSuccess());
    } catch (err) {
      dispatch(notificationsListCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const {
  notificationsPaginationUpdateBegin,
  notificationsPaginationUpdateSuccess,
  notificationsPaginationUpdateErr,
} = actions;

const notificationsPaginationUpdateAction = data => {
  return async dispatch => {
    dispatch(notificationsPaginationUpdateBegin());
    try {
      dispatch(notificationsPaginationUpdateSuccess(data));
    } catch (err) {
      dispatch(notificationsPaginationUpdateErr(err.toString()));
    }
  };
};

const { notificationsFiltersUpdateBegin, notificationsFiltersUpdateSuccess, notificationsFiltersUpdateErr } = actions;

const notificationsFiltersUpdateAction = data => {
  return async dispatch => {
    dispatch(notificationsFiltersUpdateBegin());
    try {
      dispatch(notificationsFiltersUpdateSuccess(data));
    } catch (err) {
      dispatch(notificationsFiltersUpdateErr(err.toString()));
    }
  };
};

const { notificationsSorterUpdateBegin, notificationsSorterUpdateSuccess, notificationsSorterUpdateErr } = actions;

const notificationsSorterUpdateAction = data => {
  return async dispatch => {
    dispatch(notificationsSorterUpdateBegin());
    try {
      dispatch(notificationsSorterUpdateSuccess(data));
    } catch (err) {
      dispatch(notificationsSorterUpdateErr(err.toString()));
    }
  };
};

const { notificationsUpdateBegin, notificationsUpdateSuccess, notificationsUpdateErr } = actions;

const notificationsUpdateAction = ({ pagination = undefined, filters = undefined, sorter = undefined } = {}) => {
  return async (dispatch, getState) => {
    dispatch(notificationsUpdateBegin());

    const { notifications } = getState();
    const currentPagination = notifications.pagination;
    const currentFilters = notifications.filters;
    const currentSorter = notifications.sorter;

    pagination = pagination ?? currentPagination;
    filters = filters ?? currentFilters;
    sorter = sorter ?? currentSorter;

    // pagination
    if (JSON.stringify(pagination) !== JSON.stringify(currentPagination)) {
      dispatch(notificationsPaginationUpdateAction(pagination));
    }

    // filters
    if (JSON.stringify(filters) !== JSON.stringify(currentFilters)) {
      dispatch(notificationsFiltersUpdateAction(filters));
    }

    // sorter
    if (JSON.stringify(sorter) !== JSON.stringify(currentSorter)) {
      dispatch(notificationsSorterUpdateAction(sorter));
    }

    const params = {
      page: pagination.current,
      limit: pagination.pageSize,
      filters: filters ?? null,
      sort: sorter?.field ?? null,
      direction: sorter?.order ?? null,
    };

    api.Notifications.index(params)
      .then(response => {
        if (response.status === 200) {
          const { notifications, count } = response.data;
          dispatch(notificationsUpdateSuccess({ data: notifications, count }));
          dispatch(notificationsPaginationUpdateAction(response.data.pagination));
        } else {
          dispatch(notificationsUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(notificationsUpdateErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { notificationsReadBegin, notificationsReadSuccess, notificationsReadErr } = actions;

const notificationsReadAction = ({ data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(notificationsReadBegin());
    const plural = data?.notifications?.length > 1 ? 'ões' : 'ão';
    message.loading({ content: `Marcando notificaç${plural} como lida`, duration: 0, key: messageKey });
    api.Notifications.read(data)
      .then(response => {
        if (response.status === 200) {
          dispatch(notificationsReadSuccess());
          message.success({
            content: `Notificaç${plural} marcada${plural === 'ões' ? 's' : ''} como lida`,
            key: messageKey,
          });
          onSuccess(response);
        } else {
          dispatch(notificationsReadErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(notificationsReadErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { notificationsCleanupBegin, notificationsCleanupSuccess, notificationsCleanupErr } = actions;

const notificationsCleanupAction = () => {
  return async dispatch => {
    dispatch(notificationsCleanupBegin());
    try {
      dispatch(notificationsCleanupSuccess());
    } catch (err) {
      dispatch(notificationsCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { notificationUpdateBegin, notificationUpdateSuccess, notificationUpdateErr } = actions;

const notificationUpdateAction = ({ id = undefined, data = undefined, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(notificationUpdateBegin());
    if (data !== undefined) {
      dispatch(notificationUpdateSuccess(data));
    } else if (id !== undefined) {
      api.Notifications.get(id)
        .then(response => {
          if (response.status === 200) {
            const { notification } = response.data;
            dispatch(notificationUpdateSuccess(notification));
            onSuccess(notification);
          } else if (response.data && response.data.message) {
            dispatch(notificationUpdateErr(response.data.message));
            message.error({ content: response.data.message, key: messageKey });
          }
        })
        .catch(err => {
          dispatch(notificationUpdateErr(err.toString()));
          message.error({ content: err.toString(), key: messageKey });
        });
    }
  };
};

const { notificationCleanupBegin, notificationCleanupSuccess, notificationCleanupErr } = actions;

const notificationCleanupAction = () => {
  return async dispatch => {
    dispatch(notificationCleanupBegin());
    try {
      dispatch(notificationCleanupSuccess());
    } catch (err) {
      dispatch(notificationCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

export {
  notificationsListUpdateAction,
  notificationsListCleanupAction,
  notificationsUpdateAction,
  notificationsReadAction,
  notificationsCleanupAction,
  notificationUpdateAction,
  notificationCleanupAction,
};
