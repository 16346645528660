const actions = {
  ACCESSES_UPDATE_BEGIN: 'ACCESSES_UPDATE_BEGIN',
  ACCESSES_UPDATE_SUCCESS: 'ACCESSES_UPDATE_SUCCESS',
  ACCESSES_UPDATE_ERR: 'ACCESSES_UPDATE_ERR',

  accessesUpdateBegin: () => {
    return {
      type: actions.ACCESSES_UPDATE_BEGIN,
    };
  },

  accessesUpdateSuccess: data => {
    return {
      type: actions.ACCESSES_UPDATE_SUCCESS,
      data,
    };
  },

  accessesUpdateErr: err => {
    return {
      type: actions.ACCESSES_UPDATE_ERR,
      err,
    };
  },

  ACCESSES_CLEANUP_BEGIN: 'ACCESSES_CLEANUP_BEGIN',
  ACCESSES_CLEANUP_SUCCESS: 'ACCESSES_CLEANUP_SUCCESS',
  ACCESSES_CLEANUP_ERR: 'ACCESSES_CLEANUP_ERR',

  accessesCleanupBegin: () => {
    return {
      type: actions.ACCESSES_CLEANUP_BEGIN,
    };
  },

  accessesCleanupSuccess: () => {
    return {
      type: actions.ACCESSES_CLEANUP_SUCCESS,
    };
  },

  accessesCleanupErr: err => {
    return {
      type: actions.ACCESSES_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
