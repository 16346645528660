const actions = {
  FILES_PAGINATION_UPDATE_BEGIN: 'FILES_PAGINATION_UPDATE_BEGIN',
  FILES_PAGINATION_UPDATE_SUCCESS: 'FILES_PAGINATION_UPDATE_SUCCESS',
  FILES_PAGINATION_UPDATE_ERR: 'FILES_PAGINATION_UPDATE_ERR',

  filesPaginationUpdateBegin: () => {
    return {
      type: actions.FILES_PAGINATION_UPDATE_BEGIN,
    };
  },

  filesPaginationUpdateSuccess: data => {
    return {
      type: actions.FILES_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  filesPaginationUpdateErr: err => {
    return {
      type: actions.FILES_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  FILES_FILTERS_UPDATE_BEGIN: 'FILES_FILTERS_UPDATE_BEGIN',
  FILES_FILTERS_UPDATE_SUCCESS: 'FILES_FILTERS_UPDATE_SUCCESS',
  FILES_FILTERS_UPDATE_ERR: 'FILES_FILTERS_UPDATE_ERR',

  filesFiltersUpdateBegin: () => {
    return {
      type: actions.FILES_FILTERS_UPDATE_BEGIN,
    };
  },

  filesFiltersUpdateSuccess: data => {
    return {
      type: actions.FILES_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  filesFiltersUpdateErr: err => {
    return {
      type: actions.FILES_FILTERS_UPDATE_ERR,
      err,
    };
  },

  FILES_SORTER_UPDATE_BEGIN: 'FILES_SORTER_UPDATE_BEGIN',
  FILES_SORTER_UPDATE_SUCCESS: 'FILES_SORTER_UPDATE_SUCCESS',
  FILES_SORTER_UPDATE_ERR: 'FILES_SORTER_UPDATE_ERR',

  filesSorterUpdateBegin: () => {
    return {
      type: actions.FILES_SORTER_UPDATE_BEGIN,
    };
  },

  filesSorterUpdateSuccess: data => {
    return {
      type: actions.FILES_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  filesSorterUpdateErr: err => {
    return {
      type: actions.FILES_SORTER_UPDATE_ERR,
      err,
    };
  },

  FILES_UPDATE_BEGIN: 'FILES_UPDATE_BEGIN',
  FILES_UPDATE_SUCCESS: 'FILES_UPDATE_SUCCESS',
  FILES_UPDATE_ERR: 'FILES_UPDATE_ERR',

  filesUpdateBegin: () => {
    return {
      type: actions.FILES_UPDATE_BEGIN,
    };
  },

  filesUpdateSuccess: data => {
    return {
      type: actions.FILES_UPDATE_SUCCESS,
      data,
    };
  },

  filesUpdateErr: err => {
    return {
      type: actions.FILES_UPDATE_ERR,
      err,
    };
  },

  FILES_CLEANUP_BEGIN: 'FILES_CLEANUP_BEGIN',
  FILES_CLEANUP_SUCCESS: 'FILES_CLEANUP_SUCCESS',
  FILES_CLEANUP_ERR: 'FILES_CLEANUP_ERR',

  filesCleanupBegin: () => {
    return {
      type: actions.FILES_CLEANUP_BEGIN,
    };
  },

  filesCleanupSuccess: () => {
    return {
      type: actions.FILES_CLEANUP_SUCCESS,
    };
  },

  filesCleanupErr: err => {
    return {
      type: actions.FILES_CLEANUP_ERR,
      err,
    };
  },

  FILES_LIST_UPDATE_BEGIN: 'FILES_LIST_UPDATE_BEGIN',
  FILES_LIST_UPDATE_SUCCESS: 'FILES_LIST_UPDATE_SUCCESS',
  FILES_LIST_UPDATE_ERR: 'FILES_LIST_UPDATE_ERR',

  filesListUpdateBegin: () => {
    return {
      type: actions.FILES_LIST_UPDATE_BEGIN,
    };
  },

  filesListUpdateSuccess: data => {
    return {
      type: actions.FILES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  filesListUpdateErr: err => {
    return {
      type: actions.FILES_LIST_UPDATE_ERR,
      err,
    };
  },

  FILES_LIST_CLEANUP_BEGIN: 'FILES_CLEANUP_BEGIN',
  FILES_LIST_CLEANUP_SUCCESS: 'FILES_CLEANUP_SUCCESS',
  FILES_LIST_CLEANUP_ERR: 'FILES_CLEANUP_ERR',

  filesListCleanupBegin: () => {
    return {
      type: actions.FILES_CLEANUP_BEGIN,
    };
  },

  filesListCleanupSuccess: () => {
    return {
      type: actions.FILES_CLEANUP_SUCCESS,
    };
  },

  filesListCleanupErr: err => {
    return {
      type: actions.FILES_CLEANUP_ERR,
      err,
    };
  },

  FILE_DELETE_BEGIN: 'FILE_DELETE_BEGIN',
  FILE_DELETE_SUCCESS: 'FILE_DELETE_SUCCESS',
  FILE_DELETE_ERR: 'FILE_DELETE_ERR',

  fileDeleteBegin: () => {
    return {
      type: actions.FILE_DELETE_BEGIN,
    };
  },

  fileDeleteSuccess: data => {
    return {
      type: actions.FILE_DELETE_SUCCESS,
      data,
    };
  },

  fileDeleteErr: err => {
    return {
      type: actions.FILE_DELETE_ERR,
      err,
    };
  },

  FILE_UPDATE_BEGIN: 'FILE_UPDATE_BEGIN',
  FILE_UPDATE_SUCCESS: 'FILE_UPDATE_SUCCESS',
  FILE_UPDATE_ERR: 'FILE_UPDATE_ERR',

  fileUpdateBegin: () => {
    return {
      type: actions.FILE_UPDATE_BEGIN,
    };
  },

  fileUpdateSuccess: data => {
    return {
      type: actions.FILE_UPDATE_SUCCESS,
      data,
    };
  },

  fileUpdateErr: err => {
    return {
      type: actions.FILE_UPDATE_ERR,
      err,
    };
  },

  FILE_CLEANUP_BEGIN: 'FILE_CLEANUP_BEGIN',
  FILE_CLEANUP_SUCCESS: 'FILE_CLEANUP_SUCCESS',
  FILE_CLEANUP_ERR: 'FILE_CLEANUP_ERR',

  fileCleanupBegin: () => {
    return {
      type: actions.FILE_CLEANUP_BEGIN,
    };
  },

  fileCleanupSuccess: () => {
    return {
      type: actions.FILE_CLEANUP_SUCCESS,
    };
  },

  fileCleanupErr: err => {
    return {
      type: actions.FILE_CLEANUP_ERR,
      err,
    };
  },

  FILE_SAVE_BEGIN: 'FILE_SAVE_BEGIN',
  FILE_SAVE_SUCCESS: 'FILE_SAVE_SUCCESS',
  FILE_SAVE_ERR: 'FILE_SAVE_ERR',

  fileSaveBegin: () => {
    return {
      type: actions.FILE_SAVE_BEGIN,
    };
  },

  fileSaveSuccess: data => {
    return {
      type: actions.FILE_SAVE_SUCCESS,
      data,
    };
  },

  fileSaveErr: err => {
    return {
      type: actions.FILE_SAVE_ERR,
      err,
    };
  },

  FILE_UPLOAD_BEGIN: 'FILE_UPLOAD_BEGIN',
  FILE_UPLOAD_UPDATE: 'FILE_UPLOAD_UPDATE',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_ERR: 'FILE_UPLOAD_ERR',

  fileUploadBegin: data => {
    return {
      type: actions.FILE_UPLOAD_BEGIN,
      data,
    };
  },

  fileUploadUpdate: data => {
    return {
      type: actions.FILE_UPLOAD_UPDATE,
      data,
    };
  },

  fileUploadSuccess: data => {
    return {
      type: actions.FILE_UPLOAD_SUCCESS,
      data,
    };
  },

  fileUploadErr: err => {
    return {
      type: actions.FILE_UPLOAD_ERR,
      err,
    };
  },

  FILE_DOWNLOAD_BEGIN: 'FILE_DOWNLOAD_BEGIN',
  FILE_DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
  FILE_DOWNLOAD_ERR: 'FILE_DOWNLOAD_ERR',

  fileDownloadBegin: () => {
    return {
      type: actions.FILE_DOWNLOAD_BEGIN,
    };
  },

  fileDownloadSuccess: data => {
    return {
      type: actions.FILE_DOWNLOAD_SUCCESS,
      data,
    };
  },

  fileDownloadErr: err => {
    return {
      type: actions.FILE_DOWNLOAD_ERR,
      err,
    };
  },

  FILES_DOWNLOAD_BEGIN: 'FILES_DOWNLOAD_BEGIN',
  FILES_DOWNLOAD_SUCCESS: 'FILES_DOWNLOAD_SUCCESS',
  FILES_DOWNLOAD_ERR: 'FILES_DOWNLOAD_ERR',

  filesDownloadBegin: () => {
    return {
      type: actions.FILES_DOWNLOAD_BEGIN,
    };
  },

  filesDownloadSuccess: data => {
    return {
      type: actions.FILES_DOWNLOAD_SUCCESS,
      data,
    };
  },

  filesDownloadErr: err => {
    return {
      type: actions.FILES_DOWNLOAD_ERR,
      err,
    };
  },

  FILES_CHARTS_UPDATE_BEGIN: 'FILES_CHARTS_UPDATE_BEGIN',
  FILES_CHARTS_UPDATE_SUCCESS: 'FILES_CHARTS_UPDATE_SUCCESS',
  FILES_CHARTS_UPDATE_ERR: 'FILES_CHARTS_UPDATE_ERR',

  filesChartsUpdateBegin: () => {
    return {
      type: actions.FILES_CHARTS_UPDATE_BEGIN,
    };
  },

  filesChartsUpdateSuccess: data => {
    return {
      type: actions.FILES_CHARTS_UPDATE_SUCCESS,
      data,
    };
  },

  filesChartsUpdateErr: err => {
    return {
      type: actions.FILES_CHARTS_UPDATE_ERR,
      err,
    };
  },

  FILES_CHARTS_CLEANUP_BEGIN: 'FILES_CHARTS_CLEANUP_BEGIN',
  FILES_CHARTS_CLEANUP_SUCCESS: 'FILES_CHARTS_CLEANUP_SUCCESS',
  FILES_CHARTS_CLEANUP_ERR: 'FILES_CHARTS_CLEANUP_ERR',

  filesChartsCleanupBegin: () => {
    return {
      type: actions.FILES_CHARTS_CLEANUP_BEGIN,
    };
  },

  filesChartsCleanupSuccess: () => {
    return {
      type: actions.FILES_CHARTS_CLEANUP_SUCCESS,
    };
  },

  filesChartsCleanupErr: err => {
    return {
      type: actions.FILES_CHARTS_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
