import actions from './actions';
import CONSTANTS from '../../config/constants';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {
    client_status_id: [
      CONSTANTS.CLIENT_STATUS.ATIVO,
      CONSTANTS.CLIENT_STATUS.EM_CONSTITUICAO,
      CONSTANTS.CLIENT_STATUS.EVENTUAL,
    ],
  },
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENTS_PAGINATION_UPDATE_SUCCESS,
  CLIENTS_FILTERS_UPDATE_SUCCESS,
  CLIENTS_SORTER_UPDATE_SUCCESS,

  CLIENTS_UPDATE_BEGIN,
  CLIENTS_UPDATE_SUCCESS,
  CLIENTS_UPDATE_ERR,

  CLIENTS_CLEANUP_BEGIN,
  CLIENTS_CLEANUP_SUCCESS,
  CLIENTS_CLEANUP_ERR,

  CLIENT_DELETE_BEGIN,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_ERR,
} = actions;

const clientsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENTS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CLIENTS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CLIENTS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CLIENTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_CLEANUP_SUCCESS:
      return initialIndexState;

    case CLIENTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CLIENT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  CLIENTS_LIST_UPDATE_BEGIN,
  CLIENTS_LIST_UPDATE_SUCCESS,
  CLIENTS_LIST_UPDATE_ERR,

  CLIENTS_LIST_CLEANUP_BEGIN,
  CLIENTS_LIST_CLEANUP_SUCCESS,
  CLIENTS_LIST_CLEANUP_ERR,
} = actions;

const clientsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENTS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENTS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENTS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case CLIENTS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialReportState = {
  data: [],
  filters: {},
  sorter: {},
  loading: false,
  error: null,
};

const {
  CLIENTS_REPORT_FILTERS_UPDATE_SUCCESS,
  CLIENTS_REPORT_SORTER_UPDATE_SUCCESS,

  CLIENTS_REPORT_UPDATE_BEGIN,
  CLIENTS_REPORT_UPDATE_SUCCESS,
  CLIENTS_REPORT_UPDATE_ERR,

  CLIENTS_REPORT_CLEANUP_BEGIN,
  CLIENTS_REPORT_CLEANUP_SUCCESS,
  CLIENTS_REPORT_CLEANUP_ERR,
} = actions;

const clientsReportReducer = (state = initialReportState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENTS_REPORT_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CLIENTS_REPORT_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CLIENTS_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENTS_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENTS_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_REPORT_CLEANUP_SUCCESS:
      return initialReportState;

    case CLIENTS_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  import: null,
  loading: false,
  saving: false,
  error: null,
};

const {
  CLIENT_UPDATE_BEGIN,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_ERR,

  CLIENT_IMPORT_BEGIN,
  CLIENT_IMPORT_SUCCESS,
  CLIENT_IMPORT_ERR,

  CLIENT_CLEANUP_BEGIN,
  CLIENT_CLEANUP_SUCCESS,
  CLIENT_CLEANUP_ERR,

  CLIENT_SAVE_BEGIN,
  CLIENT_SAVE_SUCCESS,
  CLIENT_SAVE_ERR,
} = actions;

const clientReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_IMPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_IMPORT_SUCCESS:
      return {
        ...state,
        data: data.client,
        import: data.import,
        loading: false,
      };

    case CLIENT_IMPORT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CLEANUP_SUCCESS:
      return initialSingleState;

    case CLIENT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CLIENT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CLIENT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { clientsReducer, clientsListReducer, clientReducer, clientsReportReducer };
