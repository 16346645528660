import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  CARD_REVIEWS_UPDATE_BEGIN,
  CARD_REVIEWS_UPDATE_SUCCESS,
  CARD_REVIEWS_UPDATE_ERR,

  CARD_REVIEWS_CLEANUP_BEGIN,
  CARD_REVIEWS_CLEANUP_SUCCESS,
  CARD_REVIEWS_CLEANUP_ERR,

  CARD_REVIEW_DELETE_BEGIN,
  CARD_REVIEW_DELETE_SUCCESS,
  CARD_REVIEW_DELETE_ERR,
} = actions;

const cardReviewsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_REVIEWS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_REVIEWS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_REVIEWS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_REVIEWS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_REVIEWS_CLEANUP_SUCCESS:
      return initialState;

    case CARD_REVIEWS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_REVIEW_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CARD_REVIEW_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CARD_REVIEW_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CARD_REVIEW_UPDATE_BEGIN,
  CARD_REVIEW_UPDATE_SUCCESS,
  CARD_REVIEW_UPDATE_ERR,

  CARD_REVIEW_CLEANUP_BEGIN,
  CARD_REVIEW_CLEANUP_SUCCESS,
  CARD_REVIEW_CLEANUP_ERR,

  CARD_REVIEW_SAVE_BEGIN,
  CARD_REVIEW_SAVE_SUCCESS,
  CARD_REVIEW_SAVE_ERR,
} = actions;

const cardReviewReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_REVIEW_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_REVIEW_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_REVIEW_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_REVIEW_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_REVIEW_CLEANUP_SUCCESS:
      return initialSingleState;

    case CARD_REVIEW_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_REVIEW_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_REVIEW_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_REVIEW_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { cardReviewsReducer, cardReviewReducer };
