const actions = {
  CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_ERR: 'CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_ERR',

  clientTaxationCalculationTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientTaxationCalculationTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxationCalculationTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_TAXATION_CALCULATION_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_ERR: 'CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_ERR',

  clientTaxationCalculationTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientTaxationCalculationTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientTaxationCalculationTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_TAXATION_CALCULATION_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  