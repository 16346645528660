const actions = {
  CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_ERR: 'CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_ERR',

  clientTaxationSocialSecurityTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientTaxationSocialSecurityTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxationSocialSecurityTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_ERR: 'CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_ERR',

  clientTaxationSocialSecurityTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientTaxationSocialSecurityTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientTaxationSocialSecurityTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_TAXATION_SOCIAL_SECURITY_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  