const actions = {
  CALENDAR_HOLIDAYS_LIST_UPDATE_BEGIN: 'CALENDAR_HOLIDAYS_LIST_UPDATE_BEGIN',
  CALENDAR_HOLIDAYS_LIST_UPDATE_SUCCESS: 'CALENDAR_HOLIDAYS_LIST_UPDATE_SUCCESS',
  CALENDAR_HOLIDAYS_LIST_UPDATE_ERR: 'CALENDAR_HOLIDAYS_LIST_UPDATE_ERR',

  calendarHolidaysListUpdateBegin: () => {
    return {
      type: actions.CALENDAR_HOLIDAYS_LIST_UPDATE_BEGIN,
    };
  },

  calendarHolidaysListUpdateSuccess: data => {
    return {
      type: actions.CALENDAR_HOLIDAYS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  calendarHolidaysListUpdateErr: err => {
    return {
      type: actions.CALENDAR_HOLIDAYS_LIST_UPDATE_ERR,
      err,
    };
  },

  CALENDAR_HOLIDAYS_LIST_CLEANUP_BEGIN: 'CALENDAR_HOLIDAYS_LIST_CLEANUP_BEGIN',
  CALENDAR_HOLIDAYS_LIST_CLEANUP_SUCCESS: 'CALENDAR_HOLIDAYS_LIST_CLEANUP_SUCCESS',
  CALENDAR_HOLIDAYS_LIST_CLEANUP_ERR: 'CALENDAR_HOLIDAYS_LIST_CLEANUP_ERR',

  calendarHolidaysListCleanupBegin: () => {
    return {
      type: actions.CALENDAR_HOLIDAYS_LIST_CLEANUP_BEGIN,
    };
  },

  calendarHolidaysListCleanupSuccess: () => {
    return {
      type: actions.CALENDAR_HOLIDAYS_LIST_CLEANUP_SUCCESS,
    };
  },

  calendarHolidaysListCleanupErr: err => {
    return {
      type: actions.CALENDAR_HOLIDAYS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
