import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  saving: false,
  error: null,
};

const {
  NEWSLETTERS_LIST_UPDATE_BEGIN,
  NEWSLETTERS_LIST_UPDATE_SUCCESS,
  NEWSLETTERS_LIST_UPDATE_ERR,

  NEWSLETTERS_LIST_CLEANUP_BEGIN,
  NEWSLETTERS_LIST_CLEANUP_SUCCESS,
  NEWSLETTERS_LIST_CLEANUP_ERR,

  NEWSLETTERS_READ_BEGIN,
  NEWSLETTERS_READ_SUCCESS,
  NEWSLETTERS_READ_ERR,
} = actions;

const newslettersListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NEWSLETTERS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTERS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case NEWSLETTERS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWSLETTERS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTERS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case NEWSLETTERS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWSLETTERS_READ_BEGIN:
      return {
        ...state,
        saving: true,
      };
    case NEWSLETTERS_READ_SUCCESS:
      return {
        ...state,
        saving: false,
      };
    case NEWSLETTERS_READ_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  NEWSLETTERS_PAGINATION_UPDATE_SUCCESS,
  NEWSLETTERS_FILTERS_UPDATE_SUCCESS,
  NEWSLETTERS_SORTER_UPDATE_SUCCESS,

  NEWSLETTERS_UPDATE_BEGIN,
  NEWSLETTERS_UPDATE_SUCCESS,
  NEWSLETTERS_UPDATE_ERR,

  NEWSLETTERS_CLEANUP_BEGIN,
  NEWSLETTERS_CLEANUP_SUCCESS,
  NEWSLETTERS_CLEANUP_ERR,

  NEWSLETTER_DELETE_BEGIN,
  NEWSLETTER_DELETE_SUCCESS,
  NEWSLETTER_DELETE_ERR,
} = actions;

const newslettersReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NEWSLETTERS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case NEWSLETTERS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case NEWSLETTERS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case NEWSLETTERS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NEWSLETTERS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case NEWSLETTERS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWSLETTERS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NEWSLETTERS_CLEANUP_SUCCESS:
      return initialIndexState;

    case NEWSLETTERS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWSLETTER_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case NEWSLETTER_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case NEWSLETTER_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  NEWSLETTER_UPDATE_BEGIN,
  NEWSLETTER_UPDATE_SUCCESS,
  NEWSLETTER_UPDATE_ERR,

  NEWSLETTER_CLEANUP_BEGIN,
  NEWSLETTER_CLEANUP_SUCCESS,
  NEWSLETTER_CLEANUP_ERR,

  NEWSLETTER_SAVE_BEGIN,
  NEWSLETTER_SAVE_SUCCESS,
  NEWSLETTER_SAVE_ERR,
} = actions;

const newsletterReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NEWSLETTER_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NEWSLETTER_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case NEWSLETTER_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWSLETTER_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NEWSLETTER_CLEANUP_SUCCESS:
      return initialSingleState;

    case NEWSLETTER_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWSLETTER_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case NEWSLETTER_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case NEWSLETTER_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { newslettersListReducer, newslettersReducer, newsletterReducer };
