import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_FILES_PAGINATION_UPDATE_SUCCESS,
  CLIENT_FILES_FILTERS_UPDATE_SUCCESS,
  CLIENT_FILES_SORTER_UPDATE_SUCCESS,

  CLIENT_FILES_UPDATE_BEGIN,
  CLIENT_FILES_UPDATE_SUCCESS,
  CLIENT_FILES_UPDATE_ERR,

  CLIENT_FILES_CLEANUP_BEGIN,
  CLIENT_FILES_CLEANUP_SUCCESS,
  CLIENT_FILES_CLEANUP_ERR,

  CLIENT_FILE_DELETE_BEGIN,
  CLIENT_FILE_DELETE_SUCCESS,
  CLIENT_FILE_DELETE_ERR,
} = actions;

const clientFilesReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_FILES_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CLIENT_FILES_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CLIENT_FILES_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CLIENT_FILES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_FILES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_FILES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_FILES_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_FILES_CLEANUP_SUCCESS:
      return initialIndexState;

    case CLIENT_FILES_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_FILE_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CLIENT_FILE_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CLIENT_FILE_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  CLIENT_FILES_LIST_UPDATE_BEGIN,
  CLIENT_FILES_LIST_UPDATE_SUCCESS,
  CLIENT_FILES_LIST_UPDATE_ERR,

  CLIENT_FILES_LIST_CLEANUP_BEGIN,
  CLIENT_FILES_LIST_CLEANUP_SUCCESS,
  CLIENT_FILES_LIST_CLEANUP_ERR,
} = actions;

const clientFilesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_FILES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_FILES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_FILES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_FILES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_FILES_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case CLIENT_FILES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CLIENT_FILE_UPDATE_BEGIN,
  CLIENT_FILE_UPDATE_SUCCESS,
  CLIENT_FILE_UPDATE_ERR,

  CLIENT_FILE_CLEANUP_BEGIN,
  CLIENT_FILE_CLEANUP_SUCCESS,
  CLIENT_FILE_CLEANUP_ERR,

  CLIENT_FILE_SAVE_BEGIN,
  CLIENT_FILE_SAVE_SUCCESS,
  CLIENT_FILE_SAVE_ERR,
} = actions;

const clientFileReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_FILE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_FILE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_FILE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_FILE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_FILE_CLEANUP_SUCCESS:
      return initialSingleState;

    case CLIENT_FILE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_FILE_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CLIENT_FILE_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CLIENT_FILE_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { clientFilesReducer, clientFilesListReducer, clientFileReducer };
