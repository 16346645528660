const actions = {
  HISTORIES_UPDATE_BEGIN: 'HISTORIES_UPDATE_BEGIN',
  HISTORIES_UPDATE_SUCCESS: 'HISTORIES_UPDATE_SUCCESS',
  HISTORIES_UPDATE_ERR: 'HISTORIES_UPDATE_ERR',

  historiesUpdateBegin: () => {
    return {
      type: actions.HISTORIES_UPDATE_BEGIN,
    };
  },

  historiesUpdateSuccess: data => {
    return {
      type: actions.HISTORIES_UPDATE_SUCCESS,
      data,
    };
  },

  historiesUpdateErr: err => {
    return {
      type: actions.HISTORIES_UPDATE_ERR,
      err,
    };
  },

  HISTORIES_CLEANUP_BEGIN: 'HISTORIES_CLEANUP_BEGIN',
  HISTORIES_CLEANUP_SUCCESS: 'HISTORIES_CLEANUP_SUCCESS',
  HISTORIES_CLEANUP_ERR: 'HISTORIES_CLEANUP_ERR',

  historiesCleanupBegin: () => {
    return {
      type: actions.HISTORIES_CLEANUP_BEGIN,
    };
  },

  historiesCleanupSuccess: () => {
    return {
      type: actions.HISTORIES_CLEANUP_SUCCESS,
    };
  },

  historiesCleanupErr: err => {
    return {
      type: actions.HISTORIES_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
