import actions from './actions';

const initialListState = {
  data: [],
  count: {
    unreaded: 0,
    readed: 0,
    total: 0,
    informacao: 0,
    aviso: 0,
    erro: 0,
  },
  loading: false,
  saving: false,
  error: null,
};

const {
  NOTIFICATIONS_LIST_UPDATE_BEGIN,
  NOTIFICATIONS_LIST_UPDATE_SUCCESS,
  NOTIFICATIONS_LIST_UPDATE_ERR,

  NOTIFICATIONS_LIST_CLEANUP_BEGIN,
  NOTIFICATIONS_LIST_CLEANUP_SUCCESS,
  NOTIFICATIONS_LIST_CLEANUP_ERR,

  NOTIFICATIONS_READ_BEGIN,
  NOTIFICATIONS_READ_SUCCESS,
  NOTIFICATIONS_READ_ERR,
} = actions;

const notificationsListReducer = (state = initialListState, action) => {
  const { type, data, count, err } = action;
  switch (type) {
    case NOTIFICATIONS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATIONS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        count,
        loading: false,
      };
    case NOTIFICATIONS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NOTIFICATIONS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATIONS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case NOTIFICATIONS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NOTIFICATIONS_READ_BEGIN:
      return {
        ...state,
        saving: true,
      };
    case NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        saving: false,
      };
    case NOTIFICATIONS_READ_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
  },
  count: {
    unreaded: 0,
    readed: 0,
    total: 0,
    informacao: 0,
    aviso: 0,
    erro: 0,
  },
  sorter: {},
  loading: false,
  error: null,
};

const {
  NOTIFICATIONS_PAGINATION_UPDATE_SUCCESS,
  NOTIFICATIONS_FILTERS_UPDATE_SUCCESS,
  NOTIFICATIONS_SORTER_UPDATE_SUCCESS,

  NOTIFICATIONS_UPDATE_BEGIN,
  NOTIFICATIONS_UPDATE_SUCCESS,
  NOTIFICATIONS_UPDATE_ERR,

  NOTIFICATIONS_CLEANUP_BEGIN,
  NOTIFICATIONS_CLEANUP_SUCCESS,
  NOTIFICATIONS_CLEANUP_ERR,
} = actions;

const notificationsReducer = (state = initialIndexState, action) => {
  const { type, data, count, err } = action;
  switch (type) {
    case NOTIFICATIONS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case NOTIFICATIONS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case NOTIFICATIONS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case NOTIFICATIONS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        count,
        loading: false,
      };

    case NOTIFICATIONS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NOTIFICATIONS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATIONS_CLEANUP_SUCCESS:
      return initialIndexState;

    case NOTIFICATIONS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  NOTIFICATION_UPDATE_BEGIN,
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_ERR,

  NOTIFICATION_CLEANUP_BEGIN,
  NOTIFICATION_CLEANUP_SUCCESS,
  NOTIFICATION_CLEANUP_ERR,
} = actions;

const notificationReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTIFICATION_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case NOTIFICATION_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NOTIFICATION_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_CLEANUP_SUCCESS:
      return initialSingleState;

    case NOTIFICATION_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { notificationsListReducer, notificationsReducer, notificationReducer };
