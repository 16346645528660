const actions = {
  CLIENT_NEGATIVE_CERTIFICATES_UPDATE_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATES_UPDATE_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATES_UPDATE_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATES_UPDATE_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATES_UPDATE_ERR: 'CLIENT_NEGATIVE_CERTIFICATES_UPDATE_ERR',

  clientNegativeCertificatesUpdateBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATES_UPDATE_BEGIN,
    };
  },

  clientNegativeCertificatesUpdateSuccess: data => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATES_UPDATE_SUCCESS,
      data,
    };
  },

  clientNegativeCertificatesUpdateErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATES_UPDATE_ERR,
      err,
    };
  },

  CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_ERR: 'CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_ERR',

  clientNegativeCertificatesCleanupBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_BEGIN,
    };
  },

  clientNegativeCertificatesCleanupSuccess: data => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_SUCCESS,
      data,
    };
  },

  clientNegativeCertificatesCleanupErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATES_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_NEGATIVE_CERTIFICATE_UPDATE_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATE_UPDATE_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATE_UPDATE_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATE_UPDATE_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATE_UPDATE_ERR: 'CLIENT_NEGATIVE_CERTIFICATE_UPDATE_ERR',

  clientNegativeCertificateUpdateBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_UPDATE_BEGIN,
    };
  },

  clientNegativeCertificateUpdateSuccess: data => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_UPDATE_SUCCESS,
      data,
    };
  },

  clientNegativeCertificateUpdateErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_UPDATE_ERR,
      err,
    };
  },

  CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_ERR: 'CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_ERR',

  clientNegativeCertificateCleanupBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_BEGIN,
    };
  },

  clientNegativeCertificateCleanupSuccess: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_SUCCESS,
    };
  },

  clientNegativeCertificateCleanupErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_NEGATIVE_CERTIFICATE_SAVE_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATE_SAVE_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATE_SAVE_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATE_SAVE_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATE_SAVE_ERR: 'CLIENT_NEGATIVE_CERTIFICATE_SAVE_ERR',

  clientNegativeCertificateSaveBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SAVE_BEGIN,
    };
  },

  clientNegativeCertificateSaveSuccess: data => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SAVE_SUCCESS,
      data,
    };
  },

  clientNegativeCertificateSaveErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SAVE_ERR,
      err,
    };
  },

  CLIENT_NEGATIVE_CERTIFICATE_DELETE_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATE_DELETE_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATE_DELETE_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATE_DELETE_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATE_DELETE_ERR: 'CLIENT_NEGATIVE_CERTIFICATE_DELETE_ERR',

  clientNegativeCertificateDeleteBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_DELETE_BEGIN,
    };
  },

  clientNegativeCertificateDeleteSuccess: data => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_DELETE_SUCCESS,
      data,
    };
  },

  clientNegativeCertificateDeleteErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_DELETE_ERR,
      err,
    };
  },
};

export default actions;
  