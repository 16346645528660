const actions = {
  NOTIFICATIONS_LIST_UPDATE_BEGIN: 'NOTIFICATIONS_LIST_UPDATE_BEGIN',
  NOTIFICATIONS_LIST_UPDATE_SUCCESS: 'NOTIFICATIONS_LIST_UPDATE_SUCCESS',
  NOTIFICATIONS_LIST_UPDATE_ERR: 'NOTIFICATIONS_LIST_UPDATE_ERR',

  notificationsListUpdateBegin: () => {
    return {
      type: actions.NOTIFICATIONS_LIST_UPDATE_BEGIN,
    };
  },

  notificationsListUpdateSuccess: ({ data, count }) => {
    return {
      type: actions.NOTIFICATIONS_LIST_UPDATE_SUCCESS,
      data,
      count,
    };
  },

  notificationsListUpdateErr: err => {
    return {
      type: actions.NOTIFICATIONS_LIST_UPDATE_ERR,
      err,
    };
  },

  NOTIFICATIONS_LIST_CLEANUP_BEGIN: 'NOTIFICATIONS_LIST_CLEANUP_BEGIN',
  NOTIFICATIONS_LIST_CLEANUP_SUCCESS: 'NOTIFICATIONS_LIST_CLEANUP_SUCCESS',
  NOTIFICATIONS_LIST_CLEANUP_ERR: 'NOTIFICATIONS_LIST_CLEANUP_ERR',

  notificationsListCleanupBegin: () => {
    return {
      type: actions.NOTIFICATIONS_LIST_CLEANUP_BEGIN,
    };
  },

  notificationsListCleanupSuccess: () => {
    return {
      type: actions.NOTIFICATIONS_LIST_CLEANUP_SUCCESS,
    };
  },

  notificationsListCleanupErr: err => {
    return {
      type: actions.NOTIFICATIONS_LIST_CLEANUP_ERR,
      err,
    };
  },

  NOTIFICATIONS_PAGINATION_UPDATE_BEGIN: 'NOTIFICATIONS_PAGINATION_UPDATE_BEGIN',
  NOTIFICATIONS_PAGINATION_UPDATE_SUCCESS: 'NOTIFICATIONS_PAGINATION_UPDATE_SUCCESS',
  NOTIFICATIONS_PAGINATION_UPDATE_ERR: 'NOTIFICATIONS_PAGINATION_UPDATE_ERR',

  notificationsPaginationUpdateBegin: () => {
    return {
      type: actions.NOTIFICATIONS_PAGINATION_UPDATE_BEGIN,
    };
  },

  notificationsPaginationUpdateSuccess: data => {
    return {
      type: actions.NOTIFICATIONS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  notificationsPaginationUpdateErr: err => {
    return {
      type: actions.NOTIFICATIONS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  NOTIFICATIONS_FILTERS_UPDATE_BEGIN: 'NOTIFICATIONS_FILTERS_UPDATE_BEGIN',
  NOTIFICATIONS_FILTERS_UPDATE_SUCCESS: 'NOTIFICATIONS_FILTERS_UPDATE_SUCCESS',
  NOTIFICATIONS_FILTERS_UPDATE_ERR: 'NOTIFICATIONS_FILTERS_UPDATE_ERR',

  notificationsFiltersUpdateBegin: () => {
    return {
      type: actions.NOTIFICATIONS_FILTERS_UPDATE_BEGIN,
    };
  },

  notificationsFiltersUpdateSuccess: data => {
    return {
      type: actions.NOTIFICATIONS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  notificationsFiltersUpdateErr: err => {
    return {
      type: actions.NOTIFICATIONS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  NOTIFICATIONS_SORTER_UPDATE_BEGIN: 'NOTIFICATIONS_SORTER_UPDATE_BEGIN',
  NOTIFICATIONS_SORTER_UPDATE_SUCCESS: 'NOTIFICATIONS_SORTER_UPDATE_SUCCESS',
  NOTIFICATIONS_SORTER_UPDATE_ERR: 'NOTIFICATIONS_SORTER_UPDATE_ERR',

  notificationsSorterUpdateBegin: () => {
    return {
      type: actions.NOTIFICATIONS_SORTER_UPDATE_BEGIN,
    };
  },

  notificationsSorterUpdateSuccess: data => {
    return {
      type: actions.NOTIFICATIONS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  notificationsSorterUpdateErr: err => {
    return {
      type: actions.NOTIFICATIONS_SORTER_UPDATE_ERR,
      err,
    };
  },

  NOTIFICATIONS_UPDATE_BEGIN: 'NOTIFICATIONS_UPDATE_BEGIN',
  NOTIFICATIONS_UPDATE_SUCCESS: 'NOTIFICATIONS_UPDATE_SUCCESS',
  NOTIFICATIONS_UPDATE_ERR: 'NOTIFICATIONS_UPDATE_ERR',

  notificationsUpdateBegin: () => {
    return {
      type: actions.NOTIFICATIONS_UPDATE_BEGIN,
    };
  },

  notificationsUpdateSuccess: ({ data, count }) => {
    return {
      type: actions.NOTIFICATIONS_UPDATE_SUCCESS,
      data,
      count,
    };
  },

  notificationsUpdateErr: err => {
    return {
      type: actions.NOTIFICATIONS_UPDATE_ERR,
      err,
    };
  },

  NOTIFICATIONS_CLEANUP_BEGIN: 'NOTIFICATIONS_CLEANUP_BEGIN',
  NOTIFICATIONS_CLEANUP_SUCCESS: 'NOTIFICATIONS_CLEANUP_SUCCESS',
  NOTIFICATIONS_CLEANUP_ERR: 'NOTIFICATIONS_CLEANUP_ERR',

  notificationsCleanupBegin: () => {
    return {
      type: actions.NOTIFICATIONS_CLEANUP_BEGIN,
    };
  },

  notificationsCleanupSuccess: () => {
    return {
      type: actions.NOTIFICATIONS_CLEANUP_SUCCESS,
    };
  },

  notificationsCleanupErr: err => {
    return {
      type: actions.NOTIFICATIONS_CLEANUP_ERR,
      err,
    };
  },

  NOTIFICATIONS_READ_BEGIN: 'NOTIFICATIONS_READ_BEGIN',
  NOTIFICATIONS_READ_SUCCESS: 'NOTIFICATIONS_READ_SUCCESS',
  NOTIFICATIONS_READ_ERR: 'NOTIFICATIONS_READ_ERR',

  notificationsReadBegin: () => {
    return {
      type: actions.NOTIFICATIONS_READ_BEGIN,
    };
  },

  notificationsReadSuccess: () => {
    return {
      type: actions.NOTIFICATIONS_READ_SUCCESS,
    };
  },

  notificationsReadErr: err => {
    return {
      type: actions.NOTIFICATIONS_READ_ERR,
      err,
    };
  },

  NOTIFICATION_UPDATE_BEGIN: 'NOTIFICATION_UPDATE_BEGIN',
  NOTIFICATION_UPDATE_SUCCESS: 'NOTIFICATION_UPDATE_SUCCESS',
  NOTIFICATION_UPDATE_ERR: 'NOTIFICATION_UPDATE_ERR',

  notificationUpdateBegin: () => {
    return {
      type: actions.NOTIFICATION_UPDATE_BEGIN,
    };
  },

  notificationUpdateSuccess: data => {
    return {
      type: actions.NOTIFICATION_UPDATE_SUCCESS,
      data,
    };
  },

  notificationUpdateErr: err => {
    return {
      type: actions.NOTIFICATION_UPDATE_ERR,
      err,
    };
  },

  NOTIFICATION_CLEANUP_BEGIN: 'NOTIFICATION_CLEANUP_BEGIN',
  NOTIFICATION_CLEANUP_SUCCESS: 'NOTIFICATION_CLEANUP_SUCCESS',
  NOTIFICATION_CLEANUP_ERR: 'NOTIFICATION_CLEANUP_ERR',

  notificationCleanupBegin: () => {
    return {
      type: actions.NOTIFICATION_CLEANUP_BEGIN,
    };
  },

  notificationCleanupSuccess: () => {
    return {
      type: actions.NOTIFICATION_CLEANUP_SUCCESS,
    };
  },

  notificationCleanupErr: err => {
    return {
      type: actions.NOTIFICATION_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
