import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const RpaSefazCeDteMessagesIndex = lazy(() => import('../../container/rpa/sefazCeDteMessages/Index'));
const RpaSefazCeSigetReportsIndex = lazy(() => import('../../container/rpa/sefazCeSigetReports/Index'));
const NotFound = lazy(() => import('../../container/pages/404'));

const RpaRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}/sefazCeDteMessages`} component={RpaSefazCeDteMessagesIndex} />
        <Route path={`${path}/sefazCeSigetReports`} component={RpaSefazCeSigetReportsIndex} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default RpaRoute;
