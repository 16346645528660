import { alertModal } from '../layout/modals/antd-modals';

const confirmAction = ({ 
  title = 'Tem certeza de que deseja executar esta ação?', 
  content = 'As alterações não poderão ser desfeitas.',
  onCancel = () => {}, 
  okText = 'Executar',
  onOk = () => {}, 
  timeleft = 2
}) => {
  const modal = alertModal.confirm({
    title,
    content,
    okButtonProps: {
      disabled: true,
    },
    onCancel: () => onCancel(),
    onOk: () => onOk(),
  });

  for (let i = timeleft; i >= 0; i -= 1) {
    setTimeout(() => {
      const newConfig = {
        okButtonProps: { disabled: (i > 0) },
        okText: (i > 0 ? i : okText)
      };
      modal.update(newConfig);
    }, (timeleft - i) * 1000);
  }
};

export default confirmAction