const actions = {
  CLIENT_PARTNERS_UPDATE_BEGIN: 'CLIENT_PARTNERS_UPDATE_BEGIN',
  CLIENT_PARTNERS_UPDATE_SUCCESS: 'CLIENT_PARTNERS_UPDATE_SUCCESS',
  CLIENT_PARTNERS_UPDATE_ERR: 'CLIENT_PARTNERS_UPDATE_ERR',

  clientPartnersUpdateBegin: () => {
    return {
      type: actions.CLIENT_PARTNERS_UPDATE_BEGIN,
    };
  },

  clientPartnersUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PARTNERS_UPDATE_SUCCESS,
      data,
    };
  },

  clientPartnersUpdateErr: err => {
    return {
      type: actions.CLIENT_PARTNERS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PARTNERS_CLEANUP_BEGIN: 'CLIENT_PARTNERS_CLEANUP_BEGIN',
  CLIENT_PARTNERS_CLEANUP_SUCCESS: 'CLIENT_PARTNERS_CLEANUP_SUCCESS',
  CLIENT_PARTNERS_CLEANUP_ERR: 'CLIENT_PARTNERS_CLEANUP_ERR',

  clientPartnersCleanupBegin: () => {
    return {
      type: actions.CLIENT_PARTNERS_CLEANUP_BEGIN,
    };
  },

  clientPartnersCleanupSuccess: data => {
    return {
      type: actions.CLIENT_PARTNERS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientPartnersCleanupErr: err => {
    return {
      type: actions.CLIENT_PARTNERS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_PARTNER_UPDATE_BEGIN: 'CLIENT_PARTNER_UPDATE_BEGIN',
  CLIENT_PARTNER_UPDATE_SUCCESS: 'CLIENT_PARTNER_UPDATE_SUCCESS',
  CLIENT_PARTNER_UPDATE_ERR: 'CLIENT_PARTNER_UPDATE_ERR',

  clientPartnerUpdateBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_UPDATE_BEGIN,
    };
  },

  clientPartnerUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_UPDATE_SUCCESS,
      data,
    };
  },

  clientPartnerUpdateErr: err => {
    return {
      type: actions.CLIENT_PARTNER_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_CLEANUP_BEGIN: 'CLIENT_PARTNER_CLEANUP_BEGIN',
  CLIENT_PARTNER_CLEANUP_SUCCESS: 'CLIENT_PARTNER_CLEANUP_SUCCESS',
  CLIENT_PARTNER_CLEANUP_ERR: 'CLIENT_PARTNER_CLEANUP_ERR',

  clientPartnerCleanupBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_CLEANUP_BEGIN,
    };
  },

  clientPartnerCleanupSuccess: () => {
    return {
      type: actions.CLIENT_PARTNER_CLEANUP_SUCCESS,
    };
  },

  clientPartnerCleanupErr: err => {
    return {
      type: actions.CLIENT_PARTNER_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_PARTNER_SAVE_BEGIN: 'CLIENT_PARTNER_SAVE_BEGIN',
  CLIENT_PARTNER_SAVE_SUCCESS: 'CLIENT_PARTNER_SAVE_SUCCESS',
  CLIENT_PARTNER_SAVE_ERR: 'CLIENT_PARTNER_SAVE_ERR',

  clientPartnerSaveBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_SAVE_BEGIN,
    };
  },

  clientPartnerSaveSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_SAVE_SUCCESS,
      data,
    };
  },

  clientPartnerSaveErr: err => {
    return {
      type: actions.CLIENT_PARTNER_SAVE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_DELETE_BEGIN: 'CLIENT_PARTNER_DELETE_BEGIN',
  CLIENT_PARTNER_DELETE_SUCCESS: 'CLIENT_PARTNER_DELETE_SUCCESS',
  CLIENT_PARTNER_DELETE_ERR: 'CLIENT_PARTNER_DELETE_ERR',

  clientPartnerDeleteBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_DELETE_BEGIN,
    };
  },

  clientPartnerDeleteSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_DELETE_SUCCESS,
      data,
    };
  },

  clientPartnerDeleteErr: err => {
    return {
      type: actions.CLIENT_PARTNER_DELETE_ERR,
      err,
    };
  },
};

export default actions;
