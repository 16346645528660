const actions = {
  CARD_REVIEWS_UPDATE_BEGIN: 'CARD_REVIEWS_UPDATE_BEGIN',
  CARD_REVIEWS_UPDATE_SUCCESS: 'CARD_REVIEWS_UPDATE_SUCCESS',
  CARD_REVIEWS_UPDATE_ERR: 'CARD_REVIEWS_UPDATE_ERR',

  cardReviewsUpdateBegin: () => {
    return {
      type: actions.CARD_REVIEWS_UPDATE_BEGIN,
    };
  },

  cardReviewsUpdateSuccess: data => {
    return {
      type: actions.CARD_REVIEWS_UPDATE_SUCCESS,
      data,
    };
  },

  cardReviewsUpdateErr: err => {
    return {
      type: actions.CARD_REVIEWS_UPDATE_ERR,
      err,
    };
  },

  CARD_REVIEWS_CLEANUP_BEGIN: 'CARD_REVIEWS_CLEANUP_BEGIN',
  CARD_REVIEWS_CLEANUP_SUCCESS: 'CARD_REVIEWS_CLEANUP_SUCCESS',
  CARD_REVIEWS_CLEANUP_ERR: 'CARD_REVIEWS_CLEANUP_ERR',

  cardReviewsCleanupBegin: () => {
    return {
      type: actions.CARD_REVIEWS_CLEANUP_BEGIN,
    };
  },

  cardReviewsCleanupSuccess: data => {
    return {
      type: actions.CARD_REVIEWS_CLEANUP_SUCCESS,
      data,
    };
  },

  cardReviewsCleanupErr: err => {
    return {
      type: actions.CARD_REVIEWS_CLEANUP_ERR,
      err,
    };
  },

  CARD_REVIEW_UPDATE_BEGIN: 'CARD_REVIEW_UPDATE_BEGIN',
  CARD_REVIEW_UPDATE_SUCCESS: 'CARD_REVIEW_UPDATE_SUCCESS',
  CARD_REVIEW_UPDATE_ERR: 'CARD_REVIEW_UPDATE_ERR',

  cardReviewUpdateBegin: () => {
    return {
      type: actions.CARD_REVIEW_UPDATE_BEGIN,
    };
  },

  cardReviewUpdateSuccess: data => {
    return {
      type: actions.CARD_REVIEW_UPDATE_SUCCESS,
      data,
    };
  },

  cardReviewUpdateErr: err => {
    return {
      type: actions.CARD_REVIEW_UPDATE_ERR,
      err,
    };
  },

  CARD_REVIEW_CLEANUP_BEGIN: 'CARD_REVIEW_CLEANUP_BEGIN',
  CARD_REVIEW_CLEANUP_SUCCESS: 'CARD_REVIEW_CLEANUP_SUCCESS',
  CARD_REVIEW_CLEANUP_ERR: 'CARD_REVIEW_CLEANUP_ERR',

  cardReviewCleanupBegin: () => {
    return {
      type: actions.CARD_REVIEW_CLEANUP_BEGIN,
    };
  },

  cardReviewCleanupSuccess: () => {
    return {
      type: actions.CARD_REVIEW_CLEANUP_SUCCESS,
    };
  },

  cardReviewCleanupErr: err => {
    return {
      type: actions.CARD_REVIEW_CLEANUP_ERR,
      err,
    };
  },

  CARD_REVIEW_SAVE_BEGIN: 'CARD_REVIEW_SAVE_BEGIN',
  CARD_REVIEW_SAVE_SUCCESS: 'CARD_REVIEW_SAVE_SUCCESS',
  CARD_REVIEW_SAVE_ERR: 'CARD_REVIEW_SAVE_ERR',

  cardReviewSaveBegin: () => {
    return {
      type: actions.CARD_REVIEW_SAVE_BEGIN,
    };
  },

  cardReviewSaveSuccess: data => {
    return {
      type: actions.CARD_REVIEW_SAVE_SUCCESS,
      data,
    };
  },

  cardReviewSaveErr: err => {
    return {
      type: actions.CARD_REVIEW_SAVE_ERR,
      err,
    };
  },

  CARD_REVIEW_DELETE_BEGIN: 'CARD_REVIEW_DELETE_BEGIN',
  CARD_REVIEW_DELETE_SUCCESS: 'CARD_REVIEW_DELETE_SUCCESS',
  CARD_REVIEW_DELETE_ERR: 'CARD_REVIEW_DELETE_ERR',

  cardReviewDeleteBegin: () => {
    return {
      type: actions.CARD_REVIEW_DELETE_BEGIN,
    };
  },

  cardReviewDeleteSuccess: data => {
    return {
      type: actions.CARD_REVIEW_DELETE_SUCCESS,
      data,
    };
  },

  cardReviewDeleteErr: err => {
    return {
      type: actions.CARD_REVIEW_DELETE_ERR,
      err,
    };
  },
};

export default actions;
