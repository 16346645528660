const actions = {
  DEPARTMENTS_LIST_UPDATE_BEGIN: 'DEPARTMENTS_LIST_UPDATE_BEGIN',
  DEPARTMENTS_LIST_UPDATE_SUCCESS: 'DEPARTMENTS_LIST_UPDATE_SUCCESS',
  DEPARTMENTS_LIST_UPDATE_ERR: 'DEPARTMENTS_LIST_UPDATE_ERR',

  departmentsListUpdateBegin: () => {
    return {
      type: actions.DEPARTMENTS_LIST_UPDATE_BEGIN,
    };
  },

  departmentsListUpdateSuccess: data => {
    return {
      type: actions.DEPARTMENTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  departmentsListUpdateErr: err => {
    return {
      type: actions.DEPARTMENTS_LIST_UPDATE_ERR,
      err,
    };
  },

  DEPARTMENTS_LIST_CLEANUP_BEGIN: 'DEPARTMENTS_LIST_CLEANUP_BEGIN',
  DEPARTMENTS_LIST_CLEANUP_SUCCESS: 'DEPARTMENTS_LIST_CLEANUP_SUCCESS',
  DEPARTMENTS_LIST_CLEANUP_ERR: 'DEPARTMENTS_LIST_CLEANUP_ERR',

  departmentsListCleanupBegin: () => {
    return {
      type: actions.DEPARTMENTS_LIST_CLEANUP_BEGIN,
    };
  },

  departmentsListCleanupSuccess: () => {
    return {
      type: actions.DEPARTMENTS_LIST_CLEANUP_SUCCESS,
    };
  },

  departmentsListCleanupErr: err => {
    return {
      type: actions.DEPARTMENTS_LIST_CLEANUP_ERR,
      err,
    };
  },

  DEPARTMENTS_TREE_UPDATE_BEGIN: 'DEPARTMENTS_TREE_UPDATE_BEGIN',
  DEPARTMENTS_TREE_UPDATE_SUCCESS: 'DEPARTMENTS_TREE_UPDATE_SUCCESS',
  DEPARTMENTS_TREE_UPDATE_ERR: 'DEPARTMENTS_TREE_UPDATE_ERR',

  departmentsTreeUpdateBegin: () => {
    return {
      type: actions.DEPARTMENTS_TREE_UPDATE_BEGIN,
    };
  },

  departmentsTreeUpdateSuccess: data => {
    return {
      type: actions.DEPARTMENTS_TREE_UPDATE_SUCCESS,
      data,
    };
  },

  departmentsTreeUpdateErr: err => {
    return {
      type: actions.DEPARTMENTS_TREE_UPDATE_ERR,
      err,
    };
  },

  DEPARTMENTS_TREE_CLEANUP_BEGIN: 'DEPARTMENTS_TREE_CLEANUP_BEGIN',
  DEPARTMENTS_TREE_CLEANUP_SUCCESS: 'DEPARTMENTS_TREE_CLEANUP_SUCCESS',
  DEPARTMENTS_TREE_CLEANUP_ERR: 'DEPARTMENTS_TREE_CLEANUP_ERR',

  departmentsTreeCleanupBegin: () => {
    return {
      type: actions.DEPARTMENTS_TREE_CLEANUP_BEGIN,
    };
  },

  departmentsTreeCleanupSuccess: data => {
    return {
      type: actions.DEPARTMENTS_TREE_CLEANUP_SUCCESS,
      data,
    };
  },

  departmentsTreeCleanupErr: err => {
    return {
      type: actions.DEPARTMENTS_TREE_CLEANUP_ERR,
      err,
    };
  },

  DEPARTMENTS_UPDATE_BEGIN: 'DEPARTMENTS_UPDATE_BEGIN',
  DEPARTMENTS_UPDATE_SUCCESS: 'DEPARTMENTS_UPDATE_SUCCESS',
  DEPARTMENTS_UPDATE_ERR: 'DEPARTMENTS_UPDATE_ERR',

  departmentsUpdateBegin: () => {
    return {
      type: actions.DEPARTMENTS_UPDATE_BEGIN,
    };
  },

  departmentsUpdateSuccess: data => {
    return {
      type: actions.DEPARTMENTS_UPDATE_SUCCESS,
      data,
    };
  },

  departmentsUpdateErr: err => {
    return {
      type: actions.DEPARTMENTS_UPDATE_ERR,
      err,
    };
  },

  DEPARTMENTS_CLEANUP_BEGIN: 'DEPARTMENTS_CLEANUP_BEGIN',
  DEPARTMENTS_CLEANUP_SUCCESS: 'DEPARTMENTS_CLEANUP_SUCCESS',
  DEPARTMENTS_CLEANUP_ERR: 'DEPARTMENTS_CLEANUP_ERR',

  departmentsCleanupBegin: () => {
    return {
      type: actions.DEPARTMENTS_CLEANUP_BEGIN,
    };
  },

  departmentsCleanupSuccess: data => {
    return {
      type: actions.DEPARTMENTS_CLEANUP_SUCCESS,
      data,
    };
  },

  departmentsCleanupErr: err => {
    return {
      type: actions.DEPARTMENTS_CLEANUP_ERR,
      err,
    };
  },

  DEPARTMENT_UPDATE_BEGIN: 'DEPARTMENT_UPDATE_BEGIN',
  DEPARTMENT_UPDATE_SUCCESS: 'DEPARTMENT_UPDATE_SUCCESS',
  DEPARTMENT_UPDATE_ERR: 'DEPARTMENT_UPDATE_ERR',

  departmentUpdateBegin: () => {
    return {
      type: actions.DEPARTMENT_UPDATE_BEGIN,
    };
  },

  departmentUpdateSuccess: data => {
    return {
      type: actions.DEPARTMENT_UPDATE_SUCCESS,
      data,
    };
  },

  departmentUpdateErr: err => {
    return {
      type: actions.DEPARTMENT_UPDATE_ERR,
      err,
    };
  },

  DEPARTMENT_CLEANUP_BEGIN: 'DEPARTMENT_CLEANUP_BEGIN',
  DEPARTMENT_CLEANUP_SUCCESS: 'DEPARTMENT_CLEANUP_SUCCESS',
  DEPARTMENT_CLEANUP_ERR: 'DEPARTMENT_CLEANUP_ERR',

  departmentCleanupBegin: () => {
    return {
      type: actions.DEPARTMENT_CLEANUP_BEGIN,
    };
  },

  departmentCleanupSuccess: () => {
    return {
      type: actions.DEPARTMENT_CLEANUP_SUCCESS,
    };
  },

  departmentCleanupErr: err => {
    return {
      type: actions.DEPARTMENT_CLEANUP_ERR,
      err,
    };
  },

  DEPARTMENT_SAVE_BEGIN: 'DEPARTMENT_SAVE_BEGIN',
  DEPARTMENT_SAVE_SUCCESS: 'DEPARTMENT_SAVE_SUCCESS',
  DEPARTMENT_SAVE_ERR: 'DEPARTMENT_SAVE_ERR',

  departmentSaveBegin: () => {
    return {
      type: actions.DEPARTMENT_SAVE_BEGIN,
    };
  },

  departmentSaveSuccess: data => {
    return {
      type: actions.DEPARTMENT_SAVE_SUCCESS,
      data,
    };
  },

  departmentSaveErr: err => {
    return {
      type: actions.DEPARTMENT_SAVE_ERR,
      err,
    };
  },

  DEPARTMENT_DELETE_BEGIN: 'DEPARTMENT_DELETE_BEGIN',
  DEPARTMENT_DELETE_SUCCESS: 'DEPARTMENT_DELETE_SUCCESS',
  DEPARTMENT_DELETE_ERR: 'DEPARTMENT_DELETE_ERR',

  departmentDeleteBegin: () => {
    return {
      type: actions.DEPARTMENT_DELETE_BEGIN,
    };
  },

  departmentDeleteSuccess: data => {
    return {
      type: actions.DEPARTMENT_DELETE_SUCCESS,
      data,
    };
  },

  departmentDeleteErr: err => {
    return {
      type: actions.DEPARTMENT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
