const actions = {
  USER_STATUSES_LIST_UPDATE_BEGIN: 'USER_STATUSES_LIST_UPDATE_BEGIN',
  USER_STATUSES_LIST_UPDATE_SUCCESS: 'USER_STATUSES_LIST_UPDATE_SUCCESS',
  USER_STATUSES_LIST_UPDATE_ERR: 'USER_STATUSES_LIST_UPDATE_ERR',

  userStatusesListUpdateBegin: () => {
    return {
      type: actions.USER_STATUSES_LIST_UPDATE_BEGIN,
    };
  },

  userStatusesListUpdateSuccess: data => {
    return {
      type: actions.USER_STATUSES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  userStatusesListUpdateErr: err => {
    return {
      type: actions.USER_STATUSES_LIST_UPDATE_ERR,
      err,
    };
  },

  USER_STATUSES_LIST_CLEANUP_BEGIN: 'USER_STATUSES_LIST_CLEANUP_BEGIN',
  USER_STATUSES_LIST_CLEANUP_SUCCESS: 'USER_STATUSES_LIST_CLEANUP_SUCCESS',
  USER_STATUSES_LIST_CLEANUP_ERR: 'USER_STATUSES_LIST_CLEANUP_ERR',

  userStatusesListCleanupBegin: () => {
    return {
      type: actions.USER_STATUSES_LIST_CLEANUP_BEGIN,
    };
  },

  userStatusesListCleanupSuccess: () => {
    return {
      type: actions.USER_STATUSES_LIST_CLEANUP_SUCCESS,
    };
  },

  userStatusesListCleanupErr: err => {
    return {
      type: actions.USER_STATUSES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  