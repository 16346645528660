import { Popover, Rate, Space, Tooltip, Typography } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import Moment from 'react-moment';
import { Achievement, AchievementGroup } from './style';
import CONSTANTS from '../../config/constants';
import format from '../../config/format';
import { theme } from '../../config/theme/themeVariables';

const { Text, Title } = Typography;

const emptyImage = require('../../static/img/achievement.png');

const renderAchievementImage = (achievement, size = 48) =>
  achievement && <Achievement src={achievement.image_url ?? emptyImage} alt={achievement.name} size={size} />;

const renderAchievement = (achievement, size = 48) =>
  achievement && (
    <Popover
      key={achievement.id}
      overlayClassName="popover-card"
      content={
        <Space direction="vertical" size="small" style={{ textAlign: 'center' }}>
          {renderAchievementImage(achievement, 128)}
          <Title level={5} style={{ margin: 0 }}>
            {achievement.name}
          </Title>
          <div>
            <Rate value={achievement.rating} tooltips={CONSTANTS.ACHIEVEMENT.RATINGS} count={3} disabled />
            <div>{CONSTANTS.ACHIEVEMENT.RATINGS[parseInt(achievement.rating) - 1]}</div>
          </div>
          <Text type="secondary" style={{ display: 'block', maxWidth: 250 }}>
            {achievement.description}
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            <Tooltip title={<Moment format={format.input.dateTime}>{achievement?.created_at}</Moment>}>
              <Moment style={{ textDecoration: 'underline dotted' }} fromNow>
                {achievement?.created_at}
              </Moment>
            </Tooltip>
          </Text>
        </Space>
      }
    >
      {renderAchievementImage(achievement, size)}
    </Popover>
  );

const renderAchievements = (achievements, size = 48, maxCount = 4) =>
  achievements?.length > 0 && (
    <AchievementGroup
      style={{ marginTop: 8 }}
      maxCount={maxCount}
      maxStyle={{
        color: theme['white-color'],
        backgroundColor: theme['link-color'],
        height: size,
        width: size,
        lineHeight: `${size}px`,
      }}
    >
      {achievements.map(achievement => renderAchievement(achievement, size))}
    </AchievementGroup>
  );

const renderBalance = (balance, align = 'center') => (
  // balance > 0 &&
  <Tooltip title="Saldo de Conquistas">
    <div style={{ fontSize: 13, marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: align, gap: 4 }}>
      <FeatherIcon icon="award" size={16} style={{ color: theme['primary-color'] }} />
      <Text type="secondary">Saldo de Conquistas: {format.thousands(balance)}</Text>
    </div>
  </Tooltip>
);

export { renderAchievementImage, renderAchievement, renderAchievements, renderBalance, emptyImage };
