import actions from './actions';

const initialState = {
  data: undefined,
  loading: false,
  error: null,
};

const {
  CLIENT_EMPLOYEES_UPDATE_BEGIN,
  CLIENT_EMPLOYEES_UPDATE_SUCCESS,
  CLIENT_EMPLOYEES_UPDATE_ERR,

  CLIENT_EMPLOYEES_CLEANUP_BEGIN,
  CLIENT_EMPLOYEES_CLEANUP_SUCCESS,
  CLIENT_EMPLOYEES_CLEANUP_ERR,

  CLIENT_EMPLOYEES_TRANSFER_BEGIN,
  CLIENT_EMPLOYEES_TRANSFER_SUCCESS,
  CLIENT_EMPLOYEES_TRANSFER_ERR,
} = actions;

const clientEmployeesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_EMPLOYEES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_EMPLOYEES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEES_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_CLEANUP_SUCCESS:
      return initialState;

    case CLIENT_EMPLOYEES_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEES_TRANSFER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_TRANSFER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_EMPLOYEES_TRANSFER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: undefined,
  loading: false,
  error: null,
};

const {
  CLIENT_EMPLOYEES_LIST_UPDATE_BEGIN,
  CLIENT_EMPLOYEES_LIST_UPDATE_SUCCESS,
  CLIENT_EMPLOYEES_LIST_UPDATE_ERR,

  CLIENT_EMPLOYEES_LIST_CLEANUP_BEGIN,
  CLIENT_EMPLOYEES_LIST_CLEANUP_SUCCESS,
  CLIENT_EMPLOYEES_LIST_CLEANUP_ERR,
} = actions;

const clientEmployeesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_EMPLOYEES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_EMPLOYEES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case CLIENT_EMPLOYEES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialReportState = {
  data: [],
  filters: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_EMPLOYEES_REPORT_UPDATE_BEGIN,
  CLIENT_EMPLOYEES_REPORT_UPDATE_SUCCESS,
  CLIENT_EMPLOYEES_REPORT_UPDATE_ERR,

  CLIENT_EMPLOYEES_REPORT_CLEANUP_BEGIN,
  CLIENT_EMPLOYEES_REPORT_CLEANUP_SUCCESS,
  CLIENT_EMPLOYEES_REPORT_CLEANUP_ERR,
} = actions;

const clientEmployeesReportReducer = (state = initialReportState, action) => {
  const { type, data, filters, err } = action;
  switch (type) {
    case CLIENT_EMPLOYEES_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        filters,
        loading: false,
      };

    case CLIENT_EMPLOYEES_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEES_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_REPORT_CLEANUP_SUCCESS:
      return initialReportState;

    case CLIENT_EMPLOYEES_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialMatchReportState = {
  data: [],
  filters: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_BEGIN,
  CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_SUCCESS,
  CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_ERR,

  CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_BEGIN,
  CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_SUCCESS,
  CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_ERR,
} = actions;

const clientEmployeesMatchReportReducer = (state = initialMatchReportState, action) => {
  const { type, data, filters, err } = action;
  switch (type) {
    case CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        filters,
        loading: false,
      };

    case CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_SUCCESS:
      return initialMatchReportState;

    case CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CLIENT_EMPLOYEE_UPDATE_BEGIN,
  CLIENT_EMPLOYEE_UPDATE_SUCCESS,
  CLIENT_EMPLOYEE_UPDATE_ERR,

  CLIENT_EMPLOYEE_CLEANUP_BEGIN,
  CLIENT_EMPLOYEE_CLEANUP_SUCCESS,
  CLIENT_EMPLOYEE_CLEANUP_ERR,

  CLIENT_EMPLOYEE_SAVE_BEGIN,
  CLIENT_EMPLOYEE_SAVE_SUCCESS,
  CLIENT_EMPLOYEE_SAVE_ERR,

  CLIENT_EMPLOYEE_DELETE_BEGIN,
  CLIENT_EMPLOYEE_DELETE_SUCCESS,
  CLIENT_EMPLOYEE_DELETE_ERR,
} = actions;

const clientEmployeeReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_EMPLOYEE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_EMPLOYEE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_EMPLOYEE_CLEANUP_SUCCESS:
      return initialSingleState;

    case CLIENT_EMPLOYEE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_EMPLOYEE_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CLIENT_EMPLOYEE_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CLIENT_EMPLOYEE_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case CLIENT_EMPLOYEE_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CLIENT_EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case CLIENT_EMPLOYEE_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

export {
  clientEmployeesReducer,
  clientEmployeesListReducer,
  clientEmployeesReportReducer,
  clientEmployeesMatchReportReducer,
  clientEmployeeReducer,
};
