import { Avatar } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../config/constants';
import { theme } from '../../config/theme/themeVariables';
// eslint-disable-next-line import/no-cycle
import UserPopover from '../../components/users/Popover';

const emptyPhoto = require('../../static/img/user.png');

const renderStatus = user => (
  <span
    className="status-label"
    style={{
      color: user.user_status.color_hex,
      backgroundColor: `${user.user_status.color_hex}15`,
      padding: '4px 12px',
      borderRadius: '12px',
    }}
  >
    {user.user_status.name}
  </span>
);

const renderInitials = user => {
  let output = '';
  if (user?.name) {
    const names = user.name.split(' ');
    output += names[0][0];
    if (names.length > 1) {
      output += names[names.length - 1][0];
    }
  }
  return output;
};

const renderUserName = (user, searchText = undefined) => {
  const highlight =
    searchText !== undefined ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={searchText ? searchText.split(';').map(searchTextWord => searchTextWord) : []}
        autoEscape
        textToHighlight={user?.name}
        title={user?.name}
      />
    ) : (
      user?.name
    );

  return user?.user_status_id === CONSTANTS.USER_STATUS.INATIVO ? (
    <del className="text-danger">{highlight}</del>
  ) : (
    highlight
  );
};

const renderPicture = (user, size = 'small') => (
  <Avatar
    src={user?.photo_thumbnail_url ?? emptyPhoto}
    // icon={user?.photo_thumbnail_url}
    size={size}
    style={{ backgroundColor: user?.photo_thumbnail_url ? theme['white-color'] : theme['link-color'] }}
  >
    {!user?.photo_thumbnail_url ? renderInitials(user) : ''}
  </Avatar>
);

const UserLink = ({ children, user, popover = true }) => (
  <Link to={`/admin/users/view/${user?.id}`} title={user?.name}>
    {popover ? (
      <UserPopover key={user?.id} id={user?.id}>
        <span style={{ textDecoration: 'underline dotted' }}>{children}</span>
      </UserPopover>
    ) : (
      children
    )}
  </Link>
);

const renderUser = (user, picture = 'small', searchText = undefined) => (
  <>
    {picture !== false && <span style={{ marginRight: 6 }}>{renderPicture(user, picture)}</span>}
    {renderUserName(user, searchText)}
  </>
);

/**
 * renderUserLink
 *
 * @param object user
 * @param bool link
 * @param string|false picture small|large
 * @param string searchText
 * @param bool popover
 * @returns
 */
const renderUserLink = (user, link = true, picture = 'small', searchText = undefined, popover = true) =>
  user ? (
    link ? (
      <UserLink user={user} popover={popover}>
        {renderUser(user, picture, searchText)}
      </UserLink>
    ) : (
      renderUser(user, picture, searchText)
    )
  ) : (
    ''
  );

const renderUsers = (users, link = true, size = 'default') => (
  <Avatar.Group maxCount={5} maxStyle={{ color: theme['white-color'], backgroundColor: theme['link-color'] }}>
    {users?.map(
      user =>
        user?.name &&
        (link ? (
          <UserLink key={user.id} user={user}>
            {renderPicture(user, size)}
          </UserLink>
        ) : (
          renderPicture(user, size)
        )),
    )}
  </Avatar.Group>
);

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const sources = [
  {
    key: 'clients',
    title: 'Clientes',
    singular: 'Cliente',
    description: 'Lista de usuários de clientes',
    icon: 'users',
    link: '/admin/users/clients',
  },
  {
    key: 'employees',
    title: 'Colaboradores',
    singular: 'Colaborador',
    description: 'Lista de usuários de colaboradores',
    icon: 'users',
    link: '/admin/users/employees',
  },
];

export {
  emptyPhoto,
  renderStatus,
  renderInitials,
  renderPicture,
  UserLink,
  renderUserLink,
  renderUser,
  renderUserName,
  renderUsers,
  dataURLtoFile,
  sources,
};
