import Styled from 'styled-components';
import { Avatar } from 'antd';

const Achievement = Styled(Avatar)`
  border-radius: 100%;
`;

const AchievementGroup = Styled(Avatar.Group)`
  .ant-avatar {
    border: none !important;
  }
`;

export { Achievement, AchievementGroup };
