const actions = {
  CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_BEGIN: 'CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_BEGIN',
  CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_SUCCESS: 'CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_SUCCESS',
  CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_ERR: 'CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_ERR',

  cardTemplateRecurrencesListUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_BEGIN,
    };
  },

  cardTemplateRecurrencesListUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplateRecurrencesListUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_BEGIN: 'CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_BEGIN',
  CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_SUCCESS: 'CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_SUCCESS',
  CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_ERR: 'CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_ERR',

  cardTemplateRecurrencesListCleanupBegin: () => {
    return {
      type: actions.CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_BEGIN,
    };
  },

  cardTemplateRecurrencesListCleanupSuccess: () => {
    return {
      type: actions.CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_SUCCESS,
    };
  },

  cardTemplateRecurrencesListCleanupErr: err => {
    return {
      type: actions.CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
