import { message } from 'antd';
import actions from './actions';
import api from '../../config/api/index';

const messageKey = 'cache';

const { cacheClearAllBegin, cacheClearAllSuccess, cacheClearAllErr } = actions;

const cacheClearAllAction = () => {
  return async dispatch => {
    dispatch(cacheClearAllBegin());
    message.loading({ content: 'Limpando o cache', duration: 0, key: messageKey });

    api.Cache.clearAll()
      .then(response => {
        if (response.status === 200) {
          dispatch(cacheClearAllSuccess(response.data.clearAll));
          message.success({ content: 'Cache limpo com sucesso', key: messageKey });
        } else {
          dispatch(cacheClearAllErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(cacheClearAllErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { cacheCleanupBegin, cacheCleanupSuccess, cacheCleanupErr } = actions;

const cacheCleanupAction = () => {
  return async dispatch => {
    dispatch(cacheCleanupBegin());
    try {
      dispatch(cacheCleanupSuccess());
    } catch (err) {
      dispatch(cacheCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

export { cacheClearAllAction, cacheCleanupAction };
