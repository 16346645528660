const actions = {
  CLIENT_EMPLOYEES_UPDATE_BEGIN: 'CLIENT_EMPLOYEES_UPDATE_BEGIN',
  CLIENT_EMPLOYEES_UPDATE_SUCCESS: 'CLIENT_EMPLOYEES_UPDATE_SUCCESS',
  CLIENT_EMPLOYEES_UPDATE_ERR: 'CLIENT_EMPLOYEES_UPDATE_ERR',

  clientEmployeesUpdateBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_UPDATE_BEGIN,
    };
  },

  clientEmployeesUpdateSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEES_UPDATE_SUCCESS,
      data,
    };
  },

  clientEmployeesUpdateErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_UPDATE_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_CLEANUP_BEGIN: 'CLIENT_EMPLOYEES_CLEANUP_BEGIN',
  CLIENT_EMPLOYEES_CLEANUP_SUCCESS: 'CLIENT_EMPLOYEES_CLEANUP_SUCCESS',
  CLIENT_EMPLOYEES_CLEANUP_ERR: 'CLIENT_EMPLOYEES_CLEANUP_ERR',

  clientEmployeesCleanupBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_CLEANUP_BEGIN,
    };
  },

  clientEmployeesCleanupSuccess: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_CLEANUP_SUCCESS,
    };
  },

  clientEmployeesCleanupErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_LIST_UPDATE_BEGIN: 'CLIENT_EMPLOYEES_LIST_UPDATE_BEGIN',
  CLIENT_EMPLOYEES_LIST_UPDATE_SUCCESS: 'CLIENT_EMPLOYEES_LIST_UPDATE_SUCCESS',
  CLIENT_EMPLOYEES_LIST_UPDATE_ERR: 'CLIENT_EMPLOYEES_LIST_UPDATE_ERR',

  clientEmployeesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_LIST_UPDATE_BEGIN,
    };
  },

  clientEmployeesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientEmployeesListUpdateErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_LIST_CLEANUP_BEGIN: 'CLIENT_EMPLOYEES_LIST_CLEANUP_BEGIN',
  CLIENT_EMPLOYEES_LIST_CLEANUP_SUCCESS: 'CLIENT_EMPLOYEES_LIST_CLEANUP_SUCCESS',
  CLIENT_EMPLOYEES_LIST_CLEANUP_ERR: 'CLIENT_EMPLOYEES_LIST_CLEANUP_ERR',

  clientEmployeesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_LIST_CLEANUP_BEGIN,
    };
  },

  clientEmployeesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientEmployeesListCleanupErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_LIST_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_REPORT_UPDATE_BEGIN: 'CLIENT_EMPLOYEES_REPORT_UPDATE_BEGIN',
  CLIENT_EMPLOYEES_REPORT_UPDATE_SUCCESS: 'CLIENT_EMPLOYEES_REPORT_UPDATE_SUCCESS',
  CLIENT_EMPLOYEES_REPORT_UPDATE_ERR: 'CLIENT_EMPLOYEES_REPORT_UPDATE_ERR',

  clientEmployeesReportUpdateBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_REPORT_UPDATE_BEGIN,
    };
  },

  clientEmployeesReportUpdateSuccess: (data, filters) => {
    return {
      type: actions.CLIENT_EMPLOYEES_REPORT_UPDATE_SUCCESS,
      data,
      filters,
    };
  },

  clientEmployeesReportUpdateErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_REPORT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_REPORT_CLEANUP_BEGIN: 'CLIENT_EMPLOYEES_REPORT_CLEANUP_BEGIN',
  CLIENT_EMPLOYEES_REPORT_CLEANUP_SUCCESS: 'CLIENT_EMPLOYEES_REPORT_CLEANUP_SUCCESS',
  CLIENT_EMPLOYEES_REPORT_CLEANUP_ERR: 'CLIENT_EMPLOYEES_REPORT_CLEANUP_ERR',

  clientEmployeesReportCleanupBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_REPORT_CLEANUP_BEGIN,
    };
  },

  clientEmployeesReportCleanupSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEES_REPORT_CLEANUP_SUCCESS,
      data,
    };
  },

  clientEmployeesReportCleanupErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_REPORT_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_BEGIN: 'CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_BEGIN',
  CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_SUCCESS: 'CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_SUCCESS',
  CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_ERR: 'CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_ERR',

  clientEmployeesMatchReportUpdateBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_BEGIN,
    };
  },

  clientEmployeesMatchReportUpdateSuccess: (data, filters) => {
    return {
      type: actions.CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_SUCCESS,
      data,
      filters,
    };
  },

  clientEmployeesMatchReportUpdateErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_MATCH_REPORT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_BEGIN: 'CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_BEGIN',
  CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_SUCCESS: 'CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_SUCCESS',
  CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_ERR: 'CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_ERR',

  clientEmployeesMatchReportCleanupBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_BEGIN,
    };
  },

  clientEmployeesMatchReportCleanupSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_SUCCESS,
      data,
    };
  },

  clientEmployeesMatchReportCleanupErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_MATCH_REPORT_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEES_TRANSFER_BEGIN: 'CLIENT_EMPLOYEES_TRANSFER_BEGIN',
  CLIENT_EMPLOYEES_TRANSFER_SUCCESS: 'CLIENT_EMPLOYEES_TRANSFER_SUCCESS',
  CLIENT_EMPLOYEES_TRANSFER_ERR: 'CLIENT_EMPLOYEES_TRANSFER_ERR',

  clientEmployeesTransferBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEES_TRANSFER_BEGIN,
    };
  },

  clientEmployeesTransferSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEES_TRANSFER_SUCCESS,
      data,
    };
  },

  clientEmployeesTransferErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEES_TRANSFER_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEE_UPDATE_BEGIN: 'CLIENT_EMPLOYEE_UPDATE_BEGIN',
  CLIENT_EMPLOYEE_UPDATE_SUCCESS: 'CLIENT_EMPLOYEE_UPDATE_SUCCESS',
  CLIENT_EMPLOYEE_UPDATE_ERR: 'CLIENT_EMPLOYEE_UPDATE_ERR',

  clientEmployeeUpdateBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEE_UPDATE_BEGIN,
    };
  },

  clientEmployeeUpdateSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEE_UPDATE_SUCCESS,
      data,
    };
  },

  clientEmployeeUpdateErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEE_UPDATE_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEE_CLEANUP_BEGIN: 'CLIENT_EMPLOYEE_CLEANUP_BEGIN',
  CLIENT_EMPLOYEE_CLEANUP_SUCCESS: 'CLIENT_EMPLOYEE_CLEANUP_SUCCESS',
  CLIENT_EMPLOYEE_CLEANUP_ERR: 'CLIENT_EMPLOYEE_CLEANUP_ERR',

  clientEmployeeCleanupBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEE_CLEANUP_BEGIN,
    };
  },

  clientEmployeeCleanupSuccess: () => {
    return {
      type: actions.CLIENT_EMPLOYEE_CLEANUP_SUCCESS,
    };
  },

  clientEmployeeCleanupErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEE_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEE_SAVE_BEGIN: 'CLIENT_EMPLOYEE_SAVE_BEGIN',
  CLIENT_EMPLOYEE_SAVE_SUCCESS: 'CLIENT_EMPLOYEE_SAVE_SUCCESS',
  CLIENT_EMPLOYEE_SAVE_ERR: 'CLIENT_EMPLOYEE_SAVE_ERR',

  clientEmployeeSaveBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEE_SAVE_BEGIN,
    };
  },

  clientEmployeeSaveSuccess: data => {
    return {
      type: actions.CLIENT_EMPLOYEE_SAVE_SUCCESS,
      data,
    };
  },

  clientEmployeeSaveErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEE_SAVE_ERR,
      err,
    };
  },

  CLIENT_EMPLOYEE_DELETE_BEGIN: 'CLIENT_EMPLOYEE_DELETE_BEGIN',
  CLIENT_EMPLOYEE_DELETE_SUCCESS: 'CLIENT_EMPLOYEE_DELETE_SUCCESS',
  CLIENT_EMPLOYEE_DELETE_ERR: 'CLIENT_EMPLOYEE_DELETE_ERR',

  clientEmployeeDeleteBegin: () => {
    return {
      type: actions.CLIENT_EMPLOYEE_DELETE_BEGIN,
    };
  },

  clientEmployeeDeleteSuccess: () => {
    return {
      type: actions.CLIENT_EMPLOYEE_DELETE_SUCCESS,
    };
  },

  clientEmployeeDeleteErr: err => {
    return {
      type: actions.CLIENT_EMPLOYEE_DELETE_ERR,
      err,
    };
  },
};

export default actions;
