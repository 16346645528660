const actions = {
  CONTACTS_UPDATE_BEGIN: 'CONTACTS_UPDATE_BEGIN',
  CONTACTS_UPDATE_SUCCESS: 'CONTACTS_UPDATE_SUCCESS',
  CONTACTS_UPDATE_ERR: 'CONTACTS_UPDATE_ERR',

  contactsUpdateBegin: () => {
    return {
      type: actions.CONTACTS_UPDATE_BEGIN,
    };
  },

  contactsUpdateSuccess: data => {
    return {
      type: actions.CONTACTS_UPDATE_SUCCESS,
      data,
    };
  },

  contactsUpdateErr: err => {
    return {
      type: actions.CONTACTS_UPDATE_ERR,
      err,
    };
  },

  CONTACTS_CLEANUP_BEGIN: 'CONTACTS_CLEANUP_BEGIN',
  CONTACTS_CLEANUP_SUCCESS: 'CONTACTS_CLEANUP_SUCCESS',
  CONTACTS_CLEANUP_ERR: 'CONTACTS_CLEANUP_ERR',

  contactsCleanupBegin: () => {
    return {
      type: actions.CONTACTS_CLEANUP_BEGIN,
    };
  },

  contactsCleanupSuccess: data => {
    return {
      type: actions.CONTACTS_CLEANUP_SUCCESS,
      data,
    };
  },

  contactsCleanupErr: err => {
    return {
      type: actions.CONTACTS_CLEANUP_ERR,
      err,
    };
  },

  CONTACT_UPDATE_BEGIN: 'CONTACT_UPDATE_BEGIN',
  CONTACT_UPDATE_SUCCESS: 'CONTACT_UPDATE_SUCCESS',
  CONTACT_UPDATE_ERR: 'CONTACT_UPDATE_ERR',

  contactUpdateBegin: () => {
    return {
      type: actions.CONTACT_UPDATE_BEGIN,
    };
  },

  contactUpdateSuccess: data => {
    return {
      type: actions.CONTACT_UPDATE_SUCCESS,
      data,
    };
  },

  contactUpdateErr: err => {
    return {
      type: actions.CONTACT_UPDATE_ERR,
      err,
    };
  },

  CONTACT_CLEANUP_BEGIN: 'CONTACT_CLEANUP_BEGIN',
  CONTACT_CLEANUP_SUCCESS: 'CONTACT_CLEANUP_SUCCESS',
  CONTACT_CLEANUP_ERR: 'CONTACT_CLEANUP_ERR',

  contactCleanupBegin: () => {
    return {
      type: actions.CONTACT_CLEANUP_BEGIN,
    };
  },

  contactCleanupSuccess: () => {
    return {
      type: actions.CONTACT_CLEANUP_SUCCESS,
    };
  },

  contactCleanupErr: err => {
    return {
      type: actions.CONTACT_CLEANUP_ERR,
      err,
    };
  },

  CONTACT_SAVE_BEGIN: 'CONTACT_SAVE_BEGIN',
  CONTACT_SAVE_SUCCESS: 'CONTACT_SAVE_SUCCESS',
  CONTACT_SAVE_ERR: 'CONTACT_SAVE_ERR',

  contactSaveBegin: () => {
    return {
      type: actions.CONTACT_SAVE_BEGIN,
    };
  },

  contactSaveSuccess: data => {
    return {
      type: actions.CONTACT_SAVE_SUCCESS,
      data,
    };
  },

  contactSaveErr: err => {
    return {
      type: actions.CONTACT_SAVE_ERR,
      err,
    };
  },

  CONTACT_DELETE_BEGIN: 'CONTACT_DELETE_BEGIN',
  CONTACT_DELETE_SUCCESS: 'CONTACT_DELETE_SUCCESS',
  CONTACT_DELETE_ERR: 'CONTACT_DELETE_ERR',

  contactDeleteBegin: () => {
    return {
      type: actions.CONTACT_DELETE_BEGIN,
    };
  },

  contactDeleteSuccess: data => {
    return {
      type: actions.CONTACT_DELETE_SUCCESS,
      data,
    };
  },

  contactDeleteErr: err => {
    return {
      type: actions.CONTACT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
