import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  saving: false,
  deleting: false,
  error: null,
};

const {
  CARD_JUSTIFICATIONS_UPDATE_BEGIN,
  CARD_JUSTIFICATIONS_UPDATE_SUCCESS,
  CARD_JUSTIFICATIONS_UPDATE_ERR,

  CARD_JUSTIFICATIONS_CLEANUP_BEGIN,
  CARD_JUSTIFICATIONS_CLEANUP_SUCCESS,
  CARD_JUSTIFICATIONS_CLEANUP_ERR,

  CARD_JUSTIFICATIONS_UPDATE_STATUS_BEGIN,
  CARD_JUSTIFICATIONS_UPDATE_STATUS_SUCCESS,
  CARD_JUSTIFICATIONS_UPDATE_STATUS_ERR,

  CARD_JUSTIFICATIONS_SAVE_MANY_BEGIN,
  CARD_JUSTIFICATIONS_SAVE_MANY_SUCCESS,
  CARD_JUSTIFICATIONS_SAVE_MANY_ERR,

  CARD_JUSTIFICATION_DELETE_BEGIN,
  CARD_JUSTIFICATION_DELETE_SUCCESS,
  CARD_JUSTIFICATION_DELETE_ERR,
} = actions;

const cardJustificationsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_JUSTIFICATIONS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_JUSTIFICATIONS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATIONS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATIONS_CLEANUP_SUCCESS:
      return initialState;

    case CARD_JUSTIFICATIONS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATIONS_UPDATE_STATUS_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_JUSTIFICATIONS_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_JUSTIFICATIONS_UPDATE_STATUS_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case CARD_JUSTIFICATIONS_SAVE_MANY_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_JUSTIFICATIONS_SAVE_MANY_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_JUSTIFICATIONS_SAVE_MANY_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case CARD_JUSTIFICATION_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CARD_JUSTIFICATION_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CARD_JUSTIFICATION_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CARD_JUSTIFICATION_UPDATE_BEGIN,
  CARD_JUSTIFICATION_UPDATE_SUCCESS,
  CARD_JUSTIFICATION_UPDATE_ERR,

  CARD_JUSTIFICATION_CLEANUP_BEGIN,
  CARD_JUSTIFICATION_CLEANUP_SUCCESS,
  CARD_JUSTIFICATION_CLEANUP_ERR,

  CARD_JUSTIFICATION_SAVE_BEGIN,
  CARD_JUSTIFICATION_SAVE_SUCCESS,
  CARD_JUSTIFICATION_SAVE_ERR,
} = actions;

const cardJustificationReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_JUSTIFICATION_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_JUSTIFICATION_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_CLEANUP_SUCCESS:
      return initialSingleState;

    case CARD_JUSTIFICATION_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_JUSTIFICATION_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_JUSTIFICATION_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { cardJustificationsReducer, cardJustificationReducer };
