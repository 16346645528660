const actions = {
  NEWSLETTERS_LIST_UPDATE_BEGIN: 'NEWSLETTERS_LIST_UPDATE_BEGIN',
  NEWSLETTERS_LIST_UPDATE_SUCCESS: 'NEWSLETTERS_LIST_UPDATE_SUCCESS',
  NEWSLETTERS_LIST_UPDATE_ERR: 'NEWSLETTERS_LIST_UPDATE_ERR',

  newslettersListUpdateBegin: () => {
    return {
      type: actions.NEWSLETTERS_LIST_UPDATE_BEGIN,
    };
  },

  newslettersListUpdateSuccess: data => {
    return {
      type: actions.NEWSLETTERS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  newslettersListUpdateErr: err => {
    return {
      type: actions.NEWSLETTERS_LIST_UPDATE_ERR,
      err,
    };
  },

  NEWSLETTERS_LIST_CLEANUP_BEGIN: 'NEWSLETTERS_LIST_CLEANUP_BEGIN',
  NEWSLETTERS_LIST_CLEANUP_SUCCESS: 'NEWSLETTERS_LIST_CLEANUP_SUCCESS',
  NEWSLETTERS_LIST_CLEANUP_ERR: 'NEWSLETTERS_LIST_CLEANUP_ERR',

  newslettersListCleanupBegin: () => {
    return {
      type: actions.NEWSLETTERS_LIST_CLEANUP_BEGIN,
    };
  },

  newslettersListCleanupSuccess: () => {
    return {
      type: actions.NEWSLETTERS_LIST_CLEANUP_SUCCESS,
    };
  },

  newslettersListCleanupErr: err => {
    return {
      type: actions.NEWSLETTERS_LIST_CLEANUP_ERR,
      err,
    };
  },

  NEWSLETTERS_READ_BEGIN: 'NEWSLETTERS_READ_BEGIN',
  NEWSLETTERS_READ_SUCCESS: 'NEWSLETTERS_READ_SUCCESS',
  NEWSLETTERS_READ_ERR: 'NEWSLETTERS_READ_ERR',

  newslettersReadBegin: () => {
    return {
      type: actions.NEWSLETTERS_READ_BEGIN,
    };
  },

  newslettersReadSuccess: () => {
    return {
      type: actions.NEWSLETTERS_READ_SUCCESS,
    };
  },

  newslettersReadErr: err => {
    return {
      type: actions.NEWSLETTERS_READ_ERR,
      err,
    };
  },

  NEWSLETTERS_PAGINATION_UPDATE_BEGIN: 'NEWSLETTERS_PAGINATION_UPDATE_BEGIN',
  NEWSLETTERS_PAGINATION_UPDATE_SUCCESS: 'NEWSLETTERS_PAGINATION_UPDATE_SUCCESS',
  NEWSLETTERS_PAGINATION_UPDATE_ERR: 'NEWSLETTERS_PAGINATION_UPDATE_ERR',

  newslettersPaginationUpdateBegin: () => {
    return {
      type: actions.NEWSLETTERS_PAGINATION_UPDATE_BEGIN,
    };
  },

  newslettersPaginationUpdateSuccess: data => {
    return {
      type: actions.NEWSLETTERS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  newslettersPaginationUpdateErr: err => {
    return {
      type: actions.NEWSLETTERS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  NEWSLETTERS_FILTERS_UPDATE_BEGIN: 'NEWSLETTERS_FILTERS_UPDATE_BEGIN',
  NEWSLETTERS_FILTERS_UPDATE_SUCCESS: 'NEWSLETTERS_FILTERS_UPDATE_SUCCESS',
  NEWSLETTERS_FILTERS_UPDATE_ERR: 'NEWSLETTERS_FILTERS_UPDATE_ERR',

  newslettersFiltersUpdateBegin: () => {
    return {
      type: actions.NEWSLETTERS_FILTERS_UPDATE_BEGIN,
    };
  },

  newslettersFiltersUpdateSuccess: data => {
    return {
      type: actions.NEWSLETTERS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  newslettersFiltersUpdateErr: err => {
    return {
      type: actions.NEWSLETTERS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  NEWSLETTERS_SORTER_UPDATE_BEGIN: 'NEWSLETTERS_SORTER_UPDATE_BEGIN',
  NEWSLETTERS_SORTER_UPDATE_SUCCESS: 'NEWSLETTERS_SORTER_UPDATE_SUCCESS',
  NEWSLETTERS_SORTER_UPDATE_ERR: 'NEWSLETTERS_SORTER_UPDATE_ERR',

  newslettersSorterUpdateBegin: () => {
    return {
      type: actions.NEWSLETTERS_SORTER_UPDATE_BEGIN,
    };
  },

  newslettersSorterUpdateSuccess: data => {
    return {
      type: actions.NEWSLETTERS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  newslettersSorterUpdateErr: err => {
    return {
      type: actions.NEWSLETTERS_SORTER_UPDATE_ERR,
      err,
    };
  },

  NEWSLETTERS_UPDATE_BEGIN: 'NEWSLETTERS_UPDATE_BEGIN',
  NEWSLETTERS_UPDATE_SUCCESS: 'NEWSLETTERS_UPDATE_SUCCESS',
  NEWSLETTERS_UPDATE_ERR: 'NEWSLETTERS_UPDATE_ERR',

  newslettersUpdateBegin: () => {
    return {
      type: actions.NEWSLETTERS_UPDATE_BEGIN,
    };
  },

  newslettersUpdateSuccess: data => {
    return {
      type: actions.NEWSLETTERS_UPDATE_SUCCESS,
      data,
    };
  },

  newslettersUpdateErr: err => {
    return {
      type: actions.NEWSLETTERS_UPDATE_ERR,
      err,
    };
  },

  NEWSLETTERS_CLEANUP_BEGIN: 'NEWSLETTERS_CLEANUP_BEGIN',
  NEWSLETTERS_CLEANUP_SUCCESS: 'NEWSLETTERS_CLEANUP_SUCCESS',
  NEWSLETTERS_CLEANUP_ERR: 'NEWSLETTERS_CLEANUP_ERR',

  newslettersCleanupBegin: () => {
    return {
      type: actions.NEWSLETTERS_CLEANUP_BEGIN,
    };
  },

  newslettersCleanupSuccess: () => {
    return {
      type: actions.NEWSLETTERS_CLEANUP_SUCCESS,
    };
  },

  newslettersCleanupErr: err => {
    return {
      type: actions.NEWSLETTERS_CLEANUP_ERR,
      err,
    };
  },

  NEWSLETTER_DELETE_BEGIN: 'NEWSLETTER_DELETE_BEGIN',
  NEWSLETTER_DELETE_SUCCESS: 'NEWSLETTER_DELETE_SUCCESS',
  NEWSLETTER_DELETE_ERR: 'NEWSLETTER_DELETE_ERR',

  newsletterDeleteBegin: () => {
    return {
      type: actions.NEWSLETTER_DELETE_BEGIN,
    };
  },

  newsletterDeleteSuccess: data => {
    return {
      type: actions.NEWSLETTER_DELETE_SUCCESS,
      data,
    };
  },

  newsletterDeleteErr: err => {
    return {
      type: actions.NEWSLETTER_DELETE_ERR,
      err,
    };
  },

  NEWSLETTER_UPDATE_BEGIN: 'NEWSLETTER_UPDATE_BEGIN',
  NEWSLETTER_UPDATE_SUCCESS: 'NEWSLETTER_UPDATE_SUCCESS',
  NEWSLETTER_UPDATE_ERR: 'NEWSLETTER_UPDATE_ERR',

  newsletterUpdateBegin: () => {
    return {
      type: actions.NEWSLETTER_UPDATE_BEGIN,
    };
  },

  newsletterUpdateSuccess: data => {
    return {
      type: actions.NEWSLETTER_UPDATE_SUCCESS,
      data,
    };
  },

  newsletterUpdateErr: err => {
    return {
      type: actions.NEWSLETTER_UPDATE_ERR,
      err,
    };
  },

  NEWSLETTER_CLEANUP_BEGIN: 'NEWSLETTER_CLEANUP_BEGIN',
  NEWSLETTER_CLEANUP_SUCCESS: 'NEWSLETTER_CLEANUP_SUCCESS',
  NEWSLETTER_CLEANUP_ERR: 'NEWSLETTER_CLEANUP_ERR',

  newsletterCleanupBegin: () => {
    return {
      type: actions.NEWSLETTER_CLEANUP_BEGIN,
    };
  },

  newsletterCleanupSuccess: () => {
    return {
      type: actions.NEWSLETTER_CLEANUP_SUCCESS,
    };
  },

  newsletterCleanupErr: err => {
    return {
      type: actions.NEWSLETTER_CLEANUP_ERR,
      err,
    };
  },

  NEWSLETTER_SAVE_BEGIN: 'NEWSLETTER_SAVE_BEGIN',
  NEWSLETTER_SAVE_SUCCESS: 'NEWSLETTER_SAVE_SUCCESS',
  NEWSLETTER_SAVE_ERR: 'NEWSLETTER_SAVE_ERR',

  newsletterSaveBegin: () => {
    return {
      type: actions.NEWSLETTER_SAVE_BEGIN,
    };
  },

  newsletterSaveSuccess: data => {
    return {
      type: actions.NEWSLETTER_SAVE_SUCCESS,
      data,
    };
  },

  newsletterSaveErr: err => {
    return {
      type: actions.NEWSLETTER_SAVE_ERR,
      err,
    };
  },
};

export default actions;
