import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  TAGS_LIST_UPDATE_BEGIN,
  TAGS_LIST_UPDATE_SUCCESS,
  TAGS_LIST_UPDATE_ERR,

  TAGS_LIST_CLEANUP_BEGIN,
  TAGS_LIST_CLEANUP_SUCCESS,
  TAGS_LIST_CLEANUP_ERR,

  TAG_DELETE_BEGIN,
  TAG_DELETE_SUCCESS,
  TAG_DELETE_ERR,
} = actions;

const tagsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TAGS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TAGS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case TAGS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case TAGS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TAGS_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case TAGS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case TAG_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case TAG_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case TAG_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  TAG_UPDATE_BEGIN,
  TAG_UPDATE_SUCCESS,
  TAG_UPDATE_ERR,

  TAG_CLEANUP_BEGIN,
  TAG_CLEANUP_SUCCESS,
  TAG_CLEANUP_ERR,

  TAG_SAVE_BEGIN,
  TAG_SAVE_SUCCESS,
  TAG_SAVE_ERR,
} = actions;

const tagReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TAG_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TAG_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case TAG_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case TAG_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TAG_CLEANUP_SUCCESS:
      return initialSingleState;

    case TAG_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case TAG_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case TAG_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case TAG_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { tagsListReducer, tagReducer };
