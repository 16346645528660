const actions = {
  SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_ERR: 'SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_ERR',

  sefazCeDteMessagesPaginationUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_BEGIN,
    };
  },

  sefazCeDteMessagesPaginationUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessagesPaginationUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_ERR: 'SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_ERR',

  sefazCeDteMessagesFiltersUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_BEGIN,
    };
  },

  sefazCeDteMessagesFiltersUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessagesFiltersUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_ERR: 'SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_ERR',

  sefazCeDteMessagesSorterUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_BEGIN,
    };
  },

  sefazCeDteMessagesSorterUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessagesSorterUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGES_UPDATE_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_UPDATE_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_UPDATE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_UPDATE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_UPDATE_ERR: 'SEFAZ_CE_DTE_MESSAGES_UPDATE_ERR',

  sefazCeDteMessagesUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_UPDATE_BEGIN,
    };
  },

  sefazCeDteMessagesUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessagesUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGES_CLEANUP_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_CLEANUP_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_CLEANUP_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_CLEANUP_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_CLEANUP_ERR: 'SEFAZ_CE_DTE_MESSAGES_CLEANUP_ERR',

  sefazCeDteMessagesCleanupBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_CLEANUP_BEGIN,
    };
  },

  sefazCeDteMessagesCleanupSuccess: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_CLEANUP_SUCCESS,
    };
  },

  sefazCeDteMessagesCleanupErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_CLEANUP_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGE_DELETE_BEGIN: 'SEFAZ_CE_DTE_MESSAGE_DELETE_BEGIN',
  SEFAZ_CE_DTE_MESSAGE_DELETE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGE_DELETE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGE_DELETE_ERR: 'SEFAZ_CE_DTE_MESSAGE_DELETE_ERR',

  sefazCeDteMessageDeleteBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_DELETE_BEGIN,
    };
  },

  sefazCeDteMessageDeleteSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_DELETE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessageDeleteErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_DELETE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGE_UPDATE_BEGIN: 'SEFAZ_CE_DTE_MESSAGE_UPDATE_BEGIN',
  SEFAZ_CE_DTE_MESSAGE_UPDATE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGE_UPDATE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGE_UPDATE_ERR: 'SEFAZ_CE_DTE_MESSAGE_UPDATE_ERR',

  sefazCeDteMessageUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_UPDATE_BEGIN,
    };
  },

  sefazCeDteMessageUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessageUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGE_CLEANUP_BEGIN: 'SEFAZ_CE_DTE_MESSAGE_CLEANUP_BEGIN',
  SEFAZ_CE_DTE_MESSAGE_CLEANUP_SUCCESS: 'SEFAZ_CE_DTE_MESSAGE_CLEANUP_SUCCESS',
  SEFAZ_CE_DTE_MESSAGE_CLEANUP_ERR: 'SEFAZ_CE_DTE_MESSAGE_CLEANUP_ERR',

  sefazCeDteMessageCleanupBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_CLEANUP_BEGIN,
    };
  },

  sefazCeDteMessageCleanupSuccess: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_CLEANUP_SUCCESS,
    };
  },

  sefazCeDteMessageCleanupErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_CLEANUP_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGE_SAVE_BEGIN: 'SEFAZ_CE_DTE_MESSAGE_SAVE_BEGIN',
  SEFAZ_CE_DTE_MESSAGE_SAVE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGE_SAVE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGE_SAVE_ERR: 'SEFAZ_CE_DTE_MESSAGE_SAVE_ERR',

  sefazCeDteMessageSaveBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_SAVE_BEGIN,
    };
  },

  sefazCeDteMessageSaveSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_SAVE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessageSaveErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGE_SAVE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_ERR: 'SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_ERR',

  sefazCeDteMessagesListUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_BEGIN,
    };
  },

  sefazCeDteMessagesListUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeDteMessagesListUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_BEGIN: 'SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_BEGIN',
  SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_SUCCESS: 'SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_SUCCESS',
  SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_ERR: 'SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_ERR',

  sefazCeDteMessagesListCleanupBegin: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_BEGIN,
    };
  },

  sefazCeDteMessagesListCleanupSuccess: () => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_SUCCESS,
    };
  },

  sefazCeDteMessagesListCleanupErr: err => {
    return {
      type: actions.SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
