import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ACCESSES_UPDATE_BEGIN,
  ACCESSES_UPDATE_SUCCESS,
  ACCESSES_UPDATE_ERR,

  ACCESSES_CLEANUP_BEGIN,
  ACCESSES_CLEANUP_SUCCESS,
  ACCESSES_CLEANUP_ERR,
} = actions;

const accessesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACCESSES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACCESSES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACCESSES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACCESSES_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACCESSES_CLEANUP_SUCCESS:
      return initialState;

    case ACCESSES_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default accessesReducer;
