const actions = {
  ASSET_CATEGORIES_LIST_UPDATE_BEGIN: 'ASSET_CATEGORIES_LIST_UPDATE_BEGIN',
  ASSET_CATEGORIES_LIST_UPDATE_SUCCESS: 'ASSET_CATEGORIES_LIST_UPDATE_SUCCESS',
  ASSET_CATEGORIES_LIST_UPDATE_ERR: 'ASSET_CATEGORIES_LIST_UPDATE_ERR',

  assetCategoriesListUpdateBegin: () => {
    return {
      type: actions.ASSET_CATEGORIES_LIST_UPDATE_BEGIN,
    };
  },

  assetCategoriesListUpdateSuccess: data => {
    return {
      type: actions.ASSET_CATEGORIES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  assetCategoriesListUpdateErr: err => {
    return {
      type: actions.ASSET_CATEGORIES_LIST_UPDATE_ERR,
      err,
    };
  },

  ASSET_CATEGORIES_LIST_CLEANUP_BEGIN: 'ASSET_CATEGORIES_LIST_CLEANUP_BEGIN',
  ASSET_CATEGORIES_LIST_CLEANUP_SUCCESS: 'ASSET_CATEGORIES_LIST_CLEANUP_SUCCESS',
  ASSET_CATEGORIES_LIST_CLEANUP_ERR: 'ASSET_CATEGORIES_LIST_CLEANUP_ERR',

  assetCategoriesListCleanupBegin: () => {
    return {
      type: actions.ASSET_CATEGORIES_LIST_CLEANUP_BEGIN,
    };
  },

  assetCategoriesListCleanupSuccess: data => {
    return {
      type: actions.ASSET_CATEGORIES_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  assetCategoriesListCleanupErr: err => {
    return {
      type: actions.ASSET_CATEGORIES_LIST_CLEANUP_ERR,
      err,
    };
  },

  ASSET_CATEGORIES_TREE_UPDATE_BEGIN: 'ASSET_CATEGORIES_TREE_UPDATE_BEGIN',
  ASSET_CATEGORIES_TREE_UPDATE_SUCCESS: 'ASSET_CATEGORIES_TREE_UPDATE_SUCCESS',
  ASSET_CATEGORIES_TREE_UPDATE_ERR: 'ASSET_CATEGORIES_TREE_UPDATE_ERR',

  assetCategoriesTreeUpdateBegin: () => {
    return {
      type: actions.ASSET_CATEGORIES_TREE_UPDATE_BEGIN,
    };
  },

  assetCategoriesTreeUpdateSuccess: data => {
    return {
      type: actions.ASSET_CATEGORIES_TREE_UPDATE_SUCCESS,
      data,
    };
  },

  assetCategoriesTreeUpdateErr: err => {
    return {
      type: actions.ASSET_CATEGORIES_TREE_UPDATE_ERR,
      err,
    };
  },

  ASSET_CATEGORIES_TREE_CLEANUP_BEGIN: 'ASSET_CATEGORIES_TREE_CLEANUP_BEGIN',
  ASSET_CATEGORIES_TREE_CLEANUP_SUCCESS: 'ASSET_CATEGORIES_TREE_CLEANUP_SUCCESS',
  ASSET_CATEGORIES_TREE_CLEANUP_ERR: 'ASSET_CATEGORIES_TREE_CLEANUP_ERR',

  assetCategoriesTreeCleanupBegin: () => {
    return {
      type: actions.ASSET_CATEGORIES_TREE_CLEANUP_BEGIN,
    };
  },

  assetCategoriesTreeCleanupSuccess: data => {
    return {
      type: actions.ASSET_CATEGORIES_TREE_CLEANUP_SUCCESS,
      data,
    };
  },

  assetCategoriesTreeCleanupErr: err => {
    return {
      type: actions.ASSET_CATEGORIES_TREE_CLEANUP_ERR,
      err,
    };
  },

  ASSET_CATEGORIES_CLEANUP_BEGIN: 'ASSET_CATEGORIES_CLEANUP_BEGIN',
  ASSET_CATEGORIES_CLEANUP_SUCCESS: 'ASSET_CATEGORIES_CLEANUP_SUCCESS',
  ASSET_CATEGORIES_CLEANUP_ERR: 'ASSET_CATEGORIES_CLEANUP_ERR',

  assetCategoriesCleanupBegin: () => {
    return {
      type: actions.ASSET_CATEGORIES_CLEANUP_BEGIN,
    };
  },

  assetCategoriesCleanupSuccess: data => {
    return {
      type: actions.ASSET_CATEGORIES_CLEANUP_SUCCESS,
      data,
    };
  },

  assetCategoriesCleanupErr: err => {
    return {
      type: actions.ASSET_CATEGORIES_CLEANUP_ERR,
      err,
    };
  },

  ASSET_CATEGORY_DELETE_BEGIN: 'ASSET_CATEGORY_DELETE_BEGIN',
  ASSET_CATEGORY_DELETE_SUCCESS: 'ASSET_CATEGORY_DELETE_SUCCESS',
  ASSET_CATEGORY_DELETE_ERR: 'ASSET_CATEGORY_DELETE_ERR',

  assetCategoryDeleteBegin: () => {
    return {
      type: actions.ASSET_CATEGORY_DELETE_BEGIN,
    };
  },

  assetCategoryDeleteSuccess: data => {
    return {
      type: actions.ASSET_CATEGORY_DELETE_SUCCESS,
      data,
    };
  },

  assetCategoryDeleteErr: err => {
    return {
      type: actions.ASSET_CATEGORY_DELETE_ERR,
      err,
    };
  },

  ASSET_CATEGORY_UPDATE_BEGIN: 'ASSET_CATEGORY_UPDATE_BEGIN',
  ASSET_CATEGORY_UPDATE_SUCCESS: 'ASSET_CATEGORY_UPDATE_SUCCESS',
  ASSET_CATEGORY_UPDATE_ERR: 'ASSET_CATEGORY_UPDATE_ERR',

  assetCategoryUpdateBegin: () => {
    return {
      type: actions.ASSET_CATEGORY_UPDATE_BEGIN,
    };
  },

  assetCategoryUpdateSuccess: data => {
    return {
      type: actions.ASSET_CATEGORY_UPDATE_SUCCESS,
      data,
    };
  },

  assetCategoryUpdateErr: err => {
    return {
      type: actions.ASSET_CATEGORY_UPDATE_ERR,
      err,
    };
  },

  ASSET_CATEGORY_CLEANUP_BEGIN: 'ASSET_CATEGORY_CLEANUP_BEGIN',
  ASSET_CATEGORY_CLEANUP_SUCCESS: 'ASSET_CATEGORY_CLEANUP_SUCCESS',
  ASSET_CATEGORY_CLEANUP_ERR: 'ASSET_CATEGORY_CLEANUP_ERR',

  assetCategoryCleanupBegin: () => {
    return {
      type: actions.ASSET_CATEGORY_CLEANUP_BEGIN,
    };
  },

  assetCategoryCleanupSuccess: () => {
    return {
      type: actions.ASSET_CATEGORY_CLEANUP_SUCCESS,
    };
  },

  assetCategoryCleanupErr: err => {
    return {
      type: actions.ASSET_CATEGORY_CLEANUP_ERR,
      err,
    };
  },

  ASSET_CATEGORY_SAVE_BEGIN: 'ASSET_CATEGORY_SAVE_BEGIN',
  ASSET_CATEGORY_SAVE_SUCCESS: 'ASSET_CATEGORY_SAVE_SUCCESS',
  ASSET_CATEGORY_SAVE_ERR: 'ASSET_CATEGORY_SAVE_ERR',

  assetCategorySaveBegin: () => {
    return {
      type: actions.ASSET_CATEGORY_SAVE_BEGIN,
    };
  },

  assetCategorySaveSuccess: data => {
    return {
      type: actions.ASSET_CATEGORY_SAVE_SUCCESS,
      data,
    };
  },

  assetCategorySaveErr: err => {
    return {
      type: actions.ASSET_CATEGORY_SAVE_ERR,
      err,
    };
  },
};

export default actions;
  