const actions = {
  USER_DEPARTMENTS_UPDATE_BEGIN: 'USER_DEPARTMENTS_UPDATE_BEGIN',
  USER_DEPARTMENTS_UPDATE_SUCCESS: 'USER_DEPARTMENTS_UPDATE_SUCCESS',
  USER_DEPARTMENTS_UPDATE_ERR: 'USER_DEPARTMENTS_UPDATE_ERR',

  userDepartmentsUpdateBegin: () => {
    return {
      type: actions.USER_DEPARTMENTS_UPDATE_BEGIN,
    };
  },

  userDepartmentsUpdateSuccess: data => {
    return {
      type: actions.USER_DEPARTMENTS_UPDATE_SUCCESS,
      data,
    };
  },

  userDepartmentsUpdateErr: err => {
    return {
      type: actions.USER_DEPARTMENTS_UPDATE_ERR,
      err,
    };
  },

  USER_DEPARTMENTS_CLEANUP_BEGIN: 'USER_DEPARTMENTS_CLEANUP_BEGIN',
  USER_DEPARTMENTS_CLEANUP_SUCCESS: 'USER_DEPARTMENTS_CLEANUP_SUCCESS',
  USER_DEPARTMENTS_CLEANUP_ERR: 'USER_DEPARTMENTS_CLEANUP_ERR',

  userDepartmentsCleanupBegin: () => {
    return {
      type: actions.USER_DEPARTMENTS_CLEANUP_BEGIN,
    };
  },

  userDepartmentsCleanupSuccess: data => {
    return {
      type: actions.USER_DEPARTMENTS_CLEANUP_SUCCESS,
      data,
    };
  },

  userDepartmentsCleanupErr: err => {
    return {
      type: actions.USER_DEPARTMENTS_CLEANUP_ERR,
      err,
    };
  },

  USER_DEPARTMENT_UPDATE_BEGIN: 'USER_DEPARTMENT_UPDATE_BEGIN',
  USER_DEPARTMENT_UPDATE_SUCCESS: 'USER_DEPARTMENT_UPDATE_SUCCESS',
  USER_DEPARTMENT_UPDATE_ERR: 'USER_DEPARTMENT_UPDATE_ERR',

  userDepartmentUpdateBegin: () => {
    return {
      type: actions.USER_DEPARTMENT_UPDATE_BEGIN,
    };
  },

  userDepartmentUpdateSuccess: data => {
    return {
      type: actions.USER_DEPARTMENT_UPDATE_SUCCESS,
      data,
    };
  },

  userDepartmentUpdateErr: err => {
    return {
      type: actions.USER_DEPARTMENT_UPDATE_ERR,
      err,
    };
  },

  USER_DEPARTMENT_CLEANUP_BEGIN: 'USER_DEPARTMENT_CLEANUP_BEGIN',
  USER_DEPARTMENT_CLEANUP_SUCCESS: 'USER_DEPARTMENT_CLEANUP_SUCCESS',
  USER_DEPARTMENT_CLEANUP_ERR: 'USER_DEPARTMENT_CLEANUP_ERR',

  userDepartmentCleanupBegin: () => {
    return {
      type: actions.USER_DEPARTMENT_CLEANUP_BEGIN,
    };
  },

  userDepartmentCleanupSuccess: () => {
    return {
      type: actions.USER_DEPARTMENT_CLEANUP_SUCCESS,
    };
  },

  userDepartmentCleanupErr: err => {
    return {
      type: actions.USER_DEPARTMENT_CLEANUP_ERR,
      err,
    };
  },

  USER_DEPARTMENT_SAVE_BEGIN: 'USER_DEPARTMENT_SAVE_BEGIN',
  USER_DEPARTMENT_SAVE_SUCCESS: 'USER_DEPARTMENT_SAVE_SUCCESS',
  USER_DEPARTMENT_SAVE_ERR: 'USER_DEPARTMENT_SAVE_ERR',

  userDepartmentSaveBegin: () => {
    return {
      type: actions.USER_DEPARTMENT_SAVE_BEGIN,
    };
  },

  userDepartmentSaveSuccess: data => {
    return {
      type: actions.USER_DEPARTMENT_SAVE_SUCCESS,
      data,
    };
  },

  userDepartmentSaveErr: err => {
    return {
      type: actions.USER_DEPARTMENT_SAVE_ERR,
      err,
    };
  },

  USER_DEPARTMENT_DELETE_BEGIN: 'USER_DEPARTMENT_DELETE_BEGIN',
  USER_DEPARTMENT_DELETE_SUCCESS: 'USER_DEPARTMENT_DELETE_SUCCESS',
  USER_DEPARTMENT_DELETE_ERR: 'USER_DEPARTMENT_DELETE_ERR',

  userDepartmentDeleteBegin: () => {
    return {
      type: actions.USER_DEPARTMENT_DELETE_BEGIN,
    };
  },

  userDepartmentDeleteSuccess: data => {
    return {
      type: actions.USER_DEPARTMENT_DELETE_SUCCESS,
      data,
    };
  },

  userDepartmentDeleteErr: err => {
    return {
      type: actions.USER_DEPARTMENT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
