const actions = {
  CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_BEGIN: 'CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_BEGIN',
  CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_SUCCESS: 'CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_SUCCESS',
  CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_ERR: 'CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_ERR',

  clientPartnerQualificationsListUpdateBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_BEGIN,
    };
  },

  clientPartnerQualificationsListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientPartnerQualificationsListUpdateErr: err => {
    return {
      type: actions.CLIENT_PARTNER_QUALIFICATIONS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_BEGIN: 'CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_BEGIN',
  CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_SUCCESS: 'CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_SUCCESS',
  CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_ERR: 'CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_ERR',

  clientPartnerQualificationsListCleanupBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_BEGIN,
    };
  },

  clientPartnerQualificationsListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientPartnerQualificationsListCleanupErr: err => {
    return {
      type: actions.CLIENT_PARTNER_QUALIFICATIONS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  