import actions from './actions';
import localStorage from '../../utility/localStorageControl';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,

  IDENTITY_UPDATE_BEGIN,
  IDENTITY_UPDATE_SUCCESS,
  IDENTITY_UPDATE_ERR,

  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
} = actions;

const initIdentityState = {
  data: undefined,
  loading: false,
  error: null,
};

const initState = {
  loggedIn: !!localStorage.getItem('token'),
  identity: initIdentityState,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    // LOGIN
    case LOGIN_BEGIN:
      return {
        ...state,
        identity: {
          ...state.identity,
          loading: true,
        },
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        identity: {
          ...state.identity,
          data,
          loading: false,
        },
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        identity: {
          ...state.identity,
          loading: false,
        },
        loading: false,
        error: err,
      };

    // IDENTITY
    case IDENTITY_UPDATE_BEGIN:
      return {
        ...state,
        identity: {
          ...state.identity,
          loading: true,
        },
      };
    case IDENTITY_UPDATE_SUCCESS:
      return {
        ...state,
        identity: {
          ...state.identity,
          data,
          loading: false,
        },
      };
    case IDENTITY_UPDATE_ERR:
      return {
        ...state,
        identity: {
          ...state.identity,
          loading: false,
          error: err,
        },
      };

    // LOGOUT
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        identity: initIdentityState,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default AuthReducer;
