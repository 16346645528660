import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const CardMapViewsIndex = lazy(() => import('../../container/cardMapViews/Index'));
const CardMapViewsMap = lazy(() => import('../../container/cardMapViews/Map'));
const NotFound = lazy(() => import('../../container/pages/404'));

const CardMapViewsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        {/* <Route exact path={path} component={CardMapViewsIndex} />
        <Route path={[`${path}/add`, `${path}/edit/:id`]} component={CardMapViewsIndex} /> */}
        <Route path={`${path}/map/:id`} component={CardMapViewsMap} />
        <Route path={path} component={CardMapViewsIndex} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default CardMapViewsRoute;
