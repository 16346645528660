import React from 'react';
import { Badge } from 'antd';

const renderTitle = (efficiencyPercentual, theme = 'dark') => {
  let status;

  if (efficiencyPercentual >= 100) {
    status = 'success';
  } else if (efficiencyPercentual >= 85) {
    status = 'default';
  } else if (efficiencyPercentual >= 70) {
    status = 'warning';
  } else if (efficiencyPercentual >= 0) {
    status = 'error';
  }

  return (
    <Badge
      status={status}
      text={
        <span className={theme}>
          <b>{`${efficiencyPercentual}%`}</b> de eficácia
        </span>
      }
    />
  );
};

export { renderTitle };
