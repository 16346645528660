const actions = {
  /**
   * INDEX
   */
  USERS_PAGINATION_UPDATE_BEGIN: 'USERS_PAGINATION_UPDATE_BEGIN',
  USERS_PAGINATION_UPDATE_SUCCESS: 'USERS_PAGINATION_UPDATE_SUCCESS',
  USERS_PAGINATION_UPDATE_ERR: 'USERS_PAGINATION_UPDATE_ERR',

  usersPaginationUpdateBegin: () => {
    return {
      type: actions.USERS_PAGINATION_UPDATE_BEGIN,
    };
  },

  usersPaginationUpdateSuccess: data => {
    return {
      type: actions.USERS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  usersPaginationUpdateErr: err => {
    return {
      type: actions.USERS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  USERS_FILTERS_UPDATE_BEGIN: 'USERS_FILTERS_UPDATE_BEGIN',
  USERS_FILTERS_UPDATE_SUCCESS: 'USERS_FILTERS_UPDATE_SUCCESS',
  USERS_FILTERS_UPDATE_ERR: 'USERS_FILTERS_UPDATE_ERR',

  usersFiltersUpdateBegin: () => {
    return {
      type: actions.USERS_FILTERS_UPDATE_BEGIN,
    };
  },

  usersFiltersUpdateSuccess: data => {
    return {
      type: actions.USERS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  usersFiltersUpdateErr: err => {
    return {
      type: actions.USERS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  USERS_SORTER_UPDATE_BEGIN: 'USERS_SORTER_UPDATE_BEGIN',
  USERS_SORTER_UPDATE_SUCCESS: 'USERS_SORTER_UPDATE_SUCCESS',
  USERS_SORTER_UPDATE_ERR: 'USERS_SORTER_UPDATE_ERR',

  usersSorterUpdateBegin: () => {
    return {
      type: actions.USERS_SORTER_UPDATE_BEGIN,
    };
  },

  usersSorterUpdateSuccess: data => {
    return {
      type: actions.USERS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  usersSorterUpdateErr: err => {
    return {
      type: actions.USERS_SORTER_UPDATE_ERR,
      err,
    };
  },

  USERS_UPDATE_BEGIN: 'USERS_UPDATE_BEGIN',
  USERS_UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  USERS_UPDATE_ERR: 'USERS_UPDATE_ERR',

  usersUpdateBegin: () => {
    return {
      type: actions.USERS_UPDATE_BEGIN,
    };
  },

  usersUpdateSuccess: data => {
    return {
      type: actions.USERS_UPDATE_SUCCESS,
      data,
    };
  },

  usersUpdateErr: err => {
    return {
      type: actions.USERS_UPDATE_ERR,
      err,
    };
  },

  USERS_CLEANUP_BEGIN: 'USERS_CLEANUP_BEGIN',
  USERS_CLEANUP_SUCCESS: 'USERS_CLEANUP_SUCCESS',
  USERS_CLEANUP_ERR: 'USERS_CLEANUP_ERR',

  usersCleanupBegin: () => {
    return {
      type: actions.USERS_CLEANUP_BEGIN,
    };
  },

  usersCleanupSuccess: () => {
    return {
      type: actions.USERS_CLEANUP_SUCCESS,
    };
  },

  usersCleanupErr: err => {
    return {
      type: actions.USERS_CLEANUP_ERR,
      err,
    };
  },

  /**
   * LIST
   */
  USERS_LIST_UPDATE_BEGIN: 'USERS_LIST_UPDATE_BEGIN',
  USERS_LIST_UPDATE_SUCCESS: 'USERS_LIST_UPDATE_SUCCESS',
  USERS_LIST_UPDATE_ERR: 'USERS_LIST_UPDATE_ERR',

  usersListUpdateBegin: () => {
    return {
      type: actions.USERS_LIST_UPDATE_BEGIN,
    };
  },

  usersListUpdateSuccess: (data, source) => {
    return {
      type: actions.USERS_LIST_UPDATE_SUCCESS,
      data,
      source,
    };
  },

  usersListUpdateErr: err => {
    return {
      type: actions.USERS_LIST_UPDATE_ERR,
      err,
    };
  },

  USERS_LIST_CLEANUP_BEGIN: 'USERS_LIST_CLEANUP_BEGIN',
  USERS_LIST_CLEANUP_SUCCESS: 'USERS_LIST_CLEANUP_SUCCESS',
  USERS_LIST_CLEANUP_ERR: 'USERS_LIST_CLEANUP_ERR',

  usersListCleanupBegin: () => {
    return {
      type: actions.USERS_LIST_CLEANUP_BEGIN,
    };
  },

  usersListCleanupSuccess: data => {
    return {
      type: actions.USERS_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  usersListCleanupErr: err => {
    return {
      type: actions.USERS_LIST_CLEANUP_ERR,
      err,
    };
  },

  /**
   * BIRTHDAYS
   */
  USERS_BIRTHDAYS_LIST_UPDATE_BEGIN: 'USERS_BIRTHDAYS_LIST_UPDATE_BEGIN',
  USERS_BIRTHDAYS_LIST_UPDATE_SUCCESS: 'USERS_BIRTHDAYS_LIST_UPDATE_SUCCESS',
  USERS_BIRTHDAYS_LIST_UPDATE_ERR: 'USERS_BIRTHDAYS_LIST_UPDATE_ERR',

  usersBirthdaysListUpdateBegin: () => {
    return {
      type: actions.USERS_BIRTHDAYS_LIST_UPDATE_BEGIN,
    };
  },

  usersBirthdaysListUpdateSuccess: data => {
    return {
      type: actions.USERS_BIRTHDAYS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  usersBirthdaysListUpdateErr: err => {
    return {
      type: actions.USERS_BIRTHDAYS_LIST_UPDATE_ERR,
      err,
    };
  },

  USERS_BIRTHDAYS_LIST_CLEANUP_BEGIN: 'USERS_BIRTHDAYS_LIST_CLEANUP_BEGIN',
  USERS_BIRTHDAYS_LIST_CLEANUP_SUCCESS: 'USERS_BIRTHDAYS_LIST_CLEANUP_SUCCESS',
  USERS_BIRTHDAYS_LIST_CLEANUP_ERR: 'USERS_BIRTHDAYS_LIST_CLEANUP_ERR',

  usersBirthdaysListCleanupBegin: () => {
    return {
      type: actions.USERS_BIRTHDAYS_LIST_CLEANUP_BEGIN,
    };
  },

  usersBirthdaysListCleanupSuccess: data => {
    return {
      type: actions.USERS_BIRTHDAYS_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  usersBirthdaysListCleanupErr: err => {
    return {
      type: actions.USERS_BIRTHDAYS_LIST_CLEANUP_ERR,
      err,
    };
  },

  /**
   * RECOVER PASSWORD
   */
  USER_RECOVER_PASSWORD_UPDATE_BEGIN: 'USER_RECOVER_PASSWORD_UPDATE_BEGIN',
  USER_RECOVER_PASSWORD_UPDATE_SUCCESS: 'USER_RECOVER_PASSWORD_UPDATE_SUCCESS',
  USER_RECOVER_PASSWORD_UPDATE_ERR: 'USER_RECOVER_PASSWORD_UPDATE_ERR',

  userRecoverPasswordUpdateBegin: () => {
    return {
      type: actions.USER_RECOVER_PASSWORD_UPDATE_BEGIN,
    };
  },

  userRecoverPasswordUpdateSuccess: data => {
    return {
      type: actions.USER_RECOVER_PASSWORD_UPDATE_SUCCESS,
      data,
    };
  },

  userRecoverPasswordUpdateErr: err => {
    return {
      type: actions.USER_RECOVER_PASSWORD_UPDATE_ERR,
      err,
    };
  },

  /**
   * RESET PASSWORD
   */
  USER_RESET_PASSWORD_UPDATE_BEGIN: 'USER_RESET_PASSWORD_UPDATE_BEGIN',
  USER_RESET_PASSWORD_UPDATE_SUCCESS: 'USER_RESET_PASSWORD_UPDATE_SUCCESS',
  USER_RESET_PASSWORD_UPDATE_ERR: 'USER_RESET_PASSWORD_UPDATE_ERR',

  userResetPasswordUpdateBegin: () => {
    return {
      type: actions.USER_RESET_PASSWORD_UPDATE_BEGIN,
    };
  },

  userResetPasswordUpdateSuccess: data => {
    return {
      type: actions.USER_RESET_PASSWORD_UPDATE_SUCCESS,
      data,
    };
  },

  userResetPasswordUpdateErr: err => {
    return {
      type: actions.USER_RESET_PASSWORD_UPDATE_ERR,
      err,
    };
  },

  /**
   * PHOTO
   */
  USER_PHOTO_UPDATE_BEGIN: 'USER_PHOTO_UPDATE_BEGIN',
  USER_PHOTO_UPDATE_SUCCESS: 'USER_PHOTO_UPDATE_SUCCESS',
  USER_PHOTO_UPDATE_ERR: 'USER_PHOTO_UPDATE_ERR',

  userPhotoUpdateBegin: () => {
    return {
      type: actions.USER_PHOTO_UPDATE_BEGIN,
    };
  },

  userPhotoUpdateSuccess: data => {
    return {
      type: actions.USER_PHOTO_UPDATE_SUCCESS,
      data,
    };
  },

  userPhotoUpdateErr: err => {
    return {
      type: actions.USER_PHOTO_UPDATE_ERR,
      err,
    };
  },

  USER_PHOTO_CLEANUP_BEGIN: 'USER_PHOTO_CLEANUP_BEGIN',
  USER_PHOTO_CLEANUP_SUCCESS: 'USER_PHOTO_CLEANUP_SUCCESS',
  USER_PHOTO_CLEANUP_ERR: 'USER_PHOTO_CLEANUP_ERR',

  userPhotoCleanupBegin: () => {
    return {
      type: actions.USER_PHOTO_CLEANUP_BEGIN,
    };
  },

  userPhotoCleanupSuccess: data => {
    return {
      type: actions.USER_PHOTO_CLEANUP_SUCCESS,
      data,
    };
  },

  userPhotoCleanupErr: err => {
    return {
      type: actions.USER_PHOTO_CLEANUP_ERR,
      err,
    };
  },

  /**
   * SIGNATURE
   */
  USER_SIGNATURE_UPDATE_BEGIN: 'USER_SIGNATURE_UPDATE_BEGIN',
  USER_SIGNATURE_UPDATE_SUCCESS: 'USER_SIGNATURE_UPDATE_SUCCESS',
  USER_SIGNATURE_UPDATE_ERR: 'USER_SIGNATURE_UPDATE_ERR',

  userSignatureUpdateBegin: () => {
    return {
      type: actions.USER_SIGNATURE_UPDATE_BEGIN,
    };
  },

  userSignatureUpdateSuccess: data => {
    return {
      type: actions.USER_SIGNATURE_UPDATE_SUCCESS,
      data,
    };
  },

  userSignatureUpdateErr: err => {
    return {
      type: actions.USER_SIGNATURE_UPDATE_ERR,
      err,
    };
  },

  /**
   * SINGLE
   */
  USER_UPDATE_BEGIN: 'USER_UPDATE_BEGIN',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_ERR: 'USER_UPDATE_ERR',

  userUpdateBegin: () => {
    return {
      type: actions.USER_UPDATE_BEGIN,
    };
  },

  userUpdateSuccess: data => {
    return {
      type: actions.USER_UPDATE_SUCCESS,
      data,
    };
  },

  userUpdateErr: err => {
    return {
      type: actions.USER_UPDATE_ERR,
      err,
    };
  },

  USER_CLEANUP_BEGIN: 'USER_CLEANUP_BEGIN',
  USER_CLEANUP_SUCCESS: 'USER_CLEANUP_SUCCESS',
  USER_CLEANUP_ERR: 'USER_CLEANUP_ERR',

  userCleanupBegin: () => {
    return {
      type: actions.USER_CLEANUP_BEGIN,
    };
  },

  userCleanupSuccess: () => {
    return {
      type: actions.USER_CLEANUP_SUCCESS,
    };
  },

  userCleanupErr: err => {
    return {
      type: actions.USER_CLEANUP_ERR,
      err,
    };
  },

  USER_SAVE_BEGIN: 'USER_SAVE_BEGIN',
  USER_SAVE_SUCCESS: 'USER_SAVE_SUCCESS',
  USER_SAVE_ERR: 'USER_SAVE_ERR',

  userSaveBegin: () => {
    return {
      type: actions.USER_SAVE_BEGIN,
    };
  },

  userSaveSuccess: data => {
    return {
      type: actions.USER_SAVE_SUCCESS,
      data,
    };
  },

  userSaveErr: err => {
    return {
      type: actions.USER_SAVE_ERR,
      err,
    };
  },

  USER_DELETE_BEGIN: 'USER_DELETE_BEGIN',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_ERR: 'USER_DELETE_ERR',

  userDeleteBegin: () => {
    return {
      type: actions.USER_DELETE_BEGIN,
    };
  },

  userDeleteSuccess: data => {
    return {
      type: actions.USER_DELETE_SUCCESS,
      data,
    };
  },

  userDeleteErr: err => {
    return {
      type: actions.USER_DELETE_ERR,
      err,
    };
  },

  /**
   * TRANSFER
   */
  USER_EMPLOYEES_TRANSFER_BEGIN: 'USER_EMPLOYEES_TRANSFER_BEGIN',
  USER_EMPLOYEES_TRANSFER_SUCCESS: 'USER_EMPLOYEES_TRANSFER_SUCCESS',
  USER_EMPLOYEES_TRANSFER_ERR: 'USER_EMPLOYEES_TRANSFER_ERR',

  userEmployeesTransferBegin: () => {
    return {
      type: actions.USER_EMPLOYEES_TRANSFER_BEGIN,
    };
  },

  userEmployeesTransferSuccess: data => {
    return {
      type: actions.USER_EMPLOYEES_TRANSFER_SUCCESS,
      data,
    };
  },

  userEmployeesTransferErr: err => {
    return {
      type: actions.USER_EMPLOYEES_TRANSFER_ERR,
      err,
    };
  },
};

export default actions;
