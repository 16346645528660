const actions = {
  CARD_TEMPLATES_TRANSFER_BEGIN: 'CARD_TEMPLATES_TRANSFER_BEGIN',
  CARD_TEMPLATES_TRANSFER_SUCCESS: 'CARD_TEMPLATES_TRANSFER_SUCCESS',
  CARD_TEMPLATES_TRANSFER_ERR: 'CARD_TEMPLATES_TRANSFER_ERR',

  cardTemplatesTransferBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_TRANSFER_BEGIN,
    };
  },

  cardTemplatesTransferSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_TRANSFER_SUCCESS,
      data,
    };
  },

  cardTemplatesTransferErr: err => {
    return {
      type: actions.CARD_TEMPLATES_TRANSFER_ERR,
      err,
    };
  },

  CARD_TEMPLATES_BUILD_BEGIN: 'CARD_TEMPLATES_BUILD_BEGIN',
  CARD_TEMPLATES_BUILD_SUCCESS: 'CARD_TEMPLATES_BUILD_SUCCESS',
  CARD_TEMPLATES_BUILD_ERR: 'CARD_TEMPLATES_BUILD_ERR',

  cardTemplatesBuildBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_BUILD_BEGIN,
    };
  },

  cardTemplatesBuildSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_BUILD_SUCCESS,
      data,
    };
  },

  cardTemplatesBuildErr: err => {
    return {
      type: actions.CARD_TEMPLATES_BUILD_ERR,
      err,
    };
  },

  CARD_TEMPLATES_PAGINATION_UPDATE_BEGIN: 'CARD_TEMPLATES_PAGINATION_UPDATE_BEGIN',
  CARD_TEMPLATES_PAGINATION_UPDATE_SUCCESS: 'CARD_TEMPLATES_PAGINATION_UPDATE_SUCCESS',
  CARD_TEMPLATES_PAGINATION_UPDATE_ERR: 'CARD_TEMPLATES_PAGINATION_UPDATE_ERR',

  cardTemplatesPaginationUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_PAGINATION_UPDATE_BEGIN,
    };
  },

  cardTemplatesPaginationUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplatesPaginationUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATES_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATES_FILTERS_UPDATE_BEGIN: 'CARD_TEMPLATES_FILTERS_UPDATE_BEGIN',
  CARD_TEMPLATES_FILTERS_UPDATE_SUCCESS: 'CARD_TEMPLATES_FILTERS_UPDATE_SUCCESS',
  CARD_TEMPLATES_FILTERS_UPDATE_ERR: 'CARD_TEMPLATES_FILTERS_UPDATE_ERR',

  cardTemplatesFiltersUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_FILTERS_UPDATE_BEGIN,
    };
  },

  cardTemplatesFiltersUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplatesFiltersUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATES_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATES_SORTER_UPDATE_BEGIN: 'CARD_TEMPLATES_SORTER_UPDATE_BEGIN',
  CARD_TEMPLATES_SORTER_UPDATE_SUCCESS: 'CARD_TEMPLATES_SORTER_UPDATE_SUCCESS',
  CARD_TEMPLATES_SORTER_UPDATE_ERR: 'CARD_TEMPLATES_SORTER_UPDATE_ERR',

  cardTemplatesSorterUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_SORTER_UPDATE_BEGIN,
    };
  },

  cardTemplatesSorterUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplatesSorterUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATES_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATES_UPDATE_BEGIN: 'CARD_TEMPLATES_UPDATE_BEGIN',
  CARD_TEMPLATES_UPDATE_SUCCESS: 'CARD_TEMPLATES_UPDATE_SUCCESS',
  CARD_TEMPLATES_UPDATE_ERR: 'CARD_TEMPLATES_UPDATE_ERR',

  cardTemplatesUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_UPDATE_BEGIN,
    };
  },

  cardTemplatesUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplatesUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATES_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATES_CLEANUP_BEGIN: 'CARD_TEMPLATES_CLEANUP_BEGIN',
  CARD_TEMPLATES_CLEANUP_SUCCESS: 'CARD_TEMPLATES_CLEANUP_SUCCESS',
  CARD_TEMPLATES_CLEANUP_ERR: 'CARD_TEMPLATES_CLEANUP_ERR',

  cardTemplatesCleanupBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_CLEANUP_BEGIN,
    };
  },

  cardTemplatesCleanupSuccess: () => {
    return {
      type: actions.CARD_TEMPLATES_CLEANUP_SUCCESS,
    };
  },

  cardTemplatesCleanupErr: err => {
    return {
      type: actions.CARD_TEMPLATES_CLEANUP_ERR,
      err,
    };
  },

  CARD_TEMPLATE_DELETE_BEGIN: 'CARD_TEMPLATE_DELETE_BEGIN',
  CARD_TEMPLATE_DELETE_SUCCESS: 'CARD_TEMPLATE_DELETE_SUCCESS',
  CARD_TEMPLATE_DELETE_ERR: 'CARD_TEMPLATE_DELETE_ERR',

  cardTemplateDeleteBegin: () => {
    return {
      type: actions.CARD_TEMPLATE_DELETE_BEGIN,
    };
  },

  cardTemplateDeleteSuccess: data => {
    return {
      type: actions.CARD_TEMPLATE_DELETE_SUCCESS,
      data,
    };
  },

  cardTemplateDeleteErr: err => {
    return {
      type: actions.CARD_TEMPLATE_DELETE_ERR,
      err,
    };
  },

  CARD_TEMPLATE_UPDATE_BEGIN: 'CARD_TEMPLATE_UPDATE_BEGIN',
  CARD_TEMPLATE_UPDATE_SUCCESS: 'CARD_TEMPLATE_UPDATE_SUCCESS',
  CARD_TEMPLATE_UPDATE_ERR: 'CARD_TEMPLATE_UPDATE_ERR',

  cardTemplateUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATE_UPDATE_BEGIN,
    };
  },

  cardTemplateUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATE_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplateUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATE_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATE_CLEANUP_BEGIN: 'CARD_TEMPLATE_CLEANUP_BEGIN',
  CARD_TEMPLATE_CLEANUP_SUCCESS: 'CARD_TEMPLATE_CLEANUP_SUCCESS',
  CARD_TEMPLATE_CLEANUP_ERR: 'CARD_TEMPLATE_CLEANUP_ERR',

  cardTemplateCleanupBegin: () => {
    return {
      type: actions.CARD_TEMPLATE_CLEANUP_BEGIN,
    };
  },

  cardTemplateCleanupSuccess: () => {
    return {
      type: actions.CARD_TEMPLATE_CLEANUP_SUCCESS,
    };
  },

  cardTemplateCleanupErr: err => {
    return {
      type: actions.CARD_TEMPLATE_CLEANUP_ERR,
      err,
    };
  },

  CARD_TEMPLATE_SAVE_BEGIN: 'CARD_TEMPLATE_SAVE_BEGIN',
  CARD_TEMPLATE_SAVE_SUCCESS: 'CARD_TEMPLATE_SAVE_SUCCESS',
  CARD_TEMPLATE_SAVE_ERR: 'CARD_TEMPLATE_SAVE_ERR',

  cardTemplateSaveBegin: () => {
    return {
      type: actions.CARD_TEMPLATE_SAVE_BEGIN,
    };
  },

  cardTemplateSaveSuccess: data => {
    return {
      type: actions.CARD_TEMPLATE_SAVE_SUCCESS,
      data,
    };
  },

  cardTemplateSaveErr: err => {
    return {
      type: actions.CARD_TEMPLATE_SAVE_ERR,
      err,
    };
  },

  CARD_TEMPLATES_LIST_UPDATE_BEGIN: 'CARD_TEMPLATES_LIST_UPDATE_BEGIN',
  CARD_TEMPLATES_LIST_UPDATE_SUCCESS: 'CARD_TEMPLATES_LIST_UPDATE_SUCCESS',
  CARD_TEMPLATES_LIST_UPDATE_ERR: 'CARD_TEMPLATES_LIST_UPDATE_ERR',

  cardTemplatesListUpdateBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_LIST_UPDATE_BEGIN,
    };
  },

  cardTemplatesListUpdateSuccess: data => {
    return {
      type: actions.CARD_TEMPLATES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cardTemplatesListUpdateErr: err => {
    return {
      type: actions.CARD_TEMPLATES_LIST_UPDATE_ERR,
      err,
    };
  },

  CARD_TEMPLATES_LIST_CLEANUP_BEGIN: 'CARD_TEMPLATES_LIST_CLEANUP_BEGIN',
  CARD_TEMPLATES_LIST_CLEANUP_SUCCESS: 'CARD_TEMPLATES_LIST_CLEANUP_SUCCESS',
  CARD_TEMPLATES_LIST_CLEANUP_ERR: 'CARD_TEMPLATES_LIST_CLEANUP_ERR',

  cardTemplatesListCleanupBegin: () => {
    return {
      type: actions.CARD_TEMPLATES_LIST_CLEANUP_BEGIN,
    };
  },

  cardTemplatesListCleanupSuccess: () => {
    return {
      type: actions.CARD_TEMPLATES_LIST_CLEANUP_SUCCESS,
    };
  },

  cardTemplatesListCleanupErr: err => {
    return {
      type: actions.CARD_TEMPLATES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
