import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';

const MenuClient = ({ toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Menu.ItemGroup key="principal" title="Principal">
        <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="home">
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            Dashboard
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu key="principal-arquivos" icon={!topMenu && <FeatherIcon icon="hard-drive" />} title="Arquivos">
          <Menu.Item key="/clientsFiles">
            <NavLink onClick={toggleCollapsed} to={`${path}/clients/files`}>
              Enviados
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/files">
            <NavLink onClick={toggleCollapsed} to={`${path}/cards/files`}>
              Recebidos
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item icon={!topMenu && <FeatherIcon icon="tag" />} key="tickets">
          <NavLink onClick={toggleCollapsed} to={`${path}/cards/clients`}>
            Lista de Tickets
          </NavLink>
        </Menu.Item>
        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="clients">
          <NavLink onClick={toggleCollapsed} to={`${path}/clients/employees`}>
            Minhas Empresas
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <img src="https://portal.escritoriointeligente.com/Scripts/img/favicon.ico" alt="EI" key="ei-portal" />
            )
          }
          key="escritorio-inteligente"
        >
          <Link to={{ pathname: 'https://portal.escritoriointeligente.com/LoginX' }} target="_blank">
            Escritório Inteligente
          </Link>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 116 110" fill="none">
                <path
                  d="M0.0234375 0V110H115.023V0H0.0234375ZM97.5077 92.4034H80.9614V45.3077H32.7058C32.1529 45.2918 31.6025 45.3875 31.0871 45.5891C30.5717 45.7906 30.1018 46.094 29.7051 46.4812C29.3085 46.8684 28.9932 47.3316 28.7779 47.8434C28.5626 48.3552 28.4516 48.9052 28.4516 49.4608C28.4516 50.0165 28.5626 50.5665 28.7779 51.0783C28.9932 51.59 29.3085 52.0533 29.7051 52.4405C30.1018 52.8277 30.5717 53.1311 31.0871 53.3326C31.6025 53.5342 32.1529 53.6298 32.7058 53.6139H56.1471C61.1349 53.8132 65.8525 55.9439 69.3114 59.5596C72.7703 63.1754 74.7021 67.9956 74.7021 73.0104C74.7021 78.0252 72.7703 82.8454 69.3114 86.4611C65.8525 90.0769 61.1349 92.2076 56.1471 92.4068H17.5494V78.5529H56.1437C57.6128 78.5529 59.0217 77.9667 60.0605 76.9231C61.0993 75.8795 61.6829 74.4641 61.6829 72.9882C61.6829 71.5124 61.0993 70.097 60.0605 69.0534C59.0217 68.0098 57.6128 67.4235 56.1437 67.4235H31.2345C26.6174 67.0508 22.3231 64.8967 19.2523 61.4128C16.1815 57.929 14.5725 53.3861 14.7628 48.7366C14.953 44.0871 16.9277 39.6921 20.2729 36.4733C23.618 33.2544 28.0737 31.4617 32.7058 31.4709H80.9614V45.3077H97.5077V92.4034ZM97.5077 31.4437H80.9614V17.6068H97.5077V31.4437Z"
                  fill="white"
                />
              </svg>
            )
          }
          key="iris-fiscal"
        >
          <Link to={{ pathname: 'https://auth.sieg.com/login' }} target="_blank">
            Íris Fiscal
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>
    </>
  );
};

MenuClient.propTypes = {
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuClient;
