import { message } from 'antd';
import actions from './actions';
import api from '../../config/api/index';

const messageKey = 'newsletters';

const { newslettersListUpdateBegin, newslettersListUpdateSuccess, newslettersListUpdateErr } = actions;

const newslettersListUpdateAction = () => {
  return async dispatch => {
    dispatch(newslettersListUpdateBegin());

    try {
      api.Newsletters.list().then(response => {
        // handle success
        if (response.data.newsletters !== undefined) {
          dispatch(newslettersListUpdateSuccess(response.data.newsletters));
        } else {
          dispatch(newslettersListUpdateErr(response.data.message));
          message.error(response.data.message);
        }
      });
    } catch (err) {
      dispatch(newslettersListUpdateErr(err));
      message.error(err.toString());
    }
  };
};

const { newslettersListCleanupBegin, newslettersListCleanupSuccess, newslettersListCleanupErr } = actions;

const newslettersListCleanupAction = () => {
  return async dispatch => {
    dispatch(newslettersListCleanupBegin());
    try {
      dispatch(newslettersListCleanupSuccess());
    } catch (err) {
      dispatch(newslettersListCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { newslettersReadBegin, newslettersReadSuccess, newslettersReadErr } = actions;

const newslettersReadAction = ({ data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(newslettersReadBegin());
    // const plural = data?.newsletters?.length > 1 ? 's' : '';
    // message.loading({ content: `Marcando noticia${plural} como lida`, duration: 0, key: messageKey });
    api.Newsletters.read(data)
      .then(response => {
        if (response.status === 200) {
          dispatch(newslettersReadSuccess());
          // message.success({
          //   content: `Noticia${plural} marcada${plural} como lida`,
          //   key: messageKey,
          // });
          onSuccess(response);
        } else {
          dispatch(newslettersReadErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(newslettersReadErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const {
  newslettersPaginationUpdateBegin,
  newslettersPaginationUpdateSuccess,
  newslettersPaginationUpdateErr,
} = actions;

const newslettersPaginationUpdateAction = data => {
  return async dispatch => {
    dispatch(newslettersPaginationUpdateBegin());
    try {
      dispatch(newslettersPaginationUpdateSuccess(data));
    } catch (err) {
      dispatch(newslettersPaginationUpdateErr(err.toString()));
    }
  };
};

const { newslettersFiltersUpdateBegin, newslettersFiltersUpdateSuccess, newslettersFiltersUpdateErr } = actions;

const newslettersFiltersUpdateAction = data => {
  return async dispatch => {
    dispatch(newslettersFiltersUpdateBegin());
    try {
      dispatch(newslettersFiltersUpdateSuccess(data));
    } catch (err) {
      dispatch(newslettersFiltersUpdateErr(err.toString()));
    }
  };
};

const { newslettersSorterUpdateBegin, newslettersSorterUpdateSuccess, newslettersSorterUpdateErr } = actions;

const newslettersSorterUpdateAction = data => {
  return async dispatch => {
    dispatch(newslettersSorterUpdateBegin());
    try {
      dispatch(newslettersSorterUpdateSuccess(data));
    } catch (err) {
      dispatch(newslettersSorterUpdateErr(err.toString()));
    }
  };
};

const { newslettersUpdateBegin, newslettersUpdateSuccess, newslettersUpdateErr } = actions;

const newslettersUpdateAction = ({ pagination = undefined, filters = undefined, sorter = undefined } = {}) => {
  return async (dispatch, getState) => {
    dispatch(newslettersUpdateBegin());

    const { newsletters } = getState();
    const currentPagination = newsletters.pagination;
    const currentFilters = newsletters.filters;
    const currentSorter = newsletters.sorter;

    pagination = pagination ?? currentPagination;
    filters = filters ?? currentFilters;
    sorter = sorter ?? currentSorter;

    // pagination
    if (JSON.stringify(pagination) !== JSON.stringify(currentPagination)) {
      dispatch(newslettersPaginationUpdateAction(pagination));
    }

    // filters
    if (JSON.stringify(filters) !== JSON.stringify(currentFilters)) {
      dispatch(newslettersFiltersUpdateAction(filters));
    }

    // sorter
    if (JSON.stringify(sorter) !== JSON.stringify(currentSorter)) {
      dispatch(newslettersSorterUpdateAction(sorter));
    }

    const params = {
      page: pagination.current,
      limit: pagination.pageSize,
      filters: filters ?? null,
      sort: sorter?.field ?? null,
      direction: sorter?.order ?? null,
    };

    api.Newsletters.index(params)
      .then(response => {
        if (response.status === 200) {
          dispatch(newslettersUpdateSuccess(response.data.newsletters));
          dispatch(newslettersPaginationUpdateAction(response.data.pagination));
        } else {
          dispatch(newslettersUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(newslettersUpdateErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { newslettersCleanupBegin, newslettersCleanupSuccess, newslettersCleanupErr } = actions;

const newslettersCleanupAction = () => {
  return async dispatch => {
    dispatch(newslettersCleanupBegin());
    try {
      dispatch(newslettersCleanupSuccess());
    } catch (err) {
      dispatch(newslettersCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { newsletterDeleteBegin, newsletterDeleteSuccess, newsletterDeleteErr } = actions;

const newsletterDeleteAction = ({ newsletter, onSuccess = () => {} }) => {
  return async (dispatch, getState) => {
    dispatch(newsletterDeleteBegin());
    const { id, name } = newsletter;
    message.loading({
      content: `Excluindo Informativo "${name}"`,
      duration: 0,
      key: `${messageKey}-${id}`,
    });
    api.Newsletters.delete(id)
      .then(response => {
        if (response.status === 200) {
          dispatch(newsletterDeleteSuccess(getState().newsletters.data.filter(newsletter => newsletter.id !== id)));
          message.success({
            content: `Informativo "${name}" excluído com sucesso`,
            key: `${messageKey}-${id}`,
          });
          onSuccess(response);
        } else if (response.data && response.data.message) {
          dispatch(newsletterDeleteErr(response.data.message));
          message.error({ content: response.data.message, key: `${messageKey}-${id}` });
        }
      })
      .catch(err => {
        dispatch(newsletterDeleteErr(err.toString()));
        message.error({ content: err.toString(), key: `${messageKey}-${id}` });
      });
  };
};

const { newsletterUpdateBegin, newsletterUpdateSuccess, newsletterUpdateErr } = actions;

const newsletterUpdateAction = ({ id = undefined, data = undefined, filter = data => data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(newsletterUpdateBegin());
    if (data !== undefined) {
      dispatch(newsletterUpdateSuccess(data));
    } else if (id !== undefined) {
      api.Newsletters.get(id)
        .then(response => {
          if (response.status === 200) {
            const filtered = filter(response.data.newsletter);
            dispatch(newsletterUpdateSuccess(filtered));
            onSuccess(response);
          } else if (response.data && response.data.message) {
            dispatch(newsletterUpdateErr(response.data.message));
            message.error({ content: response.data.message, key: messageKey });
          }
        })
        .catch(err => {
          dispatch(newsletterUpdateErr(err.toString()));
          message.error({ content: err.toString(), key: messageKey });
        });
    }
  };
};

const { newsletterCleanupBegin, newsletterCleanupSuccess, newsletterCleanupErr } = actions;

const newsletterCleanupAction = () => {
  return async dispatch => {
    dispatch(newsletterCleanupBegin());
    try {
      dispatch(newsletterCleanupSuccess());
    } catch (err) {
      dispatch(newsletterCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { newsletterSaveBegin, newsletterSaveSuccess, newsletterSaveErr } = actions;

const newsletterSaveAction = ({ data, id = undefined, filter = data => data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(newsletterSaveBegin());
    message.loading({ content: 'Salvando Informativo', duration: 0, key: messageKey });
    const action = id !== undefined ? api.Newsletters.edit(id, data) : api.Newsletters.add(data);
    action
      .then(response => {
        if (response.status === 200) {
          const filtered = filter(response.data.newsletter);
          dispatch(newsletterSaveSuccess(filtered));
          message.success({ content: 'Informativo salvo com sucesso', key: messageKey });
          onSuccess(response);
        } else {
          dispatch(newsletterSaveErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(newsletterSaveErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

export {
  newslettersListUpdateAction,
  newslettersListCleanupAction,
  newslettersReadAction,
  newslettersUpdateAction,
  newslettersCleanupAction,
  newsletterDeleteAction,
  newsletterUpdateAction,
  newsletterCleanupAction,
  newsletterSaveAction,
};
