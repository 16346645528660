import Styled from 'styled-components';

const InfoWraper = Styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
    height: 64px;

    .ant-badge-dot {
        box-shadow: unset;
    }

    .message, 
    .newsletter,
    .notification, 
    .settings, 
    .user-efficiency, 
    .help,
    .nav-author {
        line-height: initial;
        display: flex;
        margin: 0 12px;

        a {
            text-decoration: none;
            color: ${({ theme }) => theme['white-color']};
            box-shadow: none;
        }
    }

    .notification {
        height: 20px;
    }

    .bell {
        display: block;
        -webkit-animation: ring 4s .7s ease-in-out infinite;
        -webkit-transform-origin: 50% 4px;
        -moz-animation: ring 4s .7s ease-in-out infinite;
        -moz-transform-origin: 50% 4px;
        animation: ring 4s .7s ease-in-out infinite;
        transform-origin: 50% 4px;
    }
    
    @-webkit-keyframes ring {
        0% { -webkit-transform: rotateZ(0); }
        1% { -webkit-transform: rotateZ(30deg); }
        3% { -webkit-transform: rotateZ(-28deg); }
        5% { -webkit-transform: rotateZ(34deg); }
        7% { -webkit-transform: rotateZ(-32deg); }
        9% { -webkit-transform: rotateZ(30deg); }
        11% { -webkit-transform: rotateZ(-28deg); }
        13% { -webkit-transform: rotateZ(26deg); }
        15% { -webkit-transform: rotateZ(-24deg); }
        17% { -webkit-transform: rotateZ(22deg); }
        19% { -webkit-transform: rotateZ(-20deg); }
        21% { -webkit-transform: rotateZ(18deg); }
        23% { -webkit-transform: rotateZ(-16deg); }
        25% { -webkit-transform: rotateZ(14deg); }
        27% { -webkit-transform: rotateZ(-12deg); }
        29% { -webkit-transform: rotateZ(10deg); }
        31% { -webkit-transform: rotateZ(-8deg); }
        33% { -webkit-transform: rotateZ(6deg); }
        35% { -webkit-transform: rotateZ(-4deg); }
        37% { -webkit-transform: rotateZ(2deg); }
        39% { -webkit-transform: rotateZ(-1deg); }
        41% { -webkit-transform: rotateZ(1deg); }
    
        43% { -webkit-transform: rotateZ(0); }
        100% { -webkit-transform: rotateZ(0); }
    }
    
    @-moz-keyframes ring {
        0% { -moz-transform: rotate(0); }
        1% { -moz-transform: rotate(30deg); }
        3% { -moz-transform: rotate(-28deg); }
        5% { -moz-transform: rotate(34deg); }
        7% { -moz-transform: rotate(-32deg); }
        9% { -moz-transform: rotate(30deg); }
        11% { -moz-transform: rotate(-28deg); }
        13% { -moz-transform: rotate(26deg); }
        15% { -moz-transform: rotate(-24deg); }
        17% { -moz-transform: rotate(22deg); }
        19% { -moz-transform: rotate(-20deg); }
        21% { -moz-transform: rotate(18deg); }
        23% { -moz-transform: rotate(-16deg); }
        25% { -moz-transform: rotate(14deg); }
        27% { -moz-transform: rotate(-12deg); }
        29% { -moz-transform: rotate(10deg); }
        31% { -moz-transform: rotate(-8deg); }
        33% { -moz-transform: rotate(6deg); }
        35% { -moz-transform: rotate(-4deg); }
        37% { -moz-transform: rotate(2deg); }
        39% { -moz-transform: rotate(-1deg); }
        41% { -moz-transform: rotate(1deg); }
    
        43% { -moz-transform: rotate(0); }
        100% { -moz-transform: rotate(0); }
    }
    
    @keyframes ring {
        0% { transform: rotate(0); }
        1% { transform: rotate(30deg); }
        3% { transform: rotate(-28deg); }
        5% { transform: rotate(34deg); }
        7% { transform: rotate(-32deg); }
        9% { transform: rotate(30deg); }
        11% { transform: rotate(-28deg); }
        13% { transform: rotate(26deg); }
        15% { transform: rotate(-24deg); }
        17% { transform: rotate(22deg); }
        19% { transform: rotate(-20deg); }
        21% { transform: rotate(18deg); }
        23% { transform: rotate(-16deg); }
        25% { transform: rotate(14deg); }
        27% { transform: rotate(-12deg); }
        29% { transform: rotate(10deg); }
        31% { transform: rotate(-8deg); }
        33% { transform: rotate(6deg); }
        35% { transform: rotate(-4deg); }
        37% { transform: rotate(2deg); }
        39% { transform: rotate(-1deg); }
        41% { transform: rotate(1deg); }
    
        43% { transform: rotate(0); }
        100% { transform: rotate(0); }
    }
`;

const SettingDropdwon = Styled.div`
    .setting-dropdwon{
        max-width: 700px;
        padding: 4px 0;
        .setting-dropdwon__single{
            align-items: flex-start;
            padding: 16px 20px;
            margin-bottom: 0;
            position: relative;
            &:after{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 5px 20px ${({ theme }) => theme['gray-solid']}15;
                z-index: 1;
                content: '';
                opacity: 0;
                visibility: hidden;
            }
            &:hover{
                &:after{
                    opacity: 1;
                    visibility: visible;
                }
            }
            h1{
                font-size: 15px;
                font-weight: 500;
                margin: -4px 0 2px;
            }
            p{
                margin-bottom: 0;
                color: ${({ theme }) => theme['gray-solid']};
            }
            img{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 16px;
                transform: ${({ theme }) => (theme.rtl ? 'rotatey(180deg)' : 'rotatey(0deg)')};
            }
            figcaption{
                text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')}
            }
        }
    }
`;

const NestedDropdwon = Styled.div`
    .support-dropdwon{
        padding: 10px 15px;
        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};
        ul{
            &:not(:last-child){
                margin-bottom: 16px;
            }
            h1{
                font-size: 14px;
                font-weight: 400;
                color: ${({ theme }) => theme['light-color']};
            }
            li{
                a{
                    font-weight: 500;
                    padding: 4px 16px;
                    color: ${({ theme }) => theme['dark-color']};
                    &:hover{
                        background: ${({ theme }) => theme['white-color']};
                        color: ${({ theme }) => theme['primary-color']};
                    }
                }
            }
        }
    }
`;

const UserProfilePopover = Styled.div`
    .user-popover{
        gap: unset;
        max-width: 360px;
        .user-popover__info{
            padding: 15px;
            border-radius: 8px;
            background: ${({ theme }) => theme['bg-color-light']};
            // text-align: center;

            figure {
                position: relative;
                margin: 0 auto 22px;
                .ant-image-mask {
                    border-radius: 100%;
                }
                .photo { 
                    border-radius: 100%; 
                    background: #FFF;
                }
            }

            .ant-card-meta-title {
                .ant-tag {
                    margin: 0;
                }
            }

            .ant-card-meta-description {
                .user-email {
                    color: ${({ theme }) => theme['text-color-secondary']};
                    font-size: 13px;
                    padding-right: 8px;
                }
                .user-departments {
                    font-size: 13px;
                    color: ${({ theme }) => theme['text-color-secondary']};
                }
            }
        }
        .user-popover__links{
            a{
                width: 100%;
                left: -15px;
                right: -15px;
                display: inline-flex;
                align-items: center;
                padding: 10px 12px;
                font-size: 14px;
                transition: .3s;
                color: ${({ theme }) => theme['light-color']};
                &.text-danger {
                    color: ${({ theme }) => theme['error-color']};
                    &:hover {
                        background: ${({ theme }) => theme['error-color']}10;
                        color: ${({ theme }) => theme['error-color']};
                    }
                }
                span {
                    padding-left: 0;
                }
                &:hover{
                    background: ${({ theme }) => theme['link-color']}10;
                    color: ${({ theme }) => theme['link-color']};
                    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 22px;
                }
                svg{
                    width: 16px;
                    transform: ${({ theme }) => (theme.rtl ? 'rotateY(180deg)' : 'rotateY(0deg)')};
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 14px;
                }
            }
        }
        .user-popover__bottomAction{
            position: relative;
            width: calc(100% + 30px);
            left: -15px;
            right: -15px;
            height: 100%;
            bottom: -15px;
            border-radius: 0 0 6px 6px;
            padding: 10px 0;
            svg{
                width: 15px;
                height: 15px;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
            }
        }
    }
`;

const AtbdTopDropdwon = Styled.div`
    .atbd-top-dropdwon__title .title-text {        
        ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
    }
    .atbd-top-dropdwon__content figcaption .atbd-top-dropdwonText {
        min-width: 216px;
        ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
    }
    .atbd-top-dropdwon__content .notification-icon {
        width: 39.2px;
        height: 32px;
        ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .atbd-top-dropdwon__content .notification-icon.bg-primary {
        background: ${({ theme }) => theme['primary-color']}15;
        color: ${({ theme }) => theme['primary-color']};
    }
    .atbd-top-dropdwon__content .notification-icon.bg-secondary {
        background: ${({ theme }) => theme['secondary-color']}15;
        color: ${({ theme }) => theme['secondary-color']};
    }
    .atbd-top-dropdwon__content .notification-icon svg {
        width: 18px;
        height: 18px;
    }
    .atbd-top-dropdwon__content .notification-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }  

    .atbd-top-dropdwon__content figcaption span {
        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
    }

    .notification-text h1 {
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme['gray-color']};
        margin-bottom: 4px;
    }

    .notification-text h1 span {
        color: ${({ theme }) => theme['primary-color']};
        font-weight: 500;
        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
    }

    .notification-text p {
        font-size: 12px;
        color: ${({ theme }) => theme['extra-light-color']};
        margin-bottom: 0;
        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')}
    }
`;

const NavAuth = Styled.span`
    i, svg, img {
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
    }
`;

export { InfoWraper, SettingDropdwon, NestedDropdwon, UserProfilePopover, AtbdTopDropdwon, NavAuth };
