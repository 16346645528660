const actions = {
  ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_ERR: 'ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_ERR',

  achievementProductsPaginationUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_BEGIN,
    };
  },

  achievementProductsPaginationUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductsPaginationUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_ERR: 'ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_ERR',

  achievementProductsFiltersUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_BEGIN,
    };
  },

  achievementProductsFiltersUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductsFiltersUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_ERR: 'ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_ERR',

  achievementProductsSorterUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_BEGIN,
    };
  },

  achievementProductsSorterUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductsSorterUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCTS_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCTS_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCTS_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCTS_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCTS_UPDATE_ERR: 'ACHIEVEMENT_PRODUCTS_UPDATE_ERR',

  achievementProductsUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_UPDATE_BEGIN,
    };
  },

  achievementProductsUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductsUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCTS_CLEANUP_BEGIN: 'ACHIEVEMENT_PRODUCTS_CLEANUP_BEGIN',
  ACHIEVEMENT_PRODUCTS_CLEANUP_SUCCESS: 'ACHIEVEMENT_PRODUCTS_CLEANUP_SUCCESS',
  ACHIEVEMENT_PRODUCTS_CLEANUP_ERR: 'ACHIEVEMENT_PRODUCTS_CLEANUP_ERR',

  achievementProductsCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_CLEANUP_BEGIN,
    };
  },

  achievementProductsCleanupSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_CLEANUP_SUCCESS,
      data,
    };
  },

  achievementProductsCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_CLEANUP_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCTS_LIST_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCTS_LIST_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCTS_LIST_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCTS_LIST_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCTS_LIST_UPDATE_ERR: 'ACHIEVEMENT_PRODUCTS_LIST_UPDATE_ERR',

  achievementProductsListUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_LIST_UPDATE_BEGIN,
    };
  },

  achievementProductsListUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductsListUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_LIST_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_BEGIN: 'ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_BEGIN',
  ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_SUCCESS: 'ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_SUCCESS',
  ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_ERR: 'ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_ERR',

  achievementProductsListCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_BEGIN,
    };
  },

  achievementProductsListCleanupSuccess: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_SUCCESS,
    };
  },

  achievementProductsListCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCT_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCT_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCT_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCT_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCT_UPDATE_ERR: 'ACHIEVEMENT_PRODUCT_UPDATE_ERR',

  achievementProductUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_UPDATE_BEGIN,
    };
  },

  achievementProductUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCT_CLEANUP_BEGIN: 'ACHIEVEMENT_PRODUCT_CLEANUP_BEGIN',
  ACHIEVEMENT_PRODUCT_CLEANUP_SUCCESS: 'ACHIEVEMENT_PRODUCT_CLEANUP_SUCCESS',
  ACHIEVEMENT_PRODUCT_CLEANUP_ERR: 'ACHIEVEMENT_PRODUCT_CLEANUP_ERR',

  achievementProductCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_CLEANUP_BEGIN,
    };
  },

  achievementProductCleanupSuccess: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_CLEANUP_SUCCESS,
    };
  },

  achievementProductCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_CLEANUP_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCT_SAVE_BEGIN: 'ACHIEVEMENT_PRODUCT_SAVE_BEGIN',
  ACHIEVEMENT_PRODUCT_SAVE_SUCCESS: 'ACHIEVEMENT_PRODUCT_SAVE_SUCCESS',
  ACHIEVEMENT_PRODUCT_SAVE_ERR: 'ACHIEVEMENT_PRODUCT_SAVE_ERR',

  achievementProductSaveBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_SAVE_BEGIN,
    };
  },

  achievementProductSaveSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_SAVE_SUCCESS,
      data,
    };
  },

  achievementProductSaveErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_SAVE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCT_BUY_BEGIN: 'ACHIEVEMENT_PRODUCT_BUY_BEGIN',
  ACHIEVEMENT_PRODUCT_BUY_SUCCESS: 'ACHIEVEMENT_PRODUCT_BUY_SUCCESS',
  ACHIEVEMENT_PRODUCT_BUY_ERR: 'ACHIEVEMENT_PRODUCT_BUY_ERR',

  achievementProductBuyBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_BUY_BEGIN,
    };
  },

  achievementProductBuySuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_BUY_SUCCESS,
      data,
    };
  },

  achievementProductBuyErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_BUY_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCT_DELETE_BEGIN: 'ACHIEVEMENT_PRODUCT_DELETE_BEGIN',
  ACHIEVEMENT_PRODUCT_DELETE_SUCCESS: 'ACHIEVEMENT_PRODUCT_DELETE_SUCCESS',
  ACHIEVEMENT_PRODUCT_DELETE_ERR: 'ACHIEVEMENT_PRODUCT_DELETE_ERR',

  achievementProductDeleteBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_DELETE_BEGIN,
    };
  },

  achievementProductDeleteSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_DELETE_SUCCESS,
      data,
    };
  },

  achievementProductDeleteErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_DELETE_ERR,
      err,
    };
  },

  /**
   * IMAGE
   */
  ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_BEGIN: 'ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_BEGIN',
  ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_SUCCESS: 'ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_SUCCESS',
  ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_ERR: 'ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_ERR',

  achievementProductImageUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_BEGIN,
    };
  },

  achievementProductImageUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_SUCCESS,
      data,
    };
  },

  achievementProductImageUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_BEGIN: 'ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_BEGIN',
  ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_SUCCESS: 'ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_SUCCESS',
  ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_ERR: 'ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_ERR',

  achievementProductImageCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_BEGIN,
    };
  },

  achievementProductImageCleanupSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_SUCCESS,
      data,
    };
  },

  achievementProductImageCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
