const actions = {
  CLIENT_USERS_LIST_UPDATE_BEGIN: 'CLIENT_USERS_LIST_UPDATE_BEGIN',
  CLIENT_USERS_LIST_UPDATE_SUCCESS: 'CLIENT_USERS_LIST_UPDATE_SUCCESS',
  CLIENT_USERS_LIST_UPDATE_ERR: 'CLIENT_USERS_LIST_UPDATE_ERR',

  clientUsersListUpdateBegin: () => {
    return {
      type: actions.CLIENT_USERS_LIST_UPDATE_BEGIN,
    };
  },

  clientUsersListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_USERS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientUsersListUpdateErr: err => {
    return {
      type: actions.CLIENT_USERS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_USERS_LIST_CLEANUP_BEGIN: 'CLIENT_USERS_LIST_CLEANUP_BEGIN',
  CLIENT_USERS_LIST_CLEANUP_SUCCESS: 'CLIENT_USERS_LIST_CLEANUP_SUCCESS',
  CLIENT_USERS_LIST_CLEANUP_ERR: 'CLIENT_USERS_LIST_CLEANUP_ERR',

  clientUsersListCleanupBegin: () => {
    return {
      type: actions.CLIENT_USERS_LIST_CLEANUP_BEGIN,
    };
  },

  clientUsersListCleanupSuccess: data => {
    return {
      type: actions.CLIENT_USERS_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  clientUsersListCleanupErr: err => {
    return {
      type: actions.CLIENT_USERS_LIST_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_USERS_UPDATE_BEGIN: 'CLIENT_USERS_UPDATE_BEGIN',
  CLIENT_USERS_UPDATE_SUCCESS: 'CLIENT_USERS_UPDATE_SUCCESS',
  CLIENT_USERS_UPDATE_ERR: 'CLIENT_USERS_UPDATE_ERR',

  clientUsersUpdateBegin: () => {
    return {
      type: actions.CLIENT_USERS_UPDATE_BEGIN,
    };
  },

  clientUsersUpdateSuccess: data => {
    return {
      type: actions.CLIENT_USERS_UPDATE_SUCCESS,
      data,
    };
  },

  clientUsersUpdateErr: err => {
    return {
      type: actions.CLIENT_USERS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_USERS_CLEANUP_BEGIN: 'CLIENT_USERS_CLEANUP_BEGIN',
  CLIENT_USERS_CLEANUP_SUCCESS: 'CLIENT_USERS_CLEANUP_SUCCESS',
  CLIENT_USERS_CLEANUP_ERR: 'CLIENT_USERS_CLEANUP_ERR',

  clientUsersCleanupBegin: () => {
    return {
      type: actions.CLIENT_USERS_CLEANUP_BEGIN,
    };
  },

  clientUsersCleanupSuccess: data => {
    return {
      type: actions.CLIENT_USERS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientUsersCleanupErr: err => {
    return {
      type: actions.CLIENT_USERS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_USER_UPDATE_BEGIN: 'CLIENT_USER_UPDATE_BEGIN',
  CLIENT_USER_UPDATE_SUCCESS: 'CLIENT_USER_UPDATE_SUCCESS',
  CLIENT_USER_UPDATE_ERR: 'CLIENT_USER_UPDATE_ERR',

  clientUserUpdateBegin: () => {
    return {
      type: actions.CLIENT_USER_UPDATE_BEGIN,
    };
  },

  clientUserUpdateSuccess: data => {
    return {
      type: actions.CLIENT_USER_UPDATE_SUCCESS,
      data,
    };
  },

  clientUserUpdateErr: err => {
    return {
      type: actions.CLIENT_USER_UPDATE_ERR,
      err,
    };
  },

  CLIENT_USER_CLEANUP_BEGIN: 'CLIENT_USER_CLEANUP_BEGIN',
  CLIENT_USER_CLEANUP_SUCCESS: 'CLIENT_USER_CLEANUP_SUCCESS',
  CLIENT_USER_CLEANUP_ERR: 'CLIENT_USER_CLEANUP_ERR',

  clientUserCleanupBegin: () => {
    return {
      type: actions.CLIENT_USER_CLEANUP_BEGIN,
    };
  },

  clientUserCleanupSuccess: () => {
    return {
      type: actions.CLIENT_USER_CLEANUP_SUCCESS,
    };
  },

  clientUserCleanupErr: err => {
    return {
      type: actions.CLIENT_USER_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_USER_SAVE_BEGIN: 'CLIENT_USER_SAVE_BEGIN',
  CLIENT_USER_SAVE_SUCCESS: 'CLIENT_USER_SAVE_SUCCESS',
  CLIENT_USER_SAVE_ERR: 'CLIENT_USER_SAVE_ERR',

  clientUserSaveBegin: () => {
    return {
      type: actions.CLIENT_USER_SAVE_BEGIN,
    };
  },

  clientUserSaveSuccess: data => {
    return {
      type: actions.CLIENT_USER_SAVE_SUCCESS,
      data,
    };
  },

  clientUserSaveErr: err => {
    return {
      type: actions.CLIENT_USER_SAVE_ERR,
      err,
    };
  },

  CLIENT_USER_DELETE_BEGIN: 'CLIENT_USER_DELETE_BEGIN',
  CLIENT_USER_DELETE_SUCCESS: 'CLIENT_USER_DELETE_SUCCESS',
  CLIENT_USER_DELETE_ERR: 'CLIENT_USER_DELETE_ERR',

  clientUserDeleteBegin: () => {
    return {
      type: actions.CLIENT_USER_DELETE_BEGIN,
    };
  },

  clientUserDeleteSuccess: data => {
    return {
      type: actions.CLIENT_USER_DELETE_SUCCESS,
      data,
    };
  },

  clientUserDeleteErr: err => {
    return {
      type: actions.CLIENT_USER_DELETE_ERR,
      err,
    };
  },
};

export default actions;
