const actions = {
  CLIENT_ATTORNEYS_UPDATE_BEGIN: 'CLIENT_ATTORNEYS_UPDATE_BEGIN',
  CLIENT_ATTORNEYS_UPDATE_SUCCESS: 'CLIENT_ATTORNEYS_UPDATE_SUCCESS',
  CLIENT_ATTORNEYS_UPDATE_ERR: 'CLIENT_ATTORNEYS_UPDATE_ERR',

  clientAttorneysUpdateBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEYS_UPDATE_BEGIN,
    };
  },

  clientAttorneysUpdateSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEYS_UPDATE_SUCCESS,
      data,
    };
  },

  clientAttorneysUpdateErr: err => {
    return {
      type: actions.CLIENT_ATTORNEYS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_ATTORNEYS_CLEANUP_BEGIN: 'CLIENT_ATTORNEYS_CLEANUP_BEGIN',
  CLIENT_ATTORNEYS_CLEANUP_SUCCESS: 'CLIENT_ATTORNEYS_CLEANUP_SUCCESS',
  CLIENT_ATTORNEYS_CLEANUP_ERR: 'CLIENT_ATTORNEYS_CLEANUP_ERR',

  clientAttorneysCleanupBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEYS_CLEANUP_BEGIN,
    };
  },

  clientAttorneysCleanupSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEYS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientAttorneysCleanupErr: err => {
    return {
      type: actions.CLIENT_ATTORNEYS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_ATTORNEY_UPDATE_BEGIN: 'CLIENT_ATTORNEY_UPDATE_BEGIN',
  CLIENT_ATTORNEY_UPDATE_SUCCESS: 'CLIENT_ATTORNEY_UPDATE_SUCCESS',
  CLIENT_ATTORNEY_UPDATE_ERR: 'CLIENT_ATTORNEY_UPDATE_ERR',

  clientAttorneyUpdateBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_UPDATE_BEGIN,
    };
  },

  clientAttorneyUpdateSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEY_UPDATE_SUCCESS,
      data,
    };
  },

  clientAttorneyUpdateErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_UPDATE_ERR,
      err,
    };
  },

  CLIENT_ATTORNEY_CLEANUP_BEGIN: 'CLIENT_ATTORNEY_CLEANUP_BEGIN',
  CLIENT_ATTORNEY_CLEANUP_SUCCESS: 'CLIENT_ATTORNEY_CLEANUP_SUCCESS',
  CLIENT_ATTORNEY_CLEANUP_ERR: 'CLIENT_ATTORNEY_CLEANUP_ERR',

  clientAttorneyCleanupBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_CLEANUP_BEGIN,
    };
  },

  clientAttorneyCleanupSuccess: () => {
    return {
      type: actions.CLIENT_ATTORNEY_CLEANUP_SUCCESS,
    };
  },

  clientAttorneyCleanupErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_ATTORNEY_SAVE_BEGIN: 'CLIENT_ATTORNEY_SAVE_BEGIN',
  CLIENT_ATTORNEY_SAVE_SUCCESS: 'CLIENT_ATTORNEY_SAVE_SUCCESS',
  CLIENT_ATTORNEY_SAVE_ERR: 'CLIENT_ATTORNEY_SAVE_ERR',

  clientAttorneySaveBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_SAVE_BEGIN,
    };
  },

  clientAttorneySaveSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEY_SAVE_SUCCESS,
      data,
    };
  },

  clientAttorneySaveErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_SAVE_ERR,
      err,
    };
  },

  CLIENT_ATTORNEY_DELETE_BEGIN: 'CLIENT_ATTORNEY_DELETE_BEGIN',
  CLIENT_ATTORNEY_DELETE_SUCCESS: 'CLIENT_ATTORNEY_DELETE_SUCCESS',
  CLIENT_ATTORNEY_DELETE_ERR: 'CLIENT_ATTORNEY_DELETE_ERR',

  clientAttorneyDeleteBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_DELETE_BEGIN,
    };
  },

  clientAttorneyDeleteSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEY_DELETE_SUCCESS,
      data,
    };
  },

  clientAttorneyDeleteErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_DELETE_ERR,
      err,
    };
  },
};

export default actions;
