const actions = {
  USER_CLIENT_UPDATE_BEGIN: 'USER_CLIENT_UPDATE_BEGIN',
  USER_CLIENT_UPDATE_SUCCESS: 'USER_CLIENT_UPDATE_SUCCESS',
  USER_CLIENT_UPDATE_ERR: 'USER_CLIENT_UPDATE_ERR',

  userClientUpdateBegin: () => {
    return {
      type: actions.USER_CLIENT_UPDATE_BEGIN,
    };
  },

  userClientUpdateSuccess: data => {
    return {
      type: actions.USER_CLIENT_UPDATE_SUCCESS,
      data,
    };
  },

  userClientUpdateErr: err => {
    return {
      type: actions.USER_CLIENT_UPDATE_ERR,
      err,
    };
  },

  USER_CLIENT_CLEANUP_BEGIN: 'USER_CLIENT_CLEANUP_BEGIN',
  USER_CLIENT_CLEANUP_SUCCESS: 'USER_CLIENT_CLEANUP_SUCCESS',
  USER_CLIENT_CLEANUP_ERR: 'USER_CLIENT_CLEANUP_ERR',

  userClientCleanupBegin: () => {
    return {
      type: actions.USER_CLIENT_CLEANUP_BEGIN,
    };
  },

  userClientCleanupSuccess: () => {
    return {
      type: actions.USER_CLIENT_CLEANUP_SUCCESS,
    };
  },

  userClientCleanupErr: err => {
    return {
      type: actions.USER_CLIENT_CLEANUP_ERR,
      err,
    };
  },

  USER_CLIENT_SAVE_BEGIN: 'USER_CLIENT_SAVE_BEGIN',
  USER_CLIENT_SAVE_SUCCESS: 'USER_CLIENT_SAVE_SUCCESS',
  USER_CLIENT_SAVE_ERR: 'USER_CLIENT_SAVE_ERR',

  userClientSaveBegin: () => {
    return {
      type: actions.USER_CLIENT_SAVE_BEGIN,
    };
  },

  userClientSaveSuccess: data => {
    return {
      type: actions.USER_CLIENT_SAVE_SUCCESS,
      data,
    };
  },

  userClientSaveErr: err => {
    return {
      type: actions.USER_CLIENT_SAVE_ERR,
      err,
    };
  },

  USER_CLIENT_DELETE_BEGIN: 'USER_CLIENT_DELETE_BEGIN',
  USER_CLIENT_DELETE_SUCCESS: 'USER_CLIENT_DELETE_SUCCESS',
  USER_CLIENT_DELETE_ERR: 'USER_CLIENT_DELETE_ERR',

  userClientDeleteBegin: () => {
    return {
      type: actions.USER_CLIENT_DELETE_BEGIN,
    };
  },

  userClientDeleteSuccess: data => {
    return {
      type: actions.USER_CLIENT_DELETE_SUCCESS,
      data,
    };
  },

  userClientDeleteErr: err => {
    return {
      type: actions.USER_CLIENT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
