const actions = {
  FILE_CATEGORIES_LIST_UPDATE_BEGIN: 'FILE_CATEGORIES_LIST_UPDATE_BEGIN',
  FILE_CATEGORIES_LIST_UPDATE_SUCCESS: 'FILE_CATEGORIES_LIST_UPDATE_SUCCESS',
  FILE_CATEGORIES_LIST_UPDATE_ERR: 'FILE_CATEGORIES_LIST_UPDATE_ERR',

  fileCategoriesListUpdateBegin: () => {
    return {
      type: actions.FILE_CATEGORIES_LIST_UPDATE_BEGIN,
    };
  },

  fileCategoriesListUpdateSuccess: data => {
    return {
      type: actions.FILE_CATEGORIES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  fileCategoriesListUpdateErr: err => {
    return {
      type: actions.FILE_CATEGORIES_LIST_UPDATE_ERR,
      err,
    };
  },

  FILE_CATEGORIES_LIST_CLEANUP_BEGIN: 'FILE_CATEGORIES_LIST_CLEANUP_BEGIN',
  FILE_CATEGORIES_LIST_CLEANUP_SUCCESS: 'FILE_CATEGORIES_LIST_CLEANUP_SUCCESS',
  FILE_CATEGORIES_LIST_CLEANUP_ERR: 'FILE_CATEGORIES_LIST_CLEANUP_ERR',

  fileCategoriesListCleanupBegin: () => {
    return {
      type: actions.FILE_CATEGORIES_LIST_CLEANUP_BEGIN,
    };
  },

  fileCategoriesListCleanupSuccess: data => {
    return {
      type: actions.FILE_CATEGORIES_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  fileCategoriesListCleanupErr: err => {
    return {
      type: actions.FILE_CATEGORIES_LIST_CLEANUP_ERR,
      err,
    };
  },

  FILE_CATEGORIES_TREE_UPDATE_BEGIN: 'FILE_CATEGORIES_TREE_UPDATE_BEGIN',
  FILE_CATEGORIES_TREE_UPDATE_SUCCESS: 'FILE_CATEGORIES_TREE_UPDATE_SUCCESS',
  FILE_CATEGORIES_TREE_UPDATE_ERR: 'FILE_CATEGORIES_TREE_UPDATE_ERR',

  fileCategoriesTreeUpdateBegin: () => {
    return {
      type: actions.FILE_CATEGORIES_TREE_UPDATE_BEGIN,
    };
  },

  fileCategoriesTreeUpdateSuccess: data => {
    return {
      type: actions.FILE_CATEGORIES_TREE_UPDATE_SUCCESS,
      data,
    };
  },

  fileCategoriesTreeUpdateErr: err => {
    return {
      type: actions.FILE_CATEGORIES_TREE_UPDATE_ERR,
      err,
    };
  },

  FILE_CATEGORIES_TREE_CLEANUP_BEGIN: 'FILE_CATEGORIES_TREE_CLEANUP_BEGIN',
  FILE_CATEGORIES_TREE_CLEANUP_SUCCESS: 'FILE_CATEGORIES_TREE_CLEANUP_SUCCESS',
  FILE_CATEGORIES_TREE_CLEANUP_ERR: 'FILE_CATEGORIES_TREE_CLEANUP_ERR',

  fileCategoriesTreeCleanupBegin: () => {
    return {
      type: actions.FILE_CATEGORIES_TREE_CLEANUP_BEGIN,
    };
  },

  fileCategoriesTreeCleanupSuccess: data => {
    return {
      type: actions.FILE_CATEGORIES_TREE_CLEANUP_SUCCESS,
      data,
    };
  },

  fileCategoriesTreeCleanupErr: err => {
    return {
      type: actions.FILE_CATEGORIES_TREE_CLEANUP_ERR,
      err,
    };
  },

  FILE_CATEGORIES_CLEANUP_BEGIN: 'FILE_CATEGORIES_CLEANUP_BEGIN',
  FILE_CATEGORIES_CLEANUP_SUCCESS: 'FILE_CATEGORIES_CLEANUP_SUCCESS',
  FILE_CATEGORIES_CLEANUP_ERR: 'FILE_CATEGORIES_CLEANUP_ERR',

  fileCategoriesCleanupBegin: () => {
    return {
      type: actions.FILE_CATEGORIES_CLEANUP_BEGIN,
    };
  },

  fileCategoriesCleanupSuccess: data => {
    return {
      type: actions.FILE_CATEGORIES_CLEANUP_SUCCESS,
      data,
    };
  },

  fileCategoriesCleanupErr: err => {
    return {
      type: actions.FILE_CATEGORIES_CLEANUP_ERR,
      err,
    };
  },

  FILE_CATEGORY_DELETE_BEGIN: 'FILE_CATEGORY_DELETE_BEGIN',
  FILE_CATEGORY_DELETE_SUCCESS: 'FILE_CATEGORY_DELETE_SUCCESS',
  FILE_CATEGORY_DELETE_ERR: 'FILE_CATEGORY_DELETE_ERR',

  fileCategoryDeleteBegin: () => {
    return {
      type: actions.FILE_CATEGORY_DELETE_BEGIN,
    };
  },

  fileCategoryDeleteSuccess: data => {
    return {
      type: actions.FILE_CATEGORY_DELETE_SUCCESS,
      data,
    };
  },

  fileCategoryDeleteErr: err => {
    return {
      type: actions.FILE_CATEGORY_DELETE_ERR,
      err,
    };
  },

  FILE_CATEGORY_UPDATE_BEGIN: 'FILE_CATEGORY_UPDATE_BEGIN',
  FILE_CATEGORY_UPDATE_SUCCESS: 'FILE_CATEGORY_UPDATE_SUCCESS',
  FILE_CATEGORY_UPDATE_ERR: 'FILE_CATEGORY_UPDATE_ERR',

  fileCategoryUpdateBegin: () => {
    return {
      type: actions.FILE_CATEGORY_UPDATE_BEGIN,
    };
  },

  fileCategoryUpdateSuccess: data => {
    return {
      type: actions.FILE_CATEGORY_UPDATE_SUCCESS,
      data,
    };
  },

  fileCategoryUpdateErr: err => {
    return {
      type: actions.FILE_CATEGORY_UPDATE_ERR,
      err,
    };
  },

  FILE_CATEGORY_CLEANUP_BEGIN: 'FILE_CATEGORY_CLEANUP_BEGIN',
  FILE_CATEGORY_CLEANUP_SUCCESS: 'FILE_CATEGORY_CLEANUP_SUCCESS',
  FILE_CATEGORY_CLEANUP_ERR: 'FILE_CATEGORY_CLEANUP_ERR',

  fileCategoryCleanupBegin: () => {
    return {
      type: actions.FILE_CATEGORY_CLEANUP_BEGIN,
    };
  },

  fileCategoryCleanupSuccess: () => {
    return {
      type: actions.FILE_CATEGORY_CLEANUP_SUCCESS,
    };
  },

  fileCategoryCleanupErr: err => {
    return {
      type: actions.FILE_CATEGORY_CLEANUP_ERR,
      err,
    };
  },

  FILE_CATEGORY_SAVE_BEGIN: 'FILE_CATEGORY_SAVE_BEGIN',
  FILE_CATEGORY_SAVE_SUCCESS: 'FILE_CATEGORY_SAVE_SUCCESS',
  FILE_CATEGORY_SAVE_ERR: 'FILE_CATEGORY_SAVE_ERR',

  fileCategorySaveBegin: () => {
    return {
      type: actions.FILE_CATEGORY_SAVE_BEGIN,
    };
  },

  fileCategorySaveSuccess: data => {
    return {
      type: actions.FILE_CATEGORY_SAVE_SUCCESS,
      data,
    };
  },

  fileCategorySaveErr: err => {
    return {
      type: actions.FILE_CATEGORY_SAVE_ERR,
      err,
    };
  },
};

export default actions;
  