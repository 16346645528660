const actions = {
  CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_BEGIN: 'CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_BEGIN',
  CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_SUCCESS: 'CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_SUCCESS',
  CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_ERR: 'CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_ERR',

  clientTaxBreakSignersListUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_BEGIN,
    };
  },

  clientTaxBreakSignersListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxBreakSignersListUpdateErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAK_SIGNERS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_BEGIN: 'CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_BEGIN',
  CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_SUCCESS: 'CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_SUCCESS',
  CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_ERR: 'CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_ERR',

  clientTaxBreakSignersListCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_BEGIN,
    };
  },

  clientTaxBreakSignersListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientTaxBreakSignersListCleanupErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAK_SIGNERS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  