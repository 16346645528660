const actions = {
  CARDS_SUMMARY_PAGINATION_UPDATE_BEGIN: 'CARDS_SUMMARY_PAGINATION_UPDATE_BEGIN',
  CARDS_SUMMARY_PAGINATION_UPDATE_SUCCESS: 'CARDS_SUMMARY_PAGINATION_UPDATE_SUCCESS',
  CARDS_SUMMARY_PAGINATION_UPDATE_ERR: 'CARDS_SUMMARY_PAGINATION_UPDATE_ERR',

  cardsSummaryPaginationUpdateBegin: () => {
    return {
      type: actions.CARDS_SUMMARY_PAGINATION_UPDATE_BEGIN,
    };
  },

  cardsSummaryPaginationUpdateSuccess: data => {
    return {
      type: actions.CARDS_SUMMARY_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardsSummaryPaginationUpdateErr: err => {
    return {
      type: actions.CARDS_SUMMARY_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CARDS_SUMMARY_FILTERS_UPDATE_BEGIN: 'CARDS_SUMMARY_FILTERS_UPDATE_BEGIN',
  CARDS_SUMMARY_FILTERS_UPDATE_SUCCESS: 'CARDS_SUMMARY_FILTERS_UPDATE_SUCCESS',
  CARDS_SUMMARY_FILTERS_UPDATE_ERR: 'CARDS_SUMMARY_FILTERS_UPDATE_ERR',

  cardsSummaryFiltersUpdateBegin: () => {
    return {
      type: actions.CARDS_SUMMARY_FILTERS_UPDATE_BEGIN,
    };
  },

  cardsSummaryFiltersUpdateSuccess: data => {
    return {
      type: actions.CARDS_SUMMARY_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsSummaryFiltersUpdateErr: err => {
    return {
      type: actions.CARDS_SUMMARY_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARDS_SUMMARY_UPDATE_BEGIN: 'CARDS_SUMMARY_UPDATE_BEGIN',
  CARDS_SUMMARY_UPDATE_SUCCESS: 'CARDS_SUMMARY_UPDATE_SUCCESS',
  CARDS_SUMMARY_UPDATE_ERR: 'CARDS_SUMMARY_UPDATE_ERR',

  cardsSummaryUpdateBegin: () => {
    return {
      type: actions.CARDS_SUMMARY_UPDATE_BEGIN,
    };
  },

  cardsSummaryUpdateSuccess: data => {
    return {
      type: actions.CARDS_SUMMARY_UPDATE_SUCCESS,
      data,
    };
  },

  cardsSummaryUpdateErr: err => {
    return {
      type: actions.CARDS_SUMMARY_UPDATE_ERR,
      err,
    };
  },

  CARDS_SUMMARY_CLEANUP_BEGIN: 'CARDS_SUMMARY_CLEANUP_BEGIN',
  CARDS_SUMMARY_CLEANUP_SUCCESS: 'CARDS_SUMMARY_CLEANUP_SUCCESS',
  CARDS_SUMMARY_CLEANUP_ERR: 'CARDS_SUMMARY_CLEANUP_ERR',

  cardsSummaryCleanupBegin: () => {
    return {
      type: actions.CARDS_SUMMARY_CLEANUP_BEGIN,
    };
  },

  cardsSummaryCleanupSuccess: () => {
    return {
      type: actions.CARDS_SUMMARY_CLEANUP_SUCCESS,
    };
  },

  cardsSummaryCleanupErr: err => {
    return {
      type: actions.CARDS_SUMMARY_CLEANUP_ERR,
      err,
    };
  },

  CARDS_PERMISSIONS_POLICY_UPDATE_BEGIN: 'CARDS_PERMISSIONS_POLICY_UPDATE_BEGIN',
  CARDS_PERMISSIONS_POLICY_UPDATE_SUCCESS: 'CARDS_PERMISSIONS_POLICY_UPDATE_SUCCESS',
  CARDS_PERMISSIONS_POLICY_UPDATE_ERR: 'CARDS_PERMISSIONS_POLICY_UPDATE_ERR',

  cardsPermissionsPolicyUpdateBegin: () => {
    return {
      type: actions.CARDS_PERMISSIONS_POLICY_UPDATE_BEGIN,
    };
  },

  cardsPermissionsPolicyUpdateSuccess: data => {
    return {
      type: actions.CARDS_PERMISSIONS_POLICY_UPDATE_SUCCESS,
      data,
    };
  },

  cardsPermissionsPolicyUpdateErr: err => {
    return {
      type: actions.CARDS_PERMISSIONS_POLICY_UPDATE_ERR,
      err,
    };
  },

  CARDS_DATE_FILTER_FIELD_UPDATE_BEGIN: 'CARDS_DATE_FILTER_FIELD_UPDATE_BEGIN',
  CARDS_DATE_FILTER_FIELD_UPDATE_SUCCESS: 'CARDS_DATE_FILTER_FIELD_UPDATE_SUCCESS',
  CARDS_DATE_FILTER_FIELD_UPDATE_ERR: 'CARDS_DATE_FILTER_FIELD_UPDATE_ERR',

  cardsDateFilterFieldUpdateBegin: () => {
    return {
      type: actions.CARDS_DATE_FILTER_FIELD_UPDATE_BEGIN,
    };
  },

  cardsDateFilterFieldUpdateSuccess: data => {
    return {
      type: actions.CARDS_DATE_FILTER_FIELD_UPDATE_SUCCESS,
      data,
    };
  },

  cardsDateFilterFieldUpdateErr: err => {
    return {
      type: actions.CARDS_DATE_FILTER_FIELD_UPDATE_ERR,
      err,
    };
  },

  CARDS_PAGINATION_UPDATE_BEGIN: 'CARDS_PAGINATION_UPDATE_BEGIN',
  CARDS_PAGINATION_UPDATE_SUCCESS: 'CARDS_PAGINATION_UPDATE_SUCCESS',
  CARDS_PAGINATION_UPDATE_ERR: 'CARDS_PAGINATION_UPDATE_ERR',

  cardsPaginationUpdateBegin: () => {
    return {
      type: actions.CARDS_PAGINATION_UPDATE_BEGIN,
    };
  },

  cardsPaginationUpdateSuccess: data => {
    return {
      type: actions.CARDS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardsPaginationUpdateErr: err => {
    return {
      type: actions.CARDS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CARDS_FILTERS_UPDATE_BEGIN: 'CARDS_FILTERS_UPDATE_BEGIN',
  CARDS_FILTERS_UPDATE_SUCCESS: 'CARDS_FILTERS_UPDATE_SUCCESS',
  CARDS_FILTERS_UPDATE_ERR: 'CARDS_FILTERS_UPDATE_ERR',

  cardsFiltersUpdateBegin: () => {
    return {
      type: actions.CARDS_FILTERS_UPDATE_BEGIN,
    };
  },

  cardsFiltersUpdateSuccess: data => {
    return {
      type: actions.CARDS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsFiltersUpdateErr: err => {
    return {
      type: actions.CARDS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARDS_SORTER_UPDATE_BEGIN: 'CARDS_SORTER_UPDATE_BEGIN',
  CARDS_SORTER_UPDATE_SUCCESS: 'CARDS_SORTER_UPDATE_SUCCESS',
  CARDS_SORTER_UPDATE_ERR: 'CARDS_SORTER_UPDATE_ERR',

  cardsSorterUpdateBegin: () => {
    return {
      type: actions.CARDS_SORTER_UPDATE_BEGIN,
    };
  },

  cardsSorterUpdateSuccess: data => {
    return {
      type: actions.CARDS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardsSorterUpdateErr: err => {
    return {
      type: actions.CARDS_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARDS_UPDATE_BEGIN: 'CARDS_UPDATE_BEGIN',
  CARDS_UPDATE_SUCCESS: 'CARDS_UPDATE_SUCCESS',
  CARDS_UPDATE_ERR: 'CARDS_UPDATE_ERR',

  cardsUpdateBegin: () => {
    return {
      type: actions.CARDS_UPDATE_BEGIN,
    };
  },

  cardsUpdateSuccess: data => {
    return {
      type: actions.CARDS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsUpdateErr: err => {
    return {
      type: actions.CARDS_UPDATE_ERR,
      err,
    };
  },

  CARD_FILES_PAGINATION_UPDATE_BEGIN: 'CARD_FILES_PAGINATION_UPDATE_BEGIN',
  CARD_FILES_PAGINATION_UPDATE_SUCCESS: 'CARD_FILES_PAGINATION_UPDATE_SUCCESS',
  CARD_FILES_PAGINATION_UPDATE_ERR: 'CARD_FILES_PAGINATION_UPDATE_ERR',

  cardFilesPaginationUpdateBegin: () => {
    return {
      type: actions.CARD_FILES_PAGINATION_UPDATE_BEGIN,
    };
  },

  cardFilesPaginationUpdateSuccess: data => {
    return {
      type: actions.CARD_FILES_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardFilesPaginationUpdateErr: err => {
    return {
      type: actions.CARD_FILES_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CARD_FILES_FILTERS_UPDATE_BEGIN: 'CARD_FILES_FILTERS_UPDATE_BEGIN',
  CARD_FILES_FILTERS_UPDATE_SUCCESS: 'CARD_FILES_FILTERS_UPDATE_SUCCESS',
  CARD_FILES_FILTERS_UPDATE_ERR: 'CARD_FILES_FILTERS_UPDATE_ERR',

  cardFilesFiltersUpdateBegin: () => {
    return {
      type: actions.CARD_FILES_FILTERS_UPDATE_BEGIN,
    };
  },

  cardFilesFiltersUpdateSuccess: data => {
    return {
      type: actions.CARD_FILES_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardFilesFiltersUpdateErr: err => {
    return {
      type: actions.CARD_FILES_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARD_FILES_SORTER_UPDATE_BEGIN: 'CARD_FILES_SORTER_UPDATE_BEGIN',
  CARD_FILES_SORTER_UPDATE_SUCCESS: 'CARD_FILES_SORTER_UPDATE_SUCCESS',
  CARD_FILES_SORTER_UPDATE_ERR: 'CARD_FILES_SORTER_UPDATE_ERR',

  cardFilesSorterUpdateBegin: () => {
    return {
      type: actions.CARD_FILES_SORTER_UPDATE_BEGIN,
    };
  },

  cardFilesSorterUpdateSuccess: data => {
    return {
      type: actions.CARD_FILES_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardFilesSorterUpdateErr: err => {
    return {
      type: actions.CARD_FILES_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARD_FILES_UPDATE_BEGIN: 'CARD_FILES_UPDATE_BEGIN',
  CARD_FILES_UPDATE_SUCCESS: 'CARD_FILES_UPDATE_SUCCESS',
  CARD_FILES_UPDATE_ERR: 'CARD_FILES_UPDATE_ERR',

  cardFilesUpdateBegin: () => {
    return {
      type: actions.CARD_FILES_UPDATE_BEGIN,
    };
  },

  cardFilesUpdateSuccess: data => {
    return {
      type: actions.CARD_FILES_UPDATE_SUCCESS,
      data,
    };
  },

  cardFilesUpdateErr: err => {
    return {
      type: actions.CARD_FILES_UPDATE_ERR,
      err,
    };
  },

  CARD_FILES_DOWNLOAD_BEGIN: 'CARD_FILES_DOWNLOAD_BEGIN',
  CARD_FILES_DOWNLOAD_SUCCESS: 'CARD_FILES_DOWNLOAD_SUCCESS',
  CARD_FILES_DOWNLOAD_ERR: 'CARD_FILES_DOWNLOAD_ERR',

  cardFilesDownloadBegin: () => {
    return {
      type: actions.CARD_FILES_DOWNLOAD_BEGIN,
    };
  },

  cardFilesDownloadSuccess: data => {
    return {
      type: actions.CARD_FILES_DOWNLOAD_SUCCESS,
      data,
    };
  },

  cardFilesDownloadErr: err => {
    return {
      type: actions.CARD_FILES_DOWNLOAD_ERR,
      err,
    };
  },

  CARD_FILES_CLEANUP_BEGIN: 'CARD_FILES_CLEANUP_BEGIN',
  CARD_FILES_CLEANUP_SUCCESS: 'CARD_FILES_CLEANUP_SUCCESS',
  CARD_FILES_CLEANUP_ERR: 'CARD_FILES_CLEANUP_ERR',

  cardFilesCleanupBegin: () => {
    return {
      type: actions.CARD_FILES_CLEANUP_BEGIN,
    };
  },

  cardFilesCleanupSuccess: () => {
    return {
      type: actions.CARD_FILES_CLEANUP_SUCCESS,
    };
  },

  cardFilesCleanupErr: err => {
    return {
      type: actions.CARD_FILES_CLEANUP_ERR,
      err,
    };
  },

  CARDS_UPDATE_STATUS_BEGIN: 'CARDS_UPDATE_STATUS_BEGIN',
  CARDS_UPDATE_STATUS_SUCCESS: 'CARDS_UPDATE_STATUS_SUCCESS',
  CARDS_UPDATE_STATUS_ERR: 'CARDS_UPDATE_STATUS_ERR',

  cardsUpdateStatusBegin: () => {
    return {
      type: actions.CARDS_UPDATE_STATUS_BEGIN,
    };
  },

  cardsUpdateStatusSuccess: () => {
    return {
      type: actions.CARDS_UPDATE_STATUS_SUCCESS,
    };
  },

  cardsUpdateStatusErr: err => {
    return {
      type: actions.CARDS_UPDATE_STATUS_ERR,
      err,
    };
  },

  CARDS_DELETE_MANY_BEGIN: 'CARDS_DELETE_MANY_BEGIN',
  CARDS_DELETE_MANY_SUCCESS: 'CARDS_DELETE_MANY_SUCCESS',
  CARDS_DELETE_MANY_ERR: 'CARDS_DELETE_MANY_ERR',

  cardsDeleteManyBegin: () => {
    return {
      type: actions.CARDS_DELETE_MANY_BEGIN,
    };
  },

  cardsDeleteManySuccess: () => {
    return {
      type: actions.CARDS_DELETE_MANY_SUCCESS,
    };
  },

  cardsDeleteManyErr: err => {
    return {
      type: actions.CARDS_DELETE_MANY_ERR,
      err,
    };
  },

  CARDS_CLEANUP_BEGIN: 'CARDS_CLEANUP_BEGIN',
  CARDS_CLEANUP_SUCCESS: 'CARDS_CLEANUP_SUCCESS',
  CARDS_CLEANUP_ERR: 'CARDS_CLEANUP_ERR',

  cardsCleanupBegin: () => {
    return {
      type: actions.CARDS_CLEANUP_BEGIN,
    };
  },

  cardsCleanupSuccess: () => {
    return {
      type: actions.CARDS_CLEANUP_SUCCESS,
    };
  },

  cardsCleanupErr: err => {
    return {
      type: actions.CARDS_CLEANUP_ERR,
      err,
    };
  },

  CARDS_CHARTS_UPDATE_BEGIN: 'CARDS_CHARTS_UPDATE_BEGIN',
  CARDS_CHARTS_UPDATE_SUCCESS: 'CARDS_CHARTS_UPDATE_SUCCESS',
  CARDS_CHARTS_UPDATE_ERR: 'CARDS_CHARTS_UPDATE_ERR',

  cardsChartsUpdateBegin: () => {
    return {
      type: actions.CARDS_CHARTS_UPDATE_BEGIN,
    };
  },

  cardsChartsUpdateSuccess: data => {
    return {
      type: actions.CARDS_CHARTS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsChartsUpdateErr: err => {
    return {
      type: actions.CARDS_CHARTS_UPDATE_ERR,
      err,
    };
  },

  CARDS_CHARTS_CLEANUP_BEGIN: 'CARDS_CHARTS_CLEANUP_BEGIN',
  CARDS_CHARTS_CLEANUP_SUCCESS: 'CARDS_CHARTS_CLEANUP_SUCCESS',
  CARDS_CHARTS_CLEANUP_ERR: 'CARDS_CHARTS_CLEANUP_ERR',

  cardsChartsCleanupBegin: () => {
    return {
      type: actions.CARDS_CHARTS_CLEANUP_BEGIN,
    };
  },

  cardsChartsCleanupSuccess: () => {
    return {
      type: actions.CARDS_CHARTS_CLEANUP_SUCCESS,
    };
  },

  cardsChartsCleanupErr: err => {
    return {
      type: actions.CARDS_CHARTS_CLEANUP_ERR,
      err,
    };
  },

  CARDS_PENDING_CHART_UPDATE_BEGIN: 'CARDS_PENDING_CHART_UPDATE_BEGIN',
  CARDS_PENDING_CHART_UPDATE_SUCCESS: 'CARDS_PENDING_CHART_UPDATE_SUCCESS',
  CARDS_PENDING_CHART_UPDATE_ERR: 'CARDS_PENDING_CHART_UPDATE_ERR',

  cardsPendingChartUpdateBegin: () => {
    return {
      type: actions.CARDS_PENDING_CHART_UPDATE_BEGIN,
    };
  },

  cardsPendingChartUpdateSuccess: (data, permissions_policy) => {
    return {
      type: actions.CARDS_PENDING_CHART_UPDATE_SUCCESS,
      data,
      permissions_policy,
    };
  },

  cardsPendingChartUpdateErr: err => {
    return {
      type: actions.CARDS_PENDING_CHART_UPDATE_ERR,
      err,
    };
  },

  CARDS_PENDING_CHART_CLEANUP_BEGIN: 'CARDS_PENDING_CHART_CLEANUP_BEGIN',
  CARDS_PENDING_CHART_CLEANUP_SUCCESS: 'CARDS_PENDING_CHART_CLEANUP_SUCCESS',
  CARDS_PENDING_CHART_CLEANUP_ERR: 'CARDS_PENDING_CHART_CLEANUP_ERR',

  cardsPendingChartCleanupBegin: () => {
    return {
      type: actions.CARDS_PENDING_CHART_CLEANUP_BEGIN,
    };
  },

  cardsPendingChartCleanupSuccess: () => {
    return {
      type: actions.CARDS_PENDING_CHART_CLEANUP_SUCCESS,
    };
  },

  cardsPendingChartCleanupErr: err => {
    return {
      type: actions.CARDS_PENDING_CHART_CLEANUP_ERR,
      err,
    };
  },

  CARDS_REPORT_FILTERS_UPDATE_BEGIN: 'CARDS_REPORT_FILTERS_UPDATE_BEGIN',
  CARDS_REPORT_FILTERS_UPDATE_SUCCESS: 'CARDS_REPORT_FILTERS_UPDATE_SUCCESS',
  CARDS_REPORT_FILTERS_UPDATE_ERR: 'CARDS_REPORT_FILTERS_UPDATE_ERR',

  cardsReportFiltersUpdateBegin: () => {
    return {
      type: actions.CARDS_REPORT_FILTERS_UPDATE_BEGIN,
    };
  },

  cardsReportFiltersUpdateSuccess: data => {
    return {
      type: actions.CARDS_REPORT_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsReportFiltersUpdateErr: err => {
    return {
      type: actions.CARDS_REPORT_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARDS_REPORT_SORTER_UPDATE_BEGIN: 'CARDS_REPORT_SORTER_UPDATE_BEGIN',
  CARDS_REPORT_SORTER_UPDATE_SUCCESS: 'CARDS_REPORT_SORTER_UPDATE_SUCCESS',
  CARDS_REPORT_SORTER_UPDATE_ERR: 'CARDS_REPORT_SORTER_UPDATE_ERR',

  cardsReportSorterUpdateBegin: () => {
    return {
      type: actions.CARDS_REPORT_SORTER_UPDATE_BEGIN,
    };
  },

  cardsReportSorterUpdateSuccess: data => {
    return {
      type: actions.CARDS_REPORT_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardsReportSorterUpdateErr: err => {
    return {
      type: actions.CARDS_REPORT_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARDS_REPORT_UPDATE_BEGIN: 'CARDS_REPORT_UPDATE_BEGIN',
  CARDS_REPORT_UPDATE_SUCCESS: 'CARDS_REPORT_UPDATE_SUCCESS',
  CARDS_REPORT_UPDATE_ERR: 'CARDS_REPORT_UPDATE_ERR',

  cardsReportUpdateBegin: () => {
    return {
      type: actions.CARDS_REPORT_UPDATE_BEGIN,
    };
  },

  cardsReportUpdateSuccess: data => {
    return {
      type: actions.CARDS_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  cardsReportUpdateErr: err => {
    return {
      type: actions.CARDS_REPORT_UPDATE_ERR,
      err,
    };
  },

  CARDS_REPORT_CLEANUP_BEGIN: 'CARDS_REPORT_CLEANUP_BEGIN',
  CARDS_REPORT_CLEANUP_SUCCESS: 'CARDS_REPORT_CLEANUP_SUCCESS',
  CARDS_REPORT_CLEANUP_ERR: 'CARDS_REPORT_CLEANUP_ERR',

  cardsReportCleanupBegin: () => {
    return {
      type: actions.CARDS_REPORT_CLEANUP_BEGIN,
    };
  },

  cardsReportCleanupSuccess: () => {
    return {
      type: actions.CARDS_REPORT_CLEANUP_SUCCESS,
    };
  },

  cardsReportCleanupErr: err => {
    return {
      type: actions.CARDS_REPORT_CLEANUP_ERR,
      err,
    };
  },

  CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_BEGIN: 'CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_BEGIN',
  CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_SUCCESS: 'CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_SUCCESS',
  CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_ERR: 'CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_ERR',

  cardsEmployeesReportFiltersUpdateBegin: () => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_BEGIN,
    };
  },

  cardsEmployeesReportFiltersUpdateSuccess: data => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsEmployeesReportFiltersUpdateErr: err => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_BEGIN: 'CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_BEGIN',
  CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_SUCCESS: 'CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_SUCCESS',
  CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_ERR: 'CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_ERR',

  cardsEmployeesReportSorterUpdateBegin: () => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_BEGIN,
    };
  },

  cardsEmployeesReportSorterUpdateSuccess: data => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardsEmployeesReportSorterUpdateErr: err => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARDS_EMPLOYEES_REPORT_UPDATE_BEGIN: 'CARDS_EMPLOYEES_REPORT_UPDATE_BEGIN',
  CARDS_EMPLOYEES_REPORT_UPDATE_SUCCESS: 'CARDS_EMPLOYEES_REPORT_UPDATE_SUCCESS',
  CARDS_EMPLOYEES_REPORT_UPDATE_ERR: 'CARDS_EMPLOYEES_REPORT_UPDATE_ERR',

  cardsEmployeesReportUpdateBegin: () => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_UPDATE_BEGIN,
    };
  },

  cardsEmployeesReportUpdateSuccess: data => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  cardsEmployeesReportUpdateErr: err => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_UPDATE_ERR,
      err,
    };
  },

  CARDS_EMPLOYEES_REPORT_CLEANUP_BEGIN: 'CARDS_EMPLOYEES_REPORT_CLEANUP_BEGIN',
  CARDS_EMPLOYEES_REPORT_CLEANUP_SUCCESS: 'CARDS_EMPLOYEES_REPORT_CLEANUP_SUCCESS',
  CARDS_EMPLOYEES_REPORT_CLEANUP_ERR: 'CARDS_EMPLOYEES_REPORT_CLEANUP_ERR',

  cardsEmployeesReportCleanupBegin: () => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_CLEANUP_BEGIN,
    };
  },

  cardsEmployeesReportCleanupSuccess: () => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_CLEANUP_SUCCESS,
    };
  },

  cardsEmployeesReportCleanupErr: err => {
    return {
      type: actions.CARDS_EMPLOYEES_REPORT_CLEANUP_ERR,
      err,
    };
  },

  CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_BEGIN: 'CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_BEGIN',
  CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_SUCCESS: 'CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_SUCCESS',
  CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_ERR: 'CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_ERR',

  cardsQuestionsReportFiltersUpdateBegin: () => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_BEGIN,
    };
  },

  cardsQuestionsReportFiltersUpdateSuccess: data => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardsQuestionsReportFiltersUpdateErr: err => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARDS_QUESTIONS_REPORT_SORTER_UPDATE_BEGIN: 'CARDS_QUESTIONS_REPORT_SORTER_UPDATE_BEGIN',
  CARDS_QUESTIONS_REPORT_SORTER_UPDATE_SUCCESS: 'CARDS_QUESTIONS_REPORT_SORTER_UPDATE_SUCCESS',
  CARDS_QUESTIONS_REPORT_SORTER_UPDATE_ERR: 'CARDS_QUESTIONS_REPORT_SORTER_UPDATE_ERR',

  cardsQuestionsReportSorterUpdateBegin: () => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_SORTER_UPDATE_BEGIN,
    };
  },

  cardsQuestionsReportSorterUpdateSuccess: data => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardsQuestionsReportSorterUpdateErr: err => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARDS_QUESTIONS_REPORT_UPDATE_BEGIN: 'CARDS_QUESTIONS_REPORT_UPDATE_BEGIN',
  CARDS_QUESTIONS_REPORT_UPDATE_SUCCESS: 'CARDS_QUESTIONS_REPORT_UPDATE_SUCCESS',
  CARDS_QUESTIONS_REPORT_UPDATE_ERR: 'CARDS_QUESTIONS_REPORT_UPDATE_ERR',

  cardsQuestionsReportUpdateBegin: () => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_UPDATE_BEGIN,
    };
  },

  cardsQuestionsReportUpdateSuccess: data => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  cardsQuestionsReportUpdateErr: err => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_UPDATE_ERR,
      err,
    };
  },

  CARDS_QUESTIONS_REPORT_CLEANUP_BEGIN: 'CARDS_QUESTIONS_REPORT_CLEANUP_BEGIN',
  CARDS_QUESTIONS_REPORT_CLEANUP_SUCCESS: 'CARDS_QUESTIONS_REPORT_CLEANUP_SUCCESS',
  CARDS_QUESTIONS_REPORT_CLEANUP_ERR: 'CARDS_QUESTIONS_REPORT_CLEANUP_ERR',

  cardsQuestionsReportCleanupBegin: () => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_CLEANUP_BEGIN,
    };
  },

  cardsQuestionsReportCleanupSuccess: () => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_CLEANUP_SUCCESS,
    };
  },

  cardsQuestionsReportCleanupErr: err => {
    return {
      type: actions.CARDS_QUESTIONS_REPORT_CLEANUP_ERR,
      err,
    };
  },

  CARD_DELETE_BEGIN: 'CARD_DELETE_BEGIN',
  CARD_DELETE_SUCCESS: 'CARD_DELETE_SUCCESS',
  CARD_DELETE_ERR: 'CARD_DELETE_ERR',

  cardDeleteBegin: () => {
    return {
      type: actions.CARD_DELETE_BEGIN,
    };
  },

  cardDeleteSuccess: data => {
    return {
      type: actions.CARD_DELETE_SUCCESS,
      data,
    };
  },

  cardDeleteErr: err => {
    return {
      type: actions.CARD_DELETE_ERR,
      err,
    };
  },

  CARD_UPDATE_BEGIN: 'CARD_UPDATE_BEGIN',
  CARD_UPDATE_SUCCESS: 'CARD_UPDATE_SUCCESS',
  CARD_UPDATE_ERR: 'CARD_UPDATE_ERR',

  cardUpdateBegin: () => {
    return {
      type: actions.CARD_UPDATE_BEGIN,
    };
  },

  cardUpdateSuccess: data => {
    return {
      type: actions.CARD_UPDATE_SUCCESS,
      data,
    };
  },

  cardUpdateErr: err => {
    return {
      type: actions.CARD_UPDATE_ERR,
      err,
    };
  },

  CARD_CLEANUP_BEGIN: 'CARD_CLEANUP_BEGIN',
  CARD_CLEANUP_SUCCESS: 'CARD_CLEANUP_SUCCESS',
  CARD_CLEANUP_ERR: 'CARD_CLEANUP_ERR',

  cardCleanupBegin: () => {
    return {
      type: actions.CARD_CLEANUP_BEGIN,
    };
  },

  cardCleanupSuccess: () => {
    return {
      type: actions.CARD_CLEANUP_SUCCESS,
    };
  },

  cardCleanupErr: err => {
    return {
      type: actions.CARD_CLEANUP_ERR,
      err,
    };
  },

  CARD_SAVE_BEGIN: 'CARD_SAVE_BEGIN',
  CARD_SAVE_SUCCESS: 'CARD_SAVE_SUCCESS',
  CARD_SAVE_ERR: 'CARD_SAVE_ERR',

  cardSaveBegin: () => {
    return {
      type: actions.CARD_SAVE_BEGIN,
    };
  },

  cardSaveSuccess: data => {
    return {
      type: actions.CARD_SAVE_SUCCESS,
      data,
    };
  },

  cardSaveErr: err => {
    return {
      type: actions.CARD_SAVE_ERR,
      err,
    };
  },

  CARDS_TRANSFER_BEGIN: 'CARDS_TRANSFER_BEGIN',
  CARDS_TRANSFER_SUCCESS: 'CARDS_TRANSFER_SUCCESS',
  CARDS_TRANSFER_ERR: 'CARDS_TRANSFER_ERR',

  cardsTransferBegin: () => {
    return {
      type: actions.CARDS_TRANSFER_BEGIN,
    };
  },

  cardsTransferSuccess: data => {
    return {
      type: actions.CARDS_TRANSFER_SUCCESS,
      data,
    };
  },

  cardsTransferErr: err => {
    return {
      type: actions.CARDS_TRANSFER_ERR,
      err,
    };
  },

  CARD_EXISTS_CLEANUP_BEGIN: 'CARD_EXISTS_CLEANUP_BEGIN',
  CARD_EXISTS_CLEANUP_SUCCESS: 'CARD_EXISTS_CLEANUP_SUCCESS',
  CARD_EXISTS_CLEANUP_ERR: 'CARD_EXISTS_CLEANUP_ERR',

  cardExistsCleanupBegin: () => {
    return {
      type: actions.CARD_EXISTS_CLEANUP_BEGIN,
    };
  },

  cardExistsCleanupSuccess: () => {
    return {
      type: actions.CARD_EXISTS_CLEANUP_SUCCESS,
    };
  },

  cardExistsCleanupErr: err => {
    return {
      type: actions.CARD_EXISTS_CLEANUP_ERR,
      err,
    };
  },

  CARD_EXISTS_BEGIN: 'CARD_EXISTS_BEGIN',
  CARD_EXISTS_SUCCESS: 'CARD_EXISTS_SUCCESS',
  CARD_EXISTS_ERR: 'CARD_EXISTS_ERR',

  cardExistsBegin: () => {
    return {
      type: actions.CARD_EXISTS_BEGIN,
    };
  },

  cardExistsSuccess: data => {
    return {
      type: actions.CARD_EXISTS_SUCCESS,
      data,
    };
  },

  cardExistsErr: err => {
    return {
      type: actions.CARD_EXISTS_ERR,
      err,
    };
  },
};

export default actions;
