import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  ASSETS_PAGINATION_UPDATE_SUCCESS,
  ASSETS_FILTERS_UPDATE_SUCCESS,
  ASSETS_SORTER_UPDATE_SUCCESS,

  ASSETS_UPDATE_BEGIN,
  ASSETS_UPDATE_SUCCESS,
  ASSETS_UPDATE_ERR,

  ASSETS_CLEANUP_BEGIN,
  ASSETS_CLEANUP_SUCCESS,
  ASSETS_CLEANUP_ERR,

  ASSET_DELETE_BEGIN,
  ASSET_DELETE_SUCCESS,
  ASSET_DELETE_ERR,
} = actions;

const assetsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSETS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case ASSETS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case ASSETS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case ASSETS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSETS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ASSETS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSETS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSETS_CLEANUP_SUCCESS:
      return initialIndexState;

    case ASSETS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case ASSET_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case ASSET_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialTreeState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ASSETS_TREE_UPDATE_BEGIN,
  ASSETS_TREE_UPDATE_SUCCESS,
  ASSETS_TREE_UPDATE_ERR,

  ASSETS_TREE_CLEANUP_BEGIN,
  ASSETS_TREE_CLEANUP_SUCCESS,
  ASSETS_TREE_CLEANUP_ERR,
} = actions;

const assetsTreeReducer = (state = initialTreeState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSETS_TREE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSETS_TREE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ASSETS_TREE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSETS_TREE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSETS_TREE_CLEANUP_SUCCESS:
      return initialTreeState;

    case ASSETS_TREE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ASSETS_LIST_UPDATE_BEGIN,
  ASSETS_LIST_UPDATE_SUCCESS,
  ASSETS_LIST_UPDATE_ERR,

  ASSETS_LIST_CLEANUP_BEGIN,
  ASSETS_LIST_CLEANUP_SUCCESS,
  ASSETS_LIST_CLEANUP_ERR,
} = actions;

const assetsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSETS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ASSETS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ASSETS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSETS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ASSETS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case ASSETS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: null,
  loading: false,
  saving: false,
  error: null,
};

const {
  ASSET_UPDATE_BEGIN,
  ASSET_UPDATE_SUCCESS,
  ASSET_UPDATE_ERR,

  ASSET_CLEANUP_BEGIN,
  ASSET_CLEANUP_SUCCESS,
  ASSET_CLEANUP_ERR,

  ASSET_SAVE_BEGIN,
  ASSET_SAVE_SUCCESS,
  ASSET_SAVE_ERR,
} = actions;

const assetReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSET_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ASSET_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CLEANUP_SUCCESS:
      return initialSingleState;

    case ASSET_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case ASSET_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case ASSET_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { assetsReducer, assetsTreeReducer, assetsListReducer, assetReducer };
