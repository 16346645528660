import actions from './actions';
import CONSTANTS from '../../config/constants';

// Index
const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {
    user_status_id: [CONSTANTS.USER_STATUS.ATIVO],
  },
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  USERS_PAGINATION_UPDATE_SUCCESS,
  USERS_FILTERS_UPDATE_SUCCESS,
  USERS_SORTER_UPDATE_SUCCESS,

  USERS_UPDATE_BEGIN,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_ERR,

  USERS_CLEANUP_BEGIN,
  USERS_CLEANUP_SUCCESS,
  USERS_CLEANUP_ERR,

  USER_DELETE_BEGIN,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERR,

  USER_EMPLOYEES_TRANSFER_BEGIN,
  USER_EMPLOYEES_TRANSFER_SUCCESS,
  USER_EMPLOYEES_TRANSFER_ERR,
} = actions;

const usersReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USERS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case USERS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case USERS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case USERS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USERS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USERS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USERS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USERS_CLEANUP_SUCCESS:
      return initialIndexState;

    case USERS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case USER_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case USER_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    case USER_EMPLOYEES_TRANSFER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_EMPLOYEES_TRANSFER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case USER_EMPLOYEES_TRANSFER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

// List
const initialListState = {
  data: [],
  source: null,
  loading: false,
  error: null,
};

const {
  USERS_LIST_UPDATE_BEGIN,
  USERS_LIST_UPDATE_SUCCESS,
  USERS_LIST_UPDATE_ERR,

  USERS_LIST_CLEANUP_BEGIN,
  USERS_LIST_CLEANUP_SUCCESS,
  USERS_LIST_CLEANUP_ERR,
} = actions;

const usersListReducer = (state = initialListState, action) => {
  const { type, data, source, err } = action;
  switch (type) {
    case USERS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        source,
        loading: false,
      };
    case USERS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USERS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case USERS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

// CacheList
const initialCacheListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  USERS_CACHE_LIST_UPDATE_BEGIN,
  USERS_CACHE_LIST_UPDATE_SUCCESS,
  USERS_CACHE_LIST_UPDATE_ERR,

  USERS_CACHE_LIST_CLEANUP_BEGIN,
  USERS_CACHE_LIST_CLEANUP_SUCCESS,
  USERS_CACHE_LIST_CLEANUP_ERR,
} = actions;

const usersCacheListReducer = (state = initialCacheListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USERS_CACHE_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_CACHE_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case USERS_CACHE_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USERS_CACHE_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_CACHE_LIST_CLEANUP_SUCCESS:
      return initialCacheListState;
    case USERS_CACHE_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

// Birthdays List
const initialBirthdaysListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  USERS_BIRTHDAYS_LIST_UPDATE_BEGIN,
  USERS_BIRTHDAYS_LIST_UPDATE_SUCCESS,
  USERS_BIRTHDAYS_LIST_UPDATE_ERR,

  USERS_BIRTHDAYS_LIST_CLEANUP_BEGIN,
  USERS_BIRTHDAYS_LIST_CLEANUP_SUCCESS,
  USERS_BIRTHDAYS_LIST_CLEANUP_ERR,
} = actions;

const usersBirthdaysListReducer = (state = initialBirthdaysListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USERS_BIRTHDAYS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_BIRTHDAYS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case USERS_BIRTHDAYS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USERS_BIRTHDAYS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_BIRTHDAYS_LIST_CLEANUP_SUCCESS:
      return initialBirthdaysListState;
    case USERS_BIRTHDAYS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

// Single
const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
  signatureLoading: false,
};

const {
  USER_UPDATE_BEGIN,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERR,

  USER_CLEANUP_BEGIN,
  USER_CLEANUP_SUCCESS,
  USER_CLEANUP_ERR,

  USER_SAVE_BEGIN,
  USER_SAVE_SUCCESS,
  USER_SAVE_ERR,

  USER_SIGNATURE_UPDATE_BEGIN,
  USER_SIGNATURE_UPDATE_SUCCESS,
  USER_SIGNATURE_UPDATE_ERR,
} = actions;

const userReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USER_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_CLEANUP_SUCCESS:
      return initialSingleState;

    case USER_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case USER_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case USER_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case USER_SIGNATURE_UPDATE_BEGIN:
      return {
        ...state,
        signatureLoading: true,
      };

    case USER_SIGNATURE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        signatureLoading: false,
      };

    case USER_SIGNATURE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        signatureLoading: false,
      };

    default:
      return state;
  }
};

// Recover Password
const initialRecoverPasswordState = {
  data: undefined,
  loading: false,
  error: null,
};

const {
  USER_RECOVER_PASSWORD_UPDATE_BEGIN,
  USER_RECOVER_PASSWORD_UPDATE_SUCCESS,
  USER_RECOVER_PASSWORD_UPDATE_ERR,

  USER_RESET_PASSWORD_UPDATE_BEGIN,
  USER_RESET_PASSWORD_UPDATE_SUCCESS,
  USER_RESET_PASSWORD_UPDATE_ERR,
} = actions;

const userRecoverPasswordReducer = (state = initialRecoverPasswordState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_RECOVER_PASSWORD_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_RECOVER_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USER_RECOVER_PASSWORD_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_RESET_PASSWORD_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_RESET_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USER_RESET_PASSWORD_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

// Photo
const initialPhotoState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  USER_PHOTO_UPDATE_BEGIN,
  USER_PHOTO_UPDATE_SUCCESS,
  USER_PHOTO_UPDATE_ERR,
  USER_PHOTO_CLEANUP_BEGIN,
  USER_PHOTO_CLEANUP_SUCCESS,
  USER_PHOTO_CLEANUP_ERR,
} = actions;

const userPhotoReducer = (state = initialPhotoState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_PHOTO_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_PHOTO_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USER_PHOTO_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_PHOTO_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_PHOTO_CLEANUP_SUCCESS:
      return initialPhotoState;

    case USER_PHOTO_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export {
  usersReducer,
  usersListReducer,
  usersCacheListReducer,
  usersBirthdaysListReducer,
  userReducer,
  userRecoverPasswordReducer,
  userPhotoReducer,
};
