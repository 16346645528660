/* eslint-disable no-restricted-globals */
import React from 'react';
import moment from 'moment';

/**
 * output
 */
const output = {
  date: 'YYYY-MM-DD',
  date_my: 'YYYY-MM',
  time: 'HH:mm',
  dateTime: 'YYYY-MM-DD HH:mm:ss',
  moment: (value, format = this.date) => (value && moment.isMoment(value) ? moment(value).format(format) : value),

  /**
   * momentObj transforma os objetos moment do array em string
   * @param {*} obj
   * @param {*} fields
   * @returns
   */
  momentObj: (obj, fields = [], format = undefined) => {
    if (obj) {
      Object.entries(obj).forEach(([key, value]) => {
        if (fields.includes(key)) {
          // string
          if (moment.isMoment(value)) {
            obj = {
              ...obj,
              [key]: value.format(format ?? output.date),
            };
          } else if (value !== undefined && moment.isMoment(value[0])) {
            // array one value [1]
            if (value[1] === undefined) {
              obj = {
                ...obj,
                [key]: [value[0].format(format ?? output.date)],
              };
            }
            // array two values [1, 2]
            else {
              obj = {
                ...obj,
                [key]: [value[0].format(format ?? output.date), value[1].format(format ?? output.date)],
              };
            }
          }
        }
      });
    }

    return obj;
  },
};

/**
 * input
 */
const input = {
  birthdate: 'DD/MM',
  date: 'DD/MM/YYYY',
  date_my: 'MM/YYYY',
  time: 'HH:mm',
  dateTime: 'DD/MM/YYYY HH:mm',
  moment: value => (value && !moment.isMoment(value) ? moment(value) : value),

  /**
   * momentObj transforma os objetos moment do array em string
   * @param {*} obj
   * @param {*} fields
   * @returns
   */
  momentObj: (obj, fields = [], format = undefined) => {
    if (obj) {
      Object.entries(obj).forEach(([key, value]) => {
        if (fields.includes(key)) {
          // string
          if (!Array.isArray(value) && !moment.isMoment(value)) {
            obj = {
              ...obj,
              [key]: moment(value, format ?? input.date),
            };
          } else if (value !== undefined && !moment.isMoment(value[0])) {
            // array one value [1]
            if (value[1] === undefined) {
              obj = {
                ...obj,
                [key]: [moment(value[0], format ?? input.date)],
              };
            }
            // array two values [1, 2]
            else {
              obj = {
                ...obj,
                [key]: [moment(value[0], format ?? input.date), moment(value[1], format ?? input.date)],
              };
            }
          }
        }
      });
    }

    return obj;
  },
};

/**
 * birthdate
 * @param {*} date
 * @returns
 */
const birthdate = date => {
  return moment(date).format(input.birthdate);
};

/**
 * bool
 * @param {*} value
 * @returns
 */
const bool = value => (value ? 'Sim' : 'Não');

/**
 * capitalize
 * @param {*} value
 * @returns
 */
const capitalize = value => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toLocaleUpperCase() + value.slice(1).toLocaleLowerCase();
};

/**
 * checkNumber
 * @param {*} _
 * @param {*} value
 * @returns
 */
const checkNumber = (_, value) => {
  if (value > 0) {
    return Promise.resolve();
  }

  return Promise.reject(new Error('Valor deve ser maior que zero'));
};

/**
 * currency
 */
const currency = {
  /**
   * formatter
   * @param {*} value
   * @returns
   */
  formatter: value =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value),

  /**
   * parser
   * @param {*} value
   * @returns
   */
  parser: val => {
    try {
      // for when the input gets clears
      if (typeof val === 'string' && !val.length) {
        val = '0.0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, '');
      const decimal = new Intl.NumberFormat('pt-BR').format(1.1).replace(/1/g, '');
      let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
  },
};

/**
 * date
 * @param {*} date
 * @returns
 */
const date = date => {
  return new Date(date).toLocaleDateString('pt-BR');
};

/**
 * Thousands
 */
const thousands = number => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

/**
 * decimal
 */
const decimal = {
  /**
   * formatter
   * @param {*} value
   * @returns
   */
  formatter: value => {
    const valueFloat = parseFloat(value).toFixed(2);
    return thousands(`${!isNaN(valueFloat) ? valueFloat.replace('.', ',') : '0,00'}`);
  },

  /**
   * parser
   * @param {*} value
   * @returns
   */
  parser: value => {
    const valueFiltered = value.replace(/\s?|(\.*)/g, '').replace(',', '.');
    return parseFloat(valueFiltered).toFixed(2);
  },
};

/**
 * dueDateLabel
 * @param {*} date
 * @param {*} early
 * @param {*} late
 * @returns
 */
const dueDateLabel = (date, early = 'Vence', late = 'Atrasado') => {
  const diff = moment().diff(moment(date).add(1, 'day'));

  if (diff > 0) {
    return late;
  }

  return early;
};

/**
 * filterInt
 * @param {*} value
 * @returns
 */
const filterInt = value => {
  // eslint-disable-next-line no-useless-escape
  if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return Number(value);
  return NaN;
};

/**
 * number
 * @param {*} value
 * @param {*} decimals
 * @returns
 */
const number = (value, decimals = 2) => {
  let float = parseFloat(value);
  float = !isNaN(float) ? float : 0;
  return float.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
};

const getMonthName = monthNumber => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  const month = date.toLocaleString('pt-BR', { month: 'long' });
  return month.charAt(0).toUpperCase() + month.slice(1);
};

/**
 * pagination
 */
const pagination = {
  total: total => total > 0 && `${number(total, 0)} ${total > 1 ? ` itens` : ` item`}`,
};

/**
 * percentage
 */
const percentage = {
  /**
   * formatter
   * @param {*} value
   * @returns
   */
  formatter: value => {
    const valueFloat = parseFloat(value).toFixed(2);
    return `${!isNaN(valueFloat) ? valueFloat.replace('.', ',') : '0,00'}%`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },

  /**
   * parser
   * @param {*} value
   * @returns
   */
  parser: value => {
    const valueFiltered = value.replace(/\s?|(\.*)%/g, '').replace(',', '.');
    return parseFloat(valueFiltered).toFixed(2);
  },
};

/**
 * unique
 * @param {*} arr
 * @returns
 */
const unique = (arr = []) => {
  return arr.filter((v, i, a) => a.indexOf(v) === i);
};

const autoCompleteEmail = email =>
  email ? (email.split('@').length - 1 === 0 ? `${email}@secran.com.br` : email) : null;

const flat = (errors, acc = []) =>
  Object.values(errors)
    .flat(Infinity)
    .reduce((_acc, _el) => (typeof _el === 'object' ? flat(_el, acc) : acc.concat(_el)), acc);

/**
 * format
 * @param {*} string
 * @param {*} args
 * @returns
 */
const format = (string, args) =>
  string.replace(/{(\d+)}/g, (match, number) => (args[number] !== 'undefined' ? args[number] : match));

const text2Html = string =>
  string && <div dangerouslySetInnerHTML={{ __html: `${string}`.replace(/\n/g, '<br />') }} />;

export default {
  input,
  output,
  birthdate,
  bool,
  capitalize,
  checkNumber,
  currency,
  date,
  thousands,
  decimal,
  dueDateLabel,
  filterInt,
  number,
  getMonthName,
  pagination,
  percentage,
  unique,
  autoCompleteEmail,
  flat,
  format,
  text2Html,
};
