import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  ECONOMIC_GROUPS_PAGINATION_UPDATE_SUCCESS,
  ECONOMIC_GROUPS_FILTERS_UPDATE_SUCCESS,
  ECONOMIC_GROUPS_SORTER_UPDATE_SUCCESS,

  ECONOMIC_GROUPS_UPDATE_BEGIN,
  ECONOMIC_GROUPS_UPDATE_SUCCESS,
  ECONOMIC_GROUPS_UPDATE_ERR,

  ECONOMIC_GROUPS_CLEANUP_BEGIN,
  ECONOMIC_GROUPS_CLEANUP_SUCCESS,
  ECONOMIC_GROUPS_CLEANUP_ERR,

  ECONOMIC_GROUP_DELETE_BEGIN,
  ECONOMIC_GROUP_DELETE_SUCCESS,
  ECONOMIC_GROUP_DELETE_ERR,
} = actions;

const economicGroupsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ECONOMIC_GROUPS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case ECONOMIC_GROUPS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case ECONOMIC_GROUPS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case ECONOMIC_GROUPS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ECONOMIC_GROUPS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ECONOMIC_GROUPS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ECONOMIC_GROUPS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ECONOMIC_GROUPS_CLEANUP_SUCCESS:
      return initialIndexState;

    case ECONOMIC_GROUPS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ECONOMIC_GROUP_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case ECONOMIC_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case ECONOMIC_GROUP_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ECONOMIC_GROUPS_LIST_UPDATE_BEGIN,
  ECONOMIC_GROUPS_LIST_UPDATE_SUCCESS,
  ECONOMIC_GROUPS_LIST_UPDATE_ERR,

  ECONOMIC_GROUPS_LIST_CLEANUP_BEGIN,
  ECONOMIC_GROUPS_LIST_CLEANUP_SUCCESS,
  ECONOMIC_GROUPS_LIST_CLEANUP_ERR,
} = actions;

const economicGroupsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ECONOMIC_GROUPS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ECONOMIC_GROUPS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ECONOMIC_GROUPS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ECONOMIC_GROUPS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ECONOMIC_GROUPS_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case ECONOMIC_GROUPS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  ECONOMIC_GROUP_UPDATE_BEGIN,
  ECONOMIC_GROUP_UPDATE_SUCCESS,
  ECONOMIC_GROUP_UPDATE_ERR,

  ECONOMIC_GROUP_CLEANUP_BEGIN,
  ECONOMIC_GROUP_CLEANUP_SUCCESS,
  ECONOMIC_GROUP_CLEANUP_ERR,

  ECONOMIC_GROUP_SAVE_BEGIN,
  ECONOMIC_GROUP_SAVE_SUCCESS,
  ECONOMIC_GROUP_SAVE_ERR,
} = actions;

const economicGroupReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ECONOMIC_GROUP_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ECONOMIC_GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ECONOMIC_GROUP_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ECONOMIC_GROUP_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ECONOMIC_GROUP_CLEANUP_SUCCESS:
      return initialSingleState;

    case ECONOMIC_GROUP_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ECONOMIC_GROUP_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case ECONOMIC_GROUP_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case ECONOMIC_GROUP_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { economicGroupsReducer, economicGroupsListReducer, economicGroupReducer };
