import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const CardsIndex = lazy(() => import('../../container/cards/Index'));
const CardsTicketsIndex = lazy(() => import('../../container/cards/TicketsIndex'));
const CardsClientsIndex = lazy(() => import('../../container/cards/ClientsIndex'));
const CardsReport = lazy(() => import('../../container/cards/Report'));
const EmployeesReport = lazy(() => import('../../container/cards/EmployeesReport'));
const QuestionsReport = lazy(() => import('../../container/cards/QuestionsReport'));
const CardsTransfer = lazy(() => import('../../container/cards/Transfer'));
const CardsFilesIndex = lazy(() => import('../../container/cards/FilesIndex'));
const CardsSummary = lazy(() => import('../../container/cards/Summary'));
const CardsForm = lazy(() => import('../../container/cards/Form'));
const CardsSupportForm = lazy(() => import('../../container/cards/SupportForm'));
const CardsView = lazy(() => import('../../container/cards/View'));
const CardsInteract = lazy(() => import('../../container/cards/Interact'));
const NotFound = lazy(() => import('../../container/pages/404'));

const CardsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}/clients`} component={CardsClientsIndex} />
        <Route path={`${path}/tickets`} component={CardsTicketsIndex} />
        <Route path={`${path}/report`} component={CardsReport} />
        <Route path={`${path}/employeesReport`} component={EmployeesReport} />
        <Route path={`${path}/questionsReport`} component={QuestionsReport} />
        <Route path={`${path}/transfer`} component={CardsTransfer} />
        <Route path={`${path}/files`} component={CardsFilesIndex} />
        <Route
          path={`${path}/summary/:view(employees|employers|card_templates)?/:department_id?/:foreign_key?/:profile_id?`}
          component={CardsSummary}
        />
        <Route path={`${path}/add/:card_type_id?`} component={CardsForm} />
        <Route path={`${path}/support/add/:department_id?`} component={CardsSupportForm} />
        <Route path={`${path}/edit/:id`} component={CardsForm} />
        <Route path={`${path}/view/:id`} component={CardsView} />
        <Route path={`${path}/interact/:id`} component={CardsInteract} />
        <Route path={[path, `${path}/modal/interact/:id`]} component={CardsIndex} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default CardsRoute;
