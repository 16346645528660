import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
  USER_ACHIEVEMENTS_UPDATE_BEGIN,
  USER_ACHIEVEMENTS_UPDATE_SUCCESS,
  USER_ACHIEVEMENTS_UPDATE_ERR,

  USER_ACHIEVEMENTS_CLEANUP_BEGIN,
  USER_ACHIEVEMENTS_CLEANUP_SUCCESS,
  USER_ACHIEVEMENTS_CLEANUP_ERR,
} = actions;

const userAchievementsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_ACHIEVEMENTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_ACHIEVEMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USER_ACHIEVEMENTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_ACHIEVEMENTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_ACHIEVEMENTS_CLEANUP_SUCCESS:
      return initialState;

    case USER_ACHIEVEMENTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  USER_ACHIEVEMENTS_LIST_UPDATE_BEGIN,
  USER_ACHIEVEMENTS_LIST_UPDATE_SUCCESS,
  USER_ACHIEVEMENTS_LIST_UPDATE_ERR,

  USER_ACHIEVEMENTS_LIST_CLEANUP_BEGIN,
  USER_ACHIEVEMENTS_LIST_CLEANUP_SUCCESS,
  USER_ACHIEVEMENTS_LIST_CLEANUP_ERR,
} = actions;

const userAchievementsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_ACHIEVEMENTS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_ACHIEVEMENTS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case USER_ACHIEVEMENTS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_ACHIEVEMENTS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_ACHIEVEMENTS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case USER_ACHIEVEMENTS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: null,
  loading: false,
  saving: false,
  error: null,
};

const {
  USER_ACHIEVEMENT_UPDATE_BEGIN,
  USER_ACHIEVEMENT_UPDATE_SUCCESS,
  USER_ACHIEVEMENT_UPDATE_ERR,

  USER_ACHIEVEMENT_CLEANUP_BEGIN,
  USER_ACHIEVEMENT_CLEANUP_SUCCESS,
  USER_ACHIEVEMENT_CLEANUP_ERR,

  USER_ACHIEVEMENT_SAVE_BEGIN,
  USER_ACHIEVEMENT_SAVE_SUCCESS,
  USER_ACHIEVEMENT_SAVE_ERR,
} = actions;

const userAchievementReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_ACHIEVEMENT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_ACHIEVEMENT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case USER_ACHIEVEMENT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_ACHIEVEMENT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_ACHIEVEMENT_CLEANUP_SUCCESS:
      return initialSingleState;

    case USER_ACHIEVEMENT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_ACHIEVEMENT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case USER_ACHIEVEMENT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case USER_ACHIEVEMENT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { userAchievementsReducer, userAchievementsListReducer, userAchievementReducer };
