const actions = {
  CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_BEGIN: 'CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_BEGIN',
  CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_SUCCESS: 'CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_SUCCESS',
  CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_ERR: 'CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_ERR',

  clientCnpjEmployerUnionsListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_BEGIN,
    };
  },

  clientCnpjEmployerUnionsListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjEmployerUnionsListUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_BEGIN: 'CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_BEGIN',
  CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_SUCCESS: 'CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_SUCCESS',
  CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_ERR: 'CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_ERR',

  clientCnpjEmployerUnionsListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_BEGIN,
    };
  },

  clientCnpjEmployerUnionsListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientCnpjEmployerUnionsListCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYER_UNIONS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  