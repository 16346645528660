const actions = {
  CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_ERR: 'CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_ERR',

  clientNegativeCertificateSignersListUpdateBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_BEGIN,
    };
  },

  clientNegativeCertificateSignersListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientNegativeCertificateSignersListUpdateErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_BEGIN: 'CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_BEGIN',
  CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_SUCCESS: 'CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_SUCCESS',
  CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_ERR: 'CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_ERR',

  clientNegativeCertificateSignersListCleanupBegin: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_BEGIN,
    };
  },

  clientNegativeCertificateSignersListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientNegativeCertificateSignersListCleanupErr: err => {
    return {
      type: actions.CLIENT_NEGATIVE_CERTIFICATE_SIGNERS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  