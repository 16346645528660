import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  EMAIL_SENTS_UPDATE_BEGIN,
  EMAIL_SENTS_UPDATE_SUCCESS,
  EMAIL_SENTS_UPDATE_ERR,

  EMAIL_SENTS_CLEANUP_BEGIN,
  EMAIL_SENTS_CLEANUP_SUCCESS,
  EMAIL_SENTS_CLEANUP_ERR,

  EMAIL_SENT_DELETE_BEGIN,
  EMAIL_SENT_DELETE_SUCCESS,
  EMAIL_SENT_DELETE_ERR,
} = actions;

const emailSentsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case EMAIL_SENTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case EMAIL_SENTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case EMAIL_SENTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case EMAIL_SENTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case EMAIL_SENTS_CLEANUP_SUCCESS:
      return initialState;

    case EMAIL_SENTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case EMAIL_SENT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case EMAIL_SENT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case EMAIL_SENT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

export { emailSentsReducer };
