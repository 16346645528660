const actions = {
  CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_BEGIN: 'CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_BEGIN',
  CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_ERR: 'CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_ERR',

  cardJustificationReasonsPaginationUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_BEGIN,
    };
  },

  cardJustificationReasonsPaginationUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonsPaginationUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_BEGIN: 'CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_BEGIN',
  CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_ERR: 'CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_ERR',

  cardJustificationReasonsFiltersUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_BEGIN,
    };
  },

  cardJustificationReasonsFiltersUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonsFiltersUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_BEGIN: 'CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_BEGIN',
  CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_ERR: 'CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_ERR',

  cardJustificationReasonsSorterUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_BEGIN,
    };
  },

  cardJustificationReasonsSorterUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonsSorterUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASONS_UPDATE_BEGIN: 'CARD_JUSTIFICATION_REASONS_UPDATE_BEGIN',
  CARD_JUSTIFICATION_REASONS_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_REASONS_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_REASONS_UPDATE_ERR: 'CARD_JUSTIFICATION_REASONS_UPDATE_ERR',

  cardJustificationReasonsUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_UPDATE_BEGIN,
    };
  },

  cardJustificationReasonsUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonsUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASONS_CLEANUP_BEGIN: 'CARD_JUSTIFICATION_REASONS_CLEANUP_BEGIN',
  CARD_JUSTIFICATION_REASONS_CLEANUP_SUCCESS: 'CARD_JUSTIFICATION_REASONS_CLEANUP_SUCCESS',
  CARD_JUSTIFICATION_REASONS_CLEANUP_ERR: 'CARD_JUSTIFICATION_REASONS_CLEANUP_ERR',

  cardJustificationReasonsCleanupBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_CLEANUP_BEGIN,
    };
  },

  cardJustificationReasonsCleanupSuccess: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_CLEANUP_SUCCESS,
    };
  },

  cardJustificationReasonsCleanupErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_CLEANUP_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASON_DELETE_BEGIN: 'CARD_JUSTIFICATION_REASON_DELETE_BEGIN',
  CARD_JUSTIFICATION_REASON_DELETE_SUCCESS: 'CARD_JUSTIFICATION_REASON_DELETE_SUCCESS',
  CARD_JUSTIFICATION_REASON_DELETE_ERR: 'CARD_JUSTIFICATION_REASON_DELETE_ERR',

  cardJustificationReasonDeleteBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_DELETE_BEGIN,
    };
  },

  cardJustificationReasonDeleteSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_DELETE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonDeleteErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_DELETE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASON_UPDATE_BEGIN: 'CARD_JUSTIFICATION_REASON_UPDATE_BEGIN',
  CARD_JUSTIFICATION_REASON_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_REASON_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_REASON_UPDATE_ERR: 'CARD_JUSTIFICATION_REASON_UPDATE_ERR',

  cardJustificationReasonUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_UPDATE_BEGIN,
    };
  },

  cardJustificationReasonUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASON_CLEANUP_BEGIN: 'CARD_JUSTIFICATION_REASON_CLEANUP_BEGIN',
  CARD_JUSTIFICATION_REASON_CLEANUP_SUCCESS: 'CARD_JUSTIFICATION_REASON_CLEANUP_SUCCESS',
  CARD_JUSTIFICATION_REASON_CLEANUP_ERR: 'CARD_JUSTIFICATION_REASON_CLEANUP_ERR',

  cardJustificationReasonCleanupBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_CLEANUP_BEGIN,
    };
  },

  cardJustificationReasonCleanupSuccess: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_CLEANUP_SUCCESS,
    };
  },

  cardJustificationReasonCleanupErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_CLEANUP_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASON_SAVE_BEGIN: 'CARD_JUSTIFICATION_REASON_SAVE_BEGIN',
  CARD_JUSTIFICATION_REASON_SAVE_SUCCESS: 'CARD_JUSTIFICATION_REASON_SAVE_SUCCESS',
  CARD_JUSTIFICATION_REASON_SAVE_ERR: 'CARD_JUSTIFICATION_REASON_SAVE_ERR',

  cardJustificationReasonSaveBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_SAVE_BEGIN,
    };
  },

  cardJustificationReasonSaveSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_SAVE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonSaveErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASON_SAVE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASONS_LIST_UPDATE_BEGIN: 'CARD_JUSTIFICATION_REASONS_LIST_UPDATE_BEGIN',
  CARD_JUSTIFICATION_REASONS_LIST_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_REASONS_LIST_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_REASONS_LIST_UPDATE_ERR: 'CARD_JUSTIFICATION_REASONS_LIST_UPDATE_ERR',

  cardJustificationReasonsListUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_LIST_UPDATE_BEGIN,
    };
  },

  cardJustificationReasonsListUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationReasonsListUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_LIST_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_BEGIN: 'CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_BEGIN',
  CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_SUCCESS: 'CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_SUCCESS',
  CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_ERR: 'CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_ERR',

  cardJustificationReasonsListCleanupBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_BEGIN,
    };
  },

  cardJustificationReasonsListCleanupSuccess: () => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_SUCCESS,
    };
  },

  cardJustificationReasonsListCleanupErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
