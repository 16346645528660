const actions = {
  CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_BEGIN: 'CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_BEGIN',
  CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_SUCCESS: 'CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_SUCCESS',
  CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_ERR: 'CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_ERR',

  clientAttorneyGrantedsListUpdateBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_BEGIN,
    };
  },

  clientAttorneyGrantedsListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientAttorneyGrantedsListUpdateErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_GRANTEDS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_BEGIN: 'CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_BEGIN',
  CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_SUCCESS: 'CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_SUCCESS',
  CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_ERR: 'CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_ERR',

  clientAttorneyGrantedsListCleanupBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_BEGIN,
    };
  },

  clientAttorneyGrantedsListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientAttorneyGrantedsListCleanupErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_GRANTEDS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  