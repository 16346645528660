import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  COMMENTS_UPDATE_BEGIN,
  COMMENTS_UPDATE_SUCCESS,
  COMMENTS_UPDATE_ERR,

  COMMENTS_CLEANUP_BEGIN,
  COMMENTS_CLEANUP_SUCCESS,
  COMMENTS_CLEANUP_ERR,

  COMMENT_DELETE_BEGIN,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_ERR,
} = actions;

const commentsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COMMENTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COMMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case COMMENTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case COMMENTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COMMENTS_CLEANUP_SUCCESS:
      return initialState;

    case COMMENTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case COMMENT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case COMMENT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const { COMMENT_SAVE_BEGIN, COMMENT_SAVE_SUCCESS, COMMENT_SAVE_ERR } = actions;

const commentReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COMMENT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case COMMENT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case COMMENT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { commentsReducer, commentReducer };
