const actions = {
  ECONOMIC_GROUPS_PAGINATION_UPDATE_BEGIN: 'ECONOMIC_GROUPS_PAGINATION_UPDATE_BEGIN',
  ECONOMIC_GROUPS_PAGINATION_UPDATE_SUCCESS: 'ECONOMIC_GROUPS_PAGINATION_UPDATE_SUCCESS',
  ECONOMIC_GROUPS_PAGINATION_UPDATE_ERR: 'ECONOMIC_GROUPS_PAGINATION_UPDATE_ERR',

  economicGroupsPaginationUpdateBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_PAGINATION_UPDATE_BEGIN,
    };
  },

  economicGroupsPaginationUpdateSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUPS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  economicGroupsPaginationUpdateErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  ECONOMIC_GROUPS_FILTERS_UPDATE_BEGIN: 'ECONOMIC_GROUPS_FILTERS_UPDATE_BEGIN',
  ECONOMIC_GROUPS_FILTERS_UPDATE_SUCCESS: 'ECONOMIC_GROUPS_FILTERS_UPDATE_SUCCESS',
  ECONOMIC_GROUPS_FILTERS_UPDATE_ERR: 'ECONOMIC_GROUPS_FILTERS_UPDATE_ERR',

  economicGroupsFiltersUpdateBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_FILTERS_UPDATE_BEGIN,
    };
  },

  economicGroupsFiltersUpdateSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUPS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  economicGroupsFiltersUpdateErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  ECONOMIC_GROUPS_SORTER_UPDATE_BEGIN: 'ECONOMIC_GROUPS_SORTER_UPDATE_BEGIN',
  ECONOMIC_GROUPS_SORTER_UPDATE_SUCCESS: 'ECONOMIC_GROUPS_SORTER_UPDATE_SUCCESS',
  ECONOMIC_GROUPS_SORTER_UPDATE_ERR: 'ECONOMIC_GROUPS_SORTER_UPDATE_ERR',

  economicGroupsSorterUpdateBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_SORTER_UPDATE_BEGIN,
    };
  },

  economicGroupsSorterUpdateSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUPS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  economicGroupsSorterUpdateErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_SORTER_UPDATE_ERR,
      err,
    };
  },

  ECONOMIC_GROUPS_UPDATE_BEGIN: 'ECONOMIC_GROUPS_UPDATE_BEGIN',
  ECONOMIC_GROUPS_UPDATE_SUCCESS: 'ECONOMIC_GROUPS_UPDATE_SUCCESS',
  ECONOMIC_GROUPS_UPDATE_ERR: 'ECONOMIC_GROUPS_UPDATE_ERR',

  economicGroupsUpdateBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_UPDATE_BEGIN,
    };
  },

  economicGroupsUpdateSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUPS_UPDATE_SUCCESS,
      data,
    };
  },

  economicGroupsUpdateErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_UPDATE_ERR,
      err,
    };
  },

  ECONOMIC_GROUPS_CLEANUP_BEGIN: 'ECONOMIC_GROUPS_CLEANUP_BEGIN',
  ECONOMIC_GROUPS_CLEANUP_SUCCESS: 'ECONOMIC_GROUPS_CLEANUP_SUCCESS',
  ECONOMIC_GROUPS_CLEANUP_ERR: 'ECONOMIC_GROUPS_CLEANUP_ERR',

  economicGroupsCleanupBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_CLEANUP_BEGIN,
    };
  },

  economicGroupsCleanupSuccess: () => {
    return {
      type: actions.ECONOMIC_GROUPS_CLEANUP_SUCCESS,
    };
  },

  economicGroupsCleanupErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_CLEANUP_ERR,
      err,
    };
  },

  ECONOMIC_GROUP_DELETE_BEGIN: 'ECONOMIC_GROUP_DELETE_BEGIN',
  ECONOMIC_GROUP_DELETE_SUCCESS: 'ECONOMIC_GROUP_DELETE_SUCCESS',
  ECONOMIC_GROUP_DELETE_ERR: 'ECONOMIC_GROUP_DELETE_ERR',

  economicGroupDeleteBegin: () => {
    return {
      type: actions.ECONOMIC_GROUP_DELETE_BEGIN,
    };
  },

  economicGroupDeleteSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUP_DELETE_SUCCESS,
      data,
    };
  },

  economicGroupDeleteErr: err => {
    return {
      type: actions.ECONOMIC_GROUP_DELETE_ERR,
      err,
    };
  },

  ECONOMIC_GROUP_UPDATE_BEGIN: 'ECONOMIC_GROUP_UPDATE_BEGIN',
  ECONOMIC_GROUP_UPDATE_SUCCESS: 'ECONOMIC_GROUP_UPDATE_SUCCESS',
  ECONOMIC_GROUP_UPDATE_ERR: 'ECONOMIC_GROUP_UPDATE_ERR',

  economicGroupUpdateBegin: () => {
    return {
      type: actions.ECONOMIC_GROUP_UPDATE_BEGIN,
    };
  },

  economicGroupUpdateSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUP_UPDATE_SUCCESS,
      data,
    };
  },

  economicGroupUpdateErr: err => {
    return {
      type: actions.ECONOMIC_GROUP_UPDATE_ERR,
      err,
    };
  },

  ECONOMIC_GROUP_CLEANUP_BEGIN: 'ECONOMIC_GROUP_CLEANUP_BEGIN',
  ECONOMIC_GROUP_CLEANUP_SUCCESS: 'ECONOMIC_GROUP_CLEANUP_SUCCESS',
  ECONOMIC_GROUP_CLEANUP_ERR: 'ECONOMIC_GROUP_CLEANUP_ERR',

  economicGroupCleanupBegin: () => {
    return {
      type: actions.ECONOMIC_GROUP_CLEANUP_BEGIN,
    };
  },

  economicGroupCleanupSuccess: () => {
    return {
      type: actions.ECONOMIC_GROUP_CLEANUP_SUCCESS,
    };
  },

  economicGroupCleanupErr: err => {
    return {
      type: actions.ECONOMIC_GROUP_CLEANUP_ERR,
      err,
    };
  },

  ECONOMIC_GROUP_SAVE_BEGIN: 'ECONOMIC_GROUP_SAVE_BEGIN',
  ECONOMIC_GROUP_SAVE_SUCCESS: 'ECONOMIC_GROUP_SAVE_SUCCESS',
  ECONOMIC_GROUP_SAVE_ERR: 'ECONOMIC_GROUP_SAVE_ERR',

  economicGroupSaveBegin: () => {
    return {
      type: actions.ECONOMIC_GROUP_SAVE_BEGIN,
    };
  },

  economicGroupSaveSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUP_SAVE_SUCCESS,
      data,
    };
  },

  economicGroupSaveErr: err => {
    return {
      type: actions.ECONOMIC_GROUP_SAVE_ERR,
      err,
    };
  },

  ECONOMIC_GROUPS_LIST_UPDATE_BEGIN: 'ECONOMIC_GROUPS_LIST_UPDATE_BEGIN',
  ECONOMIC_GROUPS_LIST_UPDATE_SUCCESS: 'ECONOMIC_GROUPS_LIST_UPDATE_SUCCESS',
  ECONOMIC_GROUPS_LIST_UPDATE_ERR: 'ECONOMIC_GROUPS_LIST_UPDATE_ERR',

  economicGroupsListUpdateBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_LIST_UPDATE_BEGIN,
    };
  },

  economicGroupsListUpdateSuccess: data => {
    return {
      type: actions.ECONOMIC_GROUPS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  economicGroupsListUpdateErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_LIST_UPDATE_ERR,
      err,
    };
  },

  ECONOMIC_GROUPS_LIST_CLEANUP_BEGIN: 'ECONOMIC_GROUPS_LIST_CLEANUP_BEGIN',
  ECONOMIC_GROUPS_LIST_CLEANUP_SUCCESS: 'ECONOMIC_GROUPS_LIST_CLEANUP_SUCCESS',
  ECONOMIC_GROUPS_LIST_CLEANUP_ERR: 'ECONOMIC_GROUPS_LIST_CLEANUP_ERR',

  economicGroupsListCleanupBegin: () => {
    return {
      type: actions.ECONOMIC_GROUPS_LIST_CLEANUP_BEGIN,
    };
  },

  economicGroupsListCleanupSuccess: () => {
    return {
      type: actions.ECONOMIC_GROUPS_LIST_CLEANUP_SUCCESS,
    };
  },

  economicGroupsListCleanupErr: err => {
    return {
      type: actions.ECONOMIC_GROUPS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
