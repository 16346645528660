const actions = {
  CLIENT_PARTNER_ACTS_UPDATE_BEGIN: 'CLIENT_PARTNER_ACTS_UPDATE_BEGIN',
  CLIENT_PARTNER_ACTS_UPDATE_SUCCESS: 'CLIENT_PARTNER_ACTS_UPDATE_SUCCESS',
  CLIENT_PARTNER_ACTS_UPDATE_ERR: 'CLIENT_PARTNER_ACTS_UPDATE_ERR',

  clientPartnerActsUpdateBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACTS_UPDATE_BEGIN,
    };
  },

  clientPartnerActsUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_ACTS_UPDATE_SUCCESS,
      data,
    };
  },

  clientPartnerActsUpdateErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACTS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_ACTS_CLEANUP_BEGIN: 'CLIENT_PARTNER_ACTS_CLEANUP_BEGIN',
  CLIENT_PARTNER_ACTS_CLEANUP_SUCCESS: 'CLIENT_PARTNER_ACTS_CLEANUP_SUCCESS',
  CLIENT_PARTNER_ACTS_CLEANUP_ERR: 'CLIENT_PARTNER_ACTS_CLEANUP_ERR',

  clientPartnerActsCleanupBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACTS_CLEANUP_BEGIN,
    };
  },

  clientPartnerActsCleanupSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_ACTS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientPartnerActsCleanupErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACTS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_PARTNER_ACT_UPDATE_BEGIN: 'CLIENT_PARTNER_ACT_UPDATE_BEGIN',
  CLIENT_PARTNER_ACT_UPDATE_SUCCESS: 'CLIENT_PARTNER_ACT_UPDATE_SUCCESS',
  CLIENT_PARTNER_ACT_UPDATE_ERR: 'CLIENT_PARTNER_ACT_UPDATE_ERR',

  clientPartnerActUpdateBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_UPDATE_BEGIN,
    };
  },

  clientPartnerActUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_ACT_UPDATE_SUCCESS,
      data,
    };
  },

  clientPartnerActUpdateErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_ACT_CLEANUP_BEGIN: 'CLIENT_PARTNER_ACT_CLEANUP_BEGIN',
  CLIENT_PARTNER_ACT_CLEANUP_SUCCESS: 'CLIENT_PARTNER_ACT_CLEANUP_SUCCESS',
  CLIENT_PARTNER_ACT_CLEANUP_ERR: 'CLIENT_PARTNER_ACT_CLEANUP_ERR',

  clientPartnerActCleanupBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_CLEANUP_BEGIN,
    };
  },

  clientPartnerActCleanupSuccess: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_CLEANUP_SUCCESS,
    };
  },

  clientPartnerActCleanupErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACT_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_PARTNER_ACT_SAVE_BEGIN: 'CLIENT_PARTNER_ACT_SAVE_BEGIN',
  CLIENT_PARTNER_ACT_SAVE_SUCCESS: 'CLIENT_PARTNER_ACT_SAVE_SUCCESS',
  CLIENT_PARTNER_ACT_SAVE_ERR: 'CLIENT_PARTNER_ACT_SAVE_ERR',

  clientPartnerActSaveBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SAVE_BEGIN,
    };
  },

  clientPartnerActSaveSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SAVE_SUCCESS,
      data,
    };
  },

  clientPartnerActSaveErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SAVE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_ACT_DELETE_BEGIN: 'CLIENT_PARTNER_ACT_DELETE_BEGIN',
  CLIENT_PARTNER_ACT_DELETE_SUCCESS: 'CLIENT_PARTNER_ACT_DELETE_SUCCESS',
  CLIENT_PARTNER_ACT_DELETE_ERR: 'CLIENT_PARTNER_ACT_DELETE_ERR',

  clientPartnerActDeleteBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_DELETE_BEGIN,
    };
  },

  clientPartnerActDeleteSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_ACT_DELETE_SUCCESS,
      data,
    };
  },

  clientPartnerActDeleteErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
  