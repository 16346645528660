import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const ClientsIndex = lazy(() => import('../../container/clients/Index'));
const ClientsIndexEmployees = lazy(() => import('../../container/clients/IndexEmployees'));
const ClientsFiles = lazy(() => import('../../container/clients/Files'));
const ClientsReport = lazy(() => import('../../container/clients/Report'));
const ClientCardTemplatesReport = lazy(() => import('../../components/clientCardTemplates/Report'));
const ClientEmployeesReport = lazy(() => import('../../container/clientEmployees/Report'));
const ClientEmployeesMatchReport = lazy(() => import('../../container/clientEmployees/MatchReport'));
const ClientsTransfer = lazy(() => import('../../container/clients/Transfer'));
const ClientsForm = lazy(() => import('../../container/clients/Form'));
const ClientsView = lazy(() => import('../../container/clients/View'));
const NotFound = lazy(() => import('../../container/pages/404'));

const ClientsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={path} component={ClientsIndex} />
        <Route exact path={`${path}/employees`} component={ClientsIndexEmployees} />
        <Route path={`${path}/files`} component={ClientsFiles} />
        <Route path={`${path}/report`} component={ClientsReport} />
        <Route path={`${path}/cardTemplates/report`} component={ClientCardTemplatesReport} />
        <Route path={`${path}/employees/report`} component={ClientEmployeesReport} />
        <Route path={`${path}/employees/matchReport`} component={ClientEmployeesMatchReport} />
        <Route path={`${path}/transfer`} component={ClientsTransfer} />
        <Route path={`${path}/copy`} component={ClientsTransfer} />
        <Route path={`${path}/add`} component={ClientsForm} />
        <Route path={`${path}/edit/:id`} component={ClientsForm} />
        <Route path={`${path}/view/:id`} component={ClientsView} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default ClientsRoute;
