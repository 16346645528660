import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Alert, Descriptions, List, Progress, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../layout/popup/popup';
import Heading from '../../layout/heading/heading';
import format from '../../../config/format';
import UserEfficiency from '../../users/Efficiency';

const { Text } = Typography;

const UserEfficiencyButton = () => {
  const { identity } = useSelector(state => {
    return {
      identity: state.auth.identity.data,
    };
  });

  const renderFeedback = efficiency => {
    let message;
    let type;

    if (efficiency.efficiency_percentual >= 100) {
      message = 'Você está em dia com a sua meta.';
      type = 'success';
    } else {
      message = `Você está ${100 - efficiency.efficiency_percentual}% (${efficiency.estimated -
        efficiency.done}) abaixo da meta.`;

      if (efficiency.efficiency_percentual >= 85) {
        type = 'info';
      } else if (efficiency.efficiency_percentual >= 70) {
        type = 'warning';
      } else if (efficiency.efficiency_percentual >= 0) {
        type = 'error';
      }
    }

    return <Alert message={message} type={type} showIcon />;
  };

  const content = identity?.user?.efficiency?.total > 0 && (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Desempenho Atual</span>
      </Heading>
      <Space direction="vertical" size="middle" style={{ marginTop: 6, width: '100%' }}>
        {renderFeedback(identity.user.efficiency)}
        <Descriptions size="small" bordered>
          <Descriptions.Item label="Referência">{moment().format(format.input.date_my)}</Descriptions.Item>
        </Descriptions>
        <List className="dropdown-list" style={{ height: 320 }}>
          <List.Item>
            {/* Concluído */}
            <List.Item.Meta
              avatar={<FeatherIcon icon="check" size={28} style={{ color: '#20C997' }} />}
              title="Concluídos"
              description={<small>Cards concluídos até agora</small>}
            />
            <div>{identity.user.efficiency.done}</div>
          </List.Item>
          {/* Meta */}
          <List.Item>
            <List.Item.Meta
              avatar={<FeatherIcon icon="target" size={28} style={{ color: '#2C99FF' }} />}
              title="Meta"
              description={<small>Cards que devem estar concluídos até hoje</small>}
            />
            <div>{identity.user.efficiency.estimated}</div>
          </List.Item>
          {/* Eficácia */}
          <List.Item>
            <List.Item.Meta
              avatar={<FeatherIcon icon="crosshair" size={28} style={{ color: '#757575' }} />}
              title="Eficácia"
              description={
                <small>Relação dos Cards concluídos até agora com os que devem estar concluídos até hoje</small>
              }
            />
            <div>{identity.user.efficiency.efficiency_percentual}%</div>
          </List.Item>
          {/* Total */}
          <List.Item>
            <List.Item.Meta
              avatar={<FeatherIcon icon="package" size={28} style={{ color: '#9E9E9E' }} />}
              title="Total do Mês"
              description={
                <Space direction="vertical" size={0}>
                  <div className="user-efficiency" style={{ height: 'auto' }}>
                    <Progress
                      percent={identity.user.efficiency.estimated_percentual}
                      success={{ percent: identity.user.efficiency.done_percentual }}
                      showInfo={false}
                      style={{ width: 200 }}
                    />
                  </div>
                  <Text type="success">
                    <small>{identity.user.efficiency.done_percentual}% Concluído</small>
                  </Text>
                  <Text style={{ color: '#2C99FF' }}>
                    <small>{identity.user.efficiency.estimated_percentual}% Meta</small>
                  </Text>
                </Space>
              }
            />
            <div>{identity.user.efficiency.total}</div>
          </List.Item>
        </List>
      </Space>
    </AtbdTopDropdwon>
  );

  return (
    identity?.user?.efficiency?.total > 0 && (
      <Popover placement="bottomLeft" content={content} action="hover">
        <UserEfficiency user={identity?.user} />
      </Popover>
    )
  );
};

export default UserEfficiencyButton;
