const actions = {
  ASSETS_PAGINATION_UPDATE_BEGIN: 'ASSETS_PAGINATION_UPDATE_BEGIN',
  ASSETS_PAGINATION_UPDATE_SUCCESS: 'ASSETS_PAGINATION_UPDATE_SUCCESS',
  ASSETS_PAGINATION_UPDATE_ERR: 'ASSETS_PAGINATION_UPDATE_ERR',

  assetsPaginationUpdateBegin: () => {
    return {
      type: actions.ASSETS_PAGINATION_UPDATE_BEGIN,
    };
  },

  assetsPaginationUpdateSuccess: data => {
    return {
      type: actions.ASSETS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  assetsPaginationUpdateErr: err => {
    return {
      type: actions.ASSETS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  ASSETS_FILTERS_UPDATE_BEGIN: 'ASSETS_FILTERS_UPDATE_BEGIN',
  ASSETS_FILTERS_UPDATE_SUCCESS: 'ASSETS_FILTERS_UPDATE_SUCCESS',
  ASSETS_FILTERS_UPDATE_ERR: 'ASSETS_FILTERS_UPDATE_ERR',

  assetsFiltersUpdateBegin: () => {
    return {
      type: actions.ASSETS_FILTERS_UPDATE_BEGIN,
    };
  },

  assetsFiltersUpdateSuccess: data => {
    return {
      type: actions.ASSETS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  assetsFiltersUpdateErr: err => {
    return {
      type: actions.ASSETS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  ASSETS_SORTER_UPDATE_BEGIN: 'ASSETS_SORTER_UPDATE_BEGIN',
  ASSETS_SORTER_UPDATE_SUCCESS: 'ASSETS_SORTER_UPDATE_SUCCESS',
  ASSETS_SORTER_UPDATE_ERR: 'ASSETS_SORTER_UPDATE_ERR',

  assetsSorterUpdateBegin: () => {
    return {
      type: actions.ASSETS_SORTER_UPDATE_BEGIN,
    };
  },

  assetsSorterUpdateSuccess: data => {
    return {
      type: actions.ASSETS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  assetsSorterUpdateErr: err => {
    return {
      type: actions.ASSETS_SORTER_UPDATE_ERR,
      err,
    };
  },

  ASSETS_UPDATE_BEGIN: 'ASSETS_UPDATE_BEGIN',
  ASSETS_UPDATE_SUCCESS: 'ASSETS_UPDATE_SUCCESS',
  ASSETS_UPDATE_ERR: 'ASSETS_UPDATE_ERR',

  assetsUpdateBegin: () => {
    return {
      type: actions.ASSETS_UPDATE_BEGIN,
    };
  },

  assetsUpdateSuccess: data => {
    return {
      type: actions.ASSETS_UPDATE_SUCCESS,
      data,
    };
  },

  assetsUpdateErr: err => {
    return {
      type: actions.ASSETS_UPDATE_ERR,
      err,
    };
  },

  ASSETS_CLEANUP_BEGIN: 'ASSETS_CLEANUP_BEGIN',
  ASSETS_CLEANUP_SUCCESS: 'ASSETS_CLEANUP_SUCCESS',
  ASSETS_CLEANUP_ERR: 'ASSETS_CLEANUP_ERR',

  assetsCleanupBegin: () => {
    return {
      type: actions.ASSETS_CLEANUP_BEGIN,
    };
  },

  assetsCleanupSuccess: data => {
    return {
      type: actions.ASSETS_CLEANUP_SUCCESS,
      data,
    };
  },

  assetsCleanupErr: err => {
    return {
      type: actions.ASSETS_CLEANUP_ERR,
      err,
    };
  },

  ASSETS_LIST_UPDATE_BEGIN: 'ASSETS_LIST_UPDATE_BEGIN',
  ASSETS_LIST_UPDATE_SUCCESS: 'ASSETS_LIST_UPDATE_SUCCESS',
  ASSETS_LIST_UPDATE_ERR: 'ASSETS_LIST_UPDATE_ERR',

  assetsListUpdateBegin: () => {
    return {
      type: actions.ASSETS_LIST_UPDATE_BEGIN,
    };
  },

  assetsListUpdateSuccess: data => {
    return {
      type: actions.ASSETS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  assetsListUpdateErr: err => {
    return {
      type: actions.ASSETS_LIST_UPDATE_ERR,
      err,
    };
  },

  ASSETS_LIST_CLEANUP_BEGIN: 'ASSETS_LIST_CLEANUP_BEGIN',
  ASSETS_LIST_CLEANUP_SUCCESS: 'ASSETS_LIST_CLEANUP_SUCCESS',
  ASSETS_LIST_CLEANUP_ERR: 'ASSETS_LIST_CLEANUP_ERR',

  assetsListCleanupBegin: () => {
    return {
      type: actions.ASSETS_LIST_CLEANUP_BEGIN,
    };
  },

  assetsListCleanupSuccess: () => {
    return {
      type: actions.ASSETS_LIST_CLEANUP_SUCCESS,
    };
  },

  assetsListCleanupErr: err => {
    return {
      type: actions.ASSETS_LIST_CLEANUP_ERR,
      err,
    };
  },

  ASSETS_TREE_UPDATE_BEGIN: 'ASSETS_TREE_UPDATE_BEGIN',
  ASSETS_TREE_UPDATE_SUCCESS: 'ASSETS_TREE_UPDATE_SUCCESS',
  ASSETS_TREE_UPDATE_ERR: 'ASSETS_TREE_UPDATE_ERR',

  assetsTreeUpdateBegin: () => {
    return {
      type: actions.ASSETS_TREE_UPDATE_BEGIN,
    };
  },

  assetsTreeUpdateSuccess: data => {
    return {
      type: actions.ASSETS_TREE_UPDATE_SUCCESS,
      data,
    };
  },

  assetsTreeUpdateErr: err => {
    return {
      type: actions.ASSETS_TREE_UPDATE_ERR,
      err,
    };
  },

  ASSETS_TREE_CLEANUP_BEGIN: 'ASSETS_TREE_CLEANUP_BEGIN',
  ASSETS_TREE_CLEANUP_SUCCESS: 'ASSETS_TREE_CLEANUP_SUCCESS',
  ASSETS_TREE_CLEANUP_ERR: 'ASSETS_TREE_CLEANUP_ERR',

  assetsTreeCleanupBegin: () => {
    return {
      type: actions.ASSETS_TREE_CLEANUP_BEGIN,
    };
  },

  assetsTreeCleanupSuccess: data => {
    return {
      type: actions.ASSETS_TREE_CLEANUP_SUCCESS,
      data,
    };
  },

  assetsTreeCleanupErr: err => {
    return {
      type: actions.ASSETS_TREE_CLEANUP_ERR,
      err,
    };
  },

  ASSET_UPDATE_BEGIN: 'ASSET_UPDATE_BEGIN',
  ASSET_UPDATE_SUCCESS: 'ASSET_UPDATE_SUCCESS',
  ASSET_UPDATE_ERR: 'ASSET_UPDATE_ERR',

  assetUpdateBegin: () => {
    return {
      type: actions.ASSET_UPDATE_BEGIN,
    };
  },

  assetUpdateSuccess: data => {
    return {
      type: actions.ASSET_UPDATE_SUCCESS,
      data,
    };
  },

  assetUpdateErr: err => {
    return {
      type: actions.ASSET_UPDATE_ERR,
      err,
    };
  },

  ASSET_CLEANUP_BEGIN: 'ASSET_CLEANUP_BEGIN',
  ASSET_CLEANUP_SUCCESS: 'ASSET_CLEANUP_SUCCESS',
  ASSET_CLEANUP_ERR: 'ASSET_CLEANUP_ERR',

  assetCleanupBegin: () => {
    return {
      type: actions.ASSET_CLEANUP_BEGIN,
    };
  },

  assetCleanupSuccess: () => {
    return {
      type: actions.ASSET_CLEANUP_SUCCESS,
    };
  },

  assetCleanupErr: err => {
    return {
      type: actions.ASSET_CLEANUP_ERR,
      err,
    };
  },

  ASSET_SAVE_BEGIN: 'ASSET_SAVE_BEGIN',
  ASSET_SAVE_SUCCESS: 'ASSET_SAVE_SUCCESS',
  ASSET_SAVE_ERR: 'ASSET_SAVE_ERR',

  assetSaveBegin: () => {
    return {
      type: actions.ASSET_SAVE_BEGIN,
    };
  },

  assetSaveSuccess: data => {
    return {
      type: actions.ASSET_SAVE_SUCCESS,
      data,
    };
  },

  assetSaveErr: err => {
    return {
      type: actions.ASSET_SAVE_ERR,
      err,
    };
  },

  ASSET_DELETE_BEGIN: 'ASSET_DELETE_BEGIN',
  ASSET_DELETE_SUCCESS: 'ASSET_DELETE_SUCCESS',
  ASSET_DELETE_ERR: 'ASSET_DELETE_ERR',

  assetDeleteBegin: () => {
    return {
      type: actions.ASSET_DELETE_BEGIN,
    };
  },

  assetDeleteSuccess: data => {
    return {
      type: actions.ASSET_DELETE_SUCCESS,
      data,
    };
  },

  assetDeleteErr: err => {
    return {
      type: actions.ASSET_DELETE_ERR,
      err,
    };
  },
};

export default actions;
