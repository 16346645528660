import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  DEPARTMENTS_LIST_UPDATE_BEGIN,
  DEPARTMENTS_LIST_UPDATE_SUCCESS,
  DEPARTMENTS_LIST_UPDATE_ERR,

  DEPARTMENTS_LIST_CLEANUP_BEGIN,
  DEPARTMENTS_LIST_CLEANUP_SUCCESS,
  DEPARTMENTS_LIST_CLEANUP_ERR,
} = actions;

const departmentsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DEPARTMENTS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENTS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case DEPARTMENTS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DEPARTMENTS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENTS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case DEPARTMENTS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialTreeState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  DEPARTMENTS_TREE_UPDATE_BEGIN,
  DEPARTMENTS_TREE_UPDATE_SUCCESS,
  DEPARTMENTS_TREE_UPDATE_ERR,

  DEPARTMENTS_TREE_CLEANUP_BEGIN,
  DEPARTMENTS_TREE_CLEANUP_SUCCESS,
  DEPARTMENTS_TREE_CLEANUP_ERR,
} = actions;

const departmentsTreeReducer = (state = initialTreeState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DEPARTMENTS_TREE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DEPARTMENTS_TREE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case DEPARTMENTS_TREE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DEPARTMENTS_TREE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DEPARTMENTS_TREE_CLEANUP_SUCCESS:
      return initialTreeState;

    case DEPARTMENTS_TREE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  DEPARTMENTS_UPDATE_BEGIN,
  DEPARTMENTS_UPDATE_SUCCESS,
  DEPARTMENTS_UPDATE_ERR,

  DEPARTMENTS_CLEANUP_BEGIN,
  DEPARTMENTS_CLEANUP_SUCCESS,
  DEPARTMENTS_CLEANUP_ERR,

  DEPARTMENT_DELETE_BEGIN,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_ERR,
} = actions;

const departmentsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DEPARTMENTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DEPARTMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case DEPARTMENTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DEPARTMENTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DEPARTMENTS_CLEANUP_SUCCESS:
      return initialState;

    case DEPARTMENTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DEPARTMENT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case DEPARTMENT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case DEPARTMENT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  DEPARTMENT_UPDATE_BEGIN,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_ERR,

  DEPARTMENT_CLEANUP_BEGIN,
  DEPARTMENT_CLEANUP_SUCCESS,
  DEPARTMENT_CLEANUP_ERR,

  DEPARTMENT_SAVE_BEGIN,
  DEPARTMENT_SAVE_SUCCESS,
  DEPARTMENT_SAVE_ERR,
} = actions;

const departmentReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DEPARTMENT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DEPARTMENT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case DEPARTMENT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DEPARTMENT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DEPARTMENT_CLEANUP_SUCCESS:
      return initialSingleState;

    case DEPARTMENT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DEPARTMENT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case DEPARTMENT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case DEPARTMENT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { departmentsListReducer, departmentsTreeReducer, departmentsReducer, departmentReducer };
