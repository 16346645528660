const actions = {
  CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_BEGIN: 'CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_BEGIN',
  CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_SUCCESS: 'CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_SUCCESS',
  CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_ERR: 'CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_ERR',

  clientCnpjLegalNaturesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_BEGIN,
    };
  },

  clientCnpjLegalNaturesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjLegalNaturesListUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_LEGAL_NATURES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_BEGIN: 'CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_BEGIN',
  CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_SUCCESS: 'CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_SUCCESS',
  CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_ERR: 'CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_ERR',

  clientCnpjLegalNaturesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_BEGIN,
    };
  },

  clientCnpjLegalNaturesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientCnpjLegalNaturesListCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_LEGAL_NATURES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  