import { message } from 'antd';
import axios from 'axios';
import localStorage from '../../utility/localStorageControl';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const messageKey = 'dataService';

const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
});

const instance = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
});

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

class DataService {
  static get(path = '', params = {}) {
    return instance({
      method: 'GET',
      url: path,
      params,
      headers: { ...authHeader() },
    });
  }

  static download(path = '') {
    return instance({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
      responseType: 'blob',
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return instance({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static postFile(path = '', data = {}, { onUploadProgress = () => {} }) {
    return instance({
      method: 'POST',
      url: path,
      data,
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  static patch(path = '', data = {}) {
    return instance({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return instance({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
instance.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${localStorage.getItem('token')}` };

  return requestConfig;
});

instance.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;

    // console.log(response);
    // console.log(originalRequest);

    if (response) {
      // 400 Bad Request
      // 500 Internal Server Error
      if ([400, 500].includes(response.status)) {
        return response;
      }

      // 401 Unauthorized
      if (response.status === 401) {
        // console.log('Unauthorized', response);
        if (response.config.url === 'users/token') {
          message.error({ content: 'Email ou senha inválidos.', key: messageKey });
        } else if (response.config.url !== 'users/token') {
          message.error({ content: response.data, key: messageKey });
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      }

      return originalRequest;
    }
    return Promise.reject(error);
  },
);
export { DataService };
