import React, { useState } from 'react';
import { Badge, Carousel, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import { newslettersReadAction } from '../../../redux/newsletters/actionCreator';
import localStorage from '../../../utility/localStorageControl';

const NewslettersModal = Styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const NewslettersCarousel = Styled(Carousel)`
  margin: 0;

  .slick-prev,
  .slick-next {
    font-size: 14px;
    &::before {
      display: block;
      color: ${({ theme }) => theme['link-color']};
      font-size: 48px;
      width: 20px;
      height: 20px;
    }
  }
  .slick-prev {
    left: -35px !important;
    &::before {
      content: '‹' !important;
    }
  }
  .slick-next {
    right: -35px !important;
    &::before {
      content: '›' !important;
    }
  }
`;

const NewsletterButton = () => {
  const dispatch = useDispatch();
  const { newsletters, isLoading } = useSelector(state => {
    return {
      newsletters: state.newslettersList.data,
      isLoading: state.newslettersList.loading,
    };
  });

  const handleChange = current => {
    const newsletter = newsletters[current];
    if (!newsletter.visualized) {
      const data = { newsletters: [newsletter.id] };
      dispatch(newslettersReadAction({ data }));
    }
  };

  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
    handleChange(0); // Mark as read
  };
  const hideModal = () => setVisible(false);

  let seen = localStorage.getItem('Newsletters.seen');
  if (!seen && newsletters.length > 0 && !isLoading) {
    showModal();
    localStorage.setItem('Newsletters.seen', true);
    seen = true;
  }

  return (
    !isLoading &&
    newsletters.length > 0 && (
      <div className="newsletter">
        <Badge dot count={newsletters.filter(newsletter => !newsletter.visualized).length} offset={[-10, -7]}>
          <Link to="#" onClick={() => showModal()} title="Informativos">
            <svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
              <path
                fill="#FFF"
                d="M20 2V4L4 8V6H2V18H4V16L6 16.5V18.5C6 20.4 7.6 22 9.5 22S13 20.4 13 18.5V18.3L20 20V22H22V2H20M11 18.5C11 19.3 10.3 20 9.5 20S8 19.3 8 18.5V17L11 17.8V18.5M20 18L4 14V10L20 6V18Z"
              />
            </svg>
          </Link>
        </Badge>
        <NewslettersModal open={visible} footer={null} onCancel={hideModal}>
          <NewslettersCarousel afterChange={handleChange} autoplay autoplaySpeed={30 * 1000} draggable arrows>
            {newsletters.map(newsletter => {
              const newsletterImage = <img src={newsletter.image_url} alt={newsletter.title} width="100%" />;
              return (
                <div key={newsletter.id}>
                  {newsletter.link ? (
                    <a href={newsletter.link} target="_blank" rel="noopener noreferrer">
                      {newsletterImage}
                    </a>
                  ) : (
                    <>{newsletterImage}</>
                  )}
                </div>
              );
            })}
          </NewslettersCarousel>
        </NewslettersModal>
      </div>
    )
  );
};

export default NewsletterButton;
