const actions = {
  TAGS_LIST_UPDATE_BEGIN: 'TAGS_LIST_UPDATE_BEGIN',
  TAGS_LIST_UPDATE_SUCCESS: 'TAGS_LIST_UPDATE_SUCCESS',
  TAGS_LIST_UPDATE_ERR: 'TAGS_LIST_UPDATE_ERR',

  tagsListUpdateBegin: () => {
    return {
      type: actions.TAGS_LIST_UPDATE_BEGIN,
    };
  },

  tagsListUpdateSuccess: data => {
    return {
      type: actions.TAGS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  tagsListUpdateErr: err => {
    return {
      type: actions.TAGS_LIST_UPDATE_ERR,
      err,
    };
  },

  TAGS_LIST_CLEANUP_BEGIN: 'TAGS_LIST_CLEANUP_BEGIN',
  TAGS_LIST_CLEANUP_SUCCESS: 'TAGS_LIST_CLEANUP_SUCCESS',
  TAGS_LIST_CLEANUP_ERR: 'TAGS_LIST_CLEANUP_ERR',

  tagsListCleanupBegin: () => {
    return {
      type: actions.TAGS_LIST_CLEANUP_BEGIN,
    };
  },

  tagsListCleanupSuccess: data => {
    return {
      type: actions.TAGS_LIST_CLEANUP_SUCCESS,
      data,
    };
  },

  tagsListCleanupErr: err => {
    return {
      type: actions.TAGS_LIST_CLEANUP_ERR,
      err,
    };
  },

  TAGS_CLEANUP_BEGIN: 'TAGS_CLEANUP_BEGIN',
  TAGS_CLEANUP_SUCCESS: 'TAGS_CLEANUP_SUCCESS',
  TAGS_CLEANUP_ERR: 'TAGS_CLEANUP_ERR',

  tagsCleanupBegin: () => {
    return {
      type: actions.TAGS_CLEANUP_BEGIN,
    };
  },

  tagsCleanupSuccess: data => {
    return {
      type: actions.TAGS_CLEANUP_SUCCESS,
      data,
    };
  },

  tagsCleanupErr: err => {
    return {
      type: actions.TAGS_CLEANUP_ERR,
      err,
    };
  },

  TAG_DELETE_BEGIN: 'TAG_DELETE_BEGIN',
  TAG_DELETE_SUCCESS: 'TAG_DELETE_SUCCESS',
  TAG_DELETE_ERR: 'TAG_DELETE_ERR',

  tagDeleteBegin: () => {
    return {
      type: actions.TAG_DELETE_BEGIN,
    };
  },

  tagDeleteSuccess: data => {
    return {
      type: actions.TAG_DELETE_SUCCESS,
      data,
    };
  },

  tagDeleteErr: err => {
    return {
      type: actions.TAG_DELETE_ERR,
      err,
    };
  },

  TAG_UPDATE_BEGIN: 'TAG_UPDATE_BEGIN',
  TAG_UPDATE_SUCCESS: 'TAG_UPDATE_SUCCESS',
  TAG_UPDATE_ERR: 'TAG_UPDATE_ERR',

  tagUpdateBegin: () => {
    return {
      type: actions.TAG_UPDATE_BEGIN,
    };
  },

  tagUpdateSuccess: data => {
    return {
      type: actions.TAG_UPDATE_SUCCESS,
      data,
    };
  },

  tagUpdateErr: err => {
    return {
      type: actions.TAG_UPDATE_ERR,
      err,
    };
  },

  TAG_CLEANUP_BEGIN: 'TAG_CLEANUP_BEGIN',
  TAG_CLEANUP_SUCCESS: 'TAG_CLEANUP_SUCCESS',
  TAG_CLEANUP_ERR: 'TAG_CLEANUP_ERR',

  tagCleanupBegin: () => {
    return {
      type: actions.TAG_CLEANUP_BEGIN,
    };
  },

  tagCleanupSuccess: () => {
    return {
      type: actions.TAG_CLEANUP_SUCCESS,
    };
  },

  tagCleanupErr: err => {
    return {
      type: actions.TAG_CLEANUP_ERR,
      err,
    };
  },

  TAG_SAVE_BEGIN: 'TAG_SAVE_BEGIN',
  TAG_SAVE_SUCCESS: 'TAG_SAVE_SUCCESS',
  TAG_SAVE_ERR: 'TAG_SAVE_ERR',

  tagSaveBegin: () => {
    return {
      type: actions.TAG_SAVE_BEGIN,
    };
  },

  tagSaveSuccess: data => {
    return {
      type: actions.TAG_SAVE_SUCCESS,
      data,
    };
  },

  tagSaveErr: err => {
    return {
      type: actions.TAG_SAVE_ERR,
      err,
    };
  },
};

export default actions;
