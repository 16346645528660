const actions = {
  CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_ERR: 'CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_ERR',

  clientTaxationStateTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientTaxationStateTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxationStateTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_TAXATION_STATE_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_ERR: 'CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_ERR',

  clientTaxationStateTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientTaxationStateTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientTaxationStateTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_TAXATION_STATE_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  