const actions = {
  CLIENT_LICENSE_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_LICENSE_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_LICENSE_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_LICENSE_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_LICENSE_TYPES_LIST_UPDATE_ERR: 'CLIENT_LICENSE_TYPES_LIST_UPDATE_ERR',

  clientLicenseTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_LICENSE_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientLicenseTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_LICENSE_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientLicenseTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_LICENSE_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_LICENSE_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_LICENSE_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_LICENSE_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_LICENSE_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_LICENSE_TYPES_LIST_CLEANUP_ERR: 'CLIENT_LICENSE_TYPES_LIST_CLEANUP_ERR',

  clientLicenseTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_LICENSE_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientLicenseTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_LICENSE_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientLicenseTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_LICENSE_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  