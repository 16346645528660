import { Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../config/constants';
import { theme } from '../../config/theme/themeVariables';
import { notificationsReadAction } from '../../redux/notifications/actionCreator';

const read = ({ dispatch, notification, onSuccess = () => {} }) =>
  dispatch(
    notificationsReadAction({
      data: [notification.id],
      onSuccess,
    }),
  );

const renderClass = notification => {
  const className = [!notification.readed_at ? 'row-unreaded' : 'row-readed'];

  switch (notification.notification_type.id) {
    case CONSTANTS.NOTIFICATION_TYPE.ERROR:
      className.push('row-error');
      break;
    case CONSTANTS.NOTIFICATION_TYPE.WARNING:
      className.push('row-warning');
      break;
    case CONSTANTS.NOTIFICATION_TYPE.INFO:
      className.push('row-info');
      break;
    default:
  }
  return className.join(' ');
};

const renderIcon = notification => {
  return (
    <Tooltip title={notification.notification_type.name}>
      <FeatherIcon
        style={{
          color: !notification.readed_at ? notification.notification_type.color_hex : theme['success-color'],
          textDecoration: 'underline dotted',
        }}
        size={20}
        icon={!notification.readed_at ? notification.notification_type.icon : 'check'}
      />
    </Tooltip>
  );
};

const renderTitle = notification => {
  function toMarkup(text) {
    return text
      .replace(/<(.*?)>/g, "<a href='$1'>Link</a>")
      .replace(/\*(.*?)\*/g, '<b>$1</b>')
      .replace(/__(.*?)__/g, '<u>$1</u>')
      .replace(/~(.*?)~/g, '<i>$1</i>')
      .replace(/--(.*?)--/g, '<del>$1</del>');
  }

  function printTitle(text) {
    return ReactHtmlParser(toMarkup(text));
  }

  if (!notification.readed_at && notification.link) {
    return <Link to={`/admin${notification.link}`}>{printTitle(notification.title)}</Link>;
  }

  return printTitle(notification.title);
};

export { read, renderClass, renderIcon, renderTitle };
