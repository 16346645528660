import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_SUCCESS,
  ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_SUCCESS,
  ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_SUCCESS,

  ACHIEVEMENT_PRODUCTS_UPDATE_BEGIN,
  ACHIEVEMENT_PRODUCTS_UPDATE_SUCCESS,
  ACHIEVEMENT_PRODUCTS_UPDATE_ERR,

  ACHIEVEMENT_PRODUCTS_CLEANUP_BEGIN,
  ACHIEVEMENT_PRODUCTS_CLEANUP_SUCCESS,
  ACHIEVEMENT_PRODUCTS_CLEANUP_ERR,
} = actions;

const achievementProductsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENT_PRODUCTS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case ACHIEVEMENT_PRODUCTS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case ACHIEVEMENT_PRODUCTS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case ACHIEVEMENT_PRODUCTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_PRODUCTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_PRODUCTS_CLEANUP_SUCCESS:
      return initialIndexState;

    case ACHIEVEMENT_PRODUCTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ACHIEVEMENT_PRODUCTS_LIST_UPDATE_BEGIN,
  ACHIEVEMENT_PRODUCTS_LIST_UPDATE_SUCCESS,
  ACHIEVEMENT_PRODUCTS_LIST_UPDATE_ERR,

  ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_BEGIN,
  ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_SUCCESS,
  ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_ERR,
} = actions;

const achievementProductsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENT_PRODUCTS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACHIEVEMENT_PRODUCTS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ACHIEVEMENT_PRODUCTS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case ACHIEVEMENT_PRODUCTS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: null,
  loading: false,
  saving: false,
  error: null,
};

const {
  ACHIEVEMENT_PRODUCT_UPDATE_BEGIN,
  ACHIEVEMENT_PRODUCT_UPDATE_SUCCESS,
  ACHIEVEMENT_PRODUCT_UPDATE_ERR,

  ACHIEVEMENT_PRODUCT_CLEANUP_BEGIN,
  ACHIEVEMENT_PRODUCT_CLEANUP_SUCCESS,
  ACHIEVEMENT_PRODUCT_CLEANUP_ERR,

  ACHIEVEMENT_PRODUCT_SAVE_BEGIN,
  ACHIEVEMENT_PRODUCT_SAVE_SUCCESS,
  ACHIEVEMENT_PRODUCT_SAVE_ERR,
} = actions;

const achievementProductReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENT_PRODUCT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_PRODUCT_CLEANUP_SUCCESS:
      return initialSingleState;

    case ACHIEVEMENT_PRODUCT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case ACHIEVEMENT_PRODUCT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case ACHIEVEMENT_PRODUCT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

// Image
const initialImageState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_BEGIN,
  ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_SUCCESS,
  ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_ERR,
  ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_BEGIN,
  ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_SUCCESS,
  ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_ERR,
} = actions;

const achievementProductImageReducer = (state = initialImageState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCT_IMAGE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_SUCCESS:
      return initialImageState;

    case ACHIEVEMENT_PRODUCT_IMAGE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export {
  achievementProductsReducer,
  achievementProductsListReducer,
  achievementProductReducer,
  achievementProductImageReducer,
};
