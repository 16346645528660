const actions = {
  SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_ERR: 'SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_ERR',

  sefazCeSigetReportsPaginationUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_BEGIN,
    };
  },

  sefazCeSigetReportsPaginationUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportsPaginationUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_ERR: 'SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_ERR',

  sefazCeSigetReportsFiltersUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_BEGIN,
    };
  },

  sefazCeSigetReportsFiltersUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportsFiltersUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_ERR: 'SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_ERR',

  sefazCeSigetReportsSorterUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_BEGIN,
    };
  },

  sefazCeSigetReportsSorterUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportsSorterUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_SORTER_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORTS_UPDATE_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_UPDATE_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_UPDATE_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_UPDATE_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_UPDATE_ERR: 'SEFAZ_CE_SIGET_REPORTS_UPDATE_ERR',

  sefazCeSigetReportsUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_UPDATE_BEGIN,
    };
  },

  sefazCeSigetReportsUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportsUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORTS_CLEANUP_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_CLEANUP_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_CLEANUP_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_CLEANUP_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_CLEANUP_ERR: 'SEFAZ_CE_SIGET_REPORTS_CLEANUP_ERR',

  sefazCeSigetReportsCleanupBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_CLEANUP_BEGIN,
    };
  },

  sefazCeSigetReportsCleanupSuccess: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_CLEANUP_SUCCESS,
    };
  },

  sefazCeSigetReportsCleanupErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_CLEANUP_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORT_DELETE_BEGIN: 'SEFAZ_CE_SIGET_REPORT_DELETE_BEGIN',
  SEFAZ_CE_SIGET_REPORT_DELETE_SUCCESS: 'SEFAZ_CE_SIGET_REPORT_DELETE_SUCCESS',
  SEFAZ_CE_SIGET_REPORT_DELETE_ERR: 'SEFAZ_CE_SIGET_REPORT_DELETE_ERR',

  sefazCeSigetReportDeleteBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_DELETE_BEGIN,
    };
  },

  sefazCeSigetReportDeleteSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_DELETE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportDeleteErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_DELETE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORT_UPDATE_BEGIN: 'SEFAZ_CE_SIGET_REPORT_UPDATE_BEGIN',
  SEFAZ_CE_SIGET_REPORT_UPDATE_SUCCESS: 'SEFAZ_CE_SIGET_REPORT_UPDATE_SUCCESS',
  SEFAZ_CE_SIGET_REPORT_UPDATE_ERR: 'SEFAZ_CE_SIGET_REPORT_UPDATE_ERR',

  sefazCeSigetReportUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_UPDATE_BEGIN,
    };
  },

  sefazCeSigetReportUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORT_CLEANUP_BEGIN: 'SEFAZ_CE_SIGET_REPORT_CLEANUP_BEGIN',
  SEFAZ_CE_SIGET_REPORT_CLEANUP_SUCCESS: 'SEFAZ_CE_SIGET_REPORT_CLEANUP_SUCCESS',
  SEFAZ_CE_SIGET_REPORT_CLEANUP_ERR: 'SEFAZ_CE_SIGET_REPORT_CLEANUP_ERR',

  sefazCeSigetReportCleanupBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_CLEANUP_BEGIN,
    };
  },

  sefazCeSigetReportCleanupSuccess: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_CLEANUP_SUCCESS,
    };
  },

  sefazCeSigetReportCleanupErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_CLEANUP_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORT_SAVE_BEGIN: 'SEFAZ_CE_SIGET_REPORT_SAVE_BEGIN',
  SEFAZ_CE_SIGET_REPORT_SAVE_SUCCESS: 'SEFAZ_CE_SIGET_REPORT_SAVE_SUCCESS',
  SEFAZ_CE_SIGET_REPORT_SAVE_ERR: 'SEFAZ_CE_SIGET_REPORT_SAVE_ERR',

  sefazCeSigetReportSaveBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_SAVE_BEGIN,
    };
  },

  sefazCeSigetReportSaveSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_SAVE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportSaveErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORT_SAVE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_ERR: 'SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_ERR',

  sefazCeSigetReportsListUpdateBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_BEGIN,
    };
  },

  sefazCeSigetReportsListUpdateSuccess: data => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  sefazCeSigetReportsListUpdateErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_LIST_UPDATE_ERR,
      err,
    };
  },

  SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_BEGIN: 'SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_BEGIN',
  SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_SUCCESS: 'SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_SUCCESS',
  SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_ERR: 'SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_ERR',

  sefazCeSigetReportsListCleanupBegin: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_BEGIN,
    };
  },

  sefazCeSigetReportsListCleanupSuccess: () => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_SUCCESS,
    };
  },

  sefazCeSigetReportsListCleanupErr: err => {
    return {
      type: actions.SEFAZ_CE_SIGET_REPORTS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
