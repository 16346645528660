const actions = {
  CNAES_LIST_UPDATE_BEGIN: 'CNAES_LIST_UPDATE_BEGIN',
  CNAES_LIST_UPDATE_SUCCESS: 'CNAES_LIST_UPDATE_SUCCESS',
  CNAES_LIST_UPDATE_ERR: 'CNAES_LIST_UPDATE_ERR',

  cnaesListUpdateBegin: () => {
    return {
      type: actions.CNAES_LIST_UPDATE_BEGIN,
    };
  },

  cnaesListUpdateSuccess: data => {
    return {
      type: actions.CNAES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cnaesListUpdateErr: err => {
    return {
      type: actions.CNAES_LIST_UPDATE_ERR,
      err,
    };
  },

  CNAES_LIST_CLEANUP_BEGIN: 'CNAES_LIST_CLEANUP_BEGIN',
  CNAES_LIST_CLEANUP_SUCCESS: 'CNAES_LIST_CLEANUP_SUCCESS',
  CNAES_LIST_CLEANUP_ERR: 'CNAES_LIST_CLEANUP_ERR',

  cnaesListCleanupBegin: () => {
    return {
      type: actions.CNAES_LIST_CLEANUP_BEGIN,
    };
  },

  cnaesListCleanupSuccess: () => {
    return {
      type: actions.CNAES_LIST_CLEANUP_SUCCESS,
    };
  },

  cnaesListCleanupErr: err => {
    return {
      type: actions.CNAES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  