const actions = {
  CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_ERR: 'CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_ERR',

  clientTaxationFederalTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientTaxationFederalTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxationFederalTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_TAXATION_FEDERAL_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_ERR: 'CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_ERR',

  clientTaxationFederalTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientTaxationFederalTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientTaxationFederalTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_TAXATION_FEDERAL_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  