import React, { useEffect, useState } from 'react';
import { Badge, List, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../layout/popup/popup';
import Heading from '../../layout/heading/heading';
import format from '../../../config/format';
import { renderClass, renderIcon, renderTitle } from '../../../container/notifications/functions';
import { notificationsListUpdateAction } from '../../../redux/notifications/actionCreator';
import { NotificationsButton, NotificationsList } from '../../notifications/style';
import { TableWrapper } from '../../../container/styled-custom';

const NOTIFICATIONS_REFRESH_SECS = 180;

const NotificationButton = () => {
  const dispatch = useDispatch();

  const { notifications, count, isLoading } = useSelector(state => {
    return {
      notifications: state.notificationsList.data,
      count: state.notificationsList.count,
      isLoading: state.notificationsList.loading,
    };
  });

  const [repetitions, setRepetitions] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timer = null;

    const refreshSecs = NOTIFICATIONS_REFRESH_SECS + 30 * repetitions;

    if (seconds < refreshSecs && seconds < NOTIFICATIONS_REFRESH_SECS * 10) {
      timer = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else {
      setRepetitions(repetitions + 1);
      setSeconds(0);
      dispatch(notificationsListUpdateAction());
    }

    return () => clearInterval(timer);
  }, [dispatch, repetitions, seconds]);

  const [visible, setVisible] = useState(false);
  const hide = () => setVisible(false);
  const handleVisibleChange = visible => setVisible(visible);

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon" style={{ minWidth: 600 }}>
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Últimas Notificações</span>
        <Badge count={count?.unreaded} />
      </Heading>

      <TableWrapper>
        <NotificationsList
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={notification => (
            <List.Item key={notification.id} className={renderClass(notification)}>
              <List.Item.Meta
                avatar={renderIcon(notification)}
                title={renderTitle(notification)}
                description={
                  <Tooltip title={<Moment format={format.input.dateTime}>{notification?.created_at}</Moment>}>
                    <Moment style={{ textDecoration: 'underline dotted' }} fromNow>
                      {notification?.created_at}
                    </Moment>
                  </Tooltip>
                }
              />
            </List.Item>
          )}
        />
      </TableWrapper>

      <NotificationsButton>
        <Link to="/admin/notifications" onClick={() => hide()}>
          Ver todas
        </Link>
      </NotificationsButton>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover
        placement="bottomLeft"
        content={content}
        action="hover"
        open={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Badge dot count={count?.unreaded} offset={[-10, -7]}>
          <Link to="#" className={count?.unreaded && 'bell'} title="Notificações">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
};

export default NotificationButton;
