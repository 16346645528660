import moment from 'moment';
import actions from './actions';
import CONSTANTS from '../../config/constants';

const initialSummaryState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {
    'Cards.due_date': [moment().startOf('month'), moment().endOf('month')],
  },
  loading: false,
  error: null,
};

const {
  CARDS_SUMMARY_PAGINATION_UPDATE_SUCCESS,
  CARDS_SUMMARY_FILTERS_UPDATE_SUCCESS,

  CARDS_SUMMARY_UPDATE_BEGIN,
  CARDS_SUMMARY_UPDATE_SUCCESS,
  CARDS_SUMMARY_UPDATE_ERR,

  CARDS_SUMMARY_CLEANUP_BEGIN,
  CARDS_SUMMARY_CLEANUP_SUCCESS,
  CARDS_SUMMARY_CLEANUP_ERR,
} = actions;

const cardsSummaryReducer = (state = initialSummaryState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARDS_SUMMARY_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CARDS_SUMMARY_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARDS_SUMMARY_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_SUMMARY_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARDS_SUMMARY_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_SUMMARY_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_SUMMARY_CLEANUP_SUCCESS:
      return initialSummaryState;

    case CARDS_SUMMARY_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

/*
 * Cards -> Index
 */

// permissions_policy
const cardsIndexPermissionsPolicyDefault = CONSTANTS.CARD_PERMISSIONS_POLICY.TEAM;

// date_field_filter
const cardsIndexDateFilterFieldDefault = CONSTANTS.CARD_DATE_FIELD_FILTER.DUE_DATE;

// pagination
const cardsIndexPaginationDefault = {
  current: 1,
  pageSize: 20,
};

// filters
const cardsIndexFiltersDefault = {
  [cardsIndexDateFilterFieldDefault]: [moment().startOf('month'), moment().endOf('month')],
};

// sorter
const cardsIndexSorterDefault = {};

// initial state
const initialIndexState = {
  data: [],
  permissions_policy: cardsIndexPermissionsPolicyDefault,
  date_filter_field: cardsIndexDateFilterFieldDefault,
  pagination: cardsIndexPaginationDefault,
  filters: cardsIndexFiltersDefault,
  sorter: cardsIndexSorterDefault,
  loading: false,
  deleting: false,
  error: null,
};

// reducer
const {
  CARDS_PERMISSIONS_POLICY_UPDATE_SUCCESS,
  CARDS_DATE_FILTER_FIELD_UPDATE_SUCCESS,
  CARDS_PAGINATION_UPDATE_SUCCESS,
  CARDS_FILTERS_UPDATE_SUCCESS,
  CARDS_SORTER_UPDATE_SUCCESS,

  CARDS_UPDATE_BEGIN,
  CARDS_UPDATE_SUCCESS,
  CARDS_UPDATE_ERR,

  CARDS_UPDATE_STATUS_BEGIN,
  CARDS_UPDATE_STATUS_SUCCESS,
  CARDS_UPDATE_STATUS_ERR,

  CARDS_DELETE_MANY_BEGIN,
  CARDS_DELETE_MANY_SUCCESS,
  CARDS_DELETE_MANY_ERR,

  CARDS_CLEANUP_BEGIN,
  CARDS_CLEANUP_SUCCESS,
  CARDS_CLEANUP_ERR,

  CARD_DELETE_BEGIN,
  CARD_DELETE_SUCCESS,
  CARD_DELETE_ERR,

  CARDS_TRANSFER_BEGIN,
  CARDS_TRANSFER_SUCCESS,
  CARDS_TRANSFER_ERR,
} = actions;

const cardsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARDS_PERMISSIONS_POLICY_UPDATE_SUCCESS:
      return {
        ...state,
        permissions_policy: data,
      };

    case CARDS_DATE_FILTER_FIELD_UPDATE_SUCCESS:
      return {
        ...state,
        date_filter_field: data,
      };

    case CARDS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CARDS_FILTERS_UPDATE_SUCCESS: {
      return {
        ...state,
        filters: data,
      };
    }

    case CARDS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARDS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARDS_UPDATE_STATUS_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARDS_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARDS_UPDATE_STATUS_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case CARDS_DELETE_MANY_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CARDS_DELETE_MANY_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CARDS_DELETE_MANY_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    case CARDS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_CLEANUP_SUCCESS:
      return {
        ...initialIndexState,
      };

    case CARDS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CARD_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CARD_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    case CARDS_TRANSFER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CARDS_TRANSFER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

// permissions_policy
const cardFilesPermissionsPolicyDefault = CONSTANTS.CARD_PERMISSIONS_POLICY.TEAM;

// pagination
const cardFilesPaginationDefault = {
  current: 1,
  pageSize: 20,
};

// initial state
const initialFilesIndexState = {
  data: [],
  blob: null,
  base64: null,
  permissions_policy: cardFilesPermissionsPolicyDefault,
  pagination: cardFilesPaginationDefault,
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  downloading: false,
  error: null,
};

// reducer
const {
  CARD_FILES_PAGINATION_UPDATE_SUCCESS,
  CARD_FILES_FILTERS_UPDATE_SUCCESS,
  CARD_FILES_SORTER_UPDATE_SUCCESS,

  CARD_FILES_UPDATE_BEGIN,
  CARD_FILES_UPDATE_SUCCESS,
  CARD_FILES_UPDATE_ERR,

  CARD_FILES_DOWNLOAD_BEGIN,
  CARD_FILES_DOWNLOAD_SUCCESS,
  CARD_FILES_DOWNLOAD_ERR,

  CARD_FILES_CLEANUP_BEGIN,
  CARD_FILES_CLEANUP_SUCCESS,
  CARD_FILES_CLEANUP_ERR,
} = actions;

const cardFilesReducer = (state = initialFilesIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_FILES_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CARD_FILES_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARD_FILES_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARD_FILES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_FILES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_FILES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_FILES_DOWNLOAD_BEGIN:
      return {
        ...state,
        downloading: true,
      };

    case CARD_FILES_DOWNLOAD_SUCCESS:
      return {
        ...state,
        blob: data.blob,
        base64: data.base64,
        downloading: false,
      };

    case CARD_FILES_DOWNLOAD_ERR:
      return {
        ...state,
        error: err,
        downloading: false,
      };

    case CARD_FILES_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_FILES_CLEANUP_SUCCESS:
      return initialFilesIndexState;

    case CARD_FILES_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialChartState = {
  data: {},
  loading: false,
  error: null,
};

const {
  CARDS_CHARTS_UPDATE_BEGIN,
  CARDS_CHARTS_UPDATE_SUCCESS,
  CARDS_CHARTS_UPDATE_ERR,

  CARDS_CHARTS_CLEANUP_BEGIN,
  CARDS_CHARTS_CLEANUP_SUCCESS,
  CARDS_CHARTS_CLEANUP_ERR,
} = actions;

const cardsChartsReducer = (state = initialChartState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARDS_CHARTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_CHARTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARDS_CHARTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_CHARTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_CHARTS_CLEANUP_SUCCESS:
      return initialChartState;

    case CARDS_CHARTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialPendingChartState = {
  data: [],
  permissions_policy: cardsIndexPermissionsPolicyDefault,
  loading: false,
  error: null,
};

const {
  CARDS_PENDING_CHART_UPDATE_BEGIN,
  CARDS_PENDING_CHART_UPDATE_SUCCESS,
  CARDS_PENDING_CHART_UPDATE_ERR,

  CARDS_PENDING_CHART_CLEANUP_BEGIN,
  CARDS_PENDING_CHART_CLEANUP_SUCCESS,
  CARDS_PENDING_CHART_CLEANUP_ERR,
} = actions;

const cardsPendingChartReducer = (state = initialPendingChartState, action) => {
  const { type, data, permissions_policy, err } = action;
  switch (type) {
    case CARDS_PENDING_CHART_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_PENDING_CHART_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        permissions_policy,
        loading: false,
      };

    case CARDS_PENDING_CHART_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_PENDING_CHART_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_PENDING_CHART_CLEANUP_SUCCESS:
      return initialPendingChartState;

    case CARDS_PENDING_CHART_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialReportState = {
  data: undefined,
  filters: {},
  sorter: {},
  loading: false,
  error: null,
};

const {
  CARDS_REPORT_FILTERS_UPDATE_SUCCESS,
  CARDS_REPORT_SORTER_UPDATE_SUCCESS,

  CARDS_REPORT_UPDATE_BEGIN,
  CARDS_REPORT_UPDATE_SUCCESS,
  CARDS_REPORT_UPDATE_ERR,

  CARDS_REPORT_CLEANUP_BEGIN,
  CARDS_REPORT_CLEANUP_SUCCESS,
  CARDS_REPORT_CLEANUP_ERR,
} = actions;

const cardsReportReducer = (state = initialReportState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARDS_REPORT_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARDS_REPORT_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARDS_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARDS_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_REPORT_CLEANUP_SUCCESS:
      return initialReportState;

    case CARDS_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialEmployeesReportState = {
  data: undefined,
  filters: {},
  sorter: {},
  loading: false,
  error: null,
};

const {
  CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_SUCCESS,
  CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_SUCCESS,

  CARDS_EMPLOYEES_REPORT_UPDATE_BEGIN,
  CARDS_EMPLOYEES_REPORT_UPDATE_SUCCESS,
  CARDS_EMPLOYEES_REPORT_UPDATE_ERR,

  CARDS_EMPLOYEES_REPORT_CLEANUP_BEGIN,
  CARDS_EMPLOYEES_REPORT_CLEANUP_SUCCESS,
  CARDS_EMPLOYEES_REPORT_CLEANUP_ERR,
} = actions;

const cardsEmployeesReportReducer = (state = initialEmployeesReportState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARDS_EMPLOYEES_REPORT_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARDS_EMPLOYEES_REPORT_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARDS_EMPLOYEES_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_EMPLOYEES_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARDS_EMPLOYEES_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_EMPLOYEES_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_EMPLOYEES_REPORT_CLEANUP_SUCCESS:
      return initialEmployeesReportState;

    case CARDS_EMPLOYEES_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialQuestionsReportState = {
  data: undefined,
  filters: {},
  sorter: {},
  loading: false,
  error: null,
};

const {
  CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_SUCCESS,
  CARDS_QUESTIONS_REPORT_SORTER_UPDATE_SUCCESS,

  CARDS_QUESTIONS_REPORT_UPDATE_BEGIN,
  CARDS_QUESTIONS_REPORT_UPDATE_SUCCESS,
  CARDS_QUESTIONS_REPORT_UPDATE_ERR,

  CARDS_QUESTIONS_REPORT_CLEANUP_BEGIN,
  CARDS_QUESTIONS_REPORT_CLEANUP_SUCCESS,
  CARDS_QUESTIONS_REPORT_CLEANUP_ERR,
} = actions;

const cardsQuestionsReportReducer = (state = initialQuestionsReportState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARDS_QUESTIONS_REPORT_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARDS_QUESTIONS_REPORT_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARDS_QUESTIONS_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_QUESTIONS_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARDS_QUESTIONS_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARDS_QUESTIONS_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARDS_QUESTIONS_REPORT_CLEANUP_SUCCESS:
      return initialQuestionsReportState;

    case CARDS_QUESTIONS_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CARD_UPDATE_BEGIN,
  CARD_UPDATE_SUCCESS,
  CARD_UPDATE_ERR,

  CARD_CLEANUP_BEGIN,
  CARD_CLEANUP_SUCCESS,
  CARD_CLEANUP_ERR,

  CARD_SAVE_BEGIN,
  CARD_SAVE_SUCCESS,
  CARD_SAVE_ERR,
} = actions;

const cardReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_CLEANUP_SUCCESS:
      return initialSingleState;

    case CARD_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

// initial state
const initialExistsState = {
  data: [],
  loading: false,
  error: null,
};

// reducer
const {
  CARD_EXISTS_BEGIN,
  CARD_EXISTS_SUCCESS,
  CARD_EXISTS_ERR,

  CARD_EXISTS_CLEANUP_BEGIN,
  CARD_EXISTS_CLEANUP_SUCCESS,
  CARD_EXISTS_CLEANUP_ERR,
} = actions;

const cardExistsReducer = (state = initialExistsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_EXISTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_EXISTS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_EXISTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_EXISTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_EXISTS_CLEANUP_SUCCESS:
      return initialIndexState;

    case CARD_EXISTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export {
  cardsSummaryReducer,
  cardsIndexPermissionsPolicyDefault,
  cardsIndexDateFilterFieldDefault,
  cardsIndexPaginationDefault,
  cardsIndexFiltersDefault,
  cardsIndexSorterDefault,
  cardFilesPermissionsPolicyDefault,
  cardFilesPaginationDefault,
  cardsReducer,
  cardFilesReducer,
  cardsChartsReducer,
  cardsPendingChartReducer,
  cardsReportReducer,
  cardsEmployeesReportReducer,
  cardsQuestionsReportReducer,
  cardReducer,
  cardExistsReducer,
};
