const actions = {
  CLIENT_CNPJ_SIZES_LIST_UPDATE_BEGIN: 'CLIENT_CNPJ_SIZES_LIST_UPDATE_BEGIN',
  CLIENT_CNPJ_SIZES_LIST_UPDATE_SUCCESS: 'CLIENT_CNPJ_SIZES_LIST_UPDATE_SUCCESS',
  CLIENT_CNPJ_SIZES_LIST_UPDATE_ERR: 'CLIENT_CNPJ_SIZES_LIST_UPDATE_ERR',

  clientCnpjSizesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_SIZES_LIST_UPDATE_BEGIN,
    };
  },

  clientCnpjSizesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_SIZES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjSizesListUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_SIZES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_SIZES_LIST_CLEANUP_BEGIN: 'CLIENT_CNPJ_SIZES_LIST_CLEANUP_BEGIN',
  CLIENT_CNPJ_SIZES_LIST_CLEANUP_SUCCESS: 'CLIENT_CNPJ_SIZES_LIST_CLEANUP_SUCCESS',
  CLIENT_CNPJ_SIZES_LIST_CLEANUP_ERR: 'CLIENT_CNPJ_SIZES_LIST_CLEANUP_ERR',

  clientCnpjSizesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_SIZES_LIST_CLEANUP_BEGIN,
    };
  },

  clientCnpjSizesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_SIZES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientCnpjSizesListCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_SIZES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  