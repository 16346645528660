const actions = {
  CARD_PRIORITIES_LIST_UPDATE_BEGIN: 'CARD_PRIORITIES_LIST_UPDATE_BEGIN',
  CARD_PRIORITIES_LIST_UPDATE_SUCCESS: 'CARD_PRIORITIES_LIST_UPDATE_SUCCESS',
  CARD_PRIORITIES_LIST_UPDATE_ERR: 'CARD_PRIORITIES_LIST_UPDATE_ERR',

  cardPrioritiesListUpdateBegin: () => {
    return {
      type: actions.CARD_PRIORITIES_LIST_UPDATE_BEGIN,
    };
  },

  cardPrioritiesListUpdateSuccess: data => {
    return {
      type: actions.CARD_PRIORITIES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cardPrioritiesListUpdateErr: err => {
    return {
      type: actions.CARD_PRIORITIES_LIST_UPDATE_ERR,
      err,
    };
  },

  CARD_PRIORITIES_LIST_CLEANUP_BEGIN: 'CARD_PRIORITIES_LIST_CLEANUP_BEGIN',
  CARD_PRIORITIES_LIST_CLEANUP_SUCCESS: 'CARD_PRIORITIES_LIST_CLEANUP_SUCCESS',
  CARD_PRIORITIES_LIST_CLEANUP_ERR: 'CARD_PRIORITIES_LIST_CLEANUP_ERR',

  cardPrioritiesListCleanupBegin: () => {
    return {
      type: actions.CARD_PRIORITIES_LIST_CLEANUP_BEGIN,
    };
  },

  cardPrioritiesListCleanupSuccess: () => {
    return {
      type: actions.CARD_PRIORITIES_LIST_CLEANUP_SUCCESS,
    };
  },

  cardPrioritiesListCleanupErr: err => {
    return {
      type: actions.CARD_PRIORITIES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
