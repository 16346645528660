import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_BEGIN,
  CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_SUCCESS,
  CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_ERR,

  CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_BEGIN,
  CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_SUCCESS,
  CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_ERR,
} = actions;

const clientContractItemTypesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default clientContractItemTypesListReducer;
