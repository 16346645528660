import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Popover, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Button } from '../layout/buttons/buttons';
import { get } from '../../utility/utility';

const { Text } = Typography;

const asColumnFilterObj = value => {
  return {
    text: value,
    value,
  };
};

const removeAccents = (value = '') =>
  value
    .toString()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, ''); // Old method: .replace(/[\u0300-\u036f]/g, "");

const getColumnSearchProps = ({
  title,
  key,
  dataIndex,
  handleSearch,
  searchColumn,
  searchText,
  filters = undefined,
  filter = value => value,
  onFilter = (value, record) =>
    value
      .split(';')
      .map(word =>
        removeAccents(get(record, dataIndex))
          .toLowerCase()
          .includes(removeAccents(word).toLowerCase()),
      )
      .includes(true),
}) => {
  const props = {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: '12px' }}>
        <div style={{ marginBottom: 15, display: 'block' }}>
          <Input
            placeholder={`Buscar ${title ?? dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(key, dataIndex, selectedKeys, confirm)}
            size="small"
            autoFocus
            allowClear
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(key, dataIndex, selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
          >
            Buscar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#2C99FF' : undefined }} />,
    onFilter,
    render: value => {
      const text = value ? value.toString() : '';
      return JSON.stringify(searchColumn) === JSON.stringify(dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText ? searchText.split(';').map(searchTextWord => filter(searchTextWord, text)) : []}
          autoEscape
          textToHighlight={text}
          title={text}
        />
      ) : (
        value
      );
    },
  };

  if (filters !== undefined) {
    Object.assign(props, { filteredValue: filters[key] ?? [] });
  }

  return props;
};

const Copyable = ({ children, copyable = false, title = undefined, onClick = undefined, ...props }) => {
  return (
    <div className="truncate-group-body" {...props}>
      <Text className="truncate-group-flexible" title={title ?? children?.innerText ?? children ?? ''} ellipsis>
        {typeof onClick === 'function' ? (
          <Link to="#" onClick={onClick}>
            {children}
          </Link>
        ) : (
          children
        )}
      </Text>
      <Text className="truncate-group-fixed" copyable={copyable} />
    </div>
  );
};

const listGroup = (list, max = 3, divider = ', ') => {
  // items
  if (list.length > 1) {
    // body
    const listLimit = list.length > max ? max : list.length - 1;
    const body = list.slice(0, listLimit).map((item, index) => (
      <span key={`list-item-${index}`}>
        {item}
        {index + 1 < listLimit && divider}
      </span>
    ));

    // remaining
    const content = (
      <Space direction="vertical" size="small" style={{ fontSize: 13 }}>
        {list.slice(max).map(item => (
          <span key={item}>{item}</span>
        ))}
      </Space>
    );

    const remaining =
      list.length > max ? (
        <Popover content={content}>
          <span style={{ textDecoration: 'underline dotted' }}>mais {list.length - max}</span>
        </Popover>
      ) : (
        list[list.length - 1]
      );

    return (
      <>
        {body} e {remaining}
      </>
    );
  }

  // item
  return <>{list[0]}</>;
};

export { asColumnFilterObj, removeAccents, getColumnSearchProps, Copyable, listGroup };
