const actions = {
  ADDRESS_UPDATE_BEGIN: 'ADDRESS_UPDATE_BEGIN',
  ADDRESS_UPDATE_SUCCESS: 'ADDRESS_UPDATE_SUCCESS',
  ADDRESS_UPDATE_ERR: 'ADDRESS_UPDATE_ERR',

  addressUpdateBegin: () => {
    return {
      type: actions.ADDRESS_UPDATE_BEGIN,
    };
  },

  addressUpdateSuccess: data => {
    return {
      type: actions.ADDRESS_UPDATE_SUCCESS,
      data,
    };
  },

  addressUpdateErr: err => {
    return {
      type: actions.ADDRESS_UPDATE_ERR,
      err,
    };
  },

  ADDRESS_CLEANUP_BEGIN: 'ADDRESS_CLEANUP_BEGIN',
  ADDRESS_CLEANUP_SUCCESS: 'ADDRESS_CLEANUP_SUCCESS',
  ADDRESS_CLEANUP_ERR: 'ADDRESS_CLEANUP_ERR',

  addressCleanupBegin: () => {
    return {
      type: actions.ADDRESS_CLEANUP_BEGIN,
    };
  },

  addressCleanupSuccess: () => {
    return {
      type: actions.ADDRESS_CLEANUP_SUCCESS,
    };
  },

  addressCleanupErr: err => {
    return {
      type: actions.ADDRESS_CLEANUP_ERR,
      err,
    };
  },

  ADDRESS_SAVE_BEGIN: 'ADDRESS_SAVE_BEGIN',
  ADDRESS_SAVE_SUCCESS: 'ADDRESS_SAVE_SUCCESS',
  ADDRESS_SAVE_ERR: 'ADDRESS_SAVE_ERR',

  addressSaveBegin: () => {
    return {
      type: actions.ADDRESS_SAVE_BEGIN,
    };
  },

  addressSaveSuccess: data => {
    return {
      type: actions.ADDRESS_SAVE_SUCCESS,
      data,
    };
  },

  addressSaveErr: err => {
    return {
      type: actions.ADDRESS_SAVE_ERR,
      err,
    };
  },
};

export default actions;
