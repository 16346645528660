import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ASSET_CATEGORIES_LIST_UPDATE_BEGIN,
  ASSET_CATEGORIES_LIST_UPDATE_SUCCESS,
  ASSET_CATEGORIES_LIST_UPDATE_ERR,

  ASSET_CATEGORIES_LIST_CLEANUP_BEGIN,
  ASSET_CATEGORIES_LIST_CLEANUP_SUCCESS,
  ASSET_CATEGORIES_LIST_CLEANUP_ERR,
} = actions;

const assetCategoriesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSET_CATEGORIES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CATEGORIES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ASSET_CATEGORIES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_CATEGORIES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CATEGORIES_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case ASSET_CATEGORIES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialTreeState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  ASSET_CATEGORIES_TREE_UPDATE_BEGIN,
  ASSET_CATEGORIES_TREE_UPDATE_SUCCESS,
  ASSET_CATEGORIES_TREE_UPDATE_ERR,

  ASSET_CATEGORIES_TREE_CLEANUP_BEGIN,
  ASSET_CATEGORIES_TREE_CLEANUP_SUCCESS,
  ASSET_CATEGORIES_TREE_CLEANUP_ERR,

  ASSET_CATEGORY_DELETE_BEGIN,
  ASSET_CATEGORY_DELETE_SUCCESS,
  ASSET_CATEGORY_DELETE_ERR,
} = actions;

const assetCategoriesTreeReducer = (state = initialTreeState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSET_CATEGORIES_TREE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CATEGORIES_TREE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ASSET_CATEGORIES_TREE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_CATEGORIES_TREE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CATEGORIES_TREE_CLEANUP_SUCCESS:
      return initialTreeState;

    case ASSET_CATEGORIES_TREE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_CATEGORY_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case ASSET_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case ASSET_CATEGORY_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  ASSET_CATEGORY_UPDATE_BEGIN,
  ASSET_CATEGORY_UPDATE_SUCCESS,
  ASSET_CATEGORY_UPDATE_ERR,

  ASSET_CATEGORY_CLEANUP_BEGIN,
  ASSET_CATEGORY_CLEANUP_SUCCESS,
  ASSET_CATEGORY_CLEANUP_ERR,

  ASSET_CATEGORY_SAVE_BEGIN,
  ASSET_CATEGORY_SAVE_SUCCESS,
  ASSET_CATEGORY_SAVE_ERR,
} = actions;

const assetCategoryReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSET_CATEGORY_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ASSET_CATEGORY_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_CATEGORY_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSET_CATEGORY_CLEANUP_SUCCESS:
      return initialSingleState;

    case ASSET_CATEGORY_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ASSET_CATEGORY_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case ASSET_CATEGORY_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case ASSET_CATEGORY_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { assetCategoriesListReducer, assetCategoriesTreeReducer, assetCategoryReducer };
