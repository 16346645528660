const actions = {
  CLIENT_STATUSES_CHARTS_UPDATE_BEGIN: 'CLIENT_STATUSES_CHARTS_UPDATE_BEGIN',
  CLIENT_STATUSES_CHARTS_UPDATE_SUCCESS: 'CLIENT_STATUSES_CHARTS_UPDATE_SUCCESS',
  CLIENT_STATUSES_CHARTS_UPDATE_ERR: 'CLIENT_STATUSES_CHARTS_UPDATE_ERR',

  clientStatusesChartsUpdateBegin: () => {
    return {
      type: actions.CLIENT_STATUSES_CHARTS_UPDATE_BEGIN,
    };
  },

  clientStatusesChartsUpdateSuccess: data => {
    return {
      type: actions.CLIENT_STATUSES_CHARTS_UPDATE_SUCCESS,
      data,
    };
  },

  clientStatusesChartsUpdateErr: err => {
    return {
      type: actions.CLIENT_STATUSES_CHARTS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_STATUSES_CHARTS_CLEANUP_BEGIN: 'CLIENT_STATUSES_CHARTS_CLEANUP_BEGIN',
  CLIENT_STATUSES_CHARTS_CLEANUP_SUCCESS: 'CLIENT_STATUSES_CHARTS_CLEANUP_SUCCESS',
  CLIENT_STATUSES_CHARTS_CLEANUP_ERR: 'CLIENT_STATUSES_CHARTS_CLEANUP_ERR',

  clientStatusesChartsCleanupBegin: () => {
    return {
      type: actions.CLIENT_STATUSES_CHARTS_CLEANUP_BEGIN,
    };
  },

  clientStatusesChartsCleanupSuccess: () => {
    return {
      type: actions.CLIENT_STATUSES_CHARTS_CLEANUP_SUCCESS,
    };
  },

  clientStatusesChartsCleanupErr: err => {
    return {
      type: actions.CLIENT_STATUSES_CHARTS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_STATUSES_LIST_UPDATE_BEGIN: 'CLIENT_STATUSES_LIST_UPDATE_BEGIN',
  CLIENT_STATUSES_LIST_UPDATE_SUCCESS: 'CLIENT_STATUSES_LIST_UPDATE_SUCCESS',
  CLIENT_STATUSES_LIST_UPDATE_ERR: 'CLIENT_STATUSES_LIST_UPDATE_ERR',

  clientStatusesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_STATUSES_LIST_UPDATE_BEGIN,
    };
  },

  clientStatusesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_STATUSES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientStatusesListUpdateErr: err => {
    return {
      type: actions.CLIENT_STATUSES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_STATUSES_LIST_CLEANUP_BEGIN: 'CLIENT_STATUSES_LIST_CLEANUP_BEGIN',
  CLIENT_STATUSES_LIST_CLEANUP_SUCCESS: 'CLIENT_STATUSES_LIST_CLEANUP_SUCCESS',
  CLIENT_STATUSES_LIST_CLEANUP_ERR: 'CLIENT_STATUSES_LIST_CLEANUP_ERR',

  clientStatusesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_STATUSES_LIST_CLEANUP_BEGIN,
    };
  },

  clientStatusesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_STATUSES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientStatusesListCleanupErr: err => {
    return {
      type: actions.CLIENT_STATUSES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  