const actions = {
  CARD_STATUSES_LIST_UPDATE_BEGIN: 'CARD_STATUSES_LIST_UPDATE_BEGIN',
  CARD_STATUSES_LIST_UPDATE_SUCCESS: 'CARD_STATUSES_LIST_UPDATE_SUCCESS',
  CARD_STATUSES_LIST_UPDATE_ERR: 'CARD_STATUSES_LIST_UPDATE_ERR',

  cardStatusesListUpdateBegin: () => {
    return {
      type: actions.CARD_STATUSES_LIST_UPDATE_BEGIN,
    };
  },

  cardStatusesListUpdateSuccess: data => {
    return {
      type: actions.CARD_STATUSES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cardStatusesListUpdateErr: err => {
    return {
      type: actions.CARD_STATUSES_LIST_UPDATE_ERR,
      err,
    };
  },

  CARD_STATUSES_LIST_CLEANUP_BEGIN: 'CARD_STATUSES_LIST_CLEANUP_BEGIN',
  CARD_STATUSES_LIST_CLEANUP_SUCCESS: 'CARD_STATUSES_LIST_CLEANUP_SUCCESS',
  CARD_STATUSES_LIST_CLEANUP_ERR: 'CARD_STATUSES_LIST_CLEANUP_ERR',

  cardStatusesListCleanupBegin: () => {
    return {
      type: actions.CARD_STATUSES_LIST_CLEANUP_BEGIN,
    };
  },

  cardStatusesListCleanupSuccess: () => {
    return {
      type: actions.CARD_STATUSES_LIST_CLEANUP_SUCCESS,
    };
  },

  cardStatusesListCleanupErr: err => {
    return {
      type: actions.CARD_STATUSES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  