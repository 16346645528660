import { Modal } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styled from 'styled-components';
import { logOut } from '../../redux/authentication/actionCreator';
import { userSaveAction } from '../../redux/users/actionCreator';
import { Button } from '../layout/buttons/buttons';

const TermsOfUseAgreement = Styled.div`
    height: 600px;
    overflow: auto;

    h1 {
        font-size: 17px;
    }
    p {
        font-size: 15px;
    }
`;

const TermsOfUseAgreementModal = ({ open = false, setIsOpen = () => {} }) => {
  const dispatch = useDispatch();
  const termsOfUseAgreementRef = useRef(null);

  const { identity, isSubmitting } = useSelector(state => {
    return {
      identity: state.auth.identity,
      isSubmitting: state.user.saving,
    };
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const handleScroll = useCallback(() => {
    const position = termsOfUseAgreementRef.current.scrollTop;

    if (position === 574) {
      setIsDisabled(false);
    }
  }, [termsOfUseAgreementRef]);

  useEffect(() => {
    const element = termsOfUseAgreementRef?.current;

    if (element) {
      element.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [termsOfUseAgreementRef.current, handleScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  // SignOut
  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const handleAgreementSubmit = () => {
    const data = {
      terms_of_use_agreement: true,
    };

    if (identity.data?.user?.id) {
      dispatch(
        userSaveAction({
          data,
          id: identity.data.user.id,
          onSuccess: () => {
            setIsOpen(false);
          },
        }),
      );
    }
  };

  return (
    <Modal
      title="Termos de Uso"
      width={1200}
      closable={false}
      open={open}
      // onCancel={onClose}
      footer={[
        <Button
          key="agree"
          type="primary"
          // disabled={isDisabled || identity.loading}
          disabled={identity.loading}
          loading={isSubmitting || identity.loading}
          onClick={handleAgreementSubmit}
        >
          Aceitar
        </Button>,
        <Button
          key="disagree"
          type="light"
          // disabled={isDisabled || identity.loading}
          disabled={identity.loading}
          loading={isSubmitting || identity.loading}
          onClick={SignOut}
        >
          Recusar
        </Button>,
      ]}
    >
      <TermsOfUseAgreement id="terms-of-use" ref={termsOfUseAgreementRef}>
        <h1>1. ACEITAÇÃO.</h1>
        <p>
          Bem-vindo ao Intranet da Secran. O presente termo de uso estabelece os termos e condições aplicáveis ao uso de
          referido sistema. Por favor, revise os termos de uso cuidadosamente antes de utilizá-lo. Ao acessar, navegar
          ou utilizar o sistema (&quot;uso&quot;), todos os usuários e visitantes (&quot;você&quot;, &quot;vocês&quot;,
          &quot;usuário&quot; ou &quot;usuários&quot;) declaram aceitar os termos e condições contidos neste documento.
          Caso você não concorde com os termos e condições aqui apresentados, por favor, não use o sistema. A Secran
          reserva o direito de atualizar os termos de uso periodicamente.
        </p>

        <h1>2. ELIGIBILIDADE.</h1>
        <p>
          O sistema e todos os serviços a ele relacionados são disponibilizados a você somente se você puder celebrar
          contratos legalmente válidos sob a legislação aplicável. O uso não está disponível para menores de 18 anos.
        </p>
        <p>AO USAR O SISTEMA, VOCÊ DECLARA QUE TEM CAPACIDADE JURÍDICA PLENA, SENDO MAIOR DE 18 ANOS DE IDADE.</p>

        <h1>3. LICENÇA.</h1>
        <p>
          A Secran concede a você uma licença limitada, intransferível, revogável e não passível de cessão, autorizando
          o acesso exclusivamente para uso contratualmente estabelecido. Todos os materiais exibidos ou disponibilizados
          no sistema são de propriedade exclusiva da Secran, os quais reservam a si todos os seus direitos de
          propriedade intelectual. Os materiais são protegidos pelas leis de direitos autorais e outras regras,
          regulamentos e legislações brasileiras, americanas e internacionais de propriedade intelectual aplicáveis.
        </p>

        <h1>4. PROIBIÇÕES GERAIS.</h1>
        <p>Com relação ao uso, ao aceitar os termos aqui expostos, você concorda em NÃO:</p>
        <ol>
          <li>
            violar quaisquer regras, regulamento ou legislações aplicáveis, incluindo, sem limitação, legislações e
            regulamentos de exportação, leis contra discriminação ou leis de igualdade de oportunidade de emprego.{' '}
          </li>
          <li>
            violar quaisquer direitos de propriedade intelectual ou de privacidade, incluindo, sem limitação, patentes,
            direitos autorais, marcas ou segredos comerciais de quaisquer terceiros, incluindo a Secran e suas empresas
            afiliadas;{' '}
          </li>
          <li>violar ou tentar violar quaisquer medidas de segurança do sistema; </li>
          <li>
            usar quaisquer dispositivos, processos ou mecanismos para monitorar, recuperar, pesquisar ou acessar dados
            sem o consentimento prévio e por escrito da Secran;
          </li>
          <li>V. acessar ou tentar acessar qualquer conta ou identificação de terceiros; </li>
          <li>
            copiar, modificar, reproduzir, apagar, distribuir, realizar download, armazenar, transmitir, vender,
            revender, publicar, realizar engenharia reversa ou criar obras derivadas de quaisquer materiais aqui
            inseridos, exceto materiais que tenham sido submetidos por você e sejam de sua propriedade;{' '}
          </li>
          <li>publicar ou submeter qualquer informação imprecisa, falsa ou incompleta; </li>
          <li>passar-se por outra pessoa ou organização; </li>
          <li>fornecer falsa informação sobre você, sua afiliação junto a um terceiro ou sua organização.</li>
        </ol>
        <h1>5. USOS ESPECÍFICOS E RESPONSABILIDADES.</h1>
        <p>
          Além das responsabilidades gerais previstas na cláusula 4, você concorda em cumprir com os seguintes termos:
        </p>
        <ol>
          <li>usar o sistema somente para finalidades legítimas e já estruturadas neste; </li>
          <li>
            fornecer e manter as informações submetidas por você completas, corretas, atualizadas e precisas,
            permitindo, assim, a correta e devida identificação do usuário.{' '}
          </li>
        </ol>

        <h1>6. CONTA E SENHA.</h1>
        <p>
          O sistema poderá apresentar a você a oportunidade de abrir uma conta de usuário. Ao abrir uma conta no
          sistema, você poderá receber uma identificação de conta (login) e uma senha inicial. É de sua responsabilidade
          (a) manter o sigilo de sua identificação de conta e senha; (b) atualizar e revisar sua conta frequentemente; e
          (c) avisar prontamente a Secran caso ocorra qualquer utilização não autorizada de sua conta ou qualquer quebra
          de segurança.
        </p>
        <p>
          A Secran se exime de qualquer responsabilidade por danos oriundos, unicamente, da atuação exclusiva do usuário
        </p>

        <h1>7. DISPOSIÇÕES GERAIS.</h1>
        <p>
          Você concorda que não se constitui qualquer relação de emprego, parceria, representação, agência ou joint
          venture entre você e a Secran com resultado do seu uso do sistema. Este Termo de Uso constitui o acordo
          integral entre você e a Secran com relação ao seu uso do sistema, funcionando como adicional a qualquer
          contrato celebrado entra as partes aqui mencionadas.
        </p>
      </TermsOfUseAgreement>
    </Modal>
  );
};

export default TermsOfUseAgreementModal;
