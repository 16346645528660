const actions = {
  EMAIL_SENTS_UPDATE_BEGIN: 'EMAIL_SENTS_UPDATE_BEGIN',
  EMAIL_SENTS_UPDATE_SUCCESS: 'EMAIL_SENTS_UPDATE_SUCCESS',
  EMAIL_SENTS_UPDATE_ERR: 'EMAIL_SENTS_UPDATE_ERR',

  emailSentsUpdateBegin: () => {
    return {
      type: actions.EMAIL_SENTS_UPDATE_BEGIN,
    };
  },

  emailSentsUpdateSuccess: data => {
    return {
      type: actions.EMAIL_SENTS_UPDATE_SUCCESS,
      data,
    };
  },

  emailSentsUpdateErr: err => {
    return {
      type: actions.EMAIL_SENTS_UPDATE_ERR,
      err,
    };
  },

  EMAIL_SENTS_CLEANUP_BEGIN: 'EMAIL_SENTS_CLEANUP_BEGIN',
  EMAIL_SENTS_CLEANUP_SUCCESS: 'EMAIL_SENTS_CLEANUP_SUCCESS',
  EMAIL_SENTS_CLEANUP_ERR: 'EMAIL_SENTS_CLEANUP_ERR',

  emailSentsCleanupBegin: () => {
    return {
      type: actions.EMAIL_SENTS_CLEANUP_BEGIN,
    };
  },

  emailSentsCleanupSuccess: () => {
    return {
      type: actions.EMAIL_SENTS_CLEANUP_SUCCESS,
    };
  },

  emailSentsCleanupErr: err => {
    return {
      type: actions.EMAIL_SENTS_CLEANUP_ERR,
      err,
    };
  },

  EMAIL_SENT_DELETE_BEGIN: 'EMAIL_SENT_DELETE_BEGIN',
  EMAIL_SENT_DELETE_SUCCESS: 'EMAIL_SENT_DELETE_SUCCESS',
  EMAIL_SENT_DELETE_ERR: 'EMAIL_SENT_DELETE_ERR',

  emailSentDeleteBegin: () => {
    return {
      type: actions.EMAIL_SENT_DELETE_BEGIN,
    };
  },

  emailSentDeleteSuccess: data => {
    return {
      type: actions.EMAIL_SENT_DELETE_SUCCESS,
      data,
    };
  },

  emailSentDeleteErr: err => {
    return {
      type: actions.EMAIL_SENT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
