const actions = {
  USER_ACHIEVEMENTS_UPDATE_BEGIN: 'USER_ACHIEVEMENTS_UPDATE_BEGIN',
  USER_ACHIEVEMENTS_UPDATE_SUCCESS: 'USER_ACHIEVEMENTS_UPDATE_SUCCESS',
  USER_ACHIEVEMENTS_UPDATE_ERR: 'USER_ACHIEVEMENTS_UPDATE_ERR',

  userAchievementsUpdateBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENTS_UPDATE_BEGIN,
    };
  },

  userAchievementsUpdateSuccess: data => {
    return {
      type: actions.USER_ACHIEVEMENTS_UPDATE_SUCCESS,
      data,
    };
  },

  userAchievementsUpdateErr: err => {
    return {
      type: actions.USER_ACHIEVEMENTS_UPDATE_ERR,
      err,
    };
  },

  USER_ACHIEVEMENTS_CLEANUP_BEGIN: 'USER_ACHIEVEMENTS_CLEANUP_BEGIN',
  USER_ACHIEVEMENTS_CLEANUP_SUCCESS: 'USER_ACHIEVEMENTS_CLEANUP_SUCCESS',
  USER_ACHIEVEMENTS_CLEANUP_ERR: 'USER_ACHIEVEMENTS_CLEANUP_ERR',

  userAchievementsCleanupBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENTS_CLEANUP_BEGIN,
    };
  },

  userAchievementsCleanupSuccess: data => {
    return {
      type: actions.USER_ACHIEVEMENTS_CLEANUP_SUCCESS,
      data,
    };
  },

  userAchievementsCleanupErr: err => {
    return {
      type: actions.USER_ACHIEVEMENTS_CLEANUP_ERR,
      err,
    };
  },

  USER_ACHIEVEMENTS_LIST_UPDATE_BEGIN: 'USER_ACHIEVEMENTS_LIST_UPDATE_BEGIN',
  USER_ACHIEVEMENTS_LIST_UPDATE_SUCCESS: 'USER_ACHIEVEMENTS_LIST_UPDATE_SUCCESS',
  USER_ACHIEVEMENTS_LIST_UPDATE_ERR: 'USER_ACHIEVEMENTS_LIST_UPDATE_ERR',

  userAchievementsListUpdateBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENTS_LIST_UPDATE_BEGIN,
    };
  },

  userAchievementsListUpdateSuccess: data => {
    return {
      type: actions.USER_ACHIEVEMENTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  userAchievementsListUpdateErr: err => {
    return {
      type: actions.USER_ACHIEVEMENTS_LIST_UPDATE_ERR,
      err,
    };
  },

  USER_ACHIEVEMENTS_LIST_CLEANUP_BEGIN: 'USER_ACHIEVEMENTS_LIST_CLEANUP_BEGIN',
  USER_ACHIEVEMENTS_LIST_CLEANUP_SUCCESS: 'USER_ACHIEVEMENTS_LIST_CLEANUP_SUCCESS',
  USER_ACHIEVEMENTS_LIST_CLEANUP_ERR: 'USER_ACHIEVEMENTS_LIST_CLEANUP_ERR',

  userAchievementsListCleanupBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENTS_LIST_CLEANUP_BEGIN,
    };
  },

  userAchievementsListCleanupSuccess: () => {
    return {
      type: actions.USER_ACHIEVEMENTS_LIST_CLEANUP_SUCCESS,
    };
  },

  userAchievementsListCleanupErr: err => {
    return {
      type: actions.USER_ACHIEVEMENTS_LIST_CLEANUP_ERR,
      err,
    };
  },

  USER_ACHIEVEMENT_UPDATE_BEGIN: 'USER_ACHIEVEMENT_UPDATE_BEGIN',
  USER_ACHIEVEMENT_UPDATE_SUCCESS: 'USER_ACHIEVEMENT_UPDATE_SUCCESS',
  USER_ACHIEVEMENT_UPDATE_ERR: 'USER_ACHIEVEMENT_UPDATE_ERR',

  userAchievementUpdateBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENT_UPDATE_BEGIN,
    };
  },

  userAchievementUpdateSuccess: data => {
    return {
      type: actions.USER_ACHIEVEMENT_UPDATE_SUCCESS,
      data,
    };
  },

  userAchievementUpdateErr: err => {
    return {
      type: actions.USER_ACHIEVEMENT_UPDATE_ERR,
      err,
    };
  },

  USER_ACHIEVEMENT_CLEANUP_BEGIN: 'USER_ACHIEVEMENT_CLEANUP_BEGIN',
  USER_ACHIEVEMENT_CLEANUP_SUCCESS: 'USER_ACHIEVEMENT_CLEANUP_SUCCESS',
  USER_ACHIEVEMENT_CLEANUP_ERR: 'USER_ACHIEVEMENT_CLEANUP_ERR',

  userAchievementCleanupBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENT_CLEANUP_BEGIN,
    };
  },

  userAchievementCleanupSuccess: () => {
    return {
      type: actions.USER_ACHIEVEMENT_CLEANUP_SUCCESS,
    };
  },

  userAchievementCleanupErr: err => {
    return {
      type: actions.USER_ACHIEVEMENT_CLEANUP_ERR,
      err,
    };
  },

  USER_ACHIEVEMENT_SAVE_BEGIN: 'USER_ACHIEVEMENT_SAVE_BEGIN',
  USER_ACHIEVEMENT_SAVE_SUCCESS: 'USER_ACHIEVEMENT_SAVE_SUCCESS',
  USER_ACHIEVEMENT_SAVE_ERR: 'USER_ACHIEVEMENT_SAVE_ERR',

  userAchievementSaveBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENT_SAVE_BEGIN,
    };
  },

  userAchievementSaveSuccess: data => {
    return {
      type: actions.USER_ACHIEVEMENT_SAVE_SUCCESS,
      data,
    };
  },

  userAchievementSaveErr: err => {
    return {
      type: actions.USER_ACHIEVEMENT_SAVE_ERR,
      err,
    };
  },

  USER_ACHIEVEMENT_DELETE_BEGIN: 'USER_ACHIEVEMENT_DELETE_BEGIN',
  USER_ACHIEVEMENT_DELETE_SUCCESS: 'USER_ACHIEVEMENT_DELETE_SUCCESS',
  USER_ACHIEVEMENT_DELETE_ERR: 'USER_ACHIEVEMENT_DELETE_ERR',

  userAchievementDeleteBegin: () => {
    return {
      type: actions.USER_ACHIEVEMENT_DELETE_BEGIN,
    };
  },

  userAchievementDeleteSuccess: data => {
    return {
      type: actions.USER_ACHIEVEMENT_DELETE_SUCCESS,
      data,
    };
  },

  userAchievementDeleteErr: err => {
    return {
      type: actions.USER_ACHIEVEMENT_DELETE_ERR,
      err,
    };
  },
};

export default actions;
