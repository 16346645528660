const actions = {
  COUNTRIES_LIST_UPDATE_BEGIN: 'COUNTRIES_LIST_UPDATE_BEGIN',
  COUNTRIES_LIST_UPDATE_SUCCESS: 'COUNTRIES_LIST_UPDATE_SUCCESS',
  COUNTRIES_LIST_UPDATE_ERR: 'COUNTRIES_LIST_UPDATE_ERR',

  countriesListUpdateBegin: () => {
    return {
      type: actions.COUNTRIES_LIST_UPDATE_BEGIN,
    };
  },

  countriesListUpdateSuccess: data => {
    return {
      type: actions.COUNTRIES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  countriesListUpdateErr: err => {
    return {
      type: actions.COUNTRIES_LIST_UPDATE_ERR,
      err,
    };
  },

  COUNTRIES_LIST_CLEANUP_BEGIN: 'COUNTRIES_LIST_CLEANUP_BEGIN',
  COUNTRIES_LIST_CLEANUP_SUCCESS: 'COUNTRIES_LIST_CLEANUP_SUCCESS',
  COUNTRIES_LIST_CLEANUP_ERR: 'COUNTRIES_LIST_CLEANUP_ERR',

  countriesListCleanupBegin: () => {
    return {
      type: actions.COUNTRIES_LIST_CLEANUP_BEGIN,
    };
  },

  countriesListCleanupSuccess: () => {
    return {
      type: actions.COUNTRIES_LIST_CLEANUP_SUCCESS,
    };
  },

  countriesListCleanupErr: err => {
    return {
      type: actions.COUNTRIES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  