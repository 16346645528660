const actions = {
  ACHIEVEMENTS_UPDATE_BEGIN: 'ACHIEVEMENTS_UPDATE_BEGIN',
  ACHIEVEMENTS_UPDATE_SUCCESS: 'ACHIEVEMENTS_UPDATE_SUCCESS',
  ACHIEVEMENTS_UPDATE_ERR: 'ACHIEVEMENTS_UPDATE_ERR',

  achievementsUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENTS_UPDATE_BEGIN,
    };
  },

  achievementsUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENTS_UPDATE_SUCCESS,
      data,
    };
  },

  achievementsUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENTS_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENTS_CLEANUP_BEGIN: 'ACHIEVEMENTS_CLEANUP_BEGIN',
  ACHIEVEMENTS_CLEANUP_SUCCESS: 'ACHIEVEMENTS_CLEANUP_SUCCESS',
  ACHIEVEMENTS_CLEANUP_ERR: 'ACHIEVEMENTS_CLEANUP_ERR',

  achievementsCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENTS_CLEANUP_BEGIN,
    };
  },

  achievementsCleanupSuccess: data => {
    return {
      type: actions.ACHIEVEMENTS_CLEANUP_SUCCESS,
      data,
    };
  },

  achievementsCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENTS_CLEANUP_ERR,
      err,
    };
  },

  ACHIEVEMENTS_LIST_UPDATE_BEGIN: 'ACHIEVEMENTS_LIST_UPDATE_BEGIN',
  ACHIEVEMENTS_LIST_UPDATE_SUCCESS: 'ACHIEVEMENTS_LIST_UPDATE_SUCCESS',
  ACHIEVEMENTS_LIST_UPDATE_ERR: 'ACHIEVEMENTS_LIST_UPDATE_ERR',

  achievementsListUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENTS_LIST_UPDATE_BEGIN,
    };
  },

  achievementsListUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  achievementsListUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENTS_LIST_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENTS_LIST_CLEANUP_BEGIN: 'ACHIEVEMENTS_LIST_CLEANUP_BEGIN',
  ACHIEVEMENTS_LIST_CLEANUP_SUCCESS: 'ACHIEVEMENTS_LIST_CLEANUP_SUCCESS',
  ACHIEVEMENTS_LIST_CLEANUP_ERR: 'ACHIEVEMENTS_LIST_CLEANUP_ERR',

  achievementsListCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENTS_LIST_CLEANUP_BEGIN,
    };
  },

  achievementsListCleanupSuccess: () => {
    return {
      type: actions.ACHIEVEMENTS_LIST_CLEANUP_SUCCESS,
    };
  },

  achievementsListCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENTS_LIST_CLEANUP_ERR,
      err,
    };
  },

  ACHIEVEMENT_UPDATE_BEGIN: 'ACHIEVEMENT_UPDATE_BEGIN',
  ACHIEVEMENT_UPDATE_SUCCESS: 'ACHIEVEMENT_UPDATE_SUCCESS',
  ACHIEVEMENT_UPDATE_ERR: 'ACHIEVEMENT_UPDATE_ERR',

  achievementUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_UPDATE_BEGIN,
    };
  },

  achievementUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_UPDATE_SUCCESS,
      data,
    };
  },

  achievementUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_CLEANUP_BEGIN: 'ACHIEVEMENT_CLEANUP_BEGIN',
  ACHIEVEMENT_CLEANUP_SUCCESS: 'ACHIEVEMENT_CLEANUP_SUCCESS',
  ACHIEVEMENT_CLEANUP_ERR: 'ACHIEVEMENT_CLEANUP_ERR',

  achievementCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENT_CLEANUP_BEGIN,
    };
  },

  achievementCleanupSuccess: () => {
    return {
      type: actions.ACHIEVEMENT_CLEANUP_SUCCESS,
    };
  },

  achievementCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENT_CLEANUP_ERR,
      err,
    };
  },

  ACHIEVEMENT_SAVE_BEGIN: 'ACHIEVEMENT_SAVE_BEGIN',
  ACHIEVEMENT_SAVE_SUCCESS: 'ACHIEVEMENT_SAVE_SUCCESS',
  ACHIEVEMENT_SAVE_ERR: 'ACHIEVEMENT_SAVE_ERR',

  achievementSaveBegin: () => {
    return {
      type: actions.ACHIEVEMENT_SAVE_BEGIN,
    };
  },

  achievementSaveSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_SAVE_SUCCESS,
      data,
    };
  },

  achievementSaveErr: err => {
    return {
      type: actions.ACHIEVEMENT_SAVE_ERR,
      err,
    };
  },

  ACHIEVEMENT_DELETE_BEGIN: 'ACHIEVEMENT_DELETE_BEGIN',
  ACHIEVEMENT_DELETE_SUCCESS: 'ACHIEVEMENT_DELETE_SUCCESS',
  ACHIEVEMENT_DELETE_ERR: 'ACHIEVEMENT_DELETE_ERR',

  achievementDeleteBegin: () => {
    return {
      type: actions.ACHIEVEMENT_DELETE_BEGIN,
    };
  },

  achievementDeleteSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_DELETE_SUCCESS,
      data,
    };
  },

  achievementDeleteErr: err => {
    return {
      type: actions.ACHIEVEMENT_DELETE_ERR,
      err,
    };
  },

  /**
   * IMAGE
   */
  ACHIEVEMENT_IMAGE_UPDATE_BEGIN: 'ACHIEVEMENT_IMAGE_UPDATE_BEGIN',
  ACHIEVEMENT_IMAGE_UPDATE_SUCCESS: 'ACHIEVEMENT_IMAGE_UPDATE_SUCCESS',
  ACHIEVEMENT_IMAGE_UPDATE_ERR: 'ACHIEVEMENT_IMAGE_UPDATE_ERR',

  achievementImageUpdateBegin: () => {
    return {
      type: actions.ACHIEVEMENT_IMAGE_UPDATE_BEGIN,
    };
  },

  achievementImageUpdateSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_IMAGE_UPDATE_SUCCESS,
      data,
    };
  },

  achievementImageUpdateErr: err => {
    return {
      type: actions.ACHIEVEMENT_IMAGE_UPDATE_ERR,
      err,
    };
  },

  ACHIEVEMENT_IMAGE_CLEANUP_BEGIN: 'ACHIEVEMENT_IMAGE_CLEANUP_BEGIN',
  ACHIEVEMENT_IMAGE_CLEANUP_SUCCESS: 'ACHIEVEMENT_IMAGE_CLEANUP_SUCCESS',
  ACHIEVEMENT_IMAGE_CLEANUP_ERR: 'ACHIEVEMENT_IMAGE_CLEANUP_ERR',

  achievementImageCleanupBegin: () => {
    return {
      type: actions.ACHIEVEMENT_IMAGE_CLEANUP_BEGIN,
    };
  },

  achievementImageCleanupSuccess: data => {
    return {
      type: actions.ACHIEVEMENT_IMAGE_CLEANUP_SUCCESS,
      data,
    };
  },

  achievementImageCleanupErr: err => {
    return {
      type: actions.ACHIEVEMENT_IMAGE_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
