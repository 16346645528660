const actions = {
  CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_ERR: 'CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_ERR',

  clientContractItemTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientContractItemTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientContractItemTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_CONTRACT_ITEM_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_ERR: 'CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_ERR',

  clientContractItemTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientContractItemTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientContractItemTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_CONTRACT_ITEM_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
