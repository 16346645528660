const CONSTANTS = {
  // ACHIEVEMENTS
  ACHIEVEMENT: {
    RATINGS: ['Comum', 'Raro', 'Épico'],
  },

  //
  CARD_DATE_FIELD_FILTER: {
    DUE_DATE: 'Cards.due_date',
    CREATED_AT: 'Cards.created_at',
  },

  CARD_PERMISSIONS_POLICY: {
    MINE: 1,
    TEAM: 2,
    ALL: 3,
    CREATED_BY_ME: 4,
    CREATED_BY_TEAM: 5,
  },

  CARD_STATUS: {
    PENDENTE: 1,
    EM_ANDAMENTO: 2,
    CONCLUIDO: 3,
    SEM_MOVIMENTO: 4,
  },

  CARD_DUE_DATE_STATUS: {
    EM_BREVE: 1,
    HOJE: 2,
    ATRASADO: 3,
    JUSTIFICADO: 4,
  },

  CARD_DONE_STATUS: {
    NO_PRAZO: 1,
    JUSTIFICADO: 2,
    FORA_DO_PRAZO: 3,
  },

  CARD_JUSTIFICATION_STATUS: {
    PENDENTE: 1,
    REPROVADO: 2,
    APROVADO: 3,
  },

  CARD_JUSTIFICATION_REASON: {
    OUTRO: 6,
  },

  CARD_TEMPLATE_RECURRENCE: {
    DIARIO: 1,
    MENSAL: 2,
    TRIMESTRAL: 3,
    ANUAL: 4,
  },

  CARD_TEMPLATE_NON_BUSINESS_DAY_RULE: {
    ANTECIPAR: 1,
    MANTER: 2,
    ADIAR: 3,
  },

  CARD_TEMPLATE_BUILD_ACTION: {
    FORCE_ADD: 1,
    ADD_IF_EMPTY_OR_UPDATE_PENDING: 2,
  },

  CARD_TEMPLATE_TRANSFER_ACTION: {
    ADD: 1,
    CLEAR_ADD: 2,
    DELETE: 3,
  },

  CARD_TYPE: {
    TASK: 1,
    TICKET: 2,
  },

  CLIENT: {
    SECRAN: 770,
  },

  CLIENT_STATUS: {
    ATIVO: 1,
    BAIXADO: 2,
    RESCINDIDO: 3,
    SUSPENSO: 4,
    EM_CONSTITUICAO: 5,
    EVENTUAL: 6,
  },

  CLIENT_TYPE: {
    PESSOA_FISICA: 1,
    PESSOA_JURIDICA: 2,
  },

  DEPARTMENT: {
    FISCAL: 1,
    CONTABIL: 2,
    PESSOAL: 3,
    IMPLANTACAO: 4,
    ARQUIVOS: 5,
    TRIBUTOS: 6,
    CONSULTORIA: 7,
    DIRETORIA: 8,
    DESENVOLVIMENTO: 9,
    PARALEGAL: 10,
    RECEPCAO: 11,
    FINANCEIRO: 12,
    RECURSOS_HUMANOS: 13,
    RELACIONAMENTO: 14,
    GESTAO: 15,
    PROJETOS: 16,
    MARKETING: 17,
    AUDITORIA: 18,
    SUPORTE_TI: 19,
  },

  FILE_POLICY: {
    ALL: 1,
    CREATED_BY_ME: 2,
  },

  NOTIFICATION_TYPE: {
    ERROR: 3,
    WARNING: 2,
    INFO: 1,
  },

  PROFILE: {
    DESENVOLVEDOR: 1,
    DIRETOR: 2,
    COORDENADOR: 3,
    SUPERVISOR: 4,
    ANALISTA: 5,
    EXECUTOR: 6,
    ATENDENTE: 7,
    CLIENTE: 8,
    GESTOR: 9,
    ESTAGIARIO: 10,
  },

  QUESTION_TYPE: {
    TEXT: 1,
    TEXTAREA: 2,
    INTEGER: 3,
    DECIMAL: 4,
    MONEY: 5,
    DATE: 6,
    TIME: 7,
    CHECKBOX: 8,
    PERCENTAGE: 9,
    RATING: 10,
    BOOLEAN: 11,
  },

  QUEUED_JOB_TASK: {
    CARDS_BUILD: 'CardsBuild',
    SEFAZ_CE_IMPORT: 'SefazCeImport',
  },

  QUEUED_JOB_PROGRESS_STATUS: {
    PENDING: 'pending',
    WORKING: 'working',
    DONE: 'done',
    ERROR: 'error',
  },

  USER_EMPLOYEE_TRANSFER_ACTION: {
    ADD: 1,
    CLEAR_ADD: 2,
    DELETE: 3,
    OVERWRITE: 4,
  },

  USER: {
    SECRAN: 1,
  },

  USER_STATUS: {
    ATIVO: 1,
    INATIVO: 2,
  },
};

Object.assign(CONSTANTS, {
  COLABORADORES_PROFILES: [
    CONSTANTS.PROFILE.GESTOR,
    CONSTANTS.PROFILE.ESTAGIARIO,
    CONSTANTS.PROFILE.ATENDENTE,
    CONSTANTS.PROFILE.EXECUTOR,
    CONSTANTS.PROFILE.ANALISTA,
    CONSTANTS.PROFILE.SUPERVISOR,
    CONSTANTS.PROFILE.COORDENADOR,
  ],
});

export default CONSTANTS;
