const actions = {
  CLIENT_TAX_BREAKS_UPDATE_BEGIN: 'CLIENT_TAX_BREAKS_UPDATE_BEGIN',
  CLIENT_TAX_BREAKS_UPDATE_SUCCESS: 'CLIENT_TAX_BREAKS_UPDATE_SUCCESS',
  CLIENT_TAX_BREAKS_UPDATE_ERR: 'CLIENT_TAX_BREAKS_UPDATE_ERR',

  clientTaxBreaksUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAKS_UPDATE_BEGIN,
    };
  },

  clientTaxBreaksUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAX_BREAKS_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxBreaksUpdateErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAKS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAX_BREAKS_CLEANUP_BEGIN: 'CLIENT_TAX_BREAKS_CLEANUP_BEGIN',
  CLIENT_TAX_BREAKS_CLEANUP_SUCCESS: 'CLIENT_TAX_BREAKS_CLEANUP_SUCCESS',
  CLIENT_TAX_BREAKS_CLEANUP_ERR: 'CLIENT_TAX_BREAKS_CLEANUP_ERR',

  clientTaxBreaksCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAKS_CLEANUP_BEGIN,
    };
  },

  clientTaxBreaksCleanupSuccess: data => {
    return {
      type: actions.CLIENT_TAX_BREAKS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientTaxBreaksCleanupErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAKS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_TAX_BREAK_UPDATE_BEGIN: 'CLIENT_TAX_BREAK_UPDATE_BEGIN',
  CLIENT_TAX_BREAK_UPDATE_SUCCESS: 'CLIENT_TAX_BREAK_UPDATE_SUCCESS',
  CLIENT_TAX_BREAK_UPDATE_ERR: 'CLIENT_TAX_BREAK_UPDATE_ERR',

  clientTaxBreakUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_UPDATE_BEGIN,
    };
  },

  clientTaxBreakUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAX_BREAK_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxBreakUpdateErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAK_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAX_BREAK_CLEANUP_BEGIN: 'CLIENT_TAX_BREAK_CLEANUP_BEGIN',
  CLIENT_TAX_BREAK_CLEANUP_SUCCESS: 'CLIENT_TAX_BREAK_CLEANUP_SUCCESS',
  CLIENT_TAX_BREAK_CLEANUP_ERR: 'CLIENT_TAX_BREAK_CLEANUP_ERR',

  clientTaxBreakCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_CLEANUP_BEGIN,
    };
  },

  clientTaxBreakCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_CLEANUP_SUCCESS,
    };
  },

  clientTaxBreakCleanupErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAK_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_TAX_BREAK_SAVE_BEGIN: 'CLIENT_TAX_BREAK_SAVE_BEGIN',
  CLIENT_TAX_BREAK_SAVE_SUCCESS: 'CLIENT_TAX_BREAK_SAVE_SUCCESS',
  CLIENT_TAX_BREAK_SAVE_ERR: 'CLIENT_TAX_BREAK_SAVE_ERR',

  clientTaxBreakSaveBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_SAVE_BEGIN,
    };
  },

  clientTaxBreakSaveSuccess: data => {
    return {
      type: actions.CLIENT_TAX_BREAK_SAVE_SUCCESS,
      data,
    };
  },

  clientTaxBreakSaveErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAK_SAVE_ERR,
      err,
    };
  },

  CLIENT_TAX_BREAK_DELETE_BEGIN: 'CLIENT_TAX_BREAK_DELETE_BEGIN',
  CLIENT_TAX_BREAK_DELETE_SUCCESS: 'CLIENT_TAX_BREAK_DELETE_SUCCESS',
  CLIENT_TAX_BREAK_DELETE_ERR: 'CLIENT_TAX_BREAK_DELETE_ERR',

  clientTaxBreakDeleteBegin: () => {
    return {
      type: actions.CLIENT_TAX_BREAK_DELETE_BEGIN,
    };
  },

  clientTaxBreakDeleteSuccess: data => {
    return {
      type: actions.CLIENT_TAX_BREAK_DELETE_SUCCESS,
      data,
    };
  },

  clientTaxBreakDeleteErr: err => {
    return {
      type: actions.CLIENT_TAX_BREAK_DELETE_ERR,
      err,
    };
  },
};

export default actions;
