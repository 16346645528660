import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_BEGIN,
  CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_SUCCESS,
  CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_ERR,

  CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_BEGIN,
  CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_SUCCESS,
  CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_ERR,
} = actions;

const cardTemplateRecurrencesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CARD_TEMPLATE_RECURRENCES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case CARD_TEMPLATE_RECURRENCES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default cardTemplateRecurrencesListReducer;
