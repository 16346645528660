const actions = {
  CLIENT_CARD_TEMPLATES_REPORT_UPDATE_BEGIN: 'CLIENT_CARD_TEMPLATES_REPORT_UPDATE_BEGIN',
  CLIENT_CARD_TEMPLATES_REPORT_UPDATE_SUCCESS: 'CLIENT_CARD_TEMPLATES_REPORT_UPDATE_SUCCESS',
  CLIENT_CARD_TEMPLATES_REPORT_UPDATE_ERR: 'CLIENT_CARD_TEMPLATES_REPORT_UPDATE_ERR',

  clientCardTemplatesReportUpdateBegin: () => {
    return {
      type: actions.CLIENT_CARD_TEMPLATES_REPORT_UPDATE_BEGIN,
    };
  },

  clientCardTemplatesReportUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CARD_TEMPLATES_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  clientCardTemplatesReportUpdateErr: err => {
    return {
      type: actions.CLIENT_CARD_TEMPLATES_REPORT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_BEGIN: 'CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_BEGIN',
  CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_SUCCESS: 'CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_SUCCESS',
  CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_ERR: 'CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_ERR',

  clientCardTemplatesReportCleanupBegin: () => {
    return {
      type: actions.CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_BEGIN,
    };
  },

  clientCardTemplatesReportCleanupSuccess: data => {
    return {
      type: actions.CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_SUCCESS,
      data,
    };
  },

  clientCardTemplatesReportCleanupErr: err => {
    return {
      type: actions.CLIENT_CARD_TEMPLATES_REPORT_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
