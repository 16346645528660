import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  QUEUED_JOBS_PAGINATION_UPDATE_SUCCESS,
  QUEUED_JOBS_FILTERS_UPDATE_SUCCESS,
  QUEUED_JOBS_SORTER_UPDATE_SUCCESS,

  QUEUED_JOBS_UPDATE_BEGIN,
  QUEUED_JOBS_UPDATE_SUCCESS,
  QUEUED_JOBS_UPDATE_ERR,

  QUEUED_JOBS_CLEANUP_BEGIN,
  QUEUED_JOBS_CLEANUP_SUCCESS,
  QUEUED_JOBS_CLEANUP_ERR,

  QUEUED_JOB_DELETE_BEGIN,
  QUEUED_JOB_DELETE_SUCCESS,
  QUEUED_JOB_DELETE_ERR,
} = actions;

const queuedJobsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QUEUED_JOBS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case QUEUED_JOBS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case QUEUED_JOBS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case QUEUED_JOBS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOBS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case QUEUED_JOBS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case QUEUED_JOBS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOBS_CLEANUP_SUCCESS:
      return initialIndexState;

    case QUEUED_JOBS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case QUEUED_JOB_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case QUEUED_JOB_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case QUEUED_JOB_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialChartState = {
  data: [],
  loading: false,
  error: null,
};

const {
  QUEUED_JOBS_CHARTS_UPDATE_BEGIN,
  QUEUED_JOBS_CHARTS_UPDATE_SUCCESS,
  QUEUED_JOBS_CHARTS_UPDATE_ERR,

  QUEUED_JOBS_CHARTS_CLEANUP_BEGIN,
  QUEUED_JOBS_CHARTS_CLEANUP_SUCCESS,
  QUEUED_JOBS_CHARTS_CLEANUP_ERR,
} = actions;

const queuedJobsChartsReducer = (state = initialChartState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QUEUED_JOBS_CHARTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOBS_CHARTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case QUEUED_JOBS_CHARTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case QUEUED_JOBS_CHARTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOBS_CHARTS_CLEANUP_SUCCESS:
      return initialChartState;

    case QUEUED_JOBS_CHARTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  QUEUED_JOBS_LIST_UPDATE_BEGIN,
  QUEUED_JOBS_LIST_UPDATE_SUCCESS,
  QUEUED_JOBS_LIST_UPDATE_ERR,

  QUEUED_JOBS_LIST_CLEANUP_BEGIN,
  QUEUED_JOBS_LIST_CLEANUP_SUCCESS,
  QUEUED_JOBS_LIST_CLEANUP_ERR,
} = actions;

const queuedJobsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QUEUED_JOBS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOBS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case QUEUED_JOBS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case QUEUED_JOBS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOBS_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case QUEUED_JOBS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  QUEUED_JOB_UPDATE_BEGIN,
  QUEUED_JOB_UPDATE_SUCCESS,
  QUEUED_JOB_UPDATE_ERR,

  QUEUED_JOB_CLEANUP_BEGIN,
  QUEUED_JOB_CLEANUP_SUCCESS,
  QUEUED_JOB_CLEANUP_ERR,

  QUEUED_JOB_SAVE_BEGIN,
  QUEUED_JOB_SAVE_SUCCESS,
  QUEUED_JOB_SAVE_ERR,
} = actions;

const queuedJobReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QUEUED_JOB_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOB_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case QUEUED_JOB_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case QUEUED_JOB_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUEUED_JOB_CLEANUP_SUCCESS:
      return initialSingleState;

    case QUEUED_JOB_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case QUEUED_JOB_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case QUEUED_JOB_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case QUEUED_JOB_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { queuedJobsReducer, queuedJobsChartsReducer, queuedJobsListReducer, queuedJobReducer };
