const actions = {
  CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_BEGIN: 'CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_BEGIN',
  CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_SUCCESS: 'CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_SUCCESS',
  CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_ERR: 'CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_ERR',

  clientCnpjEmployeeUnionsListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_BEGIN,
    };
  },

  clientCnpjEmployeeUnionsListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjEmployeeUnionsListUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_BEGIN: 'CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_BEGIN',
  CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_SUCCESS: 'CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_SUCCESS',
  CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_ERR: 'CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_ERR',

  clientCnpjEmployeeUnionsListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_BEGIN,
    };
  },

  clientCnpjEmployeeUnionsListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientCnpjEmployeeUnionsListCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_EMPLOYEE_UNIONS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  