const actions = {
  QUEUED_JOBS_PAGINATION_UPDATE_BEGIN: 'QUEUED_JOBS_PAGINATION_UPDATE_BEGIN',
  QUEUED_JOBS_PAGINATION_UPDATE_SUCCESS: 'QUEUED_JOBS_PAGINATION_UPDATE_SUCCESS',
  QUEUED_JOBS_PAGINATION_UPDATE_ERR: 'QUEUED_JOBS_PAGINATION_UPDATE_ERR',

  queuedJobsPaginationUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOBS_PAGINATION_UPDATE_BEGIN,
    };
  },

  queuedJobsPaginationUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOBS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobsPaginationUpdateErr: err => {
    return {
      type: actions.QUEUED_JOBS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOBS_FILTERS_UPDATE_BEGIN: 'QUEUED_JOBS_FILTERS_UPDATE_BEGIN',
  QUEUED_JOBS_FILTERS_UPDATE_SUCCESS: 'QUEUED_JOBS_FILTERS_UPDATE_SUCCESS',
  QUEUED_JOBS_FILTERS_UPDATE_ERR: 'QUEUED_JOBS_FILTERS_UPDATE_ERR',

  queuedJobsFiltersUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOBS_FILTERS_UPDATE_BEGIN,
    };
  },

  queuedJobsFiltersUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOBS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobsFiltersUpdateErr: err => {
    return {
      type: actions.QUEUED_JOBS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOBS_SORTER_UPDATE_BEGIN: 'QUEUED_JOBS_SORTER_UPDATE_BEGIN',
  QUEUED_JOBS_SORTER_UPDATE_SUCCESS: 'QUEUED_JOBS_SORTER_UPDATE_SUCCESS',
  QUEUED_JOBS_SORTER_UPDATE_ERR: 'QUEUED_JOBS_SORTER_UPDATE_ERR',

  queuedJobsSorterUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOBS_SORTER_UPDATE_BEGIN,
    };
  },

  queuedJobsSorterUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOBS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobsSorterUpdateErr: err => {
    return {
      type: actions.QUEUED_JOBS_SORTER_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOBS_UPDATE_BEGIN: 'QUEUED_JOBS_UPDATE_BEGIN',
  QUEUED_JOBS_UPDATE_SUCCESS: 'QUEUED_JOBS_UPDATE_SUCCESS',
  QUEUED_JOBS_UPDATE_ERR: 'QUEUED_JOBS_UPDATE_ERR',

  queuedJobsUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOBS_UPDATE_BEGIN,
    };
  },

  queuedJobsUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOBS_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobsUpdateErr: err => {
    return {
      type: actions.QUEUED_JOBS_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOBS_CLEANUP_BEGIN: 'QUEUED_JOBS_CLEANUP_BEGIN',
  QUEUED_JOBS_CLEANUP_SUCCESS: 'QUEUED_JOBS_CLEANUP_SUCCESS',
  QUEUED_JOBS_CLEANUP_ERR: 'QUEUED_JOBS_CLEANUP_ERR',

  queuedJobsCleanupBegin: () => {
    return {
      type: actions.QUEUED_JOBS_CLEANUP_BEGIN,
    };
  },

  queuedJobsCleanupSuccess: () => {
    return {
      type: actions.QUEUED_JOBS_CLEANUP_SUCCESS,
    };
  },

  queuedJobsCleanupErr: err => {
    return {
      type: actions.QUEUED_JOBS_CLEANUP_ERR,
      err,
    };
  },

  QUEUED_JOBS_CHARTS_UPDATE_BEGIN: 'QUEUED_JOBS_CHARTS_UPDATE_BEGIN',
  QUEUED_JOBS_CHARTS_UPDATE_SUCCESS: 'QUEUED_JOBS_CHARTS_UPDATE_SUCCESS',
  QUEUED_JOBS_CHARTS_UPDATE_ERR: 'QUEUED_JOBS_CHARTS_UPDATE_ERR',

  queuedJobsChartsUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOBS_CHARTS_UPDATE_BEGIN,
    };
  },

  queuedJobsChartsUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOBS_CHARTS_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobsChartsUpdateErr: err => {
    return {
      type: actions.QUEUED_JOBS_CHARTS_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOBS_CHARTS_CLEANUP_BEGIN: 'QUEUED_JOBS_CHARTS_CLEANUP_BEGIN',
  QUEUED_JOBS_CHARTS_CLEANUP_SUCCESS: 'QUEUED_JOBS_CHARTS_CLEANUP_SUCCESS',
  QUEUED_JOBS_CHARTS_CLEANUP_ERR: 'QUEUED_JOBS_CHARTS_CLEANUP_ERR',

  queuedJobsChartsCleanupBegin: () => {
    return {
      type: actions.QUEUED_JOBS_CHARTS_CLEANUP_BEGIN,
    };
  },

  queuedJobsChartsCleanupSuccess: () => {
    return {
      type: actions.QUEUED_JOBS_CHARTS_CLEANUP_SUCCESS,
    };
  },

  queuedJobsChartsCleanupErr: err => {
    return {
      type: actions.QUEUED_JOBS_CHARTS_CLEANUP_ERR,
      err,
    };
  },

  QUEUED_JOB_DELETE_BEGIN: 'QUEUED_JOB_DELETE_BEGIN',
  QUEUED_JOB_DELETE_SUCCESS: 'QUEUED_JOB_DELETE_SUCCESS',
  QUEUED_JOB_DELETE_ERR: 'QUEUED_JOB_DELETE_ERR',

  queuedJobDeleteBegin: () => {
    return {
      type: actions.QUEUED_JOB_DELETE_BEGIN,
    };
  },

  queuedJobDeleteSuccess: data => {
    return {
      type: actions.QUEUED_JOB_DELETE_SUCCESS,
      data,
    };
  },

  queuedJobDeleteErr: err => {
    return {
      type: actions.QUEUED_JOB_DELETE_ERR,
      err,
    };
  },

  QUEUED_JOB_UPDATE_BEGIN: 'QUEUED_JOB_UPDATE_BEGIN',
  QUEUED_JOB_UPDATE_SUCCESS: 'QUEUED_JOB_UPDATE_SUCCESS',
  QUEUED_JOB_UPDATE_ERR: 'QUEUED_JOB_UPDATE_ERR',

  queuedJobUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOB_UPDATE_BEGIN,
    };
  },

  queuedJobUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOB_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobUpdateErr: err => {
    return {
      type: actions.QUEUED_JOB_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOB_CLEANUP_BEGIN: 'QUEUED_JOB_CLEANUP_BEGIN',
  QUEUED_JOB_CLEANUP_SUCCESS: 'QUEUED_JOB_CLEANUP_SUCCESS',
  QUEUED_JOB_CLEANUP_ERR: 'QUEUED_JOB_CLEANUP_ERR',

  queuedJobCleanupBegin: () => {
    return {
      type: actions.QUEUED_JOB_CLEANUP_BEGIN,
    };
  },

  queuedJobCleanupSuccess: () => {
    return {
      type: actions.QUEUED_JOB_CLEANUP_SUCCESS,
    };
  },

  queuedJobCleanupErr: err => {
    return {
      type: actions.QUEUED_JOB_CLEANUP_ERR,
      err,
    };
  },

  QUEUED_JOB_SAVE_BEGIN: 'QUEUED_JOB_SAVE_BEGIN',
  QUEUED_JOB_SAVE_SUCCESS: 'QUEUED_JOB_SAVE_SUCCESS',
  QUEUED_JOB_SAVE_ERR: 'QUEUED_JOB_SAVE_ERR',

  queuedJobSaveBegin: () => {
    return {
      type: actions.QUEUED_JOB_SAVE_BEGIN,
    };
  },

  queuedJobSaveSuccess: data => {
    return {
      type: actions.QUEUED_JOB_SAVE_SUCCESS,
      data,
    };
  },

  queuedJobSaveErr: err => {
    return {
      type: actions.QUEUED_JOB_SAVE_ERR,
      err,
    };
  },

  QUEUED_JOBS_LIST_UPDATE_BEGIN: 'QUEUED_JOBS_LIST_UPDATE_BEGIN',
  QUEUED_JOBS_LIST_UPDATE_SUCCESS: 'QUEUED_JOBS_LIST_UPDATE_SUCCESS',
  QUEUED_JOBS_LIST_UPDATE_ERR: 'QUEUED_JOBS_LIST_UPDATE_ERR',

  queuedJobsListUpdateBegin: () => {
    return {
      type: actions.QUEUED_JOBS_LIST_UPDATE_BEGIN,
    };
  },

  queuedJobsListUpdateSuccess: data => {
    return {
      type: actions.QUEUED_JOBS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  queuedJobsListUpdateErr: err => {
    return {
      type: actions.QUEUED_JOBS_LIST_UPDATE_ERR,
      err,
    };
  },

  QUEUED_JOBS_LIST_CLEANUP_BEGIN: 'QUEUED_JOBS_LIST_CLEANUP_BEGIN',
  QUEUED_JOBS_LIST_CLEANUP_SUCCESS: 'QUEUED_JOBS_LIST_CLEANUP_SUCCESS',
  QUEUED_JOBS_LIST_CLEANUP_ERR: 'QUEUED_JOBS_LIST_CLEANUP_ERR',

  queuedJobsListCleanupBegin: () => {
    return {
      type: actions.QUEUED_JOBS_LIST_CLEANUP_BEGIN,
    };
  },

  queuedJobsListCleanupSuccess: () => {
    return {
      type: actions.QUEUED_JOBS_LIST_CLEANUP_SUCCESS,
    };
  },

  queuedJobsListCleanupErr: err => {
    return {
      type: actions.QUEUED_JOBS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
