import 'moment/locale/pt-br';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import Moment from 'react-moment';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import store from './redux/store';
import { identityUpdateAction } from './redux/authentication/actionCreator';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/font/font.css';
import './static/css/style.css';
import './static/css/style-custom.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import TermsOfUseAgreementModal from './components/termsOfUseAgreement/TermsOfUseAgreementModal';
import { isUserEmployee } from './config/acl/functions';
import { newslettersListUpdateAction } from './redux/newsletters/actionCreator';
import { notificationsListUpdateAction } from './redux/notifications/actionCreator';

moment.locale('pt-br');

// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance.
Moment.globalLocale = 'pt-br';

const { theme, validateMessages } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();

  const { rtl, darkMode, topMenu, isLoggedIn } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      darkMode: state.ChangeLayoutMode.data,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.loggedIn,
    };
  });

  // https://stackoverflow.com/a/71331099
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  //
  const [isTermsOfUseAgreementModalOpened, setIsTermsOfUseAgreementModalOpened] = useState(false);
  const [path, setPath] = useState(window.location.pathname);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
      if (isLoggedIn === true) {
        dispatch(
          identityUpdateAction({
            onSuccess: response => {
              setIsTermsOfUseAgreementModalOpened(
                !response.data.user.terms_of_use_agreement && !isUserEmployee(response.data.user),
              );

              dispatch(newslettersListUpdateAction());
              dispatch(notificationsListUpdateAction());
            },
          }),
        );
      }
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [dispatch, isLoggedIn]);

  // Rotas "desprotegidas" que o usuário autenticado pode acessar
  const bypassPaths = ['/recoverPassword'];

  return (
    <ConfigProvider locale={ptBR} direction={rtl ? 'rtl' : 'ltr'} form={{ validateMessages }}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          {!isLoggedIn || bypassPaths.includes(path) ? (
            <Route path="/" component={Auth} />
          ) : (
            <>
              <TermsOfUseAgreementModal
                open={isTermsOfUseAgreementModalOpened}
                setIsOpen={setIsTermsOfUseAgreementModalOpened}
              />
              <ProtectedRoute path="/admin" component={Admin} />
            </>
          )}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Redirect to="/admin" />
          )}
        </BrowserRouter>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
