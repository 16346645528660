import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ACHIEVEMENTS_UPDATE_BEGIN,
  ACHIEVEMENTS_UPDATE_SUCCESS,
  ACHIEVEMENTS_UPDATE_ERR,

  ACHIEVEMENTS_CLEANUP_BEGIN,
  ACHIEVEMENTS_CLEANUP_SUCCESS,
  ACHIEVEMENTS_CLEANUP_ERR,
} = actions;

const achievementsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACHIEVEMENTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENTS_CLEANUP_SUCCESS:
      return initialState;

    case ACHIEVEMENTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  ACHIEVEMENTS_LIST_UPDATE_BEGIN,
  ACHIEVEMENTS_LIST_UPDATE_SUCCESS,
  ACHIEVEMENTS_LIST_UPDATE_ERR,

  ACHIEVEMENTS_LIST_CLEANUP_BEGIN,
  ACHIEVEMENTS_LIST_CLEANUP_SUCCESS,
  ACHIEVEMENTS_LIST_CLEANUP_ERR,
} = actions;

const achievementsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENTS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACHIEVEMENTS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ACHIEVEMENTS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENTS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACHIEVEMENTS_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case ACHIEVEMENTS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: null,
  loading: false,
  saving: false,
  error: null,
};

const {
  ACHIEVEMENT_UPDATE_BEGIN,
  ACHIEVEMENT_UPDATE_SUCCESS,
  ACHIEVEMENT_UPDATE_ERR,

  ACHIEVEMENT_CLEANUP_BEGIN,
  ACHIEVEMENT_CLEANUP_SUCCESS,
  ACHIEVEMENT_CLEANUP_ERR,

  ACHIEVEMENT_SAVE_BEGIN,
  ACHIEVEMENT_SAVE_SUCCESS,
  ACHIEVEMENT_SAVE_ERR,
} = actions;

const achievementReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACHIEVEMENT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_CLEANUP_SUCCESS:
      return initialSingleState;

    case ACHIEVEMENT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case ACHIEVEMENT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case ACHIEVEMENT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

// Image
const initialImageState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  ACHIEVEMENT_IMAGE_UPDATE_BEGIN,
  ACHIEVEMENT_IMAGE_UPDATE_SUCCESS,
  ACHIEVEMENT_IMAGE_UPDATE_ERR,
  ACHIEVEMENT_IMAGE_CLEANUP_BEGIN,
  ACHIEVEMENT_IMAGE_CLEANUP_SUCCESS,
  ACHIEVEMENT_IMAGE_CLEANUP_ERR,
} = actions;

const achievementImageReducer = (state = initialImageState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACHIEVEMENT_IMAGE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ACHIEVEMENT_IMAGE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACHIEVEMENT_IMAGE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACHIEVEMENT_IMAGE_CLEANUP_SUCCESS:
      return initialImageState;

    case ACHIEVEMENT_IMAGE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { achievementsReducer, achievementsListReducer, achievementReducer, achievementImageReducer };
