const actions = {
  CLIENT_ATTORNEY_TYPES_LIST_UPDATE_BEGIN: 'CLIENT_ATTORNEY_TYPES_LIST_UPDATE_BEGIN',
  CLIENT_ATTORNEY_TYPES_LIST_UPDATE_SUCCESS: 'CLIENT_ATTORNEY_TYPES_LIST_UPDATE_SUCCESS',
  CLIENT_ATTORNEY_TYPES_LIST_UPDATE_ERR: 'CLIENT_ATTORNEY_TYPES_LIST_UPDATE_ERR',

  clientAttorneyTypesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  clientAttorneyTypesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_ATTORNEY_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientAttorneyTypesListUpdateErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_BEGIN: 'CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_BEGIN',
  CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_SUCCESS: 'CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_SUCCESS',
  CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_ERR: 'CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_ERR',

  clientAttorneyTypesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  clientAttorneyTypesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientAttorneyTypesListCleanupErr: err => {
    return {
      type: actions.CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  