import moment from 'moment';
import { Tag } from 'antd';
import React from 'react';
import CONSTANTS from '../../config/constants';
import { theme } from '../../config/theme/themeVariables';
import { isUserEmployee } from '../../config/acl/functions';

const isUserActive = user => user?.user_status_id === CONSTANTS.USER_STATUS.ATIVO;

const isUserBirthday = user => {
  const today = moment();
  return moment(user.birth_date)
    .year(today.year())
    .isSame(today, 'day');
};

const greatestProfile = (user, department_id) => {
  if (!user) {
    return null;
  }

  if (user.is_admin) {
    return CONSTANTS.PROFILE.DIRETOR;
  }

  const profiles = user.users_departments.filter(users_department => users_department.department_id === department_id);

  profiles.sort((a, b) => a.position - b.position);

  return profiles[0]?.profile_id ?? null;
};

const translateUserDepartment = user_department => (
  <span key={`user_department-${user_department.id}`}>
    {user_department.profile.parent_id === null
      ? user_department.profile.name
      : `${user_department.profile.name} ${user_department.department.name}`}
  </span>
);

/**
 * renderUserType
 *
 * @param {*} isClient
 * @param {*} user
 * @returns
 */
const renderUserType = user => {
  if (!user) return null;

  let color;
  let label;

  const isClient = !isUserEmployee(user);

  // Colaborador
  if (!isClient) {
    color = 'blue';
    label = 'Colaborador';

    // Prestador de Serviços
    if (user.service_provider) {
      color = 'cyan';
      label = 'Prestador de Serviços';
    }
  }
  // Cliente
  else {
    color = 'gold';
    label = 'Cliente';

    // Cliente Administrador
    if (user.client_admin) {
      color = 'red';
      label = 'Cliente Administrador';
    }
  }

  return (
    <Tag color={color} style={{ margin: '8px 0' }}>
      {label}
    </Tag>
  );
};

const renderUserNameEmail = ({ user, prefix = <></> }) => (
  <div>
    {prefix}
    {user.name} <span style={{ color: theme['light-color'], fontSize: '85%' }}>&lt;{user.email}&gt;</span>
  </div>
);

export { isUserActive, isUserBirthday, greatestProfile, translateUserDepartment, renderUserType, renderUserNameEmail };
