const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  IDENTITY_UPDATE_BEGIN: 'IDENTITY_UPDATE_BEGIN',
  IDENTITY_UPDATE_SUCCESS: 'IDENTITY_UPDATE_SUCCESS',
  IDENTITY_UPDATE_ERR: 'IDENTITY_UPDATE_ERR',

  identityUpdateBegin: () => {
    return {
      type: actions.IDENTITY_UPDATE_BEGIN,
    };
  },

  identityUpdateSuccess: data => {
    return {
      type: actions.IDENTITY_UPDATE_SUCCESS,
      data,
    };
  },

  identityUpdateErr: err => {
    return {
      type: actions.IDENTITY_UPDATE_ERR,
      err,
    };
  },

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: () => {
    return {
      type: actions.LOGOUT_SUCCESS
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
