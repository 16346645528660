const actions = {
  COMMENTS_UPDATE_BEGIN: 'COMMENTS_UPDATE_BEGIN',
  COMMENTS_UPDATE_SUCCESS: 'COMMENTS_UPDATE_SUCCESS',
  COMMENTS_UPDATE_ERR: 'COMMENTS_UPDATE_ERR',

  commentsUpdateBegin: () => {
    return {
      type: actions.COMMENTS_UPDATE_BEGIN,
    };
  },

  commentsUpdateSuccess: data => {
    return {
      type: actions.COMMENTS_UPDATE_SUCCESS,
      data,
    };
  },

  commentsUpdateErr: err => {
    return {
      type: actions.COMMENTS_UPDATE_ERR,
      err,
    };
  },

  COMMENTS_CLEANUP_BEGIN: 'COMMENTS_CLEANUP_BEGIN',
  COMMENTS_CLEANUP_SUCCESS: 'COMMENTS_CLEANUP_SUCCESS',
  COMMENTS_CLEANUP_ERR: 'COMMENTS_CLEANUP_ERR',

  commentsCleanupBegin: () => {
    return {
      type: actions.COMMENTS_CLEANUP_BEGIN,
    };
  },

  commentsCleanupSuccess: () => {
    return {
      type: actions.COMMENTS_CLEANUP_SUCCESS,
    };
  },

  commentsCleanupErr: err => {
    return {
      type: actions.COMMENTS_CLEANUP_ERR,
      err,
    };
  },

  COMMENT_DELETE_BEGIN: 'COMMENT_DELETE_BEGIN',
  COMMENT_DELETE_SUCCESS: 'COMMENT_DELETE_SUCCESS',
  COMMENT_DELETE_ERR: 'COMMENT_DELETE_ERR',

  commentDeleteBegin: () => {
    return {
      type: actions.COMMENT_DELETE_BEGIN,
    };
  },

  commentDeleteSuccess: data => {
    return {
      type: actions.COMMENT_DELETE_SUCCESS,
      data,
    };
  },

  commentDeleteErr: err => {
    return {
      type: actions.COMMENT_DELETE_ERR,
      err,
    };
  },

  COMMENT_SAVE_BEGIN: 'COMMENT_SAVE_BEGIN',
  COMMENT_SAVE_SUCCESS: 'COMMENT_SAVE_SUCCESS',
  COMMENT_SAVE_ERR: 'COMMENT_SAVE_ERR',

  commentSaveBegin: () => {
    return {
      type: actions.COMMENT_SAVE_BEGIN,
    };
  },

  commentSaveSuccess: data => {
    return {
      type: actions.COMMENT_SAVE_SUCCESS,
      data,
    };
  },

  commentSaveErr: err => {
    return {
      type: actions.COMMENT_SAVE_ERR,
      err,
    };
  },
};

export default actions;
  