import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const FilesIndex = lazy(() => import('../../container/files/Index'));
const FilesSentIndex = lazy(() => import('../../container/files/SentIndex'));
const NotFound = lazy(() => import('../../container/pages/404'));

const FilesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={[`${path}/sent`, `${path}/sent/view/:id`, `${path}/sent/edit/:id`]} component={FilesSentIndex} />
        <Route path={path} component={FilesIndex} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default FilesRoute;
