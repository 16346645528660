import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_TAX_BREAKS_UPDATE_BEGIN,
  CLIENT_TAX_BREAKS_UPDATE_SUCCESS,
  CLIENT_TAX_BREAKS_UPDATE_ERR,

  CLIENT_TAX_BREAKS_CLEANUP_BEGIN,
  CLIENT_TAX_BREAKS_CLEANUP_SUCCESS,
  CLIENT_TAX_BREAKS_CLEANUP_ERR,

  CLIENT_TAX_BREAK_DELETE_BEGIN,
  CLIENT_TAX_BREAK_DELETE_SUCCESS,
  CLIENT_TAX_BREAK_DELETE_ERR,
} = actions;

const clientTaxBreaksReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_TAX_BREAKS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_TAX_BREAKS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_TAX_BREAKS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_TAX_BREAKS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_TAX_BREAKS_CLEANUP_SUCCESS:
      return initialState;

    case CLIENT_TAX_BREAKS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_TAX_BREAK_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CLIENT_TAX_BREAK_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CLIENT_TAX_BREAK_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CLIENT_TAX_BREAK_UPDATE_BEGIN,
  CLIENT_TAX_BREAK_UPDATE_SUCCESS,
  CLIENT_TAX_BREAK_UPDATE_ERR,

  CLIENT_TAX_BREAK_CLEANUP_BEGIN,
  CLIENT_TAX_BREAK_CLEANUP_SUCCESS,
  CLIENT_TAX_BREAK_CLEANUP_ERR,

  CLIENT_TAX_BREAK_SAVE_BEGIN,
  CLIENT_TAX_BREAK_SAVE_SUCCESS,
  CLIENT_TAX_BREAK_SAVE_ERR,
} = actions;

const clientTaxBreakReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_TAX_BREAK_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_TAX_BREAK_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_TAX_BREAK_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_TAX_BREAK_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_TAX_BREAK_CLEANUP_SUCCESS:
      return initialSingleState;

    case CLIENT_TAX_BREAK_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_TAX_BREAK_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CLIENT_TAX_BREAK_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CLIENT_TAX_BREAK_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { clientTaxBreaksReducer, clientTaxBreakReducer };
