import { message } from 'antd';
import actions from './actions';
import api from '../../config/api/index';
import localStorage from '../../utility/localStorageControl';

const messageKey = 'authentication';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  identityUpdateBegin,
  identityUpdateSuccess,
  identityUpdateErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = data => {
  return async dispatch => {
    dispatch(loginBegin());
    message.loading({ content: 'Autenticando credenciais', duration: 0, key: messageKey });

    try {
      api.Users.token(data).then(response => {
        // handle success
        if (response.data.user !== undefined) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('Newsletters.seen', false);
          dispatch(loginSuccess(response.data));
          message.destroy(messageKey);
        } else {
          dispatch(loginErr(response.data?.message ?? response.data));
          message.destroy(messageKey);
          // message.error({ content: response.data?.message ?? response.data, key: messageKey });
        }
      });
    } catch (err) {
      dispatch(loginErr(err));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      localStorage.clear();
      dispatch(logoutSuccess());
    } catch (err) {
      dispatch(logoutErr(err));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const identityUpdateAction = ({ onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(identityUpdateBegin());
    api.Users.me().then(response => {
      if (response.status === 200) {
        dispatch(
          identityUpdateSuccess({
            ...response.data,
            user: {
              ...response.data.user,
              is_admin: response.data.user.users_departments
                .map(users_department => users_department.profile.admin)
                .includes(true),
            },
          }),
        );
        onSuccess(response);
      } else if (response.data && response.data.message) {
        dispatch(identityUpdateErr(response.data.message));
        message.error({ content: JSON.stringify(response.data.message), key: messageKey });
      }
    });
  };
};

export { login, identityUpdateAction, logOut };
