import React from 'react';
import propTypes from 'prop-types';
import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import { renderTitle } from '../efficiency/functions';

const UserEfficiency = ({ user }) => {
  if (!user?.efficiency) return '';

  return (
    <div className="user-efficiency">
      <Link to="#" title="Desempenho Atual">
        {renderTitle(user.efficiency.efficiency_percentual)}
        <Progress
          percent={user.efficiency.estimated_percentual}
          success={{ percent: user.efficiency.done_percentual }}
          showInfo={false}
        />
      </Link>
    </div>
  );
};
UserEfficiency.propTypes = {
  user: propTypes.object.isRequired,
};
export default UserEfficiency;
