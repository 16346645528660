import actions from './actions';

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  ADDRESS_UPDATE_BEGIN,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_ERR,

  ADDRESS_CLEANUP_BEGIN,
  ADDRESS_CLEANUP_SUCCESS,
  ADDRESS_CLEANUP_ERR,

  ADDRESS_SAVE_BEGIN,
  ADDRESS_SAVE_SUCCESS,
  ADDRESS_SAVE_ERR,
} = actions;

const addressReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADDRESS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case ADDRESS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADDRESS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ADDRESS_CLEANUP_SUCCESS:
      return initialSingleState;

    case ADDRESS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADDRESS_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case ADDRESS_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case ADDRESS_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { addressReducer };
