const actions = {
  CLIENT_CNPJ_UPDATE_BEGIN: 'CLIENT_CNPJ_UPDATE_BEGIN',
  CLIENT_CNPJ_UPDATE_SUCCESS: 'CLIENT_CNPJ_UPDATE_SUCCESS',
  CLIENT_CNPJ_UPDATE_ERR: 'CLIENT_CNPJ_UPDATE_ERR',

  clientCnpjUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_UPDATE_BEGIN,
    };
  },

  clientCnpjUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_CLEANUP_BEGIN: 'CLIENT_CNPJ_CLEANUP_BEGIN',
  CLIENT_CNPJ_CLEANUP_SUCCESS: 'CLIENT_CNPJ_CLEANUP_SUCCESS',
  CLIENT_CNPJ_CLEANUP_ERR: 'CLIENT_CNPJ_CLEANUP_ERR',

  clientCnpjCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_CLEANUP_BEGIN,
    };
  },

  clientCnpjCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_CLEANUP_SUCCESS,
    };
  },

  clientCnpjCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_CNPJ_SAVE_BEGIN: 'CLIENT_CNPJ_SAVE_BEGIN',
  CLIENT_CNPJ_SAVE_SUCCESS: 'CLIENT_CNPJ_SAVE_SUCCESS',
  CLIENT_CNPJ_SAVE_ERR: 'CLIENT_CNPJ_SAVE_ERR',

  clientCnpjSaveBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_SAVE_BEGIN,
    };
  },

  clientCnpjSaveSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_SAVE_SUCCESS,
      data,
    };
  },

  clientCnpjSaveErr: err => {
    return {
      type: actions.CLIENT_CNPJ_SAVE_ERR,
      err,
    };
  },
};

export default actions;
