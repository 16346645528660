const actions = {
  CLIENT_TAXATIONS_UPDATE_BEGIN: 'CLIENT_TAXATIONS_UPDATE_BEGIN',
  CLIENT_TAXATIONS_UPDATE_SUCCESS: 'CLIENT_TAXATIONS_UPDATE_SUCCESS',
  CLIENT_TAXATIONS_UPDATE_ERR: 'CLIENT_TAXATIONS_UPDATE_ERR',

  clientTaxationsUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAXATIONS_UPDATE_BEGIN,
    };
  },

  clientTaxationsUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAXATIONS_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxationsUpdateErr: err => {
    return {
      type: actions.CLIENT_TAXATIONS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAXATIONS_CLEANUP_BEGIN: 'CLIENT_TAXATIONS_CLEANUP_BEGIN',
  CLIENT_TAXATIONS_CLEANUP_SUCCESS: 'CLIENT_TAXATIONS_CLEANUP_SUCCESS',
  CLIENT_TAXATIONS_CLEANUP_ERR: 'CLIENT_TAXATIONS_CLEANUP_ERR',

  clientTaxationsCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAXATIONS_CLEANUP_BEGIN,
    };
  },

  clientTaxationsCleanupSuccess: data => {
    return {
      type: actions.CLIENT_TAXATIONS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientTaxationsCleanupErr: err => {
    return {
      type: actions.CLIENT_TAXATIONS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_TAXATION_UPDATE_BEGIN: 'CLIENT_TAXATION_UPDATE_BEGIN',
  CLIENT_TAXATION_UPDATE_SUCCESS: 'CLIENT_TAXATION_UPDATE_SUCCESS',
  CLIENT_TAXATION_UPDATE_ERR: 'CLIENT_TAXATION_UPDATE_ERR',

  clientTaxationUpdateBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_UPDATE_BEGIN,
    };
  },

  clientTaxationUpdateSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_UPDATE_SUCCESS,
      data,
    };
  },

  clientTaxationUpdateErr: err => {
    return {
      type: actions.CLIENT_TAXATION_UPDATE_ERR,
      err,
    };
  },

  CLIENT_TAXATION_CLEANUP_BEGIN: 'CLIENT_TAXATION_CLEANUP_BEGIN',
  CLIENT_TAXATION_CLEANUP_SUCCESS: 'CLIENT_TAXATION_CLEANUP_SUCCESS',
  CLIENT_TAXATION_CLEANUP_ERR: 'CLIENT_TAXATION_CLEANUP_ERR',

  clientTaxationCleanupBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_CLEANUP_BEGIN,
    };
  },

  clientTaxationCleanupSuccess: () => {
    return {
      type: actions.CLIENT_TAXATION_CLEANUP_SUCCESS,
    };
  },

  clientTaxationCleanupErr: err => {
    return {
      type: actions.CLIENT_TAXATION_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_TAXATION_SAVE_BEGIN: 'CLIENT_TAXATION_SAVE_BEGIN',
  CLIENT_TAXATION_SAVE_SUCCESS: 'CLIENT_TAXATION_SAVE_SUCCESS',
  CLIENT_TAXATION_SAVE_ERR: 'CLIENT_TAXATION_SAVE_ERR',

  clientTaxationSaveBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_SAVE_BEGIN,
    };
  },

  clientTaxationSaveSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_SAVE_SUCCESS,
      data,
    };
  },

  clientTaxationSaveErr: err => {
    return {
      type: actions.CLIENT_TAXATION_SAVE_ERR,
      err,
    };
  },

  CLIENT_TAXATION_DELETE_BEGIN: 'CLIENT_TAXATION_DELETE_BEGIN',
  CLIENT_TAXATION_DELETE_SUCCESS: 'CLIENT_TAXATION_DELETE_SUCCESS',
  CLIENT_TAXATION_DELETE_ERR: 'CLIENT_TAXATION_DELETE_ERR',

  clientTaxationDeleteBegin: () => {
    return {
      type: actions.CLIENT_TAXATION_DELETE_BEGIN,
    };
  },

  clientTaxationDeleteSuccess: data => {
    return {
      type: actions.CLIENT_TAXATION_DELETE_SUCCESS,
      data,
    };
  },

  clientTaxationDeleteErr: err => {
    return {
      type: actions.CLIENT_TAXATION_DELETE_ERR,
      err,
    };
  },
};

export default actions;
