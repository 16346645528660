import { message, Modal } from 'antd';
import actions from './actions';
import api from '../../config/api/index';
import format from '../../config/format';

const messageKey = 'users';

const { usersPaginationUpdateBegin, usersPaginationUpdateSuccess, usersPaginationUpdateErr } = actions;

const usersPaginationUpdateAction = data => {
  return async dispatch => {
    dispatch(usersPaginationUpdateBegin());
    try {
      dispatch(usersPaginationUpdateSuccess(data));
    } catch (err) {
      dispatch(usersPaginationUpdateErr(err.toString()));
    }
  };
};

const { usersFiltersUpdateBegin, usersFiltersUpdateSuccess, usersFiltersUpdateErr } = actions;

const usersFiltersUpdateAction = data => {
  return async dispatch => {
    dispatch(usersFiltersUpdateBegin());
    try {
      dispatch(usersFiltersUpdateSuccess(data));
    } catch (err) {
      dispatch(usersFiltersUpdateErr(err.toString()));
    }
  };
};

const { usersSorterUpdateBegin, usersSorterUpdateSuccess, usersSorterUpdateErr } = actions;

const usersSorterUpdateAction = data => {
  return async dispatch => {
    dispatch(usersSorterUpdateBegin());
    try {
      dispatch(usersSorterUpdateSuccess(data));
    } catch (err) {
      dispatch(usersSorterUpdateErr(err.toString()));
    }
  };
};

const { usersUpdateBegin, usersUpdateSuccess, usersUpdateErr } = actions;

const usersUpdateAction = ({
  pagination = undefined,
  filters = undefined,
  sorter = undefined,
  source = 'employees',
} = {}) => {
  return async (dispatch, getState) => {
    dispatch(usersUpdateBegin());

    const { users } = getState();
    const currentPagination = users.pagination;
    const currentFilters = users.filters;
    const currentSorter = users.sorter;

    pagination = pagination ?? currentPagination;
    filters = filters ?? currentFilters;
    sorter = sorter ?? currentSorter;

    // pagination
    if (JSON.stringify(pagination) !== JSON.stringify(currentPagination)) {
      dispatch(usersPaginationUpdateAction(pagination));
    }

    // filters
    if (JSON.stringify(filters) !== JSON.stringify(currentFilters)) {
      dispatch(usersFiltersUpdateAction(filters));
    }

    // sorter
    if (JSON.stringify(sorter) !== JSON.stringify(currentSorter)) {
      dispatch(usersSorterUpdateAction(sorter));
    }

    // params
    const params = {
      page: pagination.current,
      limit: pagination.pageSize,
      filters: filters ?? null,
      sort: sorter?.field ?? null,
      direction: sorter?.order ?? null,
    };

    // action
    api.Users.index(source, params)
      .then(response => {
        if (response.status === 200) {
          dispatch(usersUpdateSuccess(response.data.users));
          dispatch(usersPaginationUpdateAction(response.data.pagination));
        } else {
          dispatch(usersUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(usersUpdateErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { usersCleanupBegin, usersCleanupSuccess, usersCleanupErr } = actions;

const usersCleanupAction = () => {
  return async dispatch => {
    dispatch(usersCleanupBegin());
    try {
      dispatch(usersCleanupSuccess());
    } catch (err) {
      dispatch(usersCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { userDeleteBegin, userDeleteSuccess, userDeleteErr } = actions;

const userDeleteAction = ({ user, onSuccess = () => {} }) => {
  return async (dispatch, getState) => {
    dispatch(userDeleteBegin());
    const { id, name } = user;
    message.loading({ content: `Excluindo usuário "${name}"`, duration: 0, key: `${messageKey}-${id}` });
    api.Users.delete(id)
      .then(response => {
        if (response.status === 200) {
          dispatch(userDeleteSuccess(getState().users.data.filter(user => user.id !== id)));
          message.success({ content: `Usuário "${name}" excluído com sucesso`, key: `${messageKey}-${id}` });
          onSuccess(response);
        } else if (response.data && response.data.message) {
          dispatch(userDeleteErr(response.data.message));
          message.error({ content: response.data.message, key: `${messageKey}-${id}` });
        }
      })
      .catch(err => {
        dispatch(userDeleteErr(err.toString()));
        message.error({ content: err.toString(), key: `${messageKey}-${id}` });
      });
  };
};

const { userPhotoUpdateBegin, userPhotoUpdateSuccess, userPhotoUpdateErr } = actions;

const userPhotoUpdateAction = (id, data, onSuccess = () => {}) => {
  return async dispatch => {
    dispatch(userPhotoUpdateBegin());
    api.Users.photo(id, data, {
      onUploadProgress: event => {
        // const percent = Math.round((event.loaded * 100) / event.total);
        // console.log('percent: ', percent);
        // dispatch(
        //   fileUploadUpdate(
        //     getState().files.data.map(file =>
        //       file.uid === uploadedFile.uid
        //         ? {
        //             ...file,
        //             status: 'uploading',
        //             percent,
        //           }
        //         : file,
        //     ),
        //   ),
        // );
      },
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(userPhotoUpdateSuccess(response.data.photo));
          onSuccess(response);
        } else if (response.data && response.data.message) {
          dispatch(userPhotoUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(userPhotoUpdateErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { userPhotoCleanupBegin, userPhotoCleanupSuccess, userPhotoCleanupErr } = actions;

const userPhotoCleanupAction = () => {
  return async dispatch => {
    dispatch(userPhotoCleanupBegin());
    try {
      dispatch(userPhotoCleanupSuccess());
    } catch (err) {
      dispatch(userPhotoCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { userSignatureUpdateBegin, userSignatureUpdateSuccess, userSignatureUpdateErr } = actions;

/**
 * userSignatureUpdateAction
 *
 * @param {*} id
 * @param {*} filter
 * @returns
 */
const userSignatureUpdateAction = ({ id = undefined, filter = data => data }) => {
  return async dispatch => {
    dispatch(userSignatureUpdateBegin());
    api.Users.signature(id)
      .then(response => {
        if (response.status === 200) {
          const filtered = filter(response.data.user);
          dispatch(userSignatureUpdateSuccess(filtered));
        } else {
          dispatch(userSignatureUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(userSignatureUpdateErr(err));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { userUpdateBegin, userUpdateSuccess, userUpdateErr } = actions;

const userUpdateAction = ({ id = undefined, data = undefined, filter = data => data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(userUpdateBegin());
    if (data !== undefined) {
      dispatch(userUpdateSuccess(data));
    } else if (id !== undefined) {
      api.Users.get(id)
        .then(response => {
          if (response.status === 200) {
            const filtered = filter(response.data.user);
            dispatch(userUpdateSuccess(filtered));
            onSuccess(response);
          } else if (response.data && response.data.message) {
            dispatch(userUpdateErr(response.data.message));
            message.error({ content: response.data.message, key: messageKey });
          }
        })
        .catch(err => {
          dispatch(userUpdateErr(err.toString()));
          message.error({ content: err.toString(), key: messageKey });
        });
    }
  };
};

const { userRecoverPasswordUpdateBegin, userRecoverPasswordUpdateSuccess, userRecoverPasswordUpdateErr } = actions;

const userRecoverPasswordAction = (email, onSuccess = () => {}, onError = () => {}) => {
  return async dispatch => {
    dispatch(userRecoverPasswordUpdateBegin());
    api.Users.recoverPassword(email)
      .then(response => {
        if (response.status === 200) {
          dispatch(userRecoverPasswordUpdateSuccess(response.data));
          if (response.data.recoverPassword === true) {
            onSuccess(response.data);
          } else if (response.data && response.data.message) {
            onError(response.data);
          }
        } else if (response.data && response.data.message) {
          dispatch(userRecoverPasswordUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(userRecoverPasswordUpdateErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { userResetPasswordUpdateBegin, userResetPasswordUpdateSuccess, userResetPasswordUpdateErr } = actions;

const userResetPasswordAction = (token, data, onSuccess = () => {}, onError = () => {}) => {
  return async dispatch => {
    dispatch(userResetPasswordUpdateBegin());
    api.Users.resetPassword(token, data)
      .then(response => {
        if (response.status === 200) {
          dispatch(userResetPasswordUpdateSuccess(response.data));
          if (response.data.resetPassword === true) {
            onSuccess(response.data);
          } else if (response.data && response.data.message) {
            onError(response.data);
          }
        } else if (response.data && response.data.message) {
          dispatch(userResetPasswordUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(userResetPasswordUpdateErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { userCleanupBegin, userCleanupSuccess, userCleanupErr } = actions;

const userCleanupAction = () => {
  return async dispatch => {
    dispatch(userCleanupBegin());
    try {
      dispatch(userCleanupSuccess());
    } catch (err) {
      dispatch(userCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { userSaveBegin, userSaveSuccess, userSaveErr } = actions;

const userSaveAction = ({ data, id = undefined, filter = data => data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(userSaveBegin());
    message.loading({ content: 'Salvando usuário', duration: 0, key: messageKey });
    const action = id !== undefined ? api.Users.edit(id, data) : api.Users.add(data);
    action
      .then(response => {
        if (response.status === 200) {
          const filtered = filter(response.data.user);
          dispatch(userSaveSuccess(filtered));
          message.success({ content: 'Usuário salvo com sucesso', key: messageKey });
          onSuccess(response);
        } else {
          dispatch(userSaveErr(response.data.message));
          // message.error({ content: response.data.message, key: messageKey });
          message.destroy(messageKey);
          Modal.error({
            title: response.data.message,
            content: format.flat(response.data.errors),
          });
        }
      })
      .catch(err => {
        dispatch(userSaveErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { usersListUpdateBegin, usersListUpdateSuccess, usersListUpdateErr } = actions;

/**
 * usersListUpdateAction
 *
 * @param {*} source all|employees|clients
 * @returns
 */
const usersListUpdateAction = (source = 'employees') => {
  return async dispatch => {
    dispatch(usersListUpdateBegin());
    api.Users.list(source)
      .then(response => {
        if (response.status === 200) {
          dispatch(usersListUpdateSuccess(response.data.users, response.data.source));
        } else {
          dispatch(usersListUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(usersListUpdateErr(err));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { usersListCleanupBegin, usersListCleanupSuccess, usersListCleanupErr } = actions;

const usersListCleanupAction = () => {
  return async dispatch => {
    dispatch(usersListCleanupBegin());
    try {
      dispatch(usersListCleanupSuccess());
    } catch (err) {
      dispatch(usersListCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { usersBirthdaysListUpdateBegin, usersBirthdaysListUpdateSuccess, usersBirthdaysListUpdateErr } = actions;

const usersBirthdaysListUpdateAction = () => {
  return async dispatch => {
    dispatch(usersBirthdaysListUpdateBegin());
    api.Users.birthdays()
      .then(response => {
        if (response.status === 200) {
          dispatch(usersBirthdaysListUpdateSuccess(response.data.birthdays));
        } else {
          dispatch(usersBirthdaysListUpdateErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(usersBirthdaysListUpdateErr(err));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

const { usersBirthdaysListCleanupBegin, usersBirthdaysListCleanupSuccess, usersBirthdaysListCleanupErr } = actions;

const usersBirthdaysListCleanupAction = () => {
  return async dispatch => {
    dispatch(usersBirthdaysListCleanupBegin());
    try {
      dispatch(usersBirthdaysListCleanupSuccess());
    } catch (err) {
      dispatch(usersBirthdaysListCleanupErr(err.toString()));
      message.error({ content: err.toString(), key: messageKey });
    }
  };
};

const { userEmployeesTransferBegin, userEmployeesTransferSuccess, userEmployeesTransferErr } = actions;

const userEmployeesTransferAction = ({ data, onSuccess = () => {} }) => {
  return async dispatch => {
    dispatch(userEmployeesTransferBegin());
    message.loading({ content: 'Transferindo responsáveis entre os clientes', duration: 0, key: messageKey });
    api.Users.employeesTransfer(data)
      .then(response => {
        if (response.status === 200) {
          dispatch(userEmployeesTransferSuccess(response.data.employeesTransfer));
          message.success({ content: 'Responsáveis transferidos com sucesso', key: messageKey });
          onSuccess(response);
        } else {
          dispatch(userEmployeesTransferErr(response.data.message));
          message.error({ content: response.data.message, key: messageKey });
        }
      })
      .catch(err => {
        dispatch(userEmployeesTransferErr(err.toString()));
        message.error({ content: err.toString(), key: messageKey });
      });
  };
};

export {
  usersUpdateAction,
  usersCleanupAction,
  userDeleteAction,
  userPhotoUpdateAction,
  userPhotoCleanupAction,
  userSignatureUpdateAction,
  userRecoverPasswordAction,
  userResetPasswordAction,
  userUpdateAction,
  userCleanupAction,
  userSaveAction,
  usersListUpdateAction,
  usersListCleanupAction,
  usersBirthdaysListUpdateAction,
  usersBirthdaysListCleanupAction,
  userEmployeesTransferAction,
};
