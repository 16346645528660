import actions from './actions';

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const { 
  CLIENT_ATTORNEY_TYPES_LIST_UPDATE_BEGIN, 
  CLIENT_ATTORNEY_TYPES_LIST_UPDATE_SUCCESS, 
  CLIENT_ATTORNEY_TYPES_LIST_UPDATE_ERR,

  CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_BEGIN, 
  CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_SUCCESS, 
  CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_ERR,
} = actions;

const clientAttorneyTypesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_ATTORNEY_TYPES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_ATTORNEY_TYPES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CLIENT_ATTORNEY_TYPES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_SUCCESS:
      return initialListState;
    case CLIENT_ATTORNEY_TYPES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default clientAttorneyTypesListReducer;