import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_SUCCESS,
  CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_SUCCESS,
  CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_SUCCESS,

  CARD_JUSTIFICATION_REASONS_UPDATE_BEGIN,
  CARD_JUSTIFICATION_REASONS_UPDATE_SUCCESS,
  CARD_JUSTIFICATION_REASONS_UPDATE_ERR,

  CARD_JUSTIFICATION_REASONS_CLEANUP_BEGIN,
  CARD_JUSTIFICATION_REASONS_CLEANUP_SUCCESS,
  CARD_JUSTIFICATION_REASONS_CLEANUP_ERR,

  CARD_JUSTIFICATION_REASON_DELETE_BEGIN,
  CARD_JUSTIFICATION_REASON_DELETE_SUCCESS,
  CARD_JUSTIFICATION_REASON_DELETE_ERR,
} = actions;

const cardJustificationReasonsReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_JUSTIFICATION_REASONS_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case CARD_JUSTIFICATION_REASONS_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case CARD_JUSTIFICATION_REASONS_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case CARD_JUSTIFICATION_REASONS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_REASONS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASONS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASONS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_REASONS_CLEANUP_SUCCESS:
      return initialIndexState;

    case CARD_JUSTIFICATION_REASONS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASON_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CARD_JUSTIFICATION_REASON_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CARD_JUSTIFICATION_REASON_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  CARD_JUSTIFICATION_REASONS_LIST_UPDATE_BEGIN,
  CARD_JUSTIFICATION_REASONS_LIST_UPDATE_SUCCESS,
  CARD_JUSTIFICATION_REASONS_LIST_UPDATE_ERR,

  CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_BEGIN,
  CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_SUCCESS,
  CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_ERR,
} = actions;

const cardJustificationReasonsListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_JUSTIFICATION_REASONS_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_REASONS_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASONS_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case CARD_JUSTIFICATION_REASONS_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CARD_JUSTIFICATION_REASON_UPDATE_BEGIN,
  CARD_JUSTIFICATION_REASON_UPDATE_SUCCESS,
  CARD_JUSTIFICATION_REASON_UPDATE_ERR,

  CARD_JUSTIFICATION_REASON_CLEANUP_BEGIN,
  CARD_JUSTIFICATION_REASON_CLEANUP_SUCCESS,
  CARD_JUSTIFICATION_REASON_CLEANUP_ERR,

  CARD_JUSTIFICATION_REASON_SAVE_BEGIN,
  CARD_JUSTIFICATION_REASON_SAVE_SUCCESS,
  CARD_JUSTIFICATION_REASON_SAVE_ERR,
} = actions;

const cardJustificationReasonReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CARD_JUSTIFICATION_REASON_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_REASON_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASON_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASON_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CARD_JUSTIFICATION_REASON_CLEANUP_SUCCESS:
      return initialSingleState;

    case CARD_JUSTIFICATION_REASON_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CARD_JUSTIFICATION_REASON_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CARD_JUSTIFICATION_REASON_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CARD_JUSTIFICATION_REASON_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { cardJustificationReasonsReducer, cardJustificationReasonsListReducer, cardJustificationReasonReducer };
