const actions = {
  PROFILES_LIST_UPDATE_BEGIN: 'PROFILES_LIST_UPDATE_BEGIN',
  PROFILES_LIST_UPDATE_SUCCESS: 'PROFILES_LIST_UPDATE_SUCCESS',
  PROFILES_LIST_UPDATE_ERR: 'PROFILES_LIST_UPDATE_ERR',

  profilesListUpdateBegin: () => {
    return {
      type: actions.PROFILES_LIST_UPDATE_BEGIN,
    };
  },

  profilesListUpdateSuccess: data => {
    return {
      type: actions.PROFILES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  profilesListUpdateErr: err => {
    return {
      type: actions.PROFILES_LIST_UPDATE_ERR,
      err,
    };
  },

  PROFILES_LIST_CLEANUP_BEGIN: 'PROFILES_LIST_CLEANUP_BEGIN',
  PROFILES_LIST_CLEANUP_SUCCESS: 'PROFILES_LIST_CLEANUP_SUCCESS',
  PROFILES_LIST_CLEANUP_ERR: 'PROFILES_LIST_CLEANUP_ERR',

  profilesListCleanupBegin: () => {
    return {
      type: actions.PROFILES_LIST_CLEANUP_BEGIN,
    };
  },

  profilesListCleanupSuccess: () => {
    return {
      type: actions.PROFILES_LIST_CLEANUP_SUCCESS,
    };
  },

  profilesListCleanupErr: err => {
    return {
      type: actions.PROFILES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  