const actions = {
  CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_BEGIN: 'CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_BEGIN',
  CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_SUCCESS: 'CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_SUCCESS',
  CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_ERR: 'CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_ERR',

  clientCnpjStateRegistrationStatusesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_BEGIN,
    };
  },

  clientCnpjStateRegistrationStatusesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjStateRegistrationStatusesListUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_BEGIN: 'CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_BEGIN',
  CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_SUCCESS: 'CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_SUCCESS',
  CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_ERR: 'CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_ERR',

  clientCnpjStateRegistrationStatusesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_BEGIN,
    };
  },

  clientCnpjStateRegistrationStatusesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientCnpjStateRegistrationStatusesListCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_STATE_REGISTRATION_STATUSES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  