const actions = {
  CACHE_CLEAR_ALL_BEGIN: 'CACHE_CLEAR_ALL_BEGIN',
  CACHE_CLEAR_ALL_SUCCESS: 'CACHE_CLEAR_ALL_SUCCESS',
  CACHE_CLEAR_ALL_ERR: 'CACHE_CLEAR_ALL_ERR',

  cacheClearAllBegin: () => {
    return {
      type: actions.CACHE_CLEAR_ALL_BEGIN,
    };
  },

  cacheClearAllSuccess: data => {
    return {
      type: actions.CACHE_CLEAR_ALL_SUCCESS,
      data,
    };
  },

  cacheClearAllErr: err => {
    return {
      type: actions.CACHE_CLEAR_ALL_ERR,
      err,
    };
  },

  CACHE_CLEANUP_BEGIN: 'CACHE_CLEANUP_BEGIN',
  CACHE_CLEANUP_SUCCESS: 'CACHE_CLEANUP_SUCCESS',
  CACHE_CLEANUP_ERR: 'CACHE_CLEANUP_ERR',

  cacheCleanupBegin: () => {
    return {
      type: actions.CACHE_CLEANUP_BEGIN,
    };
  },

  cacheCleanupSuccess: () => {
    return {
      type: actions.CACHE_CLEANUP_SUCCESS,
    };
  },

  cacheCleanupErr: err => {
    return {
      type: actions.CACHE_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
