const actions = {
  CARD_TYPES_LIST_UPDATE_BEGIN: 'CARD_TYPES_LIST_UPDATE_BEGIN',
  CARD_TYPES_LIST_UPDATE_SUCCESS: 'CARD_TYPES_LIST_UPDATE_SUCCESS',
  CARD_TYPES_LIST_UPDATE_ERR: 'CARD_TYPES_LIST_UPDATE_ERR',

  cardTypesListUpdateBegin: () => {
    return {
      type: actions.CARD_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  cardTypesListUpdateSuccess: data => {
    return {
      type: actions.CARD_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  cardTypesListUpdateErr: err => {
    return {
      type: actions.CARD_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  CARD_TYPES_LIST_CLEANUP_BEGIN: 'CARD_TYPES_LIST_CLEANUP_BEGIN',
  CARD_TYPES_LIST_CLEANUP_SUCCESS: 'CARD_TYPES_LIST_CLEANUP_SUCCESS',
  CARD_TYPES_LIST_CLEANUP_ERR: 'CARD_TYPES_LIST_CLEANUP_ERR',

  cardTypesListCleanupBegin: () => {
    return {
      type: actions.CARD_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  cardTypesListCleanupSuccess: () => {
    return {
      type: actions.CARD_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  cardTypesListCleanupErr: err => {
    return {
      type: actions.CARD_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  