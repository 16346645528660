const actions = {
  CLIENT_PASSWORDS_UPDATE_BEGIN: 'CLIENT_PASSWORDS_UPDATE_BEGIN',
  CLIENT_PASSWORDS_UPDATE_SUCCESS: 'CLIENT_PASSWORDS_UPDATE_SUCCESS',
  CLIENT_PASSWORDS_UPDATE_ERR: 'CLIENT_PASSWORDS_UPDATE_ERR',

  clientPasswordsUpdateBegin: () => {
    return {
      type: actions.CLIENT_PASSWORDS_UPDATE_BEGIN,
    };
  },

  clientPasswordsUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PASSWORDS_UPDATE_SUCCESS,
      data,
    };
  },

  clientPasswordsUpdateErr: err => {
    return {
      type: actions.CLIENT_PASSWORDS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PASSWORDS_CLEANUP_BEGIN: 'CLIENT_PASSWORDS_CLEANUP_BEGIN',
  CLIENT_PASSWORDS_CLEANUP_SUCCESS: 'CLIENT_PASSWORDS_CLEANUP_SUCCESS',
  CLIENT_PASSWORDS_CLEANUP_ERR: 'CLIENT_PASSWORDS_CLEANUP_ERR',

  clientPasswordsCleanupBegin: () => {
    return {
      type: actions.CLIENT_PASSWORDS_CLEANUP_BEGIN,
    };
  },

  clientPasswordsCleanupSuccess: () => {
    return {
      type: actions.CLIENT_PASSWORDS_CLEANUP_SUCCESS,
    };
  },

  clientPasswordsCleanupErr: err => {
    return {
      type: actions.CLIENT_PASSWORDS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_PASSWORDS_SAVE_BEGIN: 'CLIENT_PASSWORDS_SAVE_BEGIN',
  CLIENT_PASSWORDS_SAVE_SUCCESS: 'CLIENT_PASSWORDS_SAVE_SUCCESS',
  CLIENT_PASSWORDS_SAVE_ERR: 'CLIENT_PASSWORDS_SAVE_ERR',

  clientPasswordsSaveBegin: () => {
    return {
      type: actions.CLIENT_PASSWORDS_SAVE_BEGIN,
    };
  },

  clientPasswordsSaveSuccess: data => {
    return {
      type: actions.CLIENT_PASSWORDS_SAVE_SUCCESS,
      data,
    };
  },

  clientPasswordsSaveErr: err => {
    return {
      type: actions.CLIENT_PASSWORDS_SAVE_ERR,
      err,
    };
  },

  CLIENT_PASSWORDS_DELETE_BEGIN: 'CLIENT_PASSWORDS_DELETE_BEGIN',
  CLIENT_PASSWORDS_DELETE_SUCCESS: 'CLIENT_PASSWORDS_DELETE_SUCCESS',
  CLIENT_PASSWORDS_DELETE_ERR: 'CLIENT_PASSWORDS_DELETE_ERR',

  clientPasswordsDeleteBegin: () => {
    return {
      type: actions.CLIENT_PASSWORDS_DELETE_BEGIN,
    };
  },

  clientPasswordsDeleteSuccess: data => {
    return {
      type: actions.CLIENT_PASSWORDS_DELETE_SUCCESS,
      data,
    };
  },

  clientPasswordsDeleteErr: err => {
    return {
      type: actions.CLIENT_PASSWORDS_DELETE_ERR,
      err,
    };
  },
};

export default actions;
