const actions = {
  CLIENTS_PAGINATION_UPDATE_BEGIN: 'CLIENTS_PAGINATION_UPDATE_BEGIN',
  CLIENTS_PAGINATION_UPDATE_SUCCESS: 'CLIENTS_PAGINATION_UPDATE_SUCCESS',
  CLIENTS_PAGINATION_UPDATE_ERR: 'CLIENTS_PAGINATION_UPDATE_ERR',

  clientsPaginationUpdateBegin: () => {
    return {
      type: actions.CLIENTS_PAGINATION_UPDATE_BEGIN,
    };
  },

  clientsPaginationUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  clientsPaginationUpdateErr: err => {
    return {
      type: actions.CLIENTS_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_FILTERS_UPDATE_BEGIN: 'CLIENTS_FILTERS_UPDATE_BEGIN',
  CLIENTS_FILTERS_UPDATE_SUCCESS: 'CLIENTS_FILTERS_UPDATE_SUCCESS',
  CLIENTS_FILTERS_UPDATE_ERR: 'CLIENTS_FILTERS_UPDATE_ERR',

  clientsFiltersUpdateBegin: () => {
    return {
      type: actions.CLIENTS_FILTERS_UPDATE_BEGIN,
    };
  },

  clientsFiltersUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  clientsFiltersUpdateErr: err => {
    return {
      type: actions.CLIENTS_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_SORTER_UPDATE_BEGIN: 'CLIENTS_SORTER_UPDATE_BEGIN',
  CLIENTS_SORTER_UPDATE_SUCCESS: 'CLIENTS_SORTER_UPDATE_SUCCESS',
  CLIENTS_SORTER_UPDATE_ERR: 'CLIENTS_SORTER_UPDATE_ERR',

  clientsSorterUpdateBegin: () => {
    return {
      type: actions.CLIENTS_SORTER_UPDATE_BEGIN,
    };
  },

  clientsSorterUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  clientsSorterUpdateErr: err => {
    return {
      type: actions.CLIENTS_SORTER_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_UPDATE_BEGIN: 'CLIENTS_UPDATE_BEGIN',
  CLIENTS_UPDATE_SUCCESS: 'CLIENTS_UPDATE_SUCCESS',
  CLIENTS_UPDATE_ERR: 'CLIENTS_UPDATE_ERR',

  clientsUpdateBegin: () => {
    return {
      type: actions.CLIENTS_UPDATE_BEGIN,
    };
  },

  clientsUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_UPDATE_SUCCESS,
      data,
    };
  },

  clientsUpdateErr: err => {
    return {
      type: actions.CLIENTS_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_CLEANUP_BEGIN: 'CLIENTS_CLEANUP_BEGIN',
  CLIENTS_CLEANUP_SUCCESS: 'CLIENTS_CLEANUP_SUCCESS',
  CLIENTS_CLEANUP_ERR: 'CLIENTS_CLEANUP_ERR',

  clientsCleanupBegin: () => {
    return {
      type: actions.CLIENTS_CLEANUP_BEGIN,
    };
  },

  clientsCleanupSuccess: () => {
    return {
      type: actions.CLIENTS_CLEANUP_SUCCESS,
    };
  },

  clientsCleanupErr: err => {
    return {
      type: actions.CLIENTS_CLEANUP_ERR,
      err,
    };
  },

  CLIENTS_REPORT_FILTERS_UPDATE_BEGIN: 'CLIENTS_REPORT_FILTERS_UPDATE_BEGIN',
  CLIENTS_REPORT_FILTERS_UPDATE_SUCCESS: 'CLIENTS_REPORT_FILTERS_UPDATE_SUCCESS',
  CLIENTS_REPORT_FILTERS_UPDATE_ERR: 'CLIENTS_REPORT_FILTERS_UPDATE_ERR',

  clientsReportFiltersUpdateBegin: () => {
    return {
      type: actions.CLIENTS_REPORT_FILTERS_UPDATE_BEGIN,
    };
  },

  clientsReportFiltersUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_REPORT_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  clientsReportFiltersUpdateErr: err => {
    return {
      type: actions.CLIENTS_REPORT_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_REPORT_SORTER_UPDATE_BEGIN: 'CLIENTS_REPORT_SORTER_UPDATE_BEGIN',
  CLIENTS_REPORT_SORTER_UPDATE_SUCCESS: 'CLIENTS_REPORT_SORTER_UPDATE_SUCCESS',
  CLIENTS_REPORT_SORTER_UPDATE_ERR: 'CLIENTS_REPORT_SORTER_UPDATE_ERR',

  clientsReportSorterUpdateBegin: () => {
    return {
      type: actions.CLIENTS_REPORT_SORTER_UPDATE_BEGIN,
    };
  },

  clientsReportSorterUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_REPORT_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  clientsReportSorterUpdateErr: err => {
    return {
      type: actions.CLIENTS_REPORT_SORTER_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_REPORT_UPDATE_BEGIN: 'CLIENTS_REPORT_UPDATE_BEGIN',
  CLIENTS_REPORT_UPDATE_SUCCESS: 'CLIENTS_REPORT_UPDATE_SUCCESS',
  CLIENTS_REPORT_UPDATE_ERR: 'CLIENTS_REPORT_UPDATE_ERR',

  clientsReportUpdateBegin: () => {
    return {
      type: actions.CLIENTS_REPORT_UPDATE_BEGIN,
    };
  },

  clientsReportUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  clientsReportUpdateErr: err => {
    return {
      type: actions.CLIENTS_REPORT_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_REPORT_CLEANUP_BEGIN: 'CLIENTS_REPORT_CLEANUP_BEGIN',
  CLIENTS_REPORT_CLEANUP_SUCCESS: 'CLIENTS_REPORT_CLEANUP_SUCCESS',
  CLIENTS_REPORT_CLEANUP_ERR: 'CLIENTS_REPORT_CLEANUP_ERR',

  clientsReportCleanupBegin: () => {
    return {
      type: actions.CLIENTS_REPORT_CLEANUP_BEGIN,
    };
  },

  clientsReportCleanupSuccess: () => {
    return {
      type: actions.CLIENTS_REPORT_CLEANUP_SUCCESS,
    };
  },

  clientsReportCleanupErr: err => {
    return {
      type: actions.CLIENTS_REPORT_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_DELETE_BEGIN: 'CLIENT_DELETE_BEGIN',
  CLIENT_DELETE_SUCCESS: 'CLIENT_DELETE_SUCCESS',
  CLIENT_DELETE_ERR: 'CLIENT_DELETE_ERR',

  clientDeleteBegin: () => {
    return {
      type: actions.CLIENT_DELETE_BEGIN,
    };
  },

  clientDeleteSuccess: data => {
    return {
      type: actions.CLIENT_DELETE_SUCCESS,
      data,
    };
  },

  clientDeleteErr: err => {
    return {
      type: actions.CLIENT_DELETE_ERR,
      err,
    };
  },

  CLIENT_UPDATE_BEGIN: 'CLIENT_UPDATE_BEGIN',
  CLIENT_UPDATE_SUCCESS: 'CLIENT_UPDATE_SUCCESS',
  CLIENT_UPDATE_ERR: 'CLIENT_UPDATE_ERR',

  clientUpdateBegin: () => {
    return {
      type: actions.CLIENT_UPDATE_BEGIN,
    };
  },

  clientUpdateSuccess: data => {
    return {
      type: actions.CLIENT_UPDATE_SUCCESS,
      data,
    };
  },

  clientUpdateErr: err => {
    return {
      type: actions.CLIENT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_IMPORT_BEGIN: 'CLIENT_IMPORT_BEGIN',
  CLIENT_IMPORT_SUCCESS: 'CLIENT_IMPORT_SUCCESS',
  CLIENT_IMPORT_ERR: 'CLIENT_IMPORT_ERR',

  clientImportBegin: () => {
    return {
      type: actions.CLIENT_IMPORT_BEGIN,
    };
  },

  clientImportSuccess: data => {
    return {
      type: actions.CLIENT_IMPORT_SUCCESS,
      data,
    };
  },

  clientImportErr: err => {
    return {
      type: actions.CLIENT_IMPORT_ERR,
      err,
    };
  },

  CLIENT_CLEANUP_BEGIN: 'CLIENT_CLEANUP_BEGIN',
  CLIENT_CLEANUP_SUCCESS: 'CLIENT_CLEANUP_SUCCESS',
  CLIENT_CLEANUP_ERR: 'CLIENT_CLEANUP_ERR',

  clientCleanupBegin: () => {
    return {
      type: actions.CLIENT_CLEANUP_BEGIN,
    };
  },

  clientCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CLEANUP_SUCCESS,
    };
  },

  clientCleanupErr: err => {
    return {
      type: actions.CLIENT_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_SAVE_BEGIN: 'CLIENT_SAVE_BEGIN',
  CLIENT_SAVE_SUCCESS: 'CLIENT_SAVE_SUCCESS',
  CLIENT_SAVE_ERR: 'CLIENT_SAVE_ERR',

  clientSaveBegin: () => {
    return {
      type: actions.CLIENT_SAVE_BEGIN,
    };
  },

  clientSaveSuccess: data => {
    return {
      type: actions.CLIENT_SAVE_SUCCESS,
      data,
    };
  },

  clientSaveErr: err => {
    return {
      type: actions.CLIENT_SAVE_ERR,
      err,
    };
  },

  CLIENTS_LIST_UPDATE_BEGIN: 'CLIENTS_LIST_UPDATE_BEGIN',
  CLIENTS_LIST_UPDATE_SUCCESS: 'CLIENTS_LIST_UPDATE_SUCCESS',
  CLIENTS_LIST_UPDATE_ERR: 'CLIENTS_LIST_UPDATE_ERR',

  clientsListUpdateBegin: () => {
    return {
      type: actions.CLIENTS_LIST_UPDATE_BEGIN,
    };
  },

  clientsListUpdateSuccess: data => {
    return {
      type: actions.CLIENTS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientsListUpdateErr: err => {
    return {
      type: actions.CLIENTS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENTS_LIST_CLEANUP_BEGIN: 'CLIENTS_LIST_CLEANUP_BEGIN',
  CLIENTS_LIST_CLEANUP_SUCCESS: 'CLIENTS_LIST_CLEANUP_SUCCESS',
  CLIENTS_LIST_CLEANUP_ERR: 'CLIENTS_LIST_CLEANUP_ERR',

  clientsListCleanupBegin: () => {
    return {
      type: actions.CLIENTS_LIST_CLEANUP_BEGIN,
    };
  },

  clientsListCleanupSuccess: () => {
    return {
      type: actions.CLIENTS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientsListCleanupErr: err => {
    return {
      type: actions.CLIENTS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
