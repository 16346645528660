import React, { useState } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import MenuAdmin from './MenuAdmin';
import MenuClient from './MenuClient';
import { isUserClient, isUserEmployee } from '../config/acl/functions';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const { identity } = useSelector(state => {
    return {
      identity: state.auth.identity,
    };
  });

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const selected =
    mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit.join('/');

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={!topMenu ? [selected] : []}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'home'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {identity.data?.user ? (
        isUserEmployee(identity.data?.user) ? (
          <MenuAdmin toggleCollapsed={toggleCollapsed} topMenu={topMenu} />
        ) : isUserClient(identity.data?.user) ? (
          <MenuClient toggleCollapsed={toggleCollapsed} topMenu={topMenu} />
        ) : (
          <>Perfil do usuário não identificado</>
        )
      ) : (
        <>Usuário não identificado</>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
