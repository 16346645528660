import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  CONTACTS_UPDATE_BEGIN,
  CONTACTS_UPDATE_SUCCESS,
  CONTACTS_UPDATE_ERR,

  CONTACTS_CLEANUP_BEGIN,
  CONTACTS_CLEANUP_SUCCESS,
  CONTACTS_CLEANUP_ERR,

  CONTACT_DELETE_BEGIN,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_ERR,
} = actions;

const contactsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CONTACTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CONTACTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CONTACTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CONTACTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CONTACTS_CLEANUP_SUCCESS:
      return initialState;

    case CONTACTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CONTACT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CONTACT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CONTACT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CONTACT_UPDATE_BEGIN,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_ERR,

  CONTACT_CLEANUP_BEGIN,
  CONTACT_CLEANUP_SUCCESS,
  CONTACT_CLEANUP_ERR,

  CONTACT_SAVE_BEGIN,
  CONTACT_SAVE_SUCCESS,
  CONTACT_SAVE_ERR,
} = actions;

const contactReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CONTACT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CONTACT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CONTACT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CONTACT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CONTACT_CLEANUP_SUCCESS:
      return initialSingleState;

    case CONTACT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CONTACT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CONTACT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CONTACT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { contactsReducer, contactReducer };
