import { theme, darkTheme } from './theme/themeVariables';

const validateMessages = {
  required: 'Preencha este campo',
};

const config = {
  darkMode: true,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  validateMessages,
};

export default config;
