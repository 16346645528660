import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  downloading: false,
  deleting: false,
  error: null,
};

const {
  FILES_PAGINATION_UPDATE_SUCCESS,
  FILES_FILTERS_UPDATE_SUCCESS,
  FILES_SORTER_UPDATE_SUCCESS,

  FILES_UPDATE_BEGIN,
  FILES_UPDATE_SUCCESS,
  FILES_UPDATE_ERR,

  FILES_DOWNLOAD_BEGIN,
  FILES_DOWNLOAD_SUCCESS,
  FILES_DOWNLOAD_ERR,

  FILES_CLEANUP_BEGIN,
  FILES_CLEANUP_SUCCESS,
  FILES_CLEANUP_ERR,
} = actions;

const filesReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILES_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case FILES_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case FILES_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case FILES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILES_DOWNLOAD_BEGIN:
      return {
        ...state,
        downloading: true,
      };

    case FILES_DOWNLOAD_SUCCESS:
      return {
        ...state,
        blob: data.blob,
        base64: data.base64,
        downloading: false,
      };

    case FILES_DOWNLOAD_ERR:
      return {
        ...state,
        error: err,
        downloading: false,
      };

    case FILES_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILES_CLEANUP_SUCCESS:
      return initialIndexState;

    case FILES_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  FILES_LIST_UPDATE_BEGIN,
  FILES_LIST_UPDATE_SUCCESS,
  FILES_LIST_UPDATE_ERR,

  FILES_LIST_CLEANUP_BEGIN,
  FILES_LIST_CLEANUP_SUCCESS,
  FILES_LIST_CLEANUP_ERR,

  FILE_UPLOAD_BEGIN,
  FILE_UPLOAD_UPDATE,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERR,

  FILE_DELETE_BEGIN,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_ERR,
} = actions;

const filesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILES_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case FILES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_UPLOAD_BEGIN:
      return {
        ...state,
        data,
      };

    case FILE_UPLOAD_UPDATE:
      return {
        ...state,
        data,
      };

    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        data,
      };

    case FILE_UPLOAD_ERR:
      return {
        ...state,
        error: err,
      };

    case FILE_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case FILE_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case FILE_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  blob: null,
  base64: null,
  loading: false,
  saving: false,
  downloading: false,
  error: null,
};

const {
  FILE_UPDATE_BEGIN,
  FILE_UPDATE_SUCCESS,
  FILE_UPDATE_ERR,

  FILE_DOWNLOAD_BEGIN,
  FILE_DOWNLOAD_SUCCESS,
  FILE_DOWNLOAD_ERR,

  FILE_CLEANUP_BEGIN,
  FILE_CLEANUP_SUCCESS,
  FILE_CLEANUP_ERR,

  FILE_SAVE_BEGIN,
  FILE_SAVE_SUCCESS,
  FILE_SAVE_ERR,
} = actions;

const fileReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILE_CLEANUP_SUCCESS:
      return initialSingleState;

    case FILE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILE_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case FILE_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case FILE_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    case FILE_DOWNLOAD_BEGIN:
      return {
        ...state,
        downloading: true,
      };

    case FILE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        blob: data.blob,
        base64: data.base64,
        downloading: false,
      };

    case FILE_DOWNLOAD_ERR:
      return {
        ...state,
        error: err,
        downloading: false,
      };

    default:
      return state;
  }
};

const initialChartState = {
  data: [],
  loading: false,
  error: null,
};

const {
  FILES_CHARTS_UPDATE_BEGIN,
  FILES_CHARTS_UPDATE_SUCCESS,
  FILES_CHARTS_UPDATE_ERR,

  FILES_CHARTS_CLEANUP_BEGIN,
  FILES_CHARTS_CLEANUP_SUCCESS,
  FILES_CHARTS_CLEANUP_ERR,
} = actions;

const filesChartsReducer = (state = initialChartState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILES_CHARTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILES_CHARTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case FILES_CHARTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FILES_CHARTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FILES_CHARTS_CLEANUP_SUCCESS:
      return initialChartState;

    case FILES_CHARTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { filesReducer, filesListReducer, fileReducer, filesChartsReducer };
