import { Descriptions, Popover, Skeleton, Space, Tag, Tooltip, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { renderUserType, translateUserDepartment } from './functions';
import format from '../../config/format';
// eslint-disable-next-line import/no-cycle
import { renderPicture, renderStatus, renderUserLink } from '../../container/users/functions';
import { Copyable, listGroup } from '../utilities/utilities';
import { isUserEmployee } from '../../config/acl/functions';
import api from '../../config/api';
import { EntityCard } from '../../container/styled-custom';
import { renderAchievements } from '../achievements/functions';

const messageKey = 'users';

const { Text } = Typography;

const UserPopover = ({ id, children }) => {
  const { identity } = useSelector(state => {
    return {
      identity: state.auth.identity,
    };
  });

  const [isLoading, setLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getUser(id) {
      api.Users.get(id)
        .then(response => {
          if (response.status === 200) {
            setUser(response.data.user);
          } else if (response.data && response.data.message) {
            message.error({ content: response.data.message, key: messageKey });
          }
        })
        .catch(err => {
          message.error({ content: err.toString(), key: messageKey });
        });
      setLoading(true);
    }

    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (isShown && !isLoading && !user && id) {
      getUser(id);
    }
  }, [id, user, isShown, isLoading]);

  const [isClient, setIsClient] = useState(undefined);
  useEffect(() => {
    setIsClient(user ? !isUserEmployee(user) : undefined);
  }, [user]);

  return (
    <Popover
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      overlayClassName="popover-card"
      content={
        !user ? (
          <Skeleton active paragraph={{ rows: 5 }} style={{ width: 360 }} />
        ) : (
          <>
            <EntityCard
              bordered={false}
              style={{
                marginBottom: 12,
              }}
            >
              <EntityCard.Meta
                avatar={renderPicture(user, 64)}
                title={
                  <Space>
                    {renderUserLink(user, isUserEmployee(identity.data?.user), false, undefined, false)}
                    {renderUserType(user)}
                    {!!user?.external && <Tag color="processing">Externo</Tag>}
                  </Space>
                }
                description={
                  <>
                    <div className="user-departments">
                      {user?.users_departments && (
                        <>
                          {isClient && 'Cliente em '}
                          {listGroup(
                            user?.users_departments // setor, setor...
                              .map(users_department =>
                                isClient ? users_department.department.name : translateUserDepartment(users_department),
                              ),
                            2,
                          )}
                        </>
                      )}
                    </div>
                    <Copyable
                      copyable={{ text: user.email }}
                      onClick={e => {
                        window.location.href = `mailto:${user.email}`;
                        e.preventDefault();
                      }}
                    >
                      {user.email}
                    </Copyable>
                    <Space direction="vertical" size={0}>
                      {user?.phones.map(phone => (
                        <Copyable
                          key={`phone-${phone.id}`}
                          copyable={{ text: phone.number }}
                          onClick={e => {
                            window.location.href = `tel:${phone.number}`;
                            e.preventDefault();
                          }}
                        >
                          {phone.number}
                        </Copyable>
                      ))}
                    </Space>

                    {renderAchievements(user?.achievements, 40, 6)}
                  </>
                }
              />
            </EntityCard>
            <Descriptions size="small" column={1} bordered>
              <Descriptions.Item label="ID">{user?.id}</Descriptions.Item>
              <Descriptions.Item label="Status">{renderStatus(user)}</Descriptions.Item>

              <Descriptions.Item label="Último Login">
                {user?.last_login_at && (
                  <Text type="secondary">
                    <Tooltip title={<Moment format={format.input.dateTime}>{user?.last_login_at}</Moment>}>
                      <Moment style={{ textDecoration: 'underline dotted' }} fromNow>
                        {user?.last_login_at}
                      </Moment>
                    </Tooltip>
                  </Text>
                )}
              </Descriptions.Item>

              <Descriptions.Item label="Visto Por Último">
                {user?.seen_at && (
                  <Text type="secondary">
                    <Tooltip title={<Moment format={format.input.dateTime}>{user?.seen_at}</Moment>}>
                      <Moment style={{ textDecoration: 'underline dotted' }} fromNow>
                        {user?.seen_at}
                      </Moment>
                    </Tooltip>
                  </Text>
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )
      }
    >
      {children}
    </Popover>
  );
};

UserPopover.propTypes = {
  id: PropTypes.number,
};

export default UserPopover;
