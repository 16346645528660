const actions = {
  CLIENT_DIGITAL_CERTIFICATES_UPDATE_BEGIN: 'CLIENT_DIGITAL_CERTIFICATES_UPDATE_BEGIN',
  CLIENT_DIGITAL_CERTIFICATES_UPDATE_SUCCESS: 'CLIENT_DIGITAL_CERTIFICATES_UPDATE_SUCCESS',
  CLIENT_DIGITAL_CERTIFICATES_UPDATE_ERR: 'CLIENT_DIGITAL_CERTIFICATES_UPDATE_ERR',

  clientDigitalCertificatesUpdateBegin: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATES_UPDATE_BEGIN,
    };
  },

  clientDigitalCertificatesUpdateSuccess: data => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATES_UPDATE_SUCCESS,
      data,
    };
  },

  clientDigitalCertificatesUpdateErr: err => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATES_UPDATE_ERR,
      err,
    };
  },

  CLIENT_DIGITAL_CERTIFICATES_CLEANUP_BEGIN: 'CLIENT_DIGITAL_CERTIFICATES_CLEANUP_BEGIN',
  CLIENT_DIGITAL_CERTIFICATES_CLEANUP_SUCCESS: 'CLIENT_DIGITAL_CERTIFICATES_CLEANUP_SUCCESS',
  CLIENT_DIGITAL_CERTIFICATES_CLEANUP_ERR: 'CLIENT_DIGITAL_CERTIFICATES_CLEANUP_ERR',

  clientDigitalCertificatesCleanupBegin: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATES_CLEANUP_BEGIN,
    };
  },

  clientDigitalCertificatesCleanupSuccess: data => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATES_CLEANUP_SUCCESS,
      data,
    };
  },

  clientDigitalCertificatesCleanupErr: err => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATES_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_DIGITAL_CERTIFICATE_UPDATE_BEGIN: 'CLIENT_DIGITAL_CERTIFICATE_UPDATE_BEGIN',
  CLIENT_DIGITAL_CERTIFICATE_UPDATE_SUCCESS: 'CLIENT_DIGITAL_CERTIFICATE_UPDATE_SUCCESS',
  CLIENT_DIGITAL_CERTIFICATE_UPDATE_ERR: 'CLIENT_DIGITAL_CERTIFICATE_UPDATE_ERR',

  clientDigitalCertificateUpdateBegin: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_UPDATE_BEGIN,
    };
  },

  clientDigitalCertificateUpdateSuccess: data => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_UPDATE_SUCCESS,
      data,
    };
  },

  clientDigitalCertificateUpdateErr: err => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_UPDATE_ERR,
      err,
    };
  },

  CLIENT_DIGITAL_CERTIFICATE_CLEANUP_BEGIN: 'CLIENT_DIGITAL_CERTIFICATE_CLEANUP_BEGIN',
  CLIENT_DIGITAL_CERTIFICATE_CLEANUP_SUCCESS: 'CLIENT_DIGITAL_CERTIFICATE_CLEANUP_SUCCESS',
  CLIENT_DIGITAL_CERTIFICATE_CLEANUP_ERR: 'CLIENT_DIGITAL_CERTIFICATE_CLEANUP_ERR',

  clientDigitalCertificateCleanupBegin: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_CLEANUP_BEGIN,
    };
  },

  clientDigitalCertificateCleanupSuccess: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_CLEANUP_SUCCESS,
    };
  },

  clientDigitalCertificateCleanupErr: err => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_DIGITAL_CERTIFICATE_SAVE_BEGIN: 'CLIENT_DIGITAL_CERTIFICATE_SAVE_BEGIN',
  CLIENT_DIGITAL_CERTIFICATE_SAVE_SUCCESS: 'CLIENT_DIGITAL_CERTIFICATE_SAVE_SUCCESS',
  CLIENT_DIGITAL_CERTIFICATE_SAVE_ERR: 'CLIENT_DIGITAL_CERTIFICATE_SAVE_ERR',

  clientDigitalCertificateSaveBegin: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_SAVE_BEGIN,
    };
  },

  clientDigitalCertificateSaveSuccess: data => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_SAVE_SUCCESS,
      data,
    };
  },

  clientDigitalCertificateSaveErr: err => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_SAVE_ERR,
      err,
    };
  },

  CLIENT_DIGITAL_CERTIFICATE_DELETE_BEGIN: 'CLIENT_DIGITAL_CERTIFICATE_DELETE_BEGIN',
  CLIENT_DIGITAL_CERTIFICATE_DELETE_SUCCESS: 'CLIENT_DIGITAL_CERTIFICATE_DELETE_SUCCESS',
  CLIENT_DIGITAL_CERTIFICATE_DELETE_ERR: 'CLIENT_DIGITAL_CERTIFICATE_DELETE_ERR',

  clientDigitalCertificateDeleteBegin: () => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_DELETE_BEGIN,
    };
  },

  clientDigitalCertificateDeleteSuccess: data => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_DELETE_SUCCESS,
      data,
    };
  },

  clientDigitalCertificateDeleteErr: err => {
    return {
      type: actions.CLIENT_DIGITAL_CERTIFICATE_DELETE_ERR,
      err,
    };
  },
};

export default actions;
  