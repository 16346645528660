const actions = {
  CLIENT_CONTRACTS_UPDATE_BEGIN: 'CLIENT_CONTRACTS_UPDATE_BEGIN',
  CLIENT_CONTRACTS_UPDATE_SUCCESS: 'CLIENT_CONTRACTS_UPDATE_SUCCESS',
  CLIENT_CONTRACTS_UPDATE_ERR: 'CLIENT_CONTRACTS_UPDATE_ERR',

  clientContractsUpdateBegin: () => {
    return {
      type: actions.CLIENT_CONTRACTS_UPDATE_BEGIN,
    };
  },

  clientContractsUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACTS_UPDATE_SUCCESS,
      data,
    };
  },

  clientContractsUpdateErr: err => {
    return {
      type: actions.CLIENT_CONTRACTS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CONTRACTS_CLEANUP_BEGIN: 'CLIENT_CONTRACTS_CLEANUP_BEGIN',
  CLIENT_CONTRACTS_CLEANUP_SUCCESS: 'CLIENT_CONTRACTS_CLEANUP_SUCCESS',
  CLIENT_CONTRACTS_CLEANUP_ERR: 'CLIENT_CONTRACTS_CLEANUP_ERR',

  clientContractsCleanupBegin: () => {
    return {
      type: actions.CLIENT_CONTRACTS_CLEANUP_BEGIN,
    };
  },

  clientContractsCleanupSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACTS_CLEANUP_SUCCESS,
      data,
    };
  },

  clientContractsCleanupErr: err => {
    return {
      type: actions.CLIENT_CONTRACTS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_CONTRACTS_CHARTS_UPDATE_BEGIN: 'CLIENT_CONTRACTS_CHARTS_UPDATE_BEGIN',
  CLIENT_CONTRACTS_CHARTS_UPDATE_SUCCESS: 'CLIENT_CONTRACTS_CHARTS_UPDATE_SUCCESS',
  CLIENT_CONTRACTS_CHARTS_UPDATE_ERR: 'CLIENT_CONTRACTS_CHARTS_UPDATE_ERR',

  clientContractsChartsUpdateBegin: () => {
    return {
      type: actions.CLIENT_CONTRACTS_CHARTS_UPDATE_BEGIN,
    };
  },

  clientContractsChartsUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACTS_CHARTS_UPDATE_SUCCESS,
      data,
    };
  },

  clientContractsChartsUpdateErr: err => {
    return {
      type: actions.CLIENT_CONTRACTS_CHARTS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CONTRACTS_CHARTS_CLEANUP_BEGIN: 'CLIENT_CONTRACTS_CHARTS_CLEANUP_BEGIN',
  CLIENT_CONTRACTS_CHARTS_CLEANUP_SUCCESS: 'CLIENT_CONTRACTS_CHARTS_CLEANUP_SUCCESS',
  CLIENT_CONTRACTS_CHARTS_CLEANUP_ERR: 'CLIENT_CONTRACTS_CHARTS_CLEANUP_ERR',

  clientContractsChartsCleanupBegin: () => {
    return {
      type: actions.CLIENT_CONTRACTS_CHARTS_CLEANUP_BEGIN,
    };
  },

  clientContractsChartsCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CONTRACTS_CHARTS_CLEANUP_SUCCESS,
    };
  },

  clientContractsChartsCleanupErr: err => {
    return {
      type: actions.CLIENT_CONTRACTS_CHARTS_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_CONTRACT_UPDATE_BEGIN: 'CLIENT_CONTRACT_UPDATE_BEGIN',
  CLIENT_CONTRACT_UPDATE_SUCCESS: 'CLIENT_CONTRACT_UPDATE_SUCCESS',
  CLIENT_CONTRACT_UPDATE_ERR: 'CLIENT_CONTRACT_UPDATE_ERR',

  clientContractUpdateBegin: () => {
    return {
      type: actions.CLIENT_CONTRACT_UPDATE_BEGIN,
    };
  },

  clientContractUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACT_UPDATE_SUCCESS,
      data,
    };
  },

  clientContractUpdateErr: err => {
    return {
      type: actions.CLIENT_CONTRACT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CONTRACT_CLEANUP_BEGIN: 'CLIENT_CONTRACT_CLEANUP_BEGIN',
  CLIENT_CONTRACT_CLEANUP_SUCCESS: 'CLIENT_CONTRACT_CLEANUP_SUCCESS',
  CLIENT_CONTRACT_CLEANUP_ERR: 'CLIENT_CONTRACT_CLEANUP_ERR',

  clientContractCleanupBegin: () => {
    return {
      type: actions.CLIENT_CONTRACT_CLEANUP_BEGIN,
    };
  },

  clientContractCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CONTRACT_CLEANUP_SUCCESS,
    };
  },

  clientContractCleanupErr: err => {
    return {
      type: actions.CLIENT_CONTRACT_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_CONTRACT_SAVE_BEGIN: 'CLIENT_CONTRACT_SAVE_BEGIN',
  CLIENT_CONTRACT_SAVE_SUCCESS: 'CLIENT_CONTRACT_SAVE_SUCCESS',
  CLIENT_CONTRACT_SAVE_ERR: 'CLIENT_CONTRACT_SAVE_ERR',

  clientContractSaveBegin: () => {
    return {
      type: actions.CLIENT_CONTRACT_SAVE_BEGIN,
    };
  },

  clientContractSaveSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACT_SAVE_SUCCESS,
      data,
    };
  },

  clientContractSaveErr: err => {
    return {
      type: actions.CLIENT_CONTRACT_SAVE_ERR,
      err,
    };
  },

  CLIENT_CONTRACT_DELETE_BEGIN: 'CLIENT_CONTRACT_DELETE_BEGIN',
  CLIENT_CONTRACT_DELETE_SUCCESS: 'CLIENT_CONTRACT_DELETE_SUCCESS',
  CLIENT_CONTRACT_DELETE_ERR: 'CLIENT_CONTRACT_DELETE_ERR',

  clientContractDeleteBegin: () => {
    return {
      type: actions.CLIENT_CONTRACT_DELETE_BEGIN,
    };
  },

  clientContractDeleteSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACT_DELETE_SUCCESS,
      data,
    };
  },

  clientContractDeleteErr: err => {
    return {
      type: actions.CLIENT_CONTRACT_DELETE_ERR,
      err,
    };
  },

  CLIENT_CONTRACTS_REPORT_UPDATE_BEGIN: 'CLIENT_CONTRACTS_REPORT_UPDATE_BEGIN',
  CLIENT_CONTRACTS_REPORT_UPDATE_SUCCESS: 'CLIENT_CONTRACTS_REPORT_UPDATE_SUCCESS',
  CLIENT_CONTRACTS_REPORT_UPDATE_ERR: 'CLIENT_CONTRACTS_REPORT_UPDATE_ERR',

  clientContractsReportUpdateBegin: () => {
    return {
      type: actions.CLIENT_CONTRACTS_REPORT_UPDATE_BEGIN,
    };
  },

  clientContractsReportUpdateSuccess: (data, filters) => {
    return {
      type: actions.CLIENT_CONTRACTS_REPORT_UPDATE_SUCCESS,
      data,
      filters,
    };
  },

  clientContractsReportUpdateErr: err => {
    return {
      type: actions.CLIENT_CONTRACTS_REPORT_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CONTRACTS_REPORT_CLEANUP_BEGIN: 'CLIENT_CONTRACTS_REPORT_CLEANUP_BEGIN',
  CLIENT_CONTRACTS_REPORT_CLEANUP_SUCCESS: 'CLIENT_CONTRACTS_REPORT_CLEANUP_SUCCESS',
  CLIENT_CONTRACTS_REPORT_CLEANUP_ERR: 'CLIENT_CONTRACTS_REPORT_CLEANUP_ERR',

  clientContractsReportCleanupBegin: () => {
    return {
      type: actions.CLIENT_CONTRACTS_REPORT_CLEANUP_BEGIN,
    };
  },

  clientContractsReportCleanupSuccess: data => {
    return {
      type: actions.CLIENT_CONTRACTS_REPORT_CLEANUP_SUCCESS,
      data,
    };
  },

  clientContractsReportCleanupErr: err => {
    return {
      type: actions.CLIENT_CONTRACTS_REPORT_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
