const actions = {
  CLIENT_LICENSES_UPDATE_BEGIN: 'CLIENT_LICENSES_UPDATE_BEGIN',
  CLIENT_LICENSES_UPDATE_SUCCESS: 'CLIENT_LICENSES_UPDATE_SUCCESS',
  CLIENT_LICENSES_UPDATE_ERR: 'CLIENT_LICENSES_UPDATE_ERR',

  clientLicensesUpdateBegin: () => {
    return {
      type: actions.CLIENT_LICENSES_UPDATE_BEGIN,
    };
  },

  clientLicensesUpdateSuccess: data => {
    return {
      type: actions.CLIENT_LICENSES_UPDATE_SUCCESS,
      data,
    };
  },

  clientLicensesUpdateErr: err => {
    return {
      type: actions.CLIENT_LICENSES_UPDATE_ERR,
      err,
    };
  },

  CLIENT_LICENSES_CLEANUP_BEGIN: 'CLIENT_LICENSES_CLEANUP_BEGIN',
  CLIENT_LICENSES_CLEANUP_SUCCESS: 'CLIENT_LICENSES_CLEANUP_SUCCESS',
  CLIENT_LICENSES_CLEANUP_ERR: 'CLIENT_LICENSES_CLEANUP_ERR',

  clientLicensesCleanupBegin: () => {
    return {
      type: actions.CLIENT_LICENSES_CLEANUP_BEGIN,
    };
  },

  clientLicensesCleanupSuccess: data => {
    return {
      type: actions.CLIENT_LICENSES_CLEANUP_SUCCESS,
      data,
    };
  },

  clientLicensesCleanupErr: err => {
    return {
      type: actions.CLIENT_LICENSES_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_LICENSE_UPDATE_BEGIN: 'CLIENT_LICENSE_UPDATE_BEGIN',
  CLIENT_LICENSE_UPDATE_SUCCESS: 'CLIENT_LICENSE_UPDATE_SUCCESS',
  CLIENT_LICENSE_UPDATE_ERR: 'CLIENT_LICENSE_UPDATE_ERR',

  clientLicenseUpdateBegin: () => {
    return {
      type: actions.CLIENT_LICENSE_UPDATE_BEGIN,
    };
  },

  clientLicenseUpdateSuccess: data => {
    return {
      type: actions.CLIENT_LICENSE_UPDATE_SUCCESS,
      data,
    };
  },

  clientLicenseUpdateErr: err => {
    return {
      type: actions.CLIENT_LICENSE_UPDATE_ERR,
      err,
    };
  },

  CLIENT_LICENSE_CLEANUP_BEGIN: 'CLIENT_LICENSE_CLEANUP_BEGIN',
  CLIENT_LICENSE_CLEANUP_SUCCESS: 'CLIENT_LICENSE_CLEANUP_SUCCESS',
  CLIENT_LICENSE_CLEANUP_ERR: 'CLIENT_LICENSE_CLEANUP_ERR',

  clientLicenseCleanupBegin: () => {
    return {
      type: actions.CLIENT_LICENSE_CLEANUP_BEGIN,
    };
  },

  clientLicenseCleanupSuccess: () => {
    return {
      type: actions.CLIENT_LICENSE_CLEANUP_SUCCESS,
    };
  },

  clientLicenseCleanupErr: err => {
    return {
      type: actions.CLIENT_LICENSE_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_LICENSE_SAVE_BEGIN: 'CLIENT_LICENSE_SAVE_BEGIN',
  CLIENT_LICENSE_SAVE_SUCCESS: 'CLIENT_LICENSE_SAVE_SUCCESS',
  CLIENT_LICENSE_SAVE_ERR: 'CLIENT_LICENSE_SAVE_ERR',

  clientLicenseSaveBegin: () => {
    return {
      type: actions.CLIENT_LICENSE_SAVE_BEGIN,
    };
  },

  clientLicenseSaveSuccess: data => {
    return {
      type: actions.CLIENT_LICENSE_SAVE_SUCCESS,
      data,
    };
  },

  clientLicenseSaveErr: err => {
    return {
      type: actions.CLIENT_LICENSE_SAVE_ERR,
      err,
    };
  },

  CLIENT_LICENSE_DELETE_BEGIN: 'CLIENT_LICENSE_DELETE_BEGIN',
  CLIENT_LICENSE_DELETE_SUCCESS: 'CLIENT_LICENSE_DELETE_SUCCESS',
  CLIENT_LICENSE_DELETE_ERR: 'CLIENT_LICENSE_DELETE_ERR',

  clientLicenseDeleteBegin: () => {
    return {
      type: actions.CLIENT_LICENSE_DELETE_BEGIN,
    };
  },

  clientLicenseDeleteSuccess: data => {
    return {
      type: actions.CLIENT_LICENSE_DELETE_SUCCESS,
      data,
    };
  },

  clientLicenseDeleteErr: err => {
    return {
      type: actions.CLIENT_LICENSE_DELETE_ERR,
      err,
    };
  },
};

export default actions;
