import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_CONTRACTS_UPDATE_BEGIN,
  CLIENT_CONTRACTS_UPDATE_SUCCESS,
  CLIENT_CONTRACTS_UPDATE_ERR,

  CLIENT_CONTRACTS_CLEANUP_BEGIN,
  CLIENT_CONTRACTS_CLEANUP_SUCCESS,
  CLIENT_CONTRACTS_CLEANUP_ERR,

  CLIENT_CONTRACT_DELETE_BEGIN,
  CLIENT_CONTRACT_DELETE_SUCCESS,
  CLIENT_CONTRACT_DELETE_ERR,
} = actions;

const clientContractsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_CONTRACTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_CONTRACTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACTS_CLEANUP_SUCCESS:
      return initialState;

    case CLIENT_CONTRACTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CLIENT_CONTRACT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CLIENT_CONTRACT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialChartState = {
  data: [],
  loading: false,
  error: null,
};

const {
  CLIENT_CONTRACTS_CHARTS_UPDATE_BEGIN,
  CLIENT_CONTRACTS_CHARTS_UPDATE_SUCCESS,
  CLIENT_CONTRACTS_CHARTS_UPDATE_ERR,

  CLIENT_CONTRACTS_CHARTS_CLEANUP_BEGIN,
  CLIENT_CONTRACTS_CHARTS_CLEANUP_SUCCESS,
  CLIENT_CONTRACTS_CHARTS_CLEANUP_ERR,
} = actions;

const clientContractsChartsReducer = (state = initialChartState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_CONTRACTS_CHARTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACTS_CHARTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_CONTRACTS_CHARTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACTS_CHARTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACTS_CHARTS_CLEANUP_SUCCESS:
      return initialChartState;

    case CLIENT_CONTRACTS_CHARTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CLIENT_CONTRACT_UPDATE_BEGIN,
  CLIENT_CONTRACT_UPDATE_SUCCESS,
  CLIENT_CONTRACT_UPDATE_ERR,

  CLIENT_CONTRACT_CLEANUP_BEGIN,
  CLIENT_CONTRACT_CLEANUP_SUCCESS,
  CLIENT_CONTRACT_CLEANUP_ERR,

  CLIENT_CONTRACT_SAVE_BEGIN,
  CLIENT_CONTRACT_SAVE_SUCCESS,
  CLIENT_CONTRACT_SAVE_ERR,
} = actions;

const clientContractReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_CONTRACT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_CONTRACT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACT_CLEANUP_SUCCESS:
      return initialSingleState;

    case CLIENT_CONTRACT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CLIENT_CONTRACT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CLIENT_CONTRACT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

const initialReportState = {
  data: [],
  filters: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_CONTRACTS_REPORT_UPDATE_BEGIN,
  CLIENT_CONTRACTS_REPORT_UPDATE_SUCCESS,
  CLIENT_CONTRACTS_REPORT_UPDATE_ERR,

  CLIENT_CONTRACTS_REPORT_CLEANUP_BEGIN,
  CLIENT_CONTRACTS_REPORT_CLEANUP_SUCCESS,
  CLIENT_CONTRACTS_REPORT_CLEANUP_ERR,
} = actions;

const clientContractsReportReducer = (state = initialReportState, action) => {
  const { type, data, filters, err } = action;
  switch (type) {
    case CLIENT_CONTRACTS_REPORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACTS_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        filters,
        loading: false,
      };

    case CLIENT_CONTRACTS_REPORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CONTRACTS_REPORT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CONTRACTS_REPORT_CLEANUP_SUCCESS:
      return initialReportState;

    case CLIENT_CONTRACTS_REPORT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { clientContractsReducer, clientContractsChartsReducer, clientContractReducer, clientContractsReportReducer };
