const actions = {
  QUESTION_TYPES_LIST_UPDATE_BEGIN: 'QUESTION_TYPES_LIST_UPDATE_BEGIN',
  QUESTION_TYPES_LIST_UPDATE_SUCCESS: 'QUESTION_TYPES_LIST_UPDATE_SUCCESS',
  QUESTION_TYPES_LIST_UPDATE_ERR: 'QUESTION_TYPES_LIST_UPDATE_ERR',

  questionTypesListUpdateBegin: () => {
    return {
      type: actions.QUESTION_TYPES_LIST_UPDATE_BEGIN,
    };
  },

  questionTypesListUpdateSuccess: data => {
    return {
      type: actions.QUESTION_TYPES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  questionTypesListUpdateErr: err => {
    return {
      type: actions.QUESTION_TYPES_LIST_UPDATE_ERR,
      err,
    };
  },

  QUESTION_TYPES_LIST_CLEANUP_BEGIN: 'QUESTION_TYPES_LIST_CLEANUP_BEGIN',
  QUESTION_TYPES_LIST_CLEANUP_SUCCESS: 'QUESTION_TYPES_LIST_CLEANUP_SUCCESS',
  QUESTION_TYPES_LIST_CLEANUP_ERR: 'QUESTION_TYPES_LIST_CLEANUP_ERR',

  questionTypesListCleanupBegin: () => {
    return {
      type: actions.QUESTION_TYPES_LIST_CLEANUP_BEGIN,
    };
  },

  questionTypesListCleanupSuccess: () => {
    return {
      type: actions.QUESTION_TYPES_LIST_CLEANUP_SUCCESS,
    };
  },

  questionTypesListCleanupErr: err => {
    return {
      type: actions.QUESTION_TYPES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
