import actions from './actions';

const initialIndexState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  filters: {},
  sorter: {},
  loading: false,
  deleting: false,
  error: null,
};

const {
  SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_SUCCESS,

  SEFAZ_CE_DTE_MESSAGES_UPDATE_BEGIN,
  SEFAZ_CE_DTE_MESSAGES_UPDATE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGES_UPDATE_ERR,

  SEFAZ_CE_DTE_MESSAGES_CLEANUP_BEGIN,
  SEFAZ_CE_DTE_MESSAGES_CLEANUP_SUCCESS,
  SEFAZ_CE_DTE_MESSAGES_CLEANUP_ERR,

  SEFAZ_CE_DTE_MESSAGE_DELETE_BEGIN,
  SEFAZ_CE_DTE_MESSAGE_DELETE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGE_DELETE_ERR,
} = actions;

const sefazCeDteMessagesReducer = (state = initialIndexState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEFAZ_CE_DTE_MESSAGES_PAGINATION_UPDATE_SUCCESS:
      return {
        ...state,
        pagination: data,
      };

    case SEFAZ_CE_DTE_MESSAGES_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        filters: data,
      };

    case SEFAZ_CE_DTE_MESSAGES_SORTER_UPDATE_SUCCESS:
      return {
        ...state,
        sorter: data,
      };

    case SEFAZ_CE_DTE_MESSAGES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEFAZ_CE_DTE_MESSAGES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGES_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEFAZ_CE_DTE_MESSAGES_CLEANUP_SUCCESS:
      return initialIndexState;

    case SEFAZ_CE_DTE_MESSAGES_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGE_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case SEFAZ_CE_DTE_MESSAGE_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case SEFAZ_CE_DTE_MESSAGE_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialListState = {
  data: [],
  loading: false,
  error: null,
};

const {
  SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_BEGIN,
  SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_ERR,

  SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_BEGIN,
  SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_SUCCESS,
  SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_ERR,
} = actions;

const sefazCeDteMessagesListReducer = (state = initialListState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGES_LIST_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_SUCCESS:
      return initialListState;

    case SEFAZ_CE_DTE_MESSAGES_LIST_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  SEFAZ_CE_DTE_MESSAGE_UPDATE_BEGIN,
  SEFAZ_CE_DTE_MESSAGE_UPDATE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGE_UPDATE_ERR,

  SEFAZ_CE_DTE_MESSAGE_CLEANUP_BEGIN,
  SEFAZ_CE_DTE_MESSAGE_CLEANUP_SUCCESS,
  SEFAZ_CE_DTE_MESSAGE_CLEANUP_ERR,

  SEFAZ_CE_DTE_MESSAGE_SAVE_BEGIN,
  SEFAZ_CE_DTE_MESSAGE_SAVE_SUCCESS,
  SEFAZ_CE_DTE_MESSAGE_SAVE_ERR,
} = actions;

const sefazCeDteMessageReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEFAZ_CE_DTE_MESSAGE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEFAZ_CE_DTE_MESSAGE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGE_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEFAZ_CE_DTE_MESSAGE_CLEANUP_SUCCESS:
      return initialSingleState;

    case SEFAZ_CE_DTE_MESSAGE_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEFAZ_CE_DTE_MESSAGE_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case SEFAZ_CE_DTE_MESSAGE_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case SEFAZ_CE_DTE_MESSAGE_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { sefazCeDteMessagesReducer, sefazCeDteMessagesListReducer, sefazCeDteMessageReducer };
