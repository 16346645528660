const actions = {
  CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_BEGIN: 'CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_BEGIN',
  CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_SUCCESS: 'CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_SUCCESS',
  CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_ERR: 'CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_ERR',

  clientCnpjMunicipalRegistrationStatusesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_BEGIN,
    };
  },

  clientCnpjMunicipalRegistrationStatusesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientCnpjMunicipalRegistrationStatusesListUpdateErr: err => {
    return {
      type: actions.CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_BEGIN: 'CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_BEGIN',
  CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_SUCCESS: 'CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_SUCCESS',
  CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_ERR: 'CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_ERR',

  clientCnpjMunicipalRegistrationStatusesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_BEGIN,
    };
  },

  clientCnpjMunicipalRegistrationStatusesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientCnpjMunicipalRegistrationStatusesListCleanupErr: err => {
    return {
      type: actions.CLIENT_CNPJ_MUNICIPAL_REGISTRATION_STATUSES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  