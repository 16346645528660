const actions = {
  CLIENT_FILES_PAGINATION_UPDATE_BEGIN: 'CLIENT_FILES_PAGINATION_UPDATE_BEGIN',
  CLIENT_FILES_PAGINATION_UPDATE_SUCCESS: 'CLIENT_FILES_PAGINATION_UPDATE_SUCCESS',
  CLIENT_FILES_PAGINATION_UPDATE_ERR: 'CLIENT_FILES_PAGINATION_UPDATE_ERR',

  clientFilesPaginationUpdateBegin: () => {
    return {
      type: actions.CLIENT_FILES_PAGINATION_UPDATE_BEGIN,
    };
  },

  clientFilesPaginationUpdateSuccess: data => {
    return {
      type: actions.CLIENT_FILES_PAGINATION_UPDATE_SUCCESS,
      data,
    };
  },

  clientFilesPaginationUpdateErr: err => {
    return {
      type: actions.CLIENT_FILES_PAGINATION_UPDATE_ERR,
      err,
    };
  },

  CLIENT_FILES_FILTERS_UPDATE_BEGIN: 'CLIENT_FILES_FILTERS_UPDATE_BEGIN',
  CLIENT_FILES_FILTERS_UPDATE_SUCCESS: 'CLIENT_FILES_FILTERS_UPDATE_SUCCESS',
  CLIENT_FILES_FILTERS_UPDATE_ERR: 'CLIENT_FILES_FILTERS_UPDATE_ERR',

  clientFilesFiltersUpdateBegin: () => {
    return {
      type: actions.CLIENT_FILES_FILTERS_UPDATE_BEGIN,
    };
  },

  clientFilesFiltersUpdateSuccess: data => {
    return {
      type: actions.CLIENT_FILES_FILTERS_UPDATE_SUCCESS,
      data,
    };
  },

  clientFilesFiltersUpdateErr: err => {
    return {
      type: actions.CLIENT_FILES_FILTERS_UPDATE_ERR,
      err,
    };
  },

  CLIENT_FILES_SORTER_UPDATE_BEGIN: 'CLIENT_FILES_SORTER_UPDATE_BEGIN',
  CLIENT_FILES_SORTER_UPDATE_SUCCESS: 'CLIENT_FILES_SORTER_UPDATE_SUCCESS',
  CLIENT_FILES_SORTER_UPDATE_ERR: 'CLIENT_FILES_SORTER_UPDATE_ERR',

  clientFilesSorterUpdateBegin: () => {
    return {
      type: actions.CLIENT_FILES_SORTER_UPDATE_BEGIN,
    };
  },

  clientFilesSorterUpdateSuccess: data => {
    return {
      type: actions.CLIENT_FILES_SORTER_UPDATE_SUCCESS,
      data,
    };
  },

  clientFilesSorterUpdateErr: err => {
    return {
      type: actions.CLIENT_FILES_SORTER_UPDATE_ERR,
      err,
    };
  },

  CLIENT_FILES_UPDATE_BEGIN: 'CLIENT_FILES_UPDATE_BEGIN',
  CLIENT_FILES_UPDATE_SUCCESS: 'CLIENT_FILES_UPDATE_SUCCESS',
  CLIENT_FILES_UPDATE_ERR: 'CLIENT_FILES_UPDATE_ERR',

  clientFilesUpdateBegin: () => {
    return {
      type: actions.CLIENT_FILES_UPDATE_BEGIN,
    };
  },

  clientFilesUpdateSuccess: data => {
    return {
      type: actions.CLIENT_FILES_UPDATE_SUCCESS,
      data,
    };
  },

  clientFilesUpdateErr: err => {
    return {
      type: actions.CLIENT_FILES_UPDATE_ERR,
      err,
    };
  },

  CLIENT_FILES_CLEANUP_BEGIN: 'CLIENT_FILES_CLEANUP_BEGIN',
  CLIENT_FILES_CLEANUP_SUCCESS: 'CLIENT_FILES_CLEANUP_SUCCESS',
  CLIENT_FILES_CLEANUP_ERR: 'CLIENT_FILES_CLEANUP_ERR',

  clientFilesCleanupBegin: () => {
    return {
      type: actions.CLIENT_FILES_CLEANUP_BEGIN,
    };
  },

  clientFilesCleanupSuccess: () => {
    return {
      type: actions.CLIENT_FILES_CLEANUP_SUCCESS,
    };
  },

  clientFilesCleanupErr: err => {
    return {
      type: actions.CLIENT_FILES_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_FILES_LIST_UPDATE_BEGIN: 'CLIENT_FILES_LIST_UPDATE_BEGIN',
  CLIENT_FILES_LIST_UPDATE_SUCCESS: 'CLIENT_FILES_LIST_UPDATE_SUCCESS',
  CLIENT_FILES_LIST_UPDATE_ERR: 'CLIENT_FILES_LIST_UPDATE_ERR',

  clientFilesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_FILES_LIST_UPDATE_BEGIN,
    };
  },

  clientFilesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_FILES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientFilesListUpdateErr: err => {
    return {
      type: actions.CLIENT_FILES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_FILES_LIST_CLEANUP_BEGIN: 'CLIENT_FILES_LIST_CLEANUP_BEGIN',
  CLIENT_FILES_LIST_CLEANUP_SUCCESS: 'CLIENT_FILES_LIST_CLEANUP_SUCCESS',
  CLIENT_FILES_LIST_CLEANUP_ERR: 'CLIENT_FILES_LIST_CLEANUP_ERR',

  clientFilesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_FILES_LIST_CLEANUP_BEGIN,
    };
  },

  clientFilesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_FILES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientFilesListCleanupErr: err => {
    return {
      type: actions.CLIENT_FILES_LIST_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_FILE_DELETE_BEGIN: 'CLIENT_FILE_DELETE_BEGIN',
  CLIENT_FILE_DELETE_SUCCESS: 'CLIENT_FILE_DELETE_SUCCESS',
  CLIENT_FILE_DELETE_ERR: 'CLIENT_FILE_DELETE_ERR',

  clientFileDeleteBegin: () => {
    return {
      type: actions.CLIENT_FILE_DELETE_BEGIN,
    };
  },

  clientFileDeleteSuccess: () => {
    return {
      type: actions.CLIENT_FILE_DELETE_SUCCESS,
    };
  },

  clientFileDeleteErr: err => {
    return {
      type: actions.CLIENT_FILE_DELETE_ERR,
      err,
    };
  },

  CLIENT_FILE_UPDATE_BEGIN: 'CLIENT_FILE_UPDATE_BEGIN',
  CLIENT_FILE_UPDATE_SUCCESS: 'CLIENT_FILE_UPDATE_SUCCESS',
  CLIENT_FILE_UPDATE_ERR: 'CLIENT_FILE_UPDATE_ERR',

  clientFileUpdateBegin: () => {
    return {
      type: actions.CLIENT_FILE_UPDATE_BEGIN,
    };
  },

  clientFileUpdateSuccess: data => {
    return {
      type: actions.CLIENT_FILE_UPDATE_SUCCESS,
      data,
    };
  },

  clientFileUpdateErr: err => {
    return {
      type: actions.CLIENT_FILE_UPDATE_ERR,
      err,
    };
  },

  CLIENT_FILE_CLEANUP_BEGIN: 'CLIENT_FILE_CLEANUP_BEGIN',
  CLIENT_FILE_CLEANUP_SUCCESS: 'CLIENT_FILE_CLEANUP_SUCCESS',
  CLIENT_FILE_CLEANUP_ERR: 'CLIENT_FILE_CLEANUP_ERR',

  clientFileCleanupBegin: () => {
    return {
      type: actions.CLIENT_FILE_CLEANUP_BEGIN,
    };
  },

  clientFileCleanupSuccess: () => {
    return {
      type: actions.CLIENT_FILE_CLEANUP_SUCCESS,
    };
  },

  clientFileCleanupErr: err => {
    return {
      type: actions.CLIENT_FILE_CLEANUP_ERR,
      err,
    };
  },

  CLIENT_FILE_SAVE_BEGIN: 'CLIENT_FILE_SAVE_BEGIN',
  CLIENT_FILE_SAVE_SUCCESS: 'CLIENT_FILE_SAVE_SUCCESS',
  CLIENT_FILE_SAVE_ERR: 'CLIENT_FILE_SAVE_ERR',

  clientFileSaveBegin: () => {
    return {
      type: actions.CLIENT_FILE_SAVE_BEGIN,
    };
  },

  clientFileSaveSuccess: data => {
    return {
      type: actions.CLIENT_FILE_SAVE_SUCCESS,
      data,
    };
  },

  clientFileSaveErr: err => {
    return {
      type: actions.CLIENT_FILE_SAVE_ERR,
      err,
    };
  },
};

export default actions;
