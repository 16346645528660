const actions = {
  CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_BEGIN: 'CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_BEGIN',
  CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_SUCCESS: 'CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_SUCCESS',
  CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_ERR: 'CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_ERR',

  clientPartnerActSignersListUpdateBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_BEGIN,
    };
  },

  clientPartnerActSignersListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientPartnerActSignersListUpdateErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SIGNERS_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_BEGIN: 'CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_BEGIN',
  CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_SUCCESS: 'CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_SUCCESS',
  CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_ERR: 'CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_ERR',

  clientPartnerActSignersListCleanupBegin: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_BEGIN,
    };
  },

  clientPartnerActSignersListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_SUCCESS,
    };
  },

  clientPartnerActSignersListCleanupErr: err => {
    return {
      type: actions.CLIENT_PARTNER_ACT_SIGNERS_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
  