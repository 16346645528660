const actions = {
  CARD_JUSTIFICATIONS_UPDATE_BEGIN: 'CARD_JUSTIFICATIONS_UPDATE_BEGIN',
  CARD_JUSTIFICATIONS_UPDATE_SUCCESS: 'CARD_JUSTIFICATIONS_UPDATE_SUCCESS',
  CARD_JUSTIFICATIONS_UPDATE_ERR: 'CARD_JUSTIFICATIONS_UPDATE_ERR',

  cardJustificationsUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATIONS_UPDATE_BEGIN,
    };
  },

  cardJustificationsUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATIONS_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationsUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATIONS_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATIONS_CLEANUP_BEGIN: 'CARD_JUSTIFICATIONS_CLEANUP_BEGIN',
  CARD_JUSTIFICATIONS_CLEANUP_SUCCESS: 'CARD_JUSTIFICATIONS_CLEANUP_SUCCESS',
  CARD_JUSTIFICATIONS_CLEANUP_ERR: 'CARD_JUSTIFICATIONS_CLEANUP_ERR',

  cardJustificationsCleanupBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATIONS_CLEANUP_BEGIN,
    };
  },

  cardJustificationsCleanupSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATIONS_CLEANUP_SUCCESS,
      data,
    };
  },

  cardJustificationsCleanupErr: err => {
    return {
      type: actions.CARD_JUSTIFICATIONS_CLEANUP_ERR,
      err,
    };
  },

  CARD_JUSTIFICATIONS_UPDATE_STATUS_BEGIN: 'CARD_JUSTIFICATIONS_UPDATE_STATUS_BEGIN',
  CARD_JUSTIFICATIONS_UPDATE_STATUS_SUCCESS: 'CARD_JUSTIFICATIONS_UPDATE_STATUS_SUCCESS',
  CARD_JUSTIFICATIONS_UPDATE_STATUS_ERR: 'CARD_JUSTIFICATIONS_UPDATE_STATUS_ERR',

  cardJustificationsUpdateStatusBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATIONS_UPDATE_STATUS_BEGIN,
    };
  },

  cardJustificationsUpdateStatusSuccess: () => {
    return {
      type: actions.CARD_JUSTIFICATIONS_UPDATE_STATUS_SUCCESS,
    };
  },

  cardJustificationsUpdateStatusErr: err => {
    return {
      type: actions.CARD_JUSTIFICATIONS_UPDATE_STATUS_ERR,
      err,
    };
  },

  CARD_JUSTIFICATIONS_SAVE_MANY_BEGIN: 'CARD_JUSTIFICATIONS_SAVE_MANY_BEGIN',
  CARD_JUSTIFICATIONS_SAVE_MANY_SUCCESS: 'CARD_JUSTIFICATIONS_SAVE_MANY_SUCCESS',
  CARD_JUSTIFICATIONS_SAVE_MANY_ERR: 'CARD_JUSTIFICATIONS_SAVE_MANY_ERR',

  cardJustificationsSaveManyBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATIONS_SAVE_MANY_BEGIN,
    };
  },

  cardJustificationsSaveManySuccess: () => {
    return {
      type: actions.CARD_JUSTIFICATIONS_SAVE_MANY_SUCCESS,
    };
  },

  cardJustificationsSaveManyErr: err => {
    return {
      type: actions.CARD_JUSTIFICATIONS_SAVE_MANY_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_UPDATE_BEGIN: 'CARD_JUSTIFICATION_UPDATE_BEGIN',
  CARD_JUSTIFICATION_UPDATE_SUCCESS: 'CARD_JUSTIFICATION_UPDATE_SUCCESS',
  CARD_JUSTIFICATION_UPDATE_ERR: 'CARD_JUSTIFICATION_UPDATE_ERR',

  cardJustificationUpdateBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_UPDATE_BEGIN,
    };
  },

  cardJustificationUpdateSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_UPDATE_SUCCESS,
      data,
    };
  },

  cardJustificationUpdateErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_UPDATE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_CLEANUP_BEGIN: 'CARD_JUSTIFICATION_CLEANUP_BEGIN',
  CARD_JUSTIFICATION_CLEANUP_SUCCESS: 'CARD_JUSTIFICATION_CLEANUP_SUCCESS',
  CARD_JUSTIFICATION_CLEANUP_ERR: 'CARD_JUSTIFICATION_CLEANUP_ERR',

  cardJustificationCleanupBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_CLEANUP_BEGIN,
    };
  },

  cardJustificationCleanupSuccess: () => {
    return {
      type: actions.CARD_JUSTIFICATION_CLEANUP_SUCCESS,
    };
  },

  cardJustificationCleanupErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_CLEANUP_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_SAVE_BEGIN: 'CARD_JUSTIFICATION_SAVE_BEGIN',
  CARD_JUSTIFICATION_SAVE_SUCCESS: 'CARD_JUSTIFICATION_SAVE_SUCCESS',
  CARD_JUSTIFICATION_SAVE_ERR: 'CARD_JUSTIFICATION_SAVE_ERR',

  cardJustificationSaveBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_SAVE_BEGIN,
    };
  },

  cardJustificationSaveSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_SAVE_SUCCESS,
      data,
    };
  },

  cardJustificationSaveErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_SAVE_ERR,
      err,
    };
  },

  CARD_JUSTIFICATION_DELETE_BEGIN: 'CARD_JUSTIFICATION_DELETE_BEGIN',
  CARD_JUSTIFICATION_DELETE_SUCCESS: 'CARD_JUSTIFICATION_DELETE_SUCCESS',
  CARD_JUSTIFICATION_DELETE_ERR: 'CARD_JUSTIFICATION_DELETE_ERR',

  cardJustificationDeleteBegin: () => {
    return {
      type: actions.CARD_JUSTIFICATION_DELETE_BEGIN,
    };
  },

  cardJustificationDeleteSuccess: data => {
    return {
      type: actions.CARD_JUSTIFICATION_DELETE_SUCCESS,
      data,
    };
  },

  cardJustificationDeleteErr: err => {
    return {
      type: actions.CARD_JUSTIFICATION_DELETE_ERR,
      err,
    };
  },
};

export default actions;
