import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const EconomicGroupsIndex = lazy(() => import('../../container/economicGroups/Index'));
const NotFound = lazy(() => import('../../container/pages/404'));

const EconomicGroupsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={path} component={EconomicGroupsIndex} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default EconomicGroupsRoute;
