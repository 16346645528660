import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  error: null,
};

const {
  CLIENT_PARTNER_ACTS_UPDATE_BEGIN,
  CLIENT_PARTNER_ACTS_UPDATE_SUCCESS,
  CLIENT_PARTNER_ACTS_UPDATE_ERR,

  CLIENT_PARTNER_ACTS_CLEANUP_BEGIN,
  CLIENT_PARTNER_ACTS_CLEANUP_SUCCESS,
  CLIENT_PARTNER_ACTS_CLEANUP_ERR,

  CLIENT_PARTNER_ACT_DELETE_BEGIN,
  CLIENT_PARTNER_ACT_DELETE_SUCCESS,
  CLIENT_PARTNER_ACT_DELETE_ERR,
} = actions;

const clientPartnerActsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_PARTNER_ACTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_PARTNER_ACTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_PARTNER_ACTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_PARTNER_ACTS_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_PARTNER_ACTS_CLEANUP_SUCCESS:
      return initialState;

    case CLIENT_PARTNER_ACTS_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_PARTNER_ACT_DELETE_BEGIN:
      return {
        ...state,
        deleting: true,
      };

    case CLIENT_PARTNER_ACT_DELETE_SUCCESS:
      return {
        ...state,
        data,
        deleting: false,
      };

    case CLIENT_PARTNER_ACT_DELETE_ERR:
      return {
        ...state,
        error: err,
        deleting: false,
      };

    default:
      return state;
  }
};

const initialSingleState = {
  data: undefined,
  loading: false,
  saving: false,
  error: null,
};

const {
  CLIENT_PARTNER_ACT_UPDATE_BEGIN,
  CLIENT_PARTNER_ACT_UPDATE_SUCCESS,
  CLIENT_PARTNER_ACT_UPDATE_ERR,

  CLIENT_PARTNER_ACT_CLEANUP_BEGIN,
  CLIENT_PARTNER_ACT_CLEANUP_SUCCESS,
  CLIENT_PARTNER_ACT_CLEANUP_ERR,

  CLIENT_PARTNER_ACT_SAVE_BEGIN,
  CLIENT_PARTNER_ACT_SAVE_SUCCESS,
  CLIENT_PARTNER_ACT_SAVE_ERR,
} = actions;

const clientPartnerActReducer = (state = initialSingleState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLIENT_PARTNER_ACT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_PARTNER_ACT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };

    case CLIENT_PARTNER_ACT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_PARTNER_ACT_CLEANUP_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_PARTNER_ACT_CLEANUP_SUCCESS:
      return initialSingleState;

    case CLIENT_PARTNER_ACT_CLEANUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_PARTNER_ACT_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
      };

    case CLIENT_PARTNER_ACT_SAVE_SUCCESS:
      return {
        ...state,
        data,
        saving: false,
      };

    case CLIENT_PARTNER_ACT_SAVE_ERR:
      return {
        ...state,
        error: err,
        saving: false,
      };

    default:
      return state;
  }
};

export { clientPartnerActsReducer, clientPartnerActReducer };
